import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HexColorPicker } from "react-colorful";
import { change, formValueSelector } from 'redux-form';

import withStyles from 'isomorphic-style-loader/withStyles';
import { Modal } from 'react-bootstrap';
import cx from 'classnames';

import s from './ColorPickerModal.css';
import { closeColorModal } from '../../../../actions/siteadmin/modalActions';


class ColorPickerModal extends React.Component {
    static propTypes = {
        closeLogcloseColorModalinModal: PropTypes.func,
        isOpenColor: PropTypes.bool,
        formatMessage: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            colorModalStatus: false,
            colorValue: ''
        };
        this.changeColor = this.changeColor.bind(this);
    }

    componentDidMount() {
        const { isOpenColor, color } = this.props;
        if (isOpenColor) {
            this.setState({ colorModalStatus: true, colorValue: color })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isOpenColor, color } = nextProps;
        if (isOpenColor) {
            this.setState({ colorModalStatus: true, colorValue: color })
        } else {
            this.setState({ colorModalStatus: false, colorValue: color })
        }
    }

    handleClick() {
        const { closeColorModal } = this.props;
        dispatch(closeColorModal());
    }

    async changeColor(fieldName, value) {
        const { change, formName } = this.props;
        this.setState({
            colorValue: value
        })
        await change(formName, fieldName, value);
    }

    render() {
        const { closeColorModal, fieldName, color } = this.props;
        const { colorModalStatus } = this.state;
        return (
            <div >
                <Modal show={colorModalStatus} animation={false} onHide={closeColorModal} className={cx('eatsLoginModal', 'logonMargin', 'colorPickerModal')}>
                    <div className={s.sectionBlock}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <HexColorPicker color={color} onChange={(e) => {
                                this.changeColor(fieldName, e);
                            }} />
                            <div className={s.colorBg}>{this.state.colorValue}</div>
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }
}

const selector = formValueSelector('AddDeliveryTypeForm');

const mapState = state => ({
    isOpenColor: state.adminModalStatus.isOpenColor,
    fieldName: state.adminModalStatus.fieldName,
    color: state.adminModalStatus.color,
})

const mapDispatch = {
    closeColorModal,
    change
};

export default withStyles(s)(connect(mapState, mapDispatch)(ColorPickerModal))