import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Home.css';

//Components
import CitySection from '../../components/Home/CitySection/CitySection';
import HomeBanner from '../../components/Home/HomeBanner/HomeBanner';
import HomePartners from '../../components/Home/HomePartners/HomePartners';
import EatsMobileApp from '../../components/Home/EatsMobileApp/EatsMobileApp';
import DriverMobileApp from '../../components/Home/DriverMobileApp/DriverMobileApp';
import StaticImage from '../../components/Home/StaticImage/StaticImage';
import Loader from '../../components/Common/Loader';

// Graphql
import getAllHomePageSettings from './getAllHomePageSettings.graphql';
class Home extends React.Component {

  getHomeSettingName = (name) => {
    const { getAllHomePageSettings: { getAllHomePageSettings } } = this.props;
    const setting = Array.isArray(getAllHomePageSettings?.homePageData) && getAllHomePageSettings.homePageData.find(item => item.name === name);
    return (setting && setting.value);
  }

  render() {
    const { getAllHomePageSettings: { loading } } = this.props;
    return (
      <>
        {
          !loading ? <>
            <div className={s.overFlowHidden}>
              <HomeBanner getSettings={this.getHomeSettingName} />
              <HomePartners getSettings={this.getHomeSettingName} />
              <EatsMobileApp getSettings={this.getHomeSettingName} />
              <DriverMobileApp getSettings={this.getHomeSettingName} />
              <CitySection getSettings={this.getHomeSettingName} />
              <StaticImage getSettings={this.getHomeSettingName} />
            </div>
          </> : <Loader type={"text"} />
        }
      </>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllHomePageSettings, {
    name: 'getAllHomePageSettings',
    options: {
      ssr: true,
    },
  }),
)(Home);