import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditContentPage.css';
import cx from 'classnames';
import { graphql } from 'react-apollo';
import editContentPage from './editContentPage.graphql';
import AddContentPageForm from '../../../../components/SiteAdmin/ContentPage/AddContentPageForm';
import Loader from '../../../../components/Common/Loader/Loader';

//meassage
import { FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

export class EditContentPage extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }

  static defaultProps = {
    data: {
      loading: true
    }
  }

  render() {
    const { title, editContentPageData: { loading, editContentPage } } = this.props;

    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={cx(s.heading, 'textAlignRightRTL')}>
            <FormattedMessage {...messages.EditContentDetails} />
          </div>
          <div>
            <Loader type={"page"} show={loading}>
              {
                !loading && <AddContentPageForm
                  title={title} initialValues={editContentPage}
                />
              }
            </Loader>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default compose(
  withStyles(s),
  graphql(editContentPage, {
    name: 'editContentPageData',
    options: (props) => ({
      variables: {
        id: props.id,
      },
      fetchPolicy: 'network-only'
    })
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(EditContentPage)