import { defineMessages } from 'react-intl';

const messages = defineMessages({
    brand: {
        id: 'app.brand',
        defaultMessage: 'RadicalStart',
        description: 'Brand name displayed in header',
    },
    about: {
        id: 'app.about',
        defaultMessage: 'About',
        description: 'About Nav link',
    },
    login: {
        id: 'app.login',
        defaultMessage: 'Login',
        description: 'Login Nav link',
    },
    cantSignIn: {
        id: 'app.cantSignIn',
        defaultMessage: "Can't Sign In?",
        description: 'Signup ',
    },
    signup: {
        id: 'app.signup',
        defaultMessage: 'Sign up',
        description: 'Sign up Nav link',
    },
    signin: {
        id: 'app.signin',
        defaultMessage: 'Sign in',
        description: 'Sign in Nav link',
    },
    next: {
        id: 'app.next',
        defaultMessage: "Next",
        description: 'Email Login',
    },
    contact: {
        id: 'app.contact',
        defaultMessage: 'Contact',
        description: 'Contact Nav link',
    },
    bannerTitle: {
        id: 'app.bannerTitle',
        defaultMessage: 'React',
        description: 'Title in page header',
    },
    logo: {
        id: 'app.logo',
        defaultMessage: 'Logo',
        description: 'Logo'
    },
    siteSettings: {
        id: 'app.siteSettings',
        defaultMessage: 'Site Settings',
        description: 'Site Settings'
    },
    restuarantHomepageSettings: {
        id: 'app.restuarantHomepageSettings',
        defaultMessage: 'Store Settings',
        description: 'restuarantHomepageSettings'
    },
    HomeSiteSettings: {
        id: 'app.HomeSiteSettings',
        defaultMessage: 'Homepage Settings',
        description: 'HomeSiteSettings'
    },
    driverRatings: {
        id: 'app.driverRatings',
        defaultMessage: 'Driver Ratings',
        description: 'Driver Ratings'
    },
    messageRequired: {
        id: 'app.messageRequired',
        defaultMessage: 'Message is required',
        description: 'Message is required'
    },
    messageLength: {
        id: 'app.messageLength',
        defaultMessage: 'Message must be 200 characters or less',
        description: 'Message must be 200 characters or less'
    },
    metaKeyword: {
        id: 'app.metaKeyword',
        defaultMessage: 'Keyword must be 255 characters or less',
        description: 'Keyword must be 255 characters or less'
    },
    metaTitle: {
        id: 'app.metaTitle',
        defaultMessage: 'Meta Title',
        description: 'Meta Title'
    },
    metaDescriptionText: {
        id: 'app.metaDescriptionText',
        defaultMessage: 'Meta Description',
        description: 'Meta Description'
    },
    metaDescription: {
        id: 'app.metaDescription',
        defaultMessage: 'Description must be 255 characters or less',
        description: 'Description must be 255 characters or less'
    },
    pageTitle: {
        id: 'app.pageTitle',
        defaultMessage: 'Page Title',
        description: 'Page Title'
    },
    pageUrl: {
        id: 'app.pageUrl',
        defaultMessage: 'Page URL',
        description: 'Page URL'
    },
    invalidUrl: {
        id: 'app.invalidUrl',
        defaultMessage: 'Invalid Page URL',
        description: 'Invalid Page URL'
    },
    addPage: {
        id: 'app.addPage',
        defaultMessage: 'Add Page',
        description: 'Add Page'
    },
    ratings: {
        id: 'app.ratings',
        defaultMessage: 'Reviews',
        description: 'Reviews'
    },
    reasonRequired: {
        id: 'app.reasonRequired',
        defaultMessage: 'Reason is required',
        description: 'Reason is required'
    },
    exceedLimit100: {
        id: 'app.exceedLimit100',
        defaultMessage: 'Maximum limit 100 characters',
        description: 'Maximum limit 100 characters'
    },
    exceedLimit50: {
        id: 'app.exceedLimit50',
        defaultMessage: 'Maximum limit 50 characters',
        description: 'Maximum limit 50 characters'
    },
    exceedLimit200: {
        id: 'app.exceedLimit200',
        defaultMessage: 'Maximum limit 200 characters',
        description: 'Maximum limit 200 characters'
    },
    exceedLimit: {
        id: 'app.exceedLimit',
        defaultMessage: 'Exceeding character limit',
        description: 'Exceeding character limit'
    },
    comments: {
        id: 'app.comments',
        defaultMessage: 'Comments',
        description: 'Comments'
    },
    cancellationReasons: {
        id: 'app.cancellationReasons',
        defaultMessage: 'Cancellation Reasons',
        description: 'Cancellation Reasons'
    },
    cancelReason: {
        id: 'app.cancelReason',
        defaultMessage: 'Cancellation Reason',
        description: 'Cancel Reason'
    },
    cancelledBy: {
        id: 'app.cancelledBy',
        defaultMessage: 'Reason For',
        description: 'Cancelled By'
    },
    cancellationType: {
        id: 'app.cancellationType',
        defaultMessage: 'Type of Cancellation',
        description: 'Cancellation Type'
    },
    tripDate: {
        id: 'app.tripDate',
        defaultMessage: 'Dates',
        description: 'Trip Date'
    },
    changePassword: {
        id: 'app.changePassword',
        defaultMessage: 'Change Password',
        description: 'Change Password'
    },
    save: {
        id: 'app.save',
        defaultMessage: "Save",
        description: 'Save',
    },
    endTimeValidateMessage: {
        id: 'app.endTimeValidateMessage',
        defaultMessage: "End Time must be higher than Start Time.",
        description: 'End Time must be more than Start Time',
    },
    addCancelReason: {
        id: 'app.addCancelReason',
        defaultMessage: 'Add Cancel Reason',
        description: 'Add Cancel Reason'
    },
    editCancelReason: {
        id: 'app.editCancelReason',
        defaultMessage: 'Edit Cancel Reason',
        description: 'Edit Cancel Reason'
    },
    reason: {
        id: 'app.reason',
        defaultMessage: 'Reason',
        description: 'Reason'
    },
    manageNotifications: {
        id: 'app.manageNotifications',
        defaultMessage: 'Manage Notifications',
        description: 'Manage Notifications'
    },
    bannerDesc: {
        id: 'app.bannerDesc',
        defaultMessage: 'Complex web apps made easy',
        description: 'Description in header',
    },
    recommand: {
        id: 'app.recommand',
        defaultMessage: 'Recommand',
        description: 'recommand',
    },
    sliderContent: {
        id: 'app.sliderContent',
        defaultMessage: 'Luxury',
        description: 'Luxury',
    },
    heading: {
        id: 'app.heading',
        defaultMessage: 'Heading',
        description: 'heading',
    },
    dummyText: {
        id: 'app.dummyText',
        defaultMessage: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        description: 'dummyText',
    },
    rentallContent: {
        id: 'app.rentallContent',
        defaultMessage: 'RentALL',
        description: 'RentALL',
    },
    aboutFooter: {
        id: 'app.aboutFooter',
        defaultMessage: 'About Us',
        description: 'AboutFooter',
    },
    contactUs: {
        id: 'app.contactUs',
        defaultMessage: 'Contact Us',
        description: 'contact',
    },
    Trust: {
        id: 'app.Trust',
        defaultMessage: 'Trust & Safety',
        description: 'Trust',
    },
    Travel: {
        id: 'app.Travel',
        defaultMessage: 'Travel Credit',
        description: 'Trust',
    },
    Discover: {
        id: 'app.Discover',
        defaultMessage: 'Discover',
        description: 'Discover',
    },
    Hosting: {
        id: 'app.Hosting',
        defaultMessage: 'Hosting',
        description: 'Hosting',
    },
    Becomeahost: {
        id: 'app.Becomeahost',
        defaultMessage: 'Become a host',
        description: 'Becomeahost',
    },
    Terms: {
        id: 'app.Terms',
        defaultMessage: 'Terms & Privacy',
        description: 'Terms',
    },
    adminpanel: {
        id: 'app.adminpanel',
        defaultMessage: 'Admin Panel',
        description: 'admin panel',
    },
    dashboard: {
        id: 'app.dashboard',
        defaultMessage: 'Dashboard',
        description: 'dashboard',
    },
    settings: {
        id: 'app.settings',
        defaultMessage: 'Settings',
        description: 'settings',
    },
    formssidebar: {
        id: 'app.formssidebar',
        defaultMessage: 'Forms',
        description: 'formssidebar',
    },
    email: {
        id: 'app.emailaddress',
        defaultMessage: 'Email Address',
        description: 'emailaddress',
    },
    emailaddress: {
        id: 'app.emailaddress',
        defaultMessage: 'Email Address',
        description: 'emailaddress',
    },
    password: {
        id: 'app.password',
        defaultMessage: 'Password',
        description: 'password',
    },
    currentPassword: {
        id: 'app.currentPassword',
        defaultMessage: 'Current Password',
        description: 'Current Password',
    },
    newPassword: {
        id: 'app.newPassword',
        defaultMessage: 'New Password',
        description: 'New Password',
    },
    confirmNewPassword: {
        id: 'app.confirmNewPassword',
        defaultMessage: 'Confirm New Password',
        description: 'Confirm New Password',
    },
    select: {
        id: 'app.select',
        defaultMessage: 'Select',
        description: 'select',
    },
    textarea: {
        id: 'app.textarea',
        defaultMessage: 'Text Area',
        description: 'textarea',
    },
    firstName: {
        id: 'app.firstName',
        defaultMessage: 'First Name',
        description: 'firstName',
    },
    lastName: {
        id: 'app.lastName',
        defaultMessage: 'Last Name',
        description: 'lastName',
    },
    phoneNumber: {
        id: 'app.phoneNumber',
        defaultMessage: 'Phone Number',
        description: 'phoneNumber',
    },
    profilePicture: {
        id: 'app.profilePicture',
        defaultMessage: 'Profile Picture',
        description: 'profilePicture',
    },
    licenceFrontImage: {
        id: 'app.licenceFrontImage',
        defaultMessage: 'Licence Front Image',
        description: 'licenceFrontImage'
    },
    licenceBackImage: {
        id: 'app.licenceBackImage',
        defaultMessage: 'Licence Back Image',
        description: 'licenceBackImage'
    },
    rcImage: {
        id: 'app.rcImage',
        defaultMessage: 'RC Book Image',
        description: 'rcImage'
    },
    insuranceImage: {
        id: 'app.insuranceImage',
        defaultMessage: 'Insurance Image',
        description: 'insuranceImage'
    },
    required: {
        id: 'app.required',
        defaultMessage: '*Required',
        description: 'required error message',
    },
    invalidFee: {
        id: 'app.invalidFee',
        defaultMessage: 'Invalid Fee',
        description: 'required error message',
    },
    emailRequired: {
        id: 'app.emailRequired',
        defaultMessage: 'Email is required',
        description: 'Email is required',
    },
    emailInvalid: {
        id: 'app.emailInValid',
        defaultMessage: 'Invalid Email Address',
        description: 'Invalid Email Address',
    },
    emailNotExists: {
        id: 'app.emailNotExists',
        defaultMessage: 'No account exists for this email. Make sure it\'s typed in correctly, or “sign up” instead',
        description: 'Email Address is not exist',
    },
    passwordRequired: {
        id: 'app.passwordRequired',
        defaultMessage: 'Password is required',
        description: 'Password is required',
    },
    passwordInvalid: {
        id: 'app.passwordInValid',
        defaultMessage: 'Your password must be at least 8 characters. Please try again',
        description: 'Invalid Password',
    },
    passwordWrong: {
        id: 'app.passwordWrong',
        defaultMessage: 'Wrong Password. Please try again',
        description: 'Wrong Password',
    },
    operationHours: {
        id: 'app.operationHours',
        defaultMessage: 'Operating Hours',
        description: 'Operation Hours',
    },
    somethingWentWrong: {
        id: 'app.somethingWentWrong',
        defaultMessage: 'Sorry, something went wrong. Please try again',
        description: 'Wrong went wrong',
    },
    loggedIn: {
        id: 'app.loggedIn',
        defaultMessage: 'You are already logged in!',
        description: 'You are already Logged-in',
    },
    storeLoggedIn: {
        id: 'app.storeLoggedIn',
        defaultMessage: 'You are already logged in as Store user!',
        description: 'You are already logged in as Store user!',
    },
    adminLoggedIn: {
        id: 'app.adminLoggedIn',
        defaultMessage: 'You are already logged in as Admin user!',
        description: 'You are already logged in as Admin user!',
    },
    submitButton: {
        id: 'app.submitButton',
        defaultMessage: 'Submit',
        description: 'Submit',
    },
    cancelButton: {
        id: 'app.cancelButton',
        defaultMessage: 'Cancel',
        description: 'Cancel',
    },
    table: {
        id: 'app.table',
        defaultMessage: 'Table',
        description: 'Table',
    },
    form: {
        id: 'app.form',
        defaultMessage: 'Form',
        description: 'form',
    },
    menuOne: {
        id: 'app.menuOne',
        defaultMessage: 'MenuOne',
        description: 'form',
    },
    menuTwo: {
        id: 'app.menuTwo',
        defaultMessage: 'MenuTwo',
        description: 'MenuTwo',
    },
    menuFour: {
        id: 'app.menuFour',
        defaultMessage: 'MenuFour',
        description: 'menuFour',
    },
    subMenuOne: {
        id: 'app.subMenuOne',
        defaultMessage: 'subMenuOne',
        description: 'subMenuOne',
    },
    subMenuTwo: {
        id: 'app.subMenuTwo',
        defaultMessage: 'subMenuTwo',
        description: 'subMenuTwo',
    },
    subMenuThree: {
        id: 'app.subMenuThree',
        defaultMessage: 'subMenuThree',
        description: 'subMenuThree',
    },
    siteAdmin: {
        id: 'app.siteAdmin',
        defaultMessage: 'Site admin',
        description: 'siteAdmin',
    },
    usersMenu: {
        id: 'app.usersMenu',
        defaultMessage: 'Manage users',
        description: 'usersMenu',
    },
    driverMenu: {
        id: 'app.driverMenu',
        defaultMessage: 'Manage drivers',
        description: 'driverMenu',
    },
    vehiclesMenu: {
        id: 'app.vehiclesMenu',
        defaultMessage: 'Manage vehicles',
        description: 'vehiclesMenu',
    },
    dietaryType: {
        id: 'app.dietaryType',
        defaultMessage: 'Dietary Type',
        description: 'Dietary Type'
    },
    priceRange: {
        id: 'app.priceRange',
        defaultMessage: 'Price Range',
        description: 'Price Range'
    },
    categoryMenu: {
        id: 'app.categoryMenu',
        defaultMessage: 'Manage Category',
        description: 'categoryMenu',
    },
    bookingsMenu: {
        id: 'app.bookingsMenu',
        defaultMessage: 'Manage Orders',
        description: 'bookingsMenu',
    },
    Wooberly: {
        id: 'app.Wooberly',
        defaultMessage: 'Wooberly',
        description: 'Wooberly',
    },
    city: {
        id: 'app.city',
        defaultMessage: 'City',
        description: 'city',
    },
    categoryTitle: {
        id: 'app.categoryTitle',
        defaultMessage: 'Category Title',
        description: 'Category Title',
    },
    categoryContent: {
        id: 'app.categoryContent',
        defaultMessage: 'Category Content',
        description: 'Category Content',
    },
    viewCity: {
        id: 'app.viewCity',
        defaultMessage: 'View city',
        description: 'view City',
    },
    Safety: {
        id: 'app.Safety',
        defaultMessage: 'Safety',
        description: 'Safety',
    },
    safetyContent: {
        id: 'app.safetyContent',
        defaultMessage: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        description: 'Safety',
    },
    safetyButton: {
        id: 'app.safetyButton',
        defaultMessage: 'View all safety features',
        description: 'safetyButton',
    },
    signupDrive: {
        id: 'app.signupDrive',
        defaultMessage: 'Sign up to drive',
        description: 'Sign up to drive',
    },
    signupRide: {
        id: 'app.signupRide',
        defaultMessage: 'Sign up to ride',
        description: 'Sign up to ride',
    },
    profileId: {
        id: 'app.profileId',
        defaultMessage: 'Profile ID',
        description: 'Profile Id for user'
    },
    country: {
        id: 'app.country',
        defaultMessage: 'Country',
        description: 'Country Name'
    },
    createdAt: {
        id: 'app.createdAt',
        defaultMessage: 'Created At',
        description: 'Created date'
    },
    action: {
        id: 'app.action',
        defaultMessage: 'Action',
        description: 'Action'
    },
    vehicleType: {
        id: 'app.vehicleType',
        defaultMessage: 'Vehicle Type',
        description: 'Vehicle Type'
    },
    minBasePrice: {
        id: 'app.minBasePrice',
        defaultMessage: 'Minimum Base Price',
        description: 'Minimum base price amount'
    },
    driverCommission: {
        id: 'app.driverCommission',
        defaultMessage: 'Driver Commission(%)',
        description: 'Driver Commission Percentage'
    },
    vehicleName: {
        id: 'app.vehicleName',
        defaultMessage: 'Vehicle Name',
        description: 'Vehicle Name'
    },
    vehicleNumber: {
        id: 'app.vehicleNumber',
        defaultMessage: 'Vehicle Number',
        description: 'Vehicle Number'
    },
    status: {
        id: 'app.status',
        defaultMessage: 'Status',
        description: 'Status Active/Inactive'
    },
    editAction: {
        id: 'app.editAction',
        defaultMessage: 'Edit',
        description: 'Edit action name'
    },
    deleteAction: {
        id: 'app.deleteAction',
        defaultMessage: 'Delete',
        description: 'Delete action name'
    },
    vehicleOwnerName: {
        id: 'app.vehicleOwnerName',
        defaultMessage: 'Vehicle Owner Name',
        description: 'Vehicle Owner Name'
    },
    vehicleOwnerEmail: {
        id: 'app.vehicleOwnerEmail',
        defaultMessage: 'Vehicle Owner Email',
        description: 'Vehicle Owner Email'
    },
    minutePrice: {
        id: 'app.minutePrice',
        defaultMessage: 'Minute Price',
        description: 'Minute Price'
    },
    unitPrice: {
        id: 'app.unitPrice',
        defaultMessage: 'Unit Price',
        description: 'Unit Price'
    },
    fixedFeeType: {
        id: 'app.fixed',
        defaultMessage: 'Fixed',
        description: 'Fixed Fee Type'
    },
    percentageFeeType: {
        id: 'app.percentageFeeType',
        defaultMessage: 'Percentage',
        description: 'Percentage Fee Type'
    },
    totalFare: {
        id: 'app.totalFare',
        defaultMessage: 'Total Fare',
        description: 'Total Fare'
    },
    promoCodeId: {
        id: 'app.promoCodeId',
        defaultMessage: 'Promo Code',
        description: 'Promo Code'
    },
    isSpecialTrip: {
        id: 'app.isSpecialTrip',
        defaultMessage: 'Special Trip',
        description: 'Special Trip'
    },
    specialTripPrice: {
        id: 'app.specialTripPrice',
        defaultMessage: 'User Discount Amount',
        description: 'discount text'
    },
    tripStatus: {
        id: 'app.tripStatus',
        defaultMessage: 'Trip Status',
        description: 'Trip Status'
    },
    driverName: {
        id: 'app.driverName',
        defaultMessage: 'Delivery Partner Name',
        description: 'Driver Name'
    },
    driverEmail: {
        id: 'app.driverEmail',
        defaultMessage: 'Driver Email',
        description: 'Driver Email'
    },
    pickUpLocation: {
        id: 'app.pickupLocation',
        defaultMessage: 'Pick Up Location',
        description: 'PickUp Location'
    },
    dropLocation: {
        id: 'app.dropLocation',
        defaultMessage: 'Drop Off Location',
        description: 'Drop Location'
    },
    bookingId: {
        id: 'app.bookingId',
        defaultMessage: 'Booking ID',
        description: 'Booking ID'
    },
    category: {
        id: 'app.category',
        defaultMessage: 'Category',
        description: 'Category'
    },
    vehicleNumber: {
        id: 'app.vehicleNumber',
        defaultMessage: 'Vehicle Number',
        description: 'Vehicle Number'
    },
    totalRideDistance: {
        id: 'app.totalRideDistance',
        defaultMessage: 'Total Distance',
        description: 'Total Distance'
    },
    totalDuration: {
        id: 'app.totalDuration',
        defaultMessage: 'Total Duration',
        description: 'Total Duration'
    },
    vehicleId: {
        id: 'app.vehicleId',
        defaultMessage: 'Vehicle ID',
        description: 'Vehicle ID'
    },
    categoryId: {
        id: 'app.categoryId',
        defaultMessage: 'Category ID',
        description: 'Category ID'
    },
    view: {
        id: 'app.view',
        defaultMessage: 'View',
        description: 'View Details Link'
    },
    details: {
        id: 'app.details',
        defaultMessage: 'Details',
        description: 'Details'
    },
    userServiceFee: {
        id: 'app.userServiceFee',
        defaultMessage: 'User Service Fee',
        description: 'User Service Fee'
    },
    driverServiceFee: {
        id: 'app.driverServiceFee',
        defaultMessage: 'Driver Service Fee',
        description: 'Driver Service Fee'
    },
    userTotalFare: {
        id: 'app.userTotalFare',
        defaultMessage: 'User Total Fare',
        description: 'User Total Fare'
    },
    driverTotalFare: {
        id: 'app.driverTotalFare',
        defaultMessage: 'Driver Total Fare',
        description: 'Driver Total Fare'
    },
    tripStart: {
        id: 'app.tripStart',
        defaultMessage: 'Trip Start',
        description: 'Trip Start'
    },
    tripEnd: {
        id: 'app.tripEnd',
        defaultMessage: 'Trip End',
        description: 'Trip End'
    },
    categoryName: {
        id: 'app.categoryName',
        defaultMessage: 'Category Name',
        description: 'Category Name'
    },
    minutes: {
        id: 'app.minutes',
        defaultMessage: 'minutes',
        description: 'minutes'
    },
    update: {
        id: 'app.update',
        defaultMessage: 'Update',
        description: 'Update'
    },
    updatePassword: {
        id: 'app.updatePassword',
        defaultMessage: 'Update Password',
        description: 'Update Password'
    },
    userStatus: {
        id: 'app.userStatus',
        defaultMessage: 'Store Status',
        description: 'User Status Active/Inactive'
    },
    active: {
        id: 'app.active',
        defaultMessage: 'Active',
        description: 'Active Status'
    },
    inactive: {
        id: 'app.inactive',
        defaultMessage: 'Inactive',
        description: 'Inactive Status'
    },
    pending: {
        id: 'app.pending',
        defaultMessage: 'Pending',
        description: 'Pending Status'
    },
    banStatus: {
        id: 'app.banStatus',
        defaultMessage: 'Ban Status',
        description: 'Ban Status Permit/Ban'
    },
    permit: {
        id: 'app.permit',
        defaultMessage: 'Permit',
        description: 'Permit Status'
    },
    ban: {
        id: 'app.ban',
        defaultMessage: 'Ban',
        description: 'Ban Status'
    },
    phoneDialCode: {
        id: 'app.phoneDialCode',
        defaultMessage: 'Country Code',
        description: 'Phone Dial Code'
    },
    dropzoneUpload: {
        id: "app.dropzoneUpload",
        defaultMessage: "Upload a file from your computer",
        description: "Dropzone upload info"
    },
    dropzoneUploadError: {
        id: "app.dropzoneUploadError",
        defaultMessage: "Remove the existing image and try upload again",
        description: "Dropzone upload error message"
    },
    addCategory: {
        id: 'app.addCategory',
        defaultMessage: 'Add Category',
        description: 'Add category form header'
    },
    editUser: {
        id: 'app.editUser',
        defaultMessage: 'Edit User',
        description: 'Edit User form header'
    },
    editDriver: {
        id: 'app.editDriver',
        defaultMessage: 'Edit Driver',
        description: 'Edit driver form header'
    },
    editCategory: {
        id: 'app.editCategory',
        defaultMessage: 'Edit Category',
        description: 'Edit category form header'
    },
    editVehicle: {
        id: 'app.editVehicle',
        defaultMessage: 'Edit Vehicle',
        description: 'Edit vehicle form header'
    },
    goBack: {
        id: 'app.goBack',
        defaultMessage: 'Go Back',
        description: 'Go Back Button Text'
    },
    capacity: {
        id: 'app.capacity',
        defaultMessage: 'Capacity',
        description: 'Capacity'
    },
    floatError: {
        id: 'app.floatError',
        defaultMessage: 'It should be a number value',
        description: 'Numeric Error Response'
    },
    numericValue: {
        id: 'app.numericValue',
        defaultMessage: 'Numeric value required',
        description: 'Numeric value required'
    },
    validUrl: {
        id: 'app.validUrl',
        defaultMessage: 'Valid URL required',
        description: 'Valid URL required'
    },
    intError: {
        id: 'app.intError',
        defaultMessage: 'Please ignore decimal value',
        description: 'Integer Error Response'
    },
    percentError: {
        id: 'app.percentError',
        defaultMessage: 'Choose percentage value between 1 to 99',
        description: 'Choose percentage value between 1 to 99'
    },
    phoneError: {
        id: 'app.numberError',
        defaultMessage: 'Invalid Phone Number',
        description: 'Invalid Phone Number'
    },
    countryCodeError: {
        id: 'app.countryCodeError',
        defaultMessage: 'Invalid Country Code',
        description: 'Invalid Country Code'
    },
    vehicleNumberError: {
        id: 'app.vehicleNumberError',
        defaultMessage: 'Invalid Vehicle Number',
        description: 'Invalid Vehicle Number'
    },
    availableSeats: {
        id: 'app.availableSeats',
        defaultMessage: 'Available Seats',
        description: 'Available Seats'
    },
    allHours: {
        id: 'app.allHours',
        defaultMessage: 'All Day(24 hrs)',
        description: 'All Hours'
    },
    specificHours: {
        id: 'app.specificHours',
        defaultMessage: 'Specific Hours',
        description: 'specific Hours'
    },
    currency: {
        id: 'app.currency',
        defaultMessage: 'Currency',
        description: 'Currency Type'
    },
    invalidVehicleName: {
        id: 'app.invalidVehicleName',
        defaultMessage: 'Invalid Vehicle Name',
        description: 'Invalid Vehicle Name'
    },
    invalidVehicleNumber: {
        id: 'app.invalidVehicleNumber',
        defaultMessage: 'Invalid Vehicle Number',
        description: 'Invalid Vehicle Number'
    },
    vehicleNameError3: {
        id: 'app.vehicleNameError3',
        defaultMessage: 'Vehicle Name must be minimum 3 characters and maximum 15 characters',
        description: 'New Password must be minimum 8 characters'
    },
    manageDashboard: {
        id: 'app.manageDashboard',
        defaultMessage: 'Dashboard',
        description: 'Manage Dashboard'
    },
    chooseFile: {
        id: 'app.chooseFile',
        defaultMessage: 'Choose File',
        description: ''
    },
    categoryIcon: {
        id: 'app.categoryIcon',
        defaultMessage: 'Category Image',
        description: ''
    },
    categoryMapMarker: {
        id: 'app.categoryMapMarker',
        defaultMessage: 'Category Map Marker',
        description: ''
    },
    maximumUploadSizeLabel: {
        id: 'app.maximumUploadSizeLabel',
        defaultMessage: 'Maximum upload size',
        description: ''
    },
    followUs: {
        id: 'app.followUs',
        defaultMessage: 'Follow  Us',
        description: 'Follow Us',
    },
    copyRights: {
        id: 'app.copyRights',
        defaultMessage: 'Wooberly 2020. All Rights Reserved',
        description: 'Copy Rights',
    },
    usefulLink: {
        id: 'app.usefulLink',
        defaultMessage: 'Useful Links',
        description: 'Useful Links',
    },
    startRideing: {
        id: 'app.startRideing',
        defaultMessage: 'Start Riding',
        description: 'Start Riding',
    },
    startDriving: {
        id: 'app.startDriving',
        defaultMessage: 'Start Driving',
        description: 'Start Driving',
    },
    privacyPolicies: {
        id: 'app.privacyPolicies',
        defaultMessage: 'Privacy Policy',
        description: 'Privacy Policy',
    },
    aboutFooterDesc: {
        id: 'app.aboutFooterDesc',
        defaultMessage: 'An cum maiorum repudiandae, cu eam dolore bonorum probatus. Et legere sanctus cum, quot nostrud postulant ex mei. Ad ullum audire admodum eos. Ne eam quod habeo aeque, ad decoreos.',
        description: 'aboutFooterDesc',
    },
    downloadAppText: {
        id: 'app.downloadAppText',
        defaultMessage: 'Download The User App',
        description: 'DownloadAppText',
    },
    downloadAppTextDriver: {
        id: 'app.downloadAppTextDriver',
        defaultMessage: 'Download The Driver App',
        description: 'DownloadAppText',
    },
    downloadAppDesc: {
        id: 'app.downloadAppDesc',
        defaultMessage: 'Delectus scaevola elaboraret vel ad, vis no noster vocent prodesset, nec ei omittantur dissentiet. Nobis postea ei est.',
        description: 'DownloadAppTextDesc',
    },
    downloadAppDriverDesc: {
        id: 'app.downloadAppDriverDesc',
        defaultMessage: 'Delectus scaevola elaboraret vel ad, vis no noster vocent prodesset, nec ei omittantur dissentiet. Nobis postea ei est.',
        description: 'DownloadAppTextDesc',
    },
    sliderTitle: {
        id: 'app.sliderTitle',
        defaultMessage: 'Lorem Ipsum',
        description: 'sliderTitle',
    },
    sliderTitleDesc: {
        id: 'app.sliderTitleDesc',
        defaultMessage: 'Percipit repudiandae an eum, enim case eos no. Percipit tractatos pertinacia cum id, ad eos facete malorum recusabo, vis insolens perpetua definitionem ex.',
        description: 'sliderTitleDesc',
    },
    driver: {
        id: 'app.driver',
        defaultMessage: 'Driver',
        description: 'driver',
    },
    users: {
        id: 'app.users',
        defaultMessage: 'Users',
        description: 'Users',
    },
    stores: {
        id: 'app.stores',
        defaultMessage: 'Stores',
        description: 'Stores',
    },
    homeContent: {
        id: 'app.homeContent',
        defaultMessage: 'Start Riding With Wooberly',
        description: 'Start Riding With Wooberly',
    },
    gridTitleOne: {
        id: 'app.gridTitle',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleTwo: {
        id: 'app.gridTitleTwo',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleThree: {
        id: 'app.gridTitleThree',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleFour: {
        id: 'app.gridTitleFour',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleFive: {
        id: 'app.gridTitleFive',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleSix: {
        id: 'app.gridTitleSix',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridDescOne: {
        id: 'app.gridDescOne',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescTwo: {
        id: 'app.gridDescTwo',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescThree: {
        id: 'app.gridDescThree',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescFour: {
        id: 'app.gridDescFour',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescFive: {
        id: 'app.gridDescFive',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescSix: {
        id: 'app.gridDescSix',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    drivers: {
        id: 'app.drivers',
        defaultMessage: 'Drivers',
        description: 'drivers',
    },
    today: {
        id: 'app.today',
        defaultMessage: 'Today',
        description: 'today',
    },
    weekly: {
        id: 'app.weekly',
        defaultMessage: 'This Week',
        description: 'weekly',
    },
    montly: {
        id: 'app.montly',
        defaultMessage: 'This Month',
        description: 'montly',
    },
    bookings: {
        id: 'app.bookings',
        defaultMessage: 'Orders',
        description: 'bookings',
    },

    logout: {
        id: 'app.logout',
        defaultMessage: 'Logout',
        description: 'logout option',
    },

    goToMainSite: {
        id: 'app.goToMainSite',
        defaultMessage: 'Go to website',
        description: 'goToMainSite option',
    },

    managePromoCode: {
        id: 'app.managePromoCode',
        defaultMessage: 'Manage Promo Code',
        description: 'managePromoCode sidemenu',
    },
    addPromoCode: {
        id: 'app.addPromoCode',
        defaultMessage: 'Add Promo Code',
        description: 'Add PromoCode',
    },
    editPromoCode: {
        id: 'app.editPromoCode',
        defaultMessage: 'Edit Promo Code',
        description: 'Edit PromoCode',
    },
    id: {
        id: 'app.id',
        defaultMessage: 'ID',
        description: 'ID text',
    },
    title: {
        id: 'app.title',
        defaultMessage: 'Title',
        description: 'text',
    },
    description: {
        id: 'app.description',
        defaultMessage: 'Description',
        description: 'text',
    },
    code: {
        id: 'app.code',
        defaultMessage: 'Code',
        description: 'text',
    },
    discount: {
        id: 'app.discount',
        defaultMessage: 'Discount',
        description: 'text',
    },
    promoType: {
        id: 'app.promoType',
        defaultMessage: 'Type',
        description: 'text',
    },
    expireDate: {
        id: 'app.expireDate',
        defaultMessage: 'Expiry Date',
        description: 'text',
    },
    percentage: {
        id: 'app.percentage',
        defaultMessage: 'Percentage',
        description: 'text',
    },
    fixedAmount: {
        id: 'app.fixedAmount',
        defaultMessage: 'Fixed Amount',
        description: 'text',
    },
    usedPromoCode: {
        id: 'app.usedPromoCode',
        defaultMessage: 'Used Promo Code',
        description: 'text',
    },
    manageCompletedTrips: {
        id: 'app.manageCompletedTrips',
        defaultMessage: 'Completed Orders',
        description: 'CompletedTrips sidemenu',
    },
    manageCancelledTrips: {
        id: 'app.manageCancelledTrips',
        defaultMessage: 'Failed Orders',
        description: 'Failed Orders sidemenu',
    },
    manageCancelReason: {
        id: 'app.manageCancelReason',
        defaultMessage: 'Cancel Reason',
        description: 'Cancel Reason',
    },
    manageCurrency: {
        id: 'app.manageCurrency',
        defaultMessage: 'Manage Currency',
        description: 'manageCurrency sidemenu',
    },
    symbol: {
        id: 'app.symbol',
        defaultMessage: 'Symbol',
        description: 'currencyManagement value',
    },
    baseCurrency: {
        id: 'app.BaseCurrency',
        defaultMessage: 'Base Currency',
        description: 'currencyManagement value',
    },
    enableDisable: {
        id: 'app.enableDisable',
        defaultMessage: 'Set Enable / Disable',
        description: 'currencyManagement value',
    },
    setBaseCurrency: {
        id: 'app.setBaseCurrency',
        defaultMessage: 'Set Base Currency',
        description: 'currencyManagement value',
    },
    allowedPaymentCurrency: {
        id: 'app.allowedPaymentCurrency',
        defaultMessage: 'Allowed Payment Currency',
        description: 'currencyManagement value',
    },
    reviewsBy: {
        id: 'app.reviewsBy',
        defaultMessage: 'Reviews By',
        description: 'reviewsBy'
    },
    noResult: {
        id: 'app.noResult',
        defaultMessage: 'No results found',
        description: 'noResult'
    },
    noMenu: {
        id: 'app.noMenu',
        defaultMessage: 'No Menu found',
        description: 'noMenu'
    },
    noSubMenu: {
        id: 'app.noSubMenu',
        defaultMessage: 'No Sub Menu found',
        description: 'noSubMenu'
    },
    noItem: {
        id: 'app.noItem',
        defaultMessage: 'No Item found',
        description: 'noItem'
    },
    tollFee: {
        id: 'app.tollFee',
        defaultMessage: 'Toll Fee',
        description: 'tollFee'
    },
    edit: {
        id: 'app.edit',
        defaultMessage: 'Edit',
        description: 'edit'
    },
    managePayout: {
        id: 'app.managePayout',
        defaultMessage: 'Manage Payout',
        description: 'managePayout'
    },
    amount: {
        id: 'app.Amount',
        defaultMessage: 'Amount',
        description: 'Amount'
    },
    payoutStatus: {
        id: 'app.payoutStatus',
        defaultMessage: 'Payout Status',
        description: 'Payout Status'
    },
    holdPayout: {
        id: 'app.holdPayout',
        defaultMessage: 'Hold Payout',
        description: 'Hold Payout'
    },
    paymentType: {
        id: 'app.paymentType',
        defaultMessage: 'Payment Type',
        description: 'Payment Type'
    },
    yes: {
        id: 'app.yes',
        defaultMessage: 'Yes',
        description: 'Yes'
    },
    no: {
        id: 'app.no',
        defaultMessage: 'No',
        description: 'no'
    },
    homeSectionSettings: {
        id: 'app.homeSectionSettings',
        defaultMessage: 'Home Banner',
        description: 'Banner Settings'
    },
    homepageBanner: {
        id: 'app.homepageBanner',
        defaultMessage: 'Homepage Banner',
        description: 'Homepage Banner Settings'
    },
    aboutSectionSettings: {
        id: 'app.aboutSectionSettings',
        defaultMessage: 'Top Features',
        description: 'Top Features Settings'
    },
    citySectionSettings: {
        id: 'app.citySectionSettings',
        defaultMessage: 'Category',
        description: 'Category Settings'
    },
    safetySectionSettings: {
        id: 'app.safetySectionSettings',
        defaultMessage: 'User App',
        description: 'User Apps Settings'
    },
    signupSectionSettings: {
        id: 'app.signupSectionSettings',
        defaultMessage: 'Driver App',
        description: 'Driver Apps Settings'
    },
    footerSectionSettings: {
        id: 'app.footerSectionSettings',
        defaultMessage: 'Footer',
        description: 'Footer Settings'
    },
    manageFailedPayout: {
        id: 'app.manageFailedPayout',
        defaultMessage: 'Failed Payout',
        description: 'Failed Payout'
    },
    title1: {
        id: 'app.title1',
        defaultMessage: 'Title 1',
        description: 'Title 1'
    },
    title2: {
        id: 'app.title2',
        defaultMessage: 'Title 2',
        description: 'Title 2'
    },
    title3: {
        id: 'app.title3',
        defaultMessage: 'Title 3',
        description: 'Title 3'
    },
    title4: {
        id: 'app.title4',
        defaultMessage: 'Title 4',
        description: 'Title 4'
    },
    title5: {
        id: 'app.title5',
        defaultMessage: 'Title 5',
        description: 'Title 5'
    },
    title6: {
        id: 'app.title6',
        defaultMessage: 'Title 6',
        description: 'Title 6'
    },
    content1: {
        id: 'app.content1',
        defaultMessage: 'Content 1',
        description: 'Content 1'
    },
    content2: {
        id: 'app.content2',
        defaultMessage: 'Content 2',
        description: 'Content 2'
    },
    content3: {
        id: 'app.content3',
        defaultMessage: 'Content 3',
        description: 'Content 3'
    },
    content4: {
        id: 'app.content4',
        defaultMessage: 'Content 4',
        description: 'Content 4'
    },
    content5: {
        id: 'app.content5',
        defaultMessage: 'Content 5',
        description: 'Content 5'
    },
    content6: {
        id: 'app.content6',
        defaultMessage: 'Content 6',
        description: 'Content 6'
    },
    button: {
        id: 'app.button',
        defaultMessage: 'Button',
        description: 'Button'
    },
    aboutImage1: {
        id: 'app.aboutImage1',
        defaultMessage: 'Feature Banner #1',
        description: 'Feature Banner 1'
    },
    aboutImage2: {
        id: 'app.aboutImage2',
        defaultMessage: 'Feature Banner #2',
        description: 'Feature Banner 2'
    },
    homeImage1: {
        id: 'app.homeImage1',
        defaultMessage: 'Banner Image #1',
        description: 'Banner Image 1'
    },
    homeImage2: {
        id: 'app.homeImage2',
        defaultMessage: 'Banner Image #2',
        description: 'Banner Image 2'
    },
    homeImage3: {
        id: 'app.homeImage3',
        defaultMessage: 'Banner Image #3',
        description: 'Banner Image 3'
    },
    homeImage4: {
        id: 'app.homeImage4',
        defaultMessage: 'Banner Image #4',
        description: 'Banner Image 4'
    },
    homeImage5: {
        id: 'app.homeImage5',
        defaultMessage: 'Banner Image #5',
        description: 'Banner Image 5'
    },
    homeImage6: {
        id: 'app.homeImage6',
        defaultMessage: 'Banner Image #6',
        description: 'Banner Image 6'
    },
    homeTitle: {
        id: 'app.homeTitle',
        defaultMessage: 'Banner Title',
        description: 'Banner Title'
    },
    homeContent1: {
        id: 'app.homeContent1',
        defaultMessage: 'Banner Content',
        description: 'Banner Content'
    },
    safetyImage1: {
        id: 'app.safetyImage1',
        defaultMessage: 'Play Store Logo',
        description: 'Playstore Logo'
    },
    safetyImage2: {
        id: 'app.safetyImage2',
        defaultMessage: 'App Store Logo',
        description: 'Appstore Logo'
    },
    safetyImage3: {
        id: 'app.safetyImage3',
        defaultMessage: 'User Banner',
        description: 'User Banner'
    },
    signupImage1: {
        id: 'app.signupImage1',
        defaultMessage: 'Play Store Logo',
        description: 'Playstore Logo'
    },
    signupImage2: {
        id: 'app.signupImage2',
        defaultMessage: 'App Store Logo',
        description: 'Appstore Logo'
    },
    signupImage3: {
        id: 'app.signupImage3',
        defaultMessage: 'Driver Banner',
        description: 'Driver Banner'
    },
    footerLogo1: {
        id: 'app.footerLogo1',
        defaultMessage: 'Footer Logo #1',
        description: 'Footer Logo 1'
    },
    footerLogo2: {
        id: 'app.footerLogo2',
        defaultMessage: 'Footer Logo #2',
        description: 'Footer Logo 2'
    },
    footerLogo3: {
        id: 'app.footerLogo3',
        defaultMessage: 'Footer Logo #3',
        description: 'Footer Logo 3'
    },
    footerLogo4: {
        id: 'app.footerLogo4',
        defaultMessage: 'Footer Logo #4',
        description: 'Footer Logo 4'
    },
    tipsAmount: {
        id: 'app.tipsAmount',
        defaultMessage: 'Tips Amount',
        description: 'Tips Amount'
    },
    earnings: {
        id: 'app.earnings',
        defaultMessage: 'Earnings',
        description: 'Earnings'
    },
    overAll: {
        id: 'app.overAll',
        defaultMessage: 'Overall',
        description: 'Overall'
    },
    approve: {
        id: 'app.approve',
        defaultMessage: 'Approve',
        description: 'Approve'
    },
    decline: {
        id: 'app.decline',
        defaultMessage: 'Decline',
        description: 'Decline'
    },
    online: {
        id: 'app.online',
        defaultMessage: 'Online',
        description: 'online'
    },
    offline: {
        id: 'app.offline',
        defaultMessage: 'Offline',
        description: 'offline'
    },
    eligibleForATrip: {
        id: 'app.eligibleForATrip',
        defaultMessage: 'Eligible for a Trip',
        description: 'text'
    },
    notEligibleForATrip: {
        id: 'app.notEligibleForATrip',
        defaultMessage: 'Not eligible for a Trip',
        description: 'text'
    },
    eligibleForAOrder: {
        id: 'app.eligibleForAOrder',
        defaultMessage: 'Eligible for Orders',
        description: 'text'
    },
    notEligibleForAOrder: {
        id: 'app.notEligibleForAOrder',
        defaultMessage: 'Not eligible for Orders',
        description: 'text'
    },
    preview: {
        id: 'app.preview',
        defaultMessage: 'Preview',
        description: 'Preview'
    },
    pageName: {
        id: 'app.pageName',
        defaultMessage: 'Page Name',
        description: 'Page Name'
    },
    staticpageManagement: {
        id: 'app.staticpageManagement',
        defaultMessage: 'Manage Static Content',
        description: 'Manage Static Content'
    },
    contentPageManagement: {
        id: 'app.contentPageManagement',
        defaultMessage: 'Content Management',
        description: 'Content Management'
    },
    editPageDetails: {
        id: 'app.editPageDetails',
        defaultMessage: 'Edit Page Details',
        description: 'Edit Page Details'
    },
    pageBanner: {
        id: 'app.pageBanner',
        defaultMessage: 'Page Banner',
        description: 'Page Banner'
    },
    pageImageBanner: {
        id: 'app.pageImageBanner',
        defaultMessage: 'Page Image Banner',
        description: 'Page Image Banner'
    },
    manageFare: {
        id: 'app.manageFare',
        defaultMessage: 'Manage Fare',
        description: 'Admin sidemenu'
    },
    locationName: {
        id: 'app.locationName',
        defaultMessage: 'Location',
        description: 'Table header'
    },
    addFare: {
        id: 'app.addFare',
        defaultMessage: 'Add Fare',
        description: 'Button '
    },
    editFare: {
        id: 'app.editFaredFare',
        defaultMessage: 'Edit Fare',
        description: 'Button '
    },
    store: {
        id: 'app.store',
        defaultMessage: 'Register your store',
        description: 'Register your store'
    },
    updateAt: {
        id: 'app.updateAt',
        defaultMessage: 'Update At',
        description: 'Header Nav'
    },
    search: {
        id: 'app.search',
        defaultMessage: 'Search',
        description: 'input placeholder'
    },
    categories: {
        id: 'app.categories',
        defaultMessage: 'Category',
        description: 'pagination'
    },
    vehicles: {
        id: 'app.vehicles',
        defaultMessage: 'Vehicles',
        description: 'pagination'
    },
    vehicleTypes: {
        id: 'app.vehicleTypes',
        defaultMessage: 'Vehicle Type',
        description: 'side menu'
    },
    add: {
        id: 'app.add',
        defaultMessage: 'Add',
        description: 'button'
    },
    addDeliveryVehicle: {
        id: 'app.addDeliveryVehicle',
        defaultMessage: 'Add Vehicle Type',
        description: 'title'
    },
    editDeliveryVehicle: {
        id: 'app.editDeliveryVehicle',
        defaultMessage: 'Edit Vehicle Type',
        description: 'title'
    },
    deliveryVehicleIcon: {
        id: 'app.deliveryVehicleIcon',
        defaultMessage: 'Vehicle Image',
        description: 'title'
    },
    isTop: {
        id: 'app.isTop',
        defaultMessage: 'Is top?',
        description: 'title'
    },
    isMore: {
        id: 'app.isMore',
        defaultMessage: 'Is More?',
        description: 'title'
    },
    shopName: {
        id: 'app.shopName',
        defaultMessage: 'Store Name',
        description: 'Store Name'
    },
    manageStores: {
        id: 'app.manageStores',
        defaultMessage: 'Manage Stores',
        description: 'Manage Stores'
    },
    manageServiceFee: {
        id: 'app.manageServiceFee',
        defaultMessage: 'Manage Service Fee',
        description: 'Manage Service Fee'
    },
    address: {
        id: 'app.address',
        defaultMessage: 'Address',
        description: 'Address'
    },
    invalidAddress: {
        id: 'app.invalidAddress',
        defaultMessage: 'Please provide a valid address.',
        description: 'Address'
    },
    zipcode: {
        id: 'app.zipcode',
        defaultMessage: 'Zipcode',
        description: 'Zipcode'
    },
    home: {
        id: 'app.home',
        defaultMessage: 'Home',
        description: 'Home'
    },
    confirmPassword: {
        id: 'app.confirmPassword',
        defaultMessage: 'Confirm Password',
        description: 'Confirm Password'
    },
    passwordMatch: {
        id: 'app.passwordMatch',
        defaultMessage: 'Password and Confirm password does not match.',
        description: 'Password and Confirm password does not match.'
    },
    newPasswordMatch: {
        id: 'app.newPasswordMatch',
        defaultMessage: 'New Password and Confirm New password does not match.',
        description: 'Password and Confirm password does not match.'
    },
    singUpDesc: {
        id: 'app.singUpDesc',
        defaultMessage: 'After clicking Sign up, the website leads to the dashboard of the store panel.',
        description: 'Sign Up Page'
    },
    newsBoxTitle: {
        id: 'app.newsBoxTitle',
        defaultMessage: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        description: 'NewsBox Title'
    },
    newsBoxBtnText: {
        id: 'app.newsBoxBtnText',
        defaultMessage: 'Contact Us',
        description: 'NewsBox Title'
    },
    storePartnerTitle: {
        id: 'app.storePartnerTitle',
        defaultMessage: 'How Wooberly Eats Works for store partners',
        description: 'StorePartner'
    },
    storePartnerTitleDesc: {
        id: 'app.storePartnerTitleDesc',
        defaultMessage: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        description: 'StorePartner'
    },
    storePartnersubText1: {
        id: 'app.storePartnersubText1',
        defaultMessage: 'Customers order',
        description: 'StorePartner'
    },
    storePartnersubText2: {
        id: 'app.storePartnersubText2',
        defaultMessage: 'Fooed preparing',
        description: 'StorePartner'
    },
    storePartnersubText3: {
        id: 'app.storePartnersubText3',
        defaultMessage: 'Delivery partners arrive',
        description: 'StorePartner'
    },
    storeBannerTitle: {
        id: 'app.storeBannerTitle',
        defaultMessage: 'Partner with',
        description: 'StoreBannerTitle'
    },
    storeBannerTitleSub: {
        id: 'app.storeBannerTitleSub',
        defaultMessage: 'Wooberly Eats',
        description: 'StoreBannerTitle'
    },
    storeBannerTitleDesc: {
        id: 'app.storeBannerTitleDesc',
        defaultMessage: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        description: 'StoreBannerTitle'
    },
    signUpFormTitle: {
        id: 'app.signUpFormTitle',
        defaultMessage: 'Partner With Us',
        description: 'SignUp Form Title'
    },
    createAccout: {
        id: 'app.createAccout',
        defaultMessage: 'Create an account',
        description: 'Create an account'
    },
    loginForm: {
        id: 'app.loginForm',
        defaultMessage: 'Login Form',
        description: ' Login Form'
    },
    HomeSliderTitle: {
        id: 'app.HomeSliderTitle',
        defaultMessage: 'Delight Customers',
        description: 'Delight Customers'
    },
    HomeSliderTitleDesc: {
        id: 'app.HomeSliderTitleDesc',
        defaultMessage: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.',
        description: 'HomeSlider'
    },
    eats: {
        id: 'app.Eats',
        defaultMessage: 'Eats',
        description: 'Eats'
    },
    ownerName: {
        id: 'app.ownerName',
        defaultMessage: 'Owner Name',
        description: 'Owner Name'
    },
    editProfile: {
        id: 'app.editProfile',
        defaultMessage: 'Edit Profile',
        description: 'Edit Profile'
    },
    editStore: {
        id: 'app.editStore',
        defaultMessage: 'Edit Store',
        description: 'Edit Store'
    },
    takeAway: {
        id: 'app.takeAway',
        defaultMessage: 'Take Away',
        description: 'Take Away'
    },
    doorStepDelivery: {
        id: 'app.doorStepDelivery',
        defaultMessage: 'Doorstep Delivery',
        description: 'Doorstep Delivery'
    },
    storeDescription: {
        id: 'app.storeDescription',
        defaultMessage: 'Store Description',
        description: 'Store Description'
    },
    storeDocument: {
        id: 'app.storeDocument',
        defaultMessage: 'Store Document',
        description: 'Store Document'
    },
    storeImage: {
        id: 'app.storeImage',
        defaultMessage: 'Store Image',
        description: 'Store Image'
    },
    documentUpload: {
        id: 'app.documentUpload',
        defaultMessage: 'Document Upload',
        description: 'Document Upload'
    },
    verificationCode: {
        id: 'app.verificationCode',
        defaultMessage: 'Verification Code',
        description: 'Verification Code'
    },
    verify: {
        id: 'app.verify',
        defaultMessage: 'Verify via SMS',
        description: 'Verify'
    },
    confirmed: {
        id: 'app.confirmed',
        defaultMessage: 'Confirmed',
        description: 'Confirmed'
    },
    remove: {
        id: 'app.remove',
        defaultMessage: 'Remove',
        description: 'Remove'
    },
    payoutMethod: {
        id: 'app.payoutMethod',
        defaultMessage: 'Payout Methods',
        description: 'Payout Method',
    },
    addPayoutMethod: {
        id: 'app.addPayoutMethod',
        defaultMessage: 'To get paid, you need to set up a payout method',
        description: 'Payout Method',
    },
    paymentReleaseInfo1: {
        id: 'app.paymentReleaseInfo1',
        defaultMessage: 'releases payout on a weekly basis for the orders placed on the platform.',
        description: 'Payout Method',
    },
    paymentReleaseInfo2: {
        id: 'app.paymentReleaseInfo2',
        defaultMessage: 'The time it takes for the funds to appear in your account depends on your payout method.',
        description: 'Payout Method',
    },
    payoutTitleBlock1: {
        id: 'app.payoutTitleBlock1',
        defaultMessage: 'When you receive a payment for an order, we call that payment to you a “payout”. Our secure payment system supports several payout methods, which can be setup and added here. Your available payout options and currencies differ by country.',
        description: 'Payout Method',
    },
    payoutFaq: {
        id: 'app.payoutFaq',
        defaultMessage: 'Visit the Payout FAQ',
        description: 'Payout Method',
    },
    addPayout: {
        id: 'app.addPayout',
        defaultMessage: 'Add Payout Method',
        description: 'Payout',
    },
    address1: {
        id: 'app.address1',
        defaultMessage: 'Address Line 1',
        description: 'Payout Form',
    },
    address2: {
        id: 'app.address2',
        defaultMessage: 'Address Line 2',
        description: 'Payout Form',
    },
    state: {
        id: 'app.state',
        defaultMessage: 'State / Province',
        description: 'Payout Form',
    },
    payoutIntro1: {
        id: 'app.payoutIntro1',
        defaultMessage: 'Payouts for orders are released to you on the same day or next day after you deliver the order, and it takes some additional time for the money to arrive depending on your payout method.',
        description: 'Payout intro',
    },
    payoutIntro2: {
        id: 'app.payoutIntro2',
        defaultMessage: 'We can send money to people in United States with these payout methods. Which do you prefer?',
        description: 'Payout intro',
    },
    payoutTitle: {
        id: 'app.payoutTitle',
        defaultMessage: 'Method',
        description: 'Payout header',
    },
    payoutTitle1: {
        id: 'app.payoutTitle1',
        defaultMessage: 'Processed in',
        description: 'Payout header',
    },
    payoutTitle2: {
        id: 'app.payoutTitle2',
        defaultMessage: 'Fees',
        description: 'Payout header',
    },
    payoutTitle3: {
        id: 'app.payoutTitle3',
        defaultMessage: 'Currency',
        description: 'Payout header',
    },
    payoutTitle4: {
        id: 'app.payoutTitle4',
        defaultMessage: 'Details',
        description: 'Payout header',
    },
    noPaymentFound: {
        id: 'app.noPaymentFound',
        defaultMessage: 'No Payment method found',
        description: 'No payment',
    },
    back: {
        id: 'app.back',
        defaultMessage: 'Back',
        description: 'Back button',
    },
    payoutType: {
        id: 'app.payoutType',
        defaultMessage: 'Account Type',
        description: 'payout Account Type',
    },
    payoutIndividual: {
        id: 'app.payoutIndividual',
        defaultMessage: 'Individual',
        description: 'Individual text',
    },
    payoutCompany: {
        id: 'app.payoutCompany',
        defaultMessage: 'Company',
        description: 'Company text',
    },
    payoutCompanyName: {
        id: 'app.payoutCompanyName',
        defaultMessage: 'Company Name',
        description: 'Company text',
    },
    payoutStripeInfo: {
        id: 'app.payoutStripeInfo',
        defaultMessage: 'We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click "Finish" to set up your payments on Stripe.',
        description: 'Stripe text info'
    },
    notReady: {
        id: 'app.notReady',
        defaultMessage: 'Not Ready',
        description: 'Payout Method',
    },
    payoutVerify: {
        id: 'app.payoutVerify',
        defaultMessage: 'Verify',
        description: 'Payout Method',
    },
    payoutRetry: {
        id: 'app.payoutRetry',
        defaultMessage: 'Retry',
        description: 'Payout Method',
    },
    payoutFirstName: {
        id: 'app.payoutFirstName',
        defaultMessage: 'Account Holder First Name',
        description: 'payoutFirstName',
    },
    payoutLastName: {
        id: 'app.payoutLastName',
        defaultMessage: 'Account Holder Last Name',
        description: 'payoutLastName',
    },
    payoutRouting: {
        id: 'app.payoutRouting',
        defaultMessage: 'Routing Number',
        description: 'payoutRouting',
    },
    accountNumber: {
        id: 'app.accountNumber',
        defaultMessage: 'Account Number',
        description: 'accountNumber',
    },
    confirmAccountNumber: {
        id: 'app.confirmAccountNumber',
        defaultMessage: 'Confirm Account Number',
        description: 'confirmAccountNumber',
    },
    ssn4Digits: {
        id: 'app.ssn4Digits',
        defaultMessage: 'SSN Last 4 Digits',
        description: 'ssn4Digits',
    },
    payoutFirstNameRequired: {
        id: 'app.payoutFirstNameRequired',
        defaultMessage: 'First name is required',
        description: 'payoutFirstNameRequired',
    },
    payoutLastNameRequired: {
        id: 'app.payoutLastNameRequired',
        defaultMessage: 'Last name is required',
        description: 'payoutLastNameRequired',
    },
    payoutRoutingRequired: {
        id: 'app.payoutRoutingRequired',
        defaultMessage: 'Routing number is required',
        description: 'payoutRoutingRequired',
    },
    payoutRoutingInvalid: {
        id: 'app.payoutRoutingInvalid',
        defaultMessage: 'Invalid routing number',
        description: 'payoutRoutingInvalid',
    },
    accountNumberRequired: {
        id: 'app.accountNumberRequired',
        defaultMessage: 'Account number is required',
        description: 'accountNumberRequired',
    },
    accountNumberInvalid: {
        id: 'app.accountNumberInvalid',
        defaultMessage: 'Invalid account number',
        description: 'accountNumberInvalid',
    },
    confirmAccountNumberRequired: {
        id: 'app.confirmAccountNumberRequired',
        defaultMessage: 'Confirm account number is required',
        description: 'confirmAccountNumberRequired',
    },
    confirmAccountNumberInvalid: {
        id: 'app.confirmAccountNumberInvalid',
        defaultMessage: 'Invalid confirm account number',
        description: 'confirmAccountNumberInvalid',
    },
    confirmAccountNumberMismatch: {
        id: 'app.confirmAccountNumberMismatch',
        defaultMessage: 'Account number mismatch',
        description: 'confirmAccountNumberMismatch',
    },
    ssn4DigitsRequired: {
        id: 'app.ssn4DigitsRequired',
        defaultMessage: 'SSN number is required',
        description: 'ssn4DigitsRequired',
    },
    ssn4DigitsInvalid: {
        id: 'app.ssn4DigitsInvalid',
        defaultMessage: 'Invalid SSN number',
        description: 'ssn4DigitsInvalid',
    },
    verifiedBy: {
        id: 'app.verifiedBy',
        defaultMessage: 'Verified By',
        description: 'verifiedBy',
    },
    saved: {
        id: 'app.saved',
        defaultMessage: 'Saved',
        description: 'saved',
    },
    finish: {
        id: 'app.finish',
        defaultMessage: 'Finish',
        description: 'Finish button',
    },
    options: {
        id: 'app.options',
        defaultMessage: 'Options',
        description: 'Payout Method',
    },
    default: {
        id: 'app.default',
        defaultMessage: 'Default',
        description: 'Payout Method',
    },
    ready: {
        id: 'app.ready',
        defaultMessage: 'Ready',
        description: 'Payout Method',
    },
    setDefault: {
        id: 'app.setDefault',
        defaultMessage: 'Set Default',
        description: 'Payout Method',
    },
    notReady: {
        id: 'app.notReady',
        defaultMessage: 'Not Ready',
        description: 'Payout Method',
    },
    payoutVerify: {
        id: 'app.payoutVerify',
        defaultMessage: 'Verify',
        description: 'Payout Method',
    },
    payoutRetry: {
        id: 'app.payoutRetry',
        defaultMessage: 'Retry',
        description: 'Payout Method',
    },
    directDeposit: {
        id: 'app.directDeposit',
        defaultMessage: 'Direct Deposit, Paypal, etc..',
        description: 'Payout Method',
    },
    payoutVerifyStripeInfo: {
        id: 'app.payoutVerifyStripeInfo',
        defaultMessage: 'We use Stripe to make sure you get paid on time safe and secure. It requires additional verification to connect your bank account with the platform.',
        description: 'Payout Method',
    },
    preferredCurrency: {
        id: 'app.preferredCurrency',
        defaultMessage: 'Preferred Currency',
        description: 'Preferred Currency',
    },
    chooseCurrency: {
        id: 'app.chooseCurrency',
        defaultMessage: 'Choose Currency',
        description: 'Choose Currency',
    },
    preferredCurrencyRequired: {
        id: 'app.preferredCurrencyRequired',
        defaultMessage: 'Preferred currency is required',
        description: 'Preferred currency is required',
    },
    payoutFailure: {
        id: 'app.payoutFailure',
        defaultMessage: 'Oops! We are sorry!',
        description: 'Uh-oh, the registration has failed.',
    },
    payoutFailureSubtitle: {
        id: 'app.payoutFailureSubtitle',
        defaultMessage: 'Connecting your bank account with the platform verification is failed.',
        description: 'Please add a valid SSN.'
    },
    payoutFailureContent: {
        id: 'app.payoutFailureContent',
        defaultMessage: 'Please try again to add your bank account with the platform by clickting the "Retry" option or Add a new payout account by clicking the "Add Payout Method".',
        description: 'Please add a valid SSN.'
    },
    weHaveSentVerificationCode: {
        id: 'app.weHaveSentVerificationCode',
        defaultMessage: 'We sent a verification code to',
        description: 'We sent a verification code to',
    },
    verificationCodeLabel: {
        id: 'app.verificationCodeLabel',
        defaultMessage: 'Please enter the 4-digit code',
        description: 'Please enter the 4-digit code',
    },
    cancel: {
        id: 'app.cancel',
        defaultMessage: 'Cancel',
        description: 'Cancel button',
    },

    categorySideMenu: {
        id: 'app.categorySideMenu',
        defaultMessage: 'Category',
        description: 'Category Side Menu',
    },
    menu: {
        id: 'app.menu',
        defaultMessage: 'Menu',
        description: 'Category Menu',
    },
    activeDays: {
        id: 'app.activeDays',
        defaultMessage: 'Active Days',
        description: 'Active Days',
    },
    noActiveDays: {
        id: 'app.noActiveDays',
        defaultMessage: 'No active days found',
        description: 'No active days found',
    },
    addMenu: {
        id: 'app.addMenu',
        defaultMessage: 'Add Menu',
        description: 'Add Menu',
    },
    craftMenu: {
        id: 'app.craftMenu',
        defaultMessage: 'Craft your menu',
        description: 'Craft your menu',
    },
    menuName: {
        id: 'app.menuName',
        defaultMessage: 'Menu Name',
        description: 'Menu Name',
    },
    documentName: {
        id: 'app.documentName',
        defaultMessage: 'Document Name',
        description: 'Document Name',
    },
    documentImage: {
        id: 'app.documentImage',
        defaultMessage: 'Document Image',
        description: 'Document Image',
    },
    documentUpload: {
        id: 'app.documentUpload',
        defaultMessage: 'Document Upload',
        description: 'Document Upload',
    },
    storeDocuments: {
        id: 'app.storeDocuments',
        defaultMessage: 'Store Documents',
        description: 'Store Documents',
    },
    document: {
        id: 'app.document',
        defaultMessage: 'Document',
        description: 'Document',
    },
    noDocument: {
        id: 'app.noDocument',
        defaultMessage: "The user hasn't uploaded the document yet",
        description: 'Document',
    },
    manageItem: {
        id: 'app.manageItem',
        defaultMessage: 'Manage Item',
        description: 'Manage Item',
    },
    totalDrivers: {
        id: 'app.totalDrivers',
        defaultMessage: 'Total Delivery Partners',
        description: 'Total Delivery Partners',
    },
    totalUsers: {
        id: 'app.totalUsers',
        defaultMessage: 'Total Users',
        description: 'Total Users',
    },
    totalStores: {
        id: 'app.totalStores',
        defaultMessage: 'Total Stores',
        description: 'Total Stores',
    },
    addItem: {
        id: 'app.addItem',
        defaultMessage: 'Add Item',
        description: 'Add Item',
    },
    addSubMenu: {
        id: 'app.addSubMenu',
        defaultMessage: 'Add Sub Menu',
        description: 'Add Sub Menu',
    },
    itemSettings: {
        id: 'app.itemSettings',
        defaultMessage: 'Item Details',
        description: 'Item Settings',
    },
    subMenu: {
        id: 'app.subMenu',
        defaultMessage: 'Sub Menu',
        description: 'Sub Menu',
    },
    enable: {
        id: 'app.enable',
        defaultMessage: 'Enable',
        description: 'Enable',
    },
    disable: {
        id: 'app.disable',
        defaultMessage: 'Disable',
        description: 'Disable',
    },
    itemType1: {
        id: 'app.itemType1',
        defaultMessage: 'Veg',
        description: 'Item Type2',
    },
    itemType2: {
        id: 'app.itemType2',
        defaultMessage: 'Non Veg',
        description: 'Item Type2',
    },
    selectType: {
        id: 'app.selectType',
        defaultMessage: 'Select Type',
        description: 'Select Type',
    },
    selectStatus: {
        id: 'app.selectStatus',
        defaultMessage: 'Select Status',
        description: 'Select Status',
    },
    price: {
        id: 'app.price',
        defaultMessage: 'Price',
        description: 'Price',
    },
    itemType: {
        id: 'app.itemType',
        defaultMessage: 'Item Type',
        description: 'Item Type',
    },
    settingName: {
        id: 'app.settingName',
        defaultMessage: 'Setting Name',
        description: 'Item Name',
    },
    subMenuName: {
        id: 'app.subMenuName',
        defaultMessage: 'Sub Menu Name',
        description: 'Sub Menu Name',
    },
    preparationTime: {
        id: 'app.preparationTime',
        defaultMessage: 'Preparation Time',
        description: 'Preparation Time',
    },
    invalid: {
        id: 'app.invalid',
        defaultMessage: 'Invalid',
        description: 'invalid',
    },
    tax: {
        id: 'app.tax',
        defaultMessage: 'Tax',
        description: 'tax',
    },
    choosePriceRange: {
        id: 'app.choosePriceRange',
        defaultMessage: 'Choose Price Range',
        description: 'Choose Price Range',
    },
    chooseCategory: {
        id: 'app.chooseCategory',
        defaultMessage: 'Choose Category',
        description: 'Choose Category',
    },
    chooseDietary: {
        id: 'app.chooseDietary',
        defaultMessage: 'Choose Dietary',
        description: 'Choose Dietary',
    },
    shopEstimatedTime: {
        id: 'app.shopEstimatedTime',
        defaultMessage: 'Estimated Preparation Time',
        description: 'Estimated Preparation Time',
    },
    menuEditer: {
        id: 'app.menuEditer',
        defaultMessage: 'Menu Editor',
        description: 'Menu Editor',
    },
    availabilty: {
        id: 'app.availabilty',
        defaultMessage: 'Availability',
        description: 'Availability',
    },
    minutes: {
        id: 'app.minutes',
        defaultMessage: 'minutes',
        description: 'minutes',
    },
    available: {
        id: 'app.available',
        defaultMessage: 'Available',
        description: 'Available',
    },
    unavailable: {
        id: 'app.unavailable',
        defaultMessage: 'Unavailable',
        description: 'Unavailable',
    },
    chooseCountry: {
        id: 'app.chooseCountry',
        defaultMessage: 'Choose Country',
        description: 'Choose Country',
    },
    chooseDialcode: {
        id: 'app.chooseDialcode',
        defaultMessage: 'Choose Dialcode',
        description: 'Choose Dialcode',
    },
    timeslotExist: {
        id: 'app.timeslotExist',
        defaultMessage: 'Please check your time slot. Either time slot is invalid or already available.',
        description: 'Time slot error. Please check your time slot values.',
    },
    opratingHours: {
        id: 'app.opratingHours',
        defaultMessage: 'Operating Hours',
        description: 'Operating Hours',
    },
    bannerSection: {
        id: 'app.bannerSection',
        defaultMessage: 'Banner Section',
        description: 'Banner Section',
    },
    sliderSection: {
        id: 'app.sliderSection',
        defaultMessage: 'Slider Section',
        description: 'Slider Section',
    },
    infoSection: {
        id: 'app.infoSection',
        defaultMessage: 'Info Section',
        description: 'Info Section',
    },
    manageMenu: {
        id: 'app.manageMenu',
        defaultMessage: 'Manage Menu',
        description: 'Manage Menu',
    },
    percent: {
        id: 'app.percent',
        defaultMessage: '%',
        description: 'percent'
    },
    profile: {
        id: 'app.profile',
        defaultMessage: 'Profile',
        description: 'Profile',
    },
    editItem: {
        id: 'app.editItem',
        defaultMessage: 'Edit',
        description: 'EditItem',
    },
    addMore: {
        id: 'app.addMore',
        defaultMessage: 'Add More',
        description: 'addMore',
    },
    deleteConfirmation: {
        id: 'app.deleteConfirmation',
        defaultMessage: 'Are you sure to delete this?',
        description: 'Are you sure to delete this?',
    },
    deleteMenuTitle: {
        id: 'app.deleteMenuTitle',
        defaultMessage: 'Deleting Menu',
        description: 'Deleting Menu',
    },
    deleteSubMenuTitle: {
        id: 'app.deleteSubMenuTitle',
        defaultMessage: 'Deleting Sub Menu',
        description: 'Deleting Sub Menu',
    },
    priceLimitReached: {
        id: 'app.priceLimitReached',
        defaultMessage: 'Item price cannot exceed more than 6 digits',
        description: 'Item price cannot exceed more than 6 digits',
    },
    feeType: {
        id: 'app.feeType',
        defaultMessage: 'Fee Type',
        description: 'Fee Type',
    },
    deliveryFee: {
        id: 'app.deliveryFee',
        defaultMessage: 'Delivery Fee',
        description: 'Delivery Fee',
    },
    pickupFee: {
        id: 'app.pickupFee',
        defaultMessage: 'Pickup Fare',
        description: 'Pickup Fare',
    },
    dropFee: {
        id: 'app.dropFee',
        defaultMessage: 'Drop Fare',
        description: 'Drop Fare',
    },
    distanceFee: {
        id: 'app.distanceFee',
        defaultMessage: 'Distance Fare',
        description: 'Distance Fare',
    },
    bookingFee: {
        id: 'app.bookingFee',
        defaultMessage: 'Ordering Fee',
        description: 'Booking Fee',
    },
    shopFee: {
        id: 'app.shopFee',
        defaultMessage: 'Store Service Fee',
        description: 'Store Service Fee',
    },
    driverFee: {
        id: 'app.driverFee',
        defaultMessage: 'Driver Service Fee',
        description: 'Driver Service Fee',
    },
    flatFee: {
        id: 'app.flatFee',
        defaultMessage: 'Flat Fee',
        description: 'Flat Fee',
    },
    distanceBasedFee: {
        id: 'app.distanceBasedFee',
        defaultMessage: 'Distance Based Fee',
        description: 'Distance Based Fee',
    },
    orderManageMent: {
        id: 'app.orderManageMent',
        defaultMessage: 'Order Management',
        description: 'Order Management',
    },
    searchById: {
        id: 'app.searchById',
        defaultMessage: 'Search orders by ID',
        description: 'Search orders by id',
    },
    orderId: {
        id: 'app.orderId',
        defaultMessage: 'Order ID',
        description: 'Order Id',
    },
    nameLabel: {
        id: 'app.nameLabel',
        defaultMessage: 'Name',
        description: 'Name',
    },
    noOfItem: {
        id: 'app.noOfItem',
        defaultMessage: 'No Of Items',
        description: 'No Of Items',
    },
    orderDetails: {
        id: 'app.orderDetails',
        defaultMessage: 'Order Details',
        description: 'Order Details',
    },
    orderStatus: {
        id: 'app.orderStatus',
        defaultMessage: 'Order Status',
        description: 'Order Status',
    },
    itemStatus: {
        id: 'app.itemStatus',
        defaultMessage: 'Item Status',
        description: 'Item Status',
    },
    customerName: {
        id: 'app.customerName',
        defaultMessage: 'Customer Name',
        description: 'Customer Name',
    },
    pendingLabel: {
        id: 'app.pendingLabel',
        defaultMessage: 'Pending',
        description: 'Pending',
    },
    acceptedLabel: {
        id: 'app.acceptedLabel',
        defaultMessage: 'Accept',
        description: 'Accepted',
    },
    rejectedLabel: {
        id: 'app.rejectedLabel',
        defaultMessage: 'Decline',
        description: 'Rejected',
    },
    orderDetails: {
        id: 'app.orderDetails',
        defaultMessage: 'Order Details',
        description: 'Order Details',
    },
    deliveryLocations: {
        id: 'app.deliveryLocations',
        defaultMessage: 'Delivery Location',
        description: 'Delivery Location',
    },
    upComingOrders: {
        id: 'app.upComingOrders',
        defaultMessage: 'Ongoing Orders',
        description: 'Ongoing Orders',
    },
    previousOrders: {
        id: 'app.previousOrders',
        defaultMessage: 'Previous Orders',
        description: 'Previous Orders',
    },
    homePageDescription: {
        id: 'app.homePageDescription',
        defaultMessage: 'Banner Description',
        description: 'Banner Description',
    },
    distanceUnits: {
        id: 'app.distanceUnits',
        defaultMessage: 'Distance Units',
        description: 'Distance Units',
    },
    howItWorks: {
        id: 'app.howItWorks',
        defaultMessage: 'How It Works?',
        description: 'How It Works ',
    },
    minExceedLimit: {
        id: 'app.minExceedLimit',
        defaultMessage: 'Minimum 2 characters',
        description: 'Minimum 2 characters'
    },
    exceedLimit30: {
        id: 'app.exceedLimit30',
        defaultMessage: 'Maximum 30 characters',
        description: 'Maximum 30 characters'
    },
    exceedLimit400: {
        id: 'app.exceedLimit400',
        defaultMessage: 'Maximum 400 characters',
        description: 'Maximum 400 characters'
    },
    currentOrders: {
        id: 'app.currentOrders',
        defaultMessage: 'Current Orders',
        description: 'Current Orders',
    },
    earningsMenu: {
        id: 'app.earningsMenu',
        defaultMessage: 'Admin Earnings',
        description: 'earningsMenu',
    },
    date: {
        id: 'app.date',
        defaultMessage: 'Date',
        description: 'Date'
    },
    paidOut: {
        id: 'app.paidOut',
        defaultMessage: 'Paid Out',
        description: 'Paid Out',
    },
    userName: {
        id: 'app.userName',
        defaultMessage: 'User Name',
        description: 'UserName',
    },
    storeName: {
        id: 'app.storeName',
        defaultMessage: 'Store Name',
        description: 'Store Name ',
    },
    total: {
        id: 'app.total',
        defaultMessage: 'Total',
        description: 'Total'
    },
    storePayout: {
        id: 'app.storePayout',
        defaultMessage: 'Store Payout Status',
        description: 'Store Payout Status',
    },
    driverPayout: {
        id: 'app.driverPayout',
        defaultMessage: 'Delivery Partner Payout Status',
        description: 'Driver Payout Status',
    },
    exportCSV: {
        id: 'app.exportCSV',
        defaultMessage: 'Export Data into CSV',
        description: 'Export Data into CSV',
    },

    exportDataIntoCSV: {
        id: 'app.exportDataIntoCSV',
        defaultMessage: 'Export Data into CSV',
        description: 'Export Data into CSV',
    },
    addNewLabel: {
        id: 'app.addNewLabel',
        defaultMessage: 'Add New',
        description: 'Add New',
    },
    idLabel: {
        id: 'app.idLabel',
        defaultMessage: 'ID',
        description: 'ID',
    },
    paidTo: {
        id: 'app.paidTo',
        defaultMessage: 'Pay To',
        description: 'Pay To',
    },
    attemptedPayout: {
        id: 'app.attemptedPayout',
        defaultMessage: 'Attempted Payout',
        description: 'Attempted Payout'
    },
    failure: {
        id: 'app.failure',
        defaultMessage: 'Failure',
        description: 'Failure',
    },
    orderAmount: {
        id: 'app.orderAmount',
        defaultMessage: 'Order Amount',
        description: 'text',
    },
    userCancel: {
        id: 'app.userCancel',
        defaultMessage: 'User Cancel',
        description: 'text',
    },
    driverCancel: {
        id: 'app.driverCancel',
        defaultMessage: 'Driver Cancel',
        description: 'text',
    },
    driverUnableToDeliver: {
        id: 'app.driverUnableToDeliver',
        defaultMessage: 'Driver Unable to deliver',
        description: 'text',
    },
    storeDecline: {
        id: 'app.storeDecline',
        defaultMessage: 'Store Decline',
        description: 'text',
    },
    itemIsDoneLabel: {
        id: 'app.itemIsDoneLabel',
        defaultMessage: 'Ready for pickup',
        description: 'Item is done',
    },
    subTotalLabel: {
        id: 'app.subTotalLabel',
        defaultMessage: 'Sub Total',
        description: 'Sub Total',
    },
    shopCommissionFee: {
        id: 'app.shopCommissionFee',
        defaultMessage: 'Store Commission Fee',
        description: 'Store Commission Fee',
    },
    driverCommissionFee: {
        id: 'app.driverCommissionFee',
        defaultMessage: 'Driver Commission Fee',
        description: 'Driver Commission Fee',
    },
    totalOrder: {
        id: 'app.totalOrder',
        defaultMessage: 'Total orders',
        description: 'Total Orders'
    },
    todayOrder: {
        id: 'app.todayOrder',
        defaultMessage: 'Today orders',
        description: 'Today Orders'
    },
    thisWeekOrder: {
        id: 'app.thisWeekOrder',
        defaultMessage: 'This week orders',
        description: 'This week orders'
    },
    thisMonthOrder: {
        id: 'app.thisMonthOrder',
        defaultMessage: 'This month orders',
        description: 'This month orders'
    },
    totalEranings: {
        id: 'app.totalEranings',
        defaultMessage: 'Total earnings',
        description: 'Total Earnings'
    },
    todayEarnings: {
        id: 'app.todayEarnings',
        defaultMessage: 'Today earnings',
        description: 'Today Earnings'
    },
    thisWeekEarnings: {
        id: 'app.thisWeekEarnings',
        defaultMessage: 'This week earnings',
        description: 'This Week Earnings'
    },
    thisMonthEarnings: {
        id: 'app.thisMonthEarnings',
        defaultMessage: 'This month earnings',
        description: 'This Month Earnings'
    },
    selectOne: {
        id: 'app.selectOne',
        defaultMessage: 'Please select one of the options',
        description: 'Please select one of the options',
    },
    ordersLabel: {
        id: 'app.ordersLabel',
        defaultMessage: 'Orders',
        description: 'Orders',
    },
    ordersCountLabel: {
        id: 'app.ordersCountLabel',
        defaultMessage: 'Orders Count',
        description: 'Orders Count',
    },
    exceedLimit8: {
        id: 'app.exceedLimit8',
        defaultMessage: 'Maximum 8 digits',
        description: 'Maximum 8 digits'
    },
    welcomeAdminLabel: {
        id: 'app.welcomeAdminLabel',
        defaultMessage: 'Welcome Admin !!!',
        description: 'Welcome Admin !!!',
    },
    exceedLimit10: {
        id: 'app.exceedLimit10',
        defaultMessage: 'Maximum limit 10 characters',
        description: 'Maximum limit 10 characters'
    },
    otherLabel: {
        id: 'app.otherLabel',
        defaultMessage: 'Other',
        description: 'Other'
    },
    noOrdersFoundLabel: {
        id: 'app.noOrdersFoundLabel',
        defaultMessage: 'No orders found.',
        description: 'No orders found label'
    },
    deliveryCompleteOn: {
        id: 'app.deliveryCompleteOn',
        defaultMessage: 'Order ready to dispatch on',
        description: 'Item delivery ready label'
    },
    userDetails: {
        id: 'app.userDetails',
        defaultMessage: 'User Details',
        description: 'userDetails label'
    },
    deliveryPartnerDetails: {
        id: 'app.deliveryPartnerDetails',
        defaultMessage: 'Delivery Partner Details',
        description: 'deliveryPartnerDetails label'
    },
    transactionManagement: {
        id: 'app.transactionManagement',
        defaultMessage: 'Transaction Management',
        description: 'Transaction Management label'
    },
    completedTransaction: {
        id: 'app.completedTransaction',
        defaultMessage: 'Completed Transaction',
        description: 'Completed Transaction label'
    },
    futureTransaction: {
        id: 'app.futureTransaction',
        defaultMessage: 'Future Transaction',
        description: 'Future Transaction label'
    },
    androidLabel: {
        id: 'app.androidLabel',
        defaultMessage: 'Android',
        description: 'Android'
    },
    iphoneLabel: {
        id: 'app.iphoneLabel',
        defaultMessage: 'Iphone',
        description: 'Iphone'
    },
    bookingStatus: {
        id: 'app.bookingStatus',
        defaultMessage: 'Status',
        description: 'Status label'
    },
    totalOrderedItems: {
        id: 'app.totalOrderedItems',
        defaultMessage: 'Total Ordered Items',
        description: 'label'
    },
    paidLabel: {
        id: 'app.paidLabel',
        defaultMessage: 'Paid',
        description: 'label'
    },
    notPaidLabel: {
        id: 'app.notPaidLabel',
        defaultMessage: 'Not Paid',
        description: 'label'
    },
    noTransactionFound: {
        id: 'app.noTransactionFound',
        defaultMessage: 'No Transaction Found.',
        description: 'no transaction label'
    },
    transactionsLabel: {
        id: 'app.transactionsLabel',
        defaultMessage: 'Transactions',
        description: 'transaction label'
    },
    includesTax: {
        id: 'app.includesTax',
        defaultMessage: 'Includes tax',
        description: 'Tax label'
    },
    milesLabel: {
        id: 'app.milesLabel',
        defaultMessage: 'Miles',
        description: 'label'
    },
    kmLabel: {
        id: 'app.kmLabel',
        defaultMessage: 'Km',
        description: 'label'
    },
    paidVia: {
        id: 'app.paidVia',
        defaultMessage: 'Paid via',
        description: 'label'
    },

    cashOnHand: {
        id: 'app.cashOnHand',
        defaultMessage: 'Cash on hand',
        description: 'label'
    },

    card: {
        id: 'app.card',
        defaultMessage: 'Credit/Debit Card',
        description: 'label'
    },

    wallet: {
        id: 'app.wallet',
        defaultMessage: 'Wallet',
        description: 'label'
    },

    notFound: {
        id: 'app.notFound',
        defaultMessage: 'Not found',
        description: 'label'
    },

    overdraft: {
        id: 'app.overdraft',
        defaultMessage: 'Overdraft',
        description: 'label'
    },

    shopDetails: {
        id: 'app.shopDetails',
        defaultMessage: 'Store Details',
        description: 'label'
    },

    driverDetails: {
        id: 'app.driverDetails',
        defaultMessage: 'Delivery Partner Details',
        description: 'label'
    },

    userLabel: {
        id: 'app.userLabel',
        defaultMessage: 'User',
        description: 'label'
    },

    shopLabel: {
        id: 'app.shopLabel',
        defaultMessage: 'Store',
        description: 'label'
    },

    driverLabel: {
        id: 'app.driverLabel',
        defaultMessage: 'Delivery Partner',
        description: 'label'
    },
    allLabel: {
        id: 'app.allLabel',
        defaultMessage: 'All',
        description: 'label'
    },
    notFoundDesc: {
        id: 'app.notFoundDesc',
        defaultMessage: 'Sorry, the page you were trying to view does not exist.',
        description: 'Sorry, the page you were trying to view does not exist.'
    },
    backToHome: {
        id: 'app.backToHome',
        defaultMessage: 'Back to home',
        description: 'Back to home label'
    },
    sender: {
        id: 'app.sender',
        defaultMessage: 'Sender',
        description: 'Review Rating'
    },
    receiver: {
        id: 'app.receiver',
        defaultMessage: 'Receiver',
        description: 'Review Rating'
    },
    reviewContent: {
        id: 'app.reviewContent',
        defaultMessage: 'Review Content',
        description: 'Label'
    },
    payment: {
        id: 'app.payment',
        defaultMessage: 'Payment',
        description: 'label'
    },
    orderCreatedAt: {
        id: 'app.orderCreatedAt',
        defaultMessage: 'Order Created at',
        description: 'label'
    },
    orderDeliveredAt: {
        id: 'app.orderDeliveredAt',
        defaultMessage: 'Order Delivered at',
        description: 'label'
    },
    orderedItems: {
        id: 'app.orderedItems',
        defaultMessage: 'Ordered Items',
        description: 'label'
    },
    remarks: {
        id: 'app.remarks',
        defaultMessage: 'Remarks',
        description: 'label'
    },
    userBillingInformation: {
        id: 'app.userBillingInformation',
        defaultMessage: 'User Billing Information',
        description: 'label'
    },
    driverBillingInformation: {
        id: 'app.driverBillingInformation',
        defaultMessage: 'Delivery Partner Billing Information',
        description: 'label'
    },

    shopBillingInformation: {
        id: 'app.shopBillingInformation',
        defaultMessage: 'Store Billing Information',
        description: 'label'
    },
    shopTotalFare: {
        id: 'app.shopTotalFare',
        defaultMessage: 'Store Total Fare',
        description: 'label'
    },
    adminOverdraft: {
        id: 'app.adminOverdraft',
        defaultMessage: 'Admin Overdraft',
        description: 'label'
    },
    faq: {
        id: 'app.faq',
        defaultMessage: 'FAQ',
        description: 'label'
    },
    menus: {
        id: 'app.menus',
        defaultMessage: 'Menus',
        description: 'label'
    },
    subMenus: {
        id: 'app.subMenus',
        defaultMessage: 'Sub Menus',
        description: 'Sub Menus label',
    },
    orderDeclineReason: {
        id: 'app.orderDeclineReason',
        defaultMessage: 'Order decline reason',
        description: 'Order decline reason'
    },
    orderHistory: {
        id: 'app.orderHistory',
        defaultMessage: 'Order History',
        description: 'Order history label'
    },
    last7Days: {
        id: 'app.last7Days',
        defaultMessage: 'Last 7 days',
        description: 'select label'
    },
    last15Days: {
        id: 'app.last15Days',
        defaultMessage: 'Last 15 days',
        description: 'select label'
    },
    last30Days: {
        id: 'app.last30Days',
        defaultMessage: 'Last 30 days',
        description: 'select label'
    },
    enabled: {
        id: 'app.enabled',
        defaultMessage: 'Enabled',
        description: 'Enabled label',
    },
    disabled: {
        id: 'app.disabled',
        defaultMessage: 'Disabled',
        description: 'Disabled label',
    },
    refundStatus: {
        id: 'app.refundStatus',
        defaultMessage: 'Refund Status',
        description: 'refund label',
    },
    isShopPayoutHold: {
        id: 'app.isShopPayoutHold',
        defaultMessage: 'Is Store Payout Hold?',
        description: 'isShopPayoutHold label',
    },
    isShopPayoutHold: {
        id: 'app.isShopPayoutHold',
        defaultMessage: 'Is Store Payout Hold?',
        description: 'isShopPayoutHold label',
    },
    isDriverPayoutHold: {
        id: 'app.isDriverPayoutHold',
        defaultMessage: 'Is Delivery Partner Payout Hold?',
        description: 'isDriverPayoutHold label',
    },
    userRefundHistory: {
        id: 'app.userRefundHistory',
        defaultMessage: 'User Refund History',
        description: 'userRefundHistory label',
    },
    userFailedRefundHistory: {
        id: 'app.userFailedRefundHistory',
        defaultMessage: 'User Refund Failed History',
        description: 'userFailedRefundHistory label',
    },
    shopPayoutHistory: {
        id: 'app.shopPayoutHistory',
        defaultMessage: 'Store Payout History',
        description: 'shopPayoutHistory label',
    },
    shopFailedPayoutHistory: {
        id: 'app.shopFailedPayoutHistory',
        defaultMessage: 'Store Failed Payout History',
        description: 'shopFailedPayoutHistory label',
    },
    driverPayoutHistory: {
        id: 'app.driverPayoutHistory',
        defaultMessage: 'Delivery Partner Payout History',
        description: 'driverPayoutHistory label',
    },
    driverFailedPayoutHistory: {
        id: 'app.driverFailedPayoutHistory',
        defaultMessage: 'Delivery Partner Failed Payout History',
        description: 'driverFailedPayoutHistory label',
    },
    transactionIdLabel: {
        id: 'app.transactionIdLabel',
        defaultMessage: 'Transaction ID',
        description: 'transactionIdLabel',
    },
    errorRemarks: {
        id: 'app.errorRemarks',
        defaultMessage: 'Error Remarks',
        description: 'label',
    },
    refundIdLabel: {
        id: 'app.refundIdLabel',
        defaultMessage: 'Refund Transaction ID',
        description: 'label',
    },
    itemName: {
        id: 'app.itemName',
        defaultMessage: 'Item Name',
        description: 'label',
    },
    itemDescription: {
        id: 'app.itemDescription',
        defaultMessage: 'Item Description',
        description: 'label',
    },
    itemImage: {
        id: 'app.itemImage',
        defaultMessage: 'Item Image',
        description: 'label',
    },
    chooseLanguageLabel: {
        id: 'app.chooseLanguageLabel',
        defaultMessage: 'Choose a language',
        description: 'Choose Language'
    },
    receipt: {
        id: 'app.receipt',
        defaultMessage: 'Receipt',
        description: 'Receipt',
    },
    endTimeLabel: {
        id: 'app.endTimeLabel',
        defaultMessage: 'End Time',
        description: 'End Time',
    },
    startTimeLabel: {
        id: 'app.startTimeLabel',
        defaultMessage: 'Start Time',
        description: 'Start Time',
    },
    daysLabel: {
        id: 'app.daysLabel',
        defaultMessage: 'Days',
        description: 'Days',
    },
    openLabel: {
        id: 'app.openLabel',
        defaultMessage: 'Open',
        description: 'Open',
    },
    closeLabel: {
        id: 'app.closeLabel',
        defaultMessage: 'Closed',
        description: 'Closed',
    },
    logoWidth: {
        id: 'app.logoWidth',
        defaultMessage: 'Logo Width',
        description: 'Logo Width',
    },
    logoHeight: {
        id: 'app.logoHeight',
        defaultMessage: 'Logo Height',
        description: 'Logo Height',
    },
    siteNameLabel: {
        id: 'app.siteNameLabel',
        defaultMessage: 'Site Name',
        description: 'Site Name',
    },
    siteTitleLabel: {
        id: 'app.siteTitleLabel',
        defaultMessage: 'Site Title',
        description: 'Site Title',
    },
    metaKeywordLabel: {
        id: 'app.metaKeywordLabel',
        defaultMessage: 'Meta Keyword',
        description: 'Meta Keyword',
    },
    metaDescriptionLabel: {
        id: 'app.metaDescriptionLabel',
        defaultMessage: 'Meta Description',
        description: 'Meta Description',
    },
    facebookURLLabel: {
        id: 'app.facebookURLLabel',
        defaultMessage: 'Facebook URL',
        description: 'Facebook URL',
    },
    twitterURLLabel: {
        id: 'app.twitterURLLabel',
        defaultMessage: 'Twitter URL',
        description: 'Twitter URL',
    },
    instagramURLLabel: {
        id: 'app.instagramURLLabel',
        defaultMessage: 'Instagram URL',
        description: 'Instagram URL',
    },
    youtubeURLLabel: {
        id: 'app.youtubeURLLabel',
        defaultMessage: 'YouTube URL',
        description: 'Youtube URL',
    },
    imageLabel: {
        id: 'app.imageLabel',
        defaultMessage: 'Image',
        description: 'Image',
    },
    image1Label: {
        id: 'app.image1Label',
        defaultMessage: 'Image #1',
        description: 'Image #1',
    },
    image2Label: {
        id: 'app.image2Label',
        defaultMessage: 'Image #2',
        description: 'Image #2',
    },
    image3Label: {
        id: 'app.image3Label',
        defaultMessage: 'Image #3',
        description: 'Image #3',
    },
    title1Label: {
        id: 'app.title1Label',
        defaultMessage: 'Title #1',
        description: 'Title #1',
    },
    title2Label: {
        id: 'app.title2Label',
        defaultMessage: 'Title #2',
        description: 'Title #2',
    },
    description1Label: {
        id: 'app.description1Label',
        defaultMessage: 'Description #1',
        description: 'Description #1',
    },
    imageTitle1Label: {
        id: 'app.imageTitle1Label',
        defaultMessage: 'Image Title #1',
        description: 'Image Title #1',
    },
    imageTitle2Label: {
        id: 'app.imageTitle2Label',
        defaultMessage: 'Image Title #2',
        description: 'Image Title #2',
    },
    imageTitle3Label: {
        id: 'app.imageTitle3Label',
        defaultMessage: 'Image Title #3',
        description: 'Image Title #3',
    },
    contactUsTitle: {
        id: 'app.contactUsTitle',
        defaultMessage: 'Contact us Title',
        description: 'Contact-Us Title',
    },
    sliderImage1Label: {
        id: 'app.sliderImage1Label',
        defaultMessage: 'Slider Image #1',
        description: 'Slider Image #1',
    },
    sliderTitle1Label: {
        id: 'app.sliderTitle1Label',
        defaultMessage: 'Slider Title #1',
        description: 'Slider Title #1',
    },
    sliderDescription1Label: {
        id: 'app.sliderDescription1Label',
        defaultMessage: 'Slider Description #1',
        description: 'Slider Description #1',
    },
    sliderImage2Label: {
        id: 'app.sliderImage2Label',
        defaultMessage: 'Slider Image #2',
        description: 'Slider Image #2',
    },
    sliderTitle2Label: {
        id: 'app.sliderTitle2Label',
        defaultMessage: 'Slider Title #2',
        description: 'Slider Title #2',
    },
    sliderDescription2Label: {
        id: 'app.sliderDescription2Label',
        defaultMessage: 'Slider Description #2',
        description: 'Slider Description #2',
    },
    sliderImage3Label: {
        id: 'app.sliderImage3Label',
        defaultMessage: 'Slider Image #3',
        description: 'Slider Image #3',
    },
    sliderTitle3Label: {
        id: 'app.sliderTitle3Label',
        defaultMessage: 'Slider Title #3',
        description: 'Slider Title #3',
    },
    sliderDescription3Label: {
        id: 'app.sliderDescription3Label',
        defaultMessage: 'Slider Description #3',
        description: 'Slider Description #3',
    },
    titleLabel: {
        id: 'app.titleLabel',
        defaultMessage: 'Title',
        description: 'Title',
    },
    descriptionLabel: {
        id: 'app.descriptionLabel',
        defaultMessage: 'Description',
        description: 'Description',
    },
    bannerImage1DescriptionLabel: {
        id: 'app.bannerImage1DescriptionLabel',
        defaultMessage: 'Banner Image#1 Description',
        description: 'Banner Image#1 Description',
    },
    bannerImage2DescriptionLabel: {
        id: 'app.bannerImage2DescriptionLabel',
        defaultMessage: 'Banner Image#2 Description',
        description: 'Banner Image#2 Description',
    },
    bannerImage3DescriptionLabel: {
        id: 'app.bannerImage3DescriptionLabel',
        defaultMessage: 'Banner Image#3 Description',
        description: 'Banner Image#3 Description',
    },
    userAppTitle: {
        id: 'app.userAppTitle',
        defaultMessage: 'User App Title',
        description: 'User App Title',
    },
    userAppContent: {
        id: 'app.userAppContent',
        defaultMessage: 'User App Content',
        description: 'User App Content',
    },
    playStoreLink: {
        id: 'app.playStoreLink',
        defaultMessage: 'Play Store Link',
        description: 'Play Store Link',
    },
    appStoreLink: {
        id: 'app.appStoreLink',
        defaultMessage: 'App Store Link',
        description: 'App Store Link',
    },
    footerTitleLabel: {
        id: 'app.footerTitleLabel',
        defaultMessage: 'Footer Title #1',
        description: 'Footer Title #1',
    },
    footerContentLabel: {
        id: 'app.footerContentLabel',
        defaultMessage: 'Footer Content #1',
        description: 'Footer Content #1',
    },
    footerURLTitleLabel: {
        id: 'app.footerURLTitleLabel',
        defaultMessage: 'Footer URL Title',
        description: 'Footer URL Title',
    },
    copyRightsContentLabel: {
        id: 'app.copyRightsContentLabel',
        defaultMessage: 'Copyrights content',
        description: 'Copyrights content',
    },
    emailNotes: {
        id: 'app.emailNotes',
        defaultMessage: "Leave the email address field blank if you don't want to replace it.",
        description: 'Leave email address field blank if you don\'t want to change the email',
    },
    toLabel: {
        id: 'app.toLabel',
        defaultMessage: 'To',
        description: 'To'
    },
    messageLabel: {
        id: 'app.messageLabel',
        defaultMessage: 'Message',
        description: 'Message'
    },
    setAsBaseCurrency: {
        id: 'app.setAsBaseCurrency',
        defaultMessage: 'Set as base currency',
        description: 'Set as base currency',
    },
    currenciesLabel: {
        id: 'app.currenciesLabel',
        defaultMessage: 'Currencies',
        description: 'Currencies',
    },
    addLabel: {
        id: 'app.addLabel',
        defaultMessage: 'Add',
        description: 'Add',
    },
    siteSettingsHeading: {
        id: 'app.siteSettingsHeading',
        defaultMessage: 'Site Settings',
        description: 'Site Settings',
    },
    manageAUserHeading: {
        id: 'app.manageAUserHeading',
        defaultMessage: 'Manage Users',
        description: 'manageAUserHeading',
    },
    manageDriverHeading: {
        id: 'app.manageDriverHeading',
        defaultMessage: 'Manage Drivers',
        description: 'manageDriverHeading',
    },
    manageStoresHeading: {
        id: 'app.manageStoresHeading',
        defaultMessage: 'Manage Stores',
        description: 'manageStoresHeading',
    },
    manageServiceHeading: {
        id: 'app.manageServiceHeading',
        defaultMessage: 'Manage Service Fee',
        description: 'manageServiceHeading',
    },
    manageCategoryHeading: {
        id: 'app.manageCategoryHeading',
        defaultMessage: 'Manage Category',
        description: 'manageCategoryHeading',
    },
    EditCategory: {
        id: 'app.EditCategory',
        defaultMessage: 'Edit Category',
        description: 'EditCategory',
    },
    manageVehicleTypes: {
        id: 'app.manageVehicleTypes',
        defaultMessage: 'Manage Vehicle Types',
        description: 'manageVehicleTypes',
    },
    addCategoryHeading: {
        id: 'app.addCategoryHeading',
        defaultMessage: 'Add Category',
        description: 'addCategoryHeading',
    },
    adminEarningDetails: {
        id: 'app.adminEarningDetails',
        defaultMessage: 'Admin Earning Details',
        description: 'Admin Earning Details',
    },
    bookingPaymentDetails: {
        id: 'app.bookingPaymentDetails',
        defaultMessage: 'Booking Payment Details',
        description: 'Booking Payment Details',
    },
    viewReview: {
        id: 'app.viewReview',
        defaultMessage: 'View Review',
        description: 'viewReview',
    },
    addPromoCodeHead: {
        id: 'app.addPromoCodeHead',
        defaultMessage: 'Add Promo Code',
        description: 'addPromoCode',
    },
    changeAdminEmail: {
        id: 'app.changeAdminEmail',
        defaultMessage: 'Change Admin Email/Password',
        description: 'Change Admin Email/Password',
    },
    currencyList: {
        id: 'app.currencyList',
        defaultMessage: 'Currency List',
        description: 'Currency List',
    },
    addCancelReasonHead: {
        id: 'app.addCancelReasonHead',
        defaultMessage: 'Add Cancel Reason',
        description: 'Add Cancel Reason',
    },
    StaticContentManagement: {
        id: 'app.StaticContentManagement',
        defaultMessage: 'Static Content Management',
        description: 'Static Content Management',
    },
    EditContentDetails: {
        id: 'app.EditContentDetails',
        defaultMessage: 'Edit Content Details',
        description: 'EditContentDetails',
    },
    content: {
        id: 'app.content',
        defaultMessage: 'Content',
        description: 'content',
    },
    page: {
        id: 'app.page',
        defaultMessage: 'page',
        description: 'page',
    },
    minimumPassword: {
        id: 'app.minimumPassword',
        defaultMessage: 'Password must be minimum 8 characters',
        description: 'Password must be minimum 8 characters',
    },
    mismatchPassword: {
        id: 'app.mismatchPassword',
        defaultMessage: 'Password is mismatching with Confirm password',
        description: 'Password is mismatching with Confirm password',
    },
    forceUpdate: {
        id: 'app.forceUpdate',
        defaultMessage: 'Force update',
        description: 'forceUpdate'
    },
    userAndriodVersion: {
        id: 'app.userAndriodVersion',
        defaultMessage: 'User Android version',
        description: 'userAndriodVersion'
    },
    useriosVersion: {
        id: 'app.useriosVersion',
        defaultMessage: 'User iOS version',
        description: 'useriosVersion'
    },
    driverAndriodVersion: {
        id: 'app.driverAndriodVersion',
        defaultMessage: 'Driver Android version',
        description: 'Andriod'
    },
    driveriosVersion: {
        id: 'app.driveriosVersion',
        defaultMessage: 'Driver iOS version',
        description: 'iOS'
    },
    invalidVersionNumber: {
        id: 'app.invalidVersionNumber',
        defaultMessage: 'Invalid version Number',
        description: 'invalidVersionNumber'
    },
    forgotPassword: {
        id: 'app.forgotPassword',
        defaultMessage: 'Forgot Password',
        description: 'Title'
    },
    forgotPasswordInfo: {
        id: 'app.forgotPasswordInfo',
        defaultMessage: 'Enter the email address associated with your account, and we’ll email you a link to reset your password.',
        description: 'Forgot password info',
    },
    backToLogin: {
        id: 'app.backToLogin',
        defaultMessage: 'Back to login',
        description: 'Back to login',
    },
    sendLink: {
        id: 'app.sendLink',
        defaultMessage: 'Send Link',
        description: 'Send Link',
    },
    preferredDelivery: {
        id: 'app.preferredDelivery',
        defaultMessage: 'Preferred Delivery Option',
        description: 'Preferred Delivery',
    },
    leaveAtDoor: {
        id: 'app.leaveAtDoor',
        defaultMessage: 'Leave at door',
        description: 'Preferred Delivery Option',
    },
    meetAtDoor: {
        id: 'app.meetAtDoor',
        defaultMessage: 'Meet at door',
        description: 'Preferred Delivery Option',
    },
    pickUpOutside: {
        id: 'app.pickUpOutside',
        defaultMessage: 'Pick up outside',
        description: 'Preferred Delivery Option',
    },
    deliveryOption: {
        id: 'app.deliveryOption',
        defaultMessage: 'Delivery Option',
        description: 'Delivery Option',
    },
    deliveryInstruction: {
        id: 'app.deliveryInstruction',
        defaultMessage: 'Delivery Instruction',
        description: 'Delivery Instruction',
    },
    modifierGroup: {
        id: 'app.modifierGroup',
        defaultMessage: 'Modifier Groups',
        description: 'Modifier Groups',
    },
    addModifierGroup: {
        id: 'app.addModifierGroup',
        defaultMessage: 'Add Modifier Group',
        description: 'Add Modifier Group',
    },
    addModifier: {
        id: 'app.addModifier',
        defaultMessage: 'Add Modifiers',
        description: 'Add Modifier',
    },
    editModifier: {
        id: 'app.editModifier',
        defaultMessage: 'Edit Modifiers',
        description: 'Edit Modifier',
    },
    modifierName: {
        id: 'app.modifierName',
        defaultMessage: 'Modifier Name',
        description: 'Modifier Name',
    },
    customerChoose: {
        id: 'app.customerChoose',
        defaultMessage: 'How many items can the customer choose? ',
        description: 'How many items can the customer choose? ',
    },
    exactly: {
        id: 'app.exactly',
        defaultMessage: 'Exactly',
        description: 'Exactly',
    },
    setRange: {
        id: 'app.setRange',
        defaultMessage: 'Set as Range',
        description: 'Set as Range',
    },
    chooseModifier: {
        id: 'app.chooseModifier',
        defaultMessage: 'Require customer to choose modifier',
        description: 'Require customer to choose modifier',
    },
    isMultiple: {
        id: 'app.isMultiple',
        defaultMessage: 'Is Multiple?',
        description: 'Is Multiple',
    },
    item: {
        id: 'app.item',
        defaultMessage: 'Items',
        description: 'Item',
    },
    modifierItemName: {
        id: 'app.modifierItemName',
        defaultMessage: 'Item Name',
        description: 'itemName',
    },
    priceOptional: {
        id: 'app.priceOptional',
        defaultMessage: 'Price (Optional)',
        description: 'priceOptional',
    },
    addItems: {
        id: 'app.addItems',
        defaultMessage: 'Add Items',
        description: 'Add Items',
    },
    maximunModifier: {
        id: 'app.maximunModifier',
        defaultMessage: 'Set maximum number of each modifier item, the customer can choose:',
        description: 'Set maximum number of each modifier item, the customer can choose',
    },
    newModifierGroups: {
        id: 'app.newModifierGroups',
        defaultMessage: 'New modifier groups',
        description: 'New modifier groups',
    },
    deleteModifierTitle: {
        id: 'app.deleteModifierTitle',
        defaultMessage: 'Deleting Modifier',
        description: 'Deleting Modifier',
    },
    noModifier: {
        id: 'app.noModifier',
        defaultMessage: 'No Modifier Groups found',
        description: 'noModifier'
    },
    modifier: {
        id: 'app.modifier',
        defaultMessage: 'Modifiers',
        description: 'modifier'
    },
    modifierItemValidate: {
        id: 'app.modifierItemValidate',
        defaultMessage: 'Minimum value should not exceed the maximum value',
        description: 'modifier'
    },
    addItemTitle: {
        id: 'app.addItemTitle',
        defaultMessage: 'AddItem',
        description: 'addItemTitle',
    },
    individual: {
        id: 'app.individual',
        defaultMessage: 'Individual',
        description: 'individual'
    },
    common: {
        id: 'app.common',
        defaultMessage: 'Common',
        description: 'common'
    },
    cloned: {
        id: 'app.cloned',
        defaultMessage: 'Cloned',
        description: 'cloned'
    },
    newModifierGroupsModal: {
        id: 'app.newModifierGroupsModal',
        defaultMessage: 'New modifier groups',
        description: 'New modifier groups'
    },
    deleteModifier: {
        id: 'app.deleteModifier',
        defaultMessage: 'Deleting Modifier',
        description: 'Deleting Modifier'
    },
    adminRoleLabel: {
        id: 'app.adminRoleLabel',
        defaultMessage: 'Admin Role',
        description: 'Admin Role',
    },
    adminUserLabel: {
        id: 'app.adminUserLabel',
        defaultMessage: 'Admin User',
        description: 'Admin User',
    },
    manageAdmin: {
        id: 'app.manageAdmin',
        defaultMessage: 'Manage Admins',
        description: 'Manage Admins',
    },
    manageAdminUsers: {
        id: 'app.manageAdminUsers',
        defaultMessage: 'Manage Admin Users',
        description: 'Manage Admin Users',
    },
    manageAdminRoles: {
        id: 'app.manageAdminRoles',
        defaultMessage: 'Manage Admin Roles',
        description: 'Manage Admin Roles',
    },
    manageUser: {
        id: 'app.manageUser',
        defaultMessage: 'Manage Users',
        description: 'Manage Users',
    },
    sNoLabel: {
        id: 'app.sNoLabel',
        defaultMessage: 'S.No',
        description: 'S.No',
    },
    emailLabel: {
        id: 'app.emailLabel',
        defaultMessage: 'Email',
        description: 'emailLabel',
    },
    noRecordFound: {
        id: 'app.noRecordFound',
        defaultMessage: 'No matching records found.',
        description: 'No matching records found.',
    },
    delete: {
        id: 'app.delete',
        defaultMessage: 'Delete',
        description: 'Manage listing Delete button',
    },
    roleLabel: {
        id: 'app.roleLabel',
        defaultMessage: 'Role',
        description: 'Role',
    },
    adminUserDesc: {
        id: 'app.adminUserDesc',
        defaultMessage: 'Provide new password only. Ignore this field to use the existing password for this user.',
        description: 'Provide new password only. Ignore this field to use the existing password for this user.',
    },
    selectroleLabel: {
        id: 'app.selectroleLabel',
        defaultMessage: 'Select Role',
        description: 'Select Role',
    },
    roleNameLabel: {
        id: 'app.roleNameLabel',
        defaultMessage: 'Role Name',
        description: 'Role Name',
    },
    descriptionAdminLabel: {
        id: 'app.descriptionAdminLabel',
        defaultMessage: 'Description',
        description: 'Description',
    },
    name: {
        id: 'app.name',
        defaultMessage: 'Name',
        description: 'Name',
    },
    privilagesLabel: {
        id: 'app.privilagesLabel',
        defaultMessage: 'Privileges',
        description: 'Privileges',
    },
    roles: {
        id: 'app.roles',
        defaultMessage: 'Roles',
        description: 'roles',
    },
    deleteConfirmationModifier: {
        id: 'radicalstart.deleteConfirmationModifier',
        defaultMessage: "Are you sure to delete it? The modifier is added in the user's cart and their cart will be reflected or cleared.",
        description: "Are you sure to delete it? The modifier is added in the user's cart and their cart will be reflected or cleared.",
    },
    deleteConfirmationModifierItem: {
        id: 'radicalstart.deleteConfirmationModifierItem',
        defaultMessage: "Are you sure to delete it? The modifier item is added in the user's cart and their cart will be reflected or cleared.",
        description: "Are you sure to delete it? The modifier item is added in the user's cart and their cart will be reflected or cleared.",
    },
    orderNotes: {
        id: 'app.orderNotes',
        defaultMessage: 'Order Notes',
        description: 'Order Notes',
    },
    modifiers: {
        id: 'app.modifiers',
        defaultMessage: 'Modifiers',
        description: 'modifiers',
    },
    promoCodeImage: {
        id: 'app.promoCodeImage',
        defaultMessage: 'Promo Code Image',
        description: 'title'
    },
    pageNotFound: {
        id: 'app.pageNotFound',
        defaultMessage: 'Page Not Found',
        description: 'Page Not Found'
    },
    resetPassword: {
        id: 'app.resetPassword',
        defaultMessage: 'Reset Password',
        description: 'Reset Password'
    },
    searchHere: {
        id: 'app.searchHere',
        defaultMessage: 'Search here',
        description: 'Search here',
    },
    manageDriverPayouts: {
        id: 'app.manageDriverPayouts',
        defaultMessage: 'Manage Driver Payouts',
        description: 'Manage Driver Payouts',
    },
    addContentDetails: {
        id: 'app.addContentDetails',
        defaultMessage: 'Add Content Details',
        description: 'AddContentDetails',
    },
    bookingDetails: {
        id: 'app.bookingDetails',
        defaultMessage: 'Booking Details',
        description: 'Booking Details',
    },
    completedBookingDetails: {
        id: 'app.completedBookingDetails',
        defaultMessage: 'Completed Orders Details',
        description: 'completedBookingDetails',
    },
    failedBookingDetails: {
        id: 'app.failedBookingDetails',
        defaultMessage: 'Failed Booking Details',
        description: 'failedBookingDetails',
    },
    editModifierGroup: {
        id: 'app.editModifierGroup',
        defaultMessage: 'Edit Modifier Group',
        description: 'Edit Modifier Group',
    },
    payoutPreferences: {
        id: 'app.payoutPreferences',
        defaultMessage: 'Payout Preferences',
        description: 'Payout Preferences',
    },
    Sunday: {
        id: 'app.Sunday',
        defaultMessage: 'Sunday',
        description: 'Availability days'
    },
    Monday: {
        id: 'app.Monday',
        defaultMessage: 'Monday',
        description: 'Availability days'
    },
    Tuesday: {
        id: 'app.Tuesday',
        defaultMessage: 'Tuesday',
        description: 'Availability days'
    },
    Wednesday: {
        id: 'app.Wednesday',
        defaultMessage: 'Wednesday',
        description: 'Availability days'
    },
    Thursday: {
        id: 'app.Thursday',
        defaultMessage: 'Thursday',
        description: 'Availability days'
    },
    Friday: {
        id: 'app.Friday',
        defaultMessage: 'Friday',
        description: 'Availability days'
    },
    Saturday: {
        id: 'app.Saturday',
        defaultMessage: 'Saturday',
        description: 'Availability days'
    },
    cookiesDisclaimer: {
        id: 'app.cookiesDisclaimer',
        defaultMessage: 'uses cookies to understand how you use our site and to improve your experience. By using our site, you accept our use of cookies. To learn more, go to our',
        description: 'extraGuestIncluded'
    },
    gotIt: {
        id: 'app.gotIt',
        defaultMessage: 'GOT IT',
        description: ' cookies Disclaimer Button'
    },
    cookiePolicy: {
        id: 'app.cookiePolicy',
        defaultMessage: 'Cookie policy',
        description: ' cookies Disclaimer Link'
    },
    currencyType: {
        id: 'app.currencyType',
        defaultMessage: 'Currency type',
        description: 'currencyType'
    },
    userActiveStatus: {
        id: 'app.userActiveStatus',
        defaultMessage: 'User Status',
        description: 'User Status Active/Inactive'
    },
    sliderContact: {
        id: 'app.sliderContact',
        defaultMessage: 'Static contact info',
        description: 'Static contact info',
    },
    sliderContactImage: {
        id: 'app.sliderContactImage',
        defaultMessage: 'Contact us image',
        description: 'Contact us image',
    },
    buttonName: {
        id: 'app.buttonName',
        defaultMessage: 'Contact us button text',
        description: 'Button Name',
    },
    buttonLink: {
        id: 'app.buttonLink',
        defaultMessage: 'Contact us button link',
        description: 'Button Link',
    },
    driverAppTitle: {
        id: 'app.driverAppTitle',
        defaultMessage: 'Driver App Title',
        description: 'Driver App Title',
    },
    driverAppContent: {
        id: 'app.driverAppContent',
        defaultMessage: 'Driver App Content',
        description: 'Driver App Content',
    },
    favIconlogoLabel: {
        id: 'app.favIconlogoLabel',
        defaultMessage: 'Fav Icon',
        description: 'Fav Icon'
    },
    stripePublishableKey: {
        id: 'app.stripePublishableKey',
        defaultMessage: 'Stripe Publishable Key',
        description: 'Stripe Publishable Key'
    },
    deliveryModuleMenu: {
        id: 'app.deliveryModuleMenu',
        defaultMessage: 'Manage delivery type',
        description: 'deliveryModuleMenu',
    },
    editDeliveryModule: {
        id: 'app.editDeliveryModule',
        defaultMessage: 'Edit Delivery Type',
        description: 'editDeliveryModule',
    },
    addDeliveryModule: {
        id: 'app.addDeliveryModule',
        defaultMessage: 'Add Delivery Type',
        description: 'addDeliveryModule',
    },

    dontSignup: {
        id: 'app.dontSignup',
        defaultMessage: 'Don\'t have an account?',
        description: 'dontSignup',
    },

    deliveryName: {
        id: 'app.deliveryName',
        defaultMessage: 'Delivery name',
        description: 'deliveryName'
    },
    deliveryDescription: {
        id: 'app.deliveryDescription',
        defaultMessage: 'Delivery Description',
        description: 'deliveryDescription'
    },
    image: {
        id: 'app.image',
        defaultMessage: 'Image',
        description: 'image'
    },
    bannerImage: {
        id: 'app.bannerImage',
        defaultMessage: 'Banner Image',
        description: 'bannerImage'
    },
    textColor: {
        id: 'app.textColor',
        defaultMessage: 'Text Color',
        description: 'textColor'
    },
    backgroundColor: {
        id: 'app.backgroundColor',
        defaultMessage: 'Text background color',
        description: 'backgroundColor'
    },
    iconBackgroundColor: {
        id: 'app.iconBackgroundColor',
        defaultMessage: 'Icon background color',
        description: 'iconBackgroundColor'
    },
    privacyType: {
        id: 'app.privacyType',
        defaultMessage: 'Privacy Type',
        description: 'privacyType'
    },
    public: {
        id: 'app.public',
        defaultMessage: 'Public',
        description: 'public'
    },
    private: {
        id: 'app.private',
        defaultMessage: 'Private',
        description: 'private'
    },
    mobileAppSettings: {
        id: 'app.mobileAppSettings',
        defaultMessage: 'Mobile app settings',
        description: 'Mobile App Settings',
    },
    requestTone: {
        id: 'app.requestTone',
        defaultMessage: 'Request accept timer tone',
        description: 'requestTone'
    },
    uploadRequestTone: {
        id: 'app.uploadRequestTone',
        defaultMessage: 'Upload timer tone',
        description: 'uploadRequestTone'
    },
    appOpenRequest: {
        id: 'app.appOpenTrip',
        defaultMessage: 'Open the provider app when a request notification is received',
        description: 'appOpenTrip'
    },
    registerStore: {
        id: 'app.registerStore',
        defaultMessage: 'Register your store',
        description: 'Register your store'
    },
    typeOfDeliverly: {
        id: 'app.typeOfDeliverly',
        defaultMessage: 'Type of delivery',
        description: 'Type of delivery',
    },
    chooseDelivery: {
        id: 'app.chooseDelivery',
        defaultMessage: 'Choose delivery',
        description: 'Choose delivery',
    },
    previewSample: {
        id: 'app.previewSample',
        defaultMessage: 'Preview sample',
        description: 'previewSample',
    },
    numberOfOrder: {
        id: 'app.numberOfOrder',
        defaultMessage: 'No. of ordered items',
        description: 'numberOfOrder',
    },
    viewDetails: {
        id: 'app.viewDetails',
        defaultMessage: 'View details',
        description: 'View Details Link'
    },
    seconds: {
        id: 'app.seconds',
        defaultMessage: 'sec',
        description: 'seconds'
    },
    orderAcceptInterval: {
        id: 'app.orderAcceptInterval',
        defaultMessage: 'Order Accept Interval',
        description: 'orderAcceptInterval'
    },
    welcomeAdmin: {
        id: 'app.welcomeAdmin',
        defaultMessage: 'Welcome Admin!',
        description: 'Welcome Admin!'
    },
    addPageDetails: {
        id: 'app.addPageDetails',
        defaultMessage: 'Add Page Details',
        description: 'Add Page Details'
    },
    description2Label: {
        id: 'app.description2Label',
        defaultMessage: 'Description #2',
        description: 'Description #2',
    },
    description3Label: {
        id: 'app.description3Label',
        defaultMessage: 'Description #3',
        description: 'Description #3',
    },
    deliveryTypeLabel: {
        id: 'app.deliveryTypeLabel',
        defaultMessage: 'Delivery type:',
        description: 'Delivery type:',
    },
    addDocumentLabel: {
        id: 'app.addDocumentLabel',
        defaultMessage: 'Add document',
        description: 'Add document',
    },
    storeWelcome: {
        id: 'app.storeWelcome',
        defaultMessage: 'Welcome ',
        description: 'Welcome',
    },
    printReceipt: {
        id: 'app.printReceipt',
        defaultMessage: 'Print receipt',
        description: 'Print receipt',
    },
    billingDetails: {
        id: 'app.billingDetails',
        defaultMessage: 'Billing details',
        description: 'Billing details',
    },

    logoutConfirm: {
        id: 'app.logoutConfirm',
        defaultMessage: 'Are you sure you want to logout from your store?',
        description: 'Logout Confirm',
    },
    goBackHome: {
        id: 'app.goBackHome',
        defaultMessage: 'No, Back to store',
        description: 'goBackHome',
    },
    logOutLabel: {
        id: 'app.logOutLabel',
        defaultMessage: 'Log out',
        description: 'Log out',
    },
    yesLogOutLabel: {
        id: 'app.yesLogOutLabel',
        defaultMessage: 'Yes, Log out',
        description: 'Yes, Log out',
    },
    bgColor: {
        id: 'app.bgColor',
        defaultMessage: 'Background color',
        description: 'bgColor'
    },
    eligibleForADelivery: {
        id: 'app.eligibleForADelivery',
        defaultMessage: 'Eligible for Delivery',
        description: 'text'
    },
    notEligibleForADelivery: {
        id: 'app.notEligibleForADelivery',
        defaultMessage: 'Not eligible for Delivery',
        description: 'text'
    },
    managePaymentGateWay: {
        id: 'app.managePaymentGateWay',
        defaultMessage: 'Manage payment gateway',
        description: 'Manage Payment Gateway',
    },
    paymentGateWay: {
        id: 'app.paymentGateWay',
        defaultMessage: 'Payment gateway',
        description: 'Payment Gateway',
    },
    pushNotificationKey: {
        id: 'app.pushNotificationKey',
        defaultMessage: 'Push notification key',
        description: 'pushNotificationKey',
    },
    fcmPushNotificationKey: {
        id: 'app.fcmPushNotificationKey',
        defaultMessage: 'Fcm Push notification key',
        description: 'fcmPushNotificationKey',
    },
    smtpHost: {
        id: 'app.smtpHost',
        defaultMessage: 'SMTP host',
        description: 'smtpHost',
    },
    smtpPort: {
        id: 'app.smtpPort',
        defaultMessage: 'SMTP port',
        description: 'smtpPort',
    },
    smptEmail: {
        id: 'app.smptEmail',
        defaultMessage: 'SMTP email',
        description: 'smptEmail',
    },
    smtpSender: {
        id: 'app.smtpSender',
        defaultMessage: 'SMTP sender',
        description: 'smtpSender',
    },
    smtpSenderEmail: {
        id: 'app.smtpSenderEmail',
        defaultMessage: 'SMTP sender email',
        description: 'smtpSenderEmail',
    },
    smtpPassWord: {
        id: 'app.smtpPassWord',
        defaultMessage: 'SMTP password',
        description: 'smtpPassWord',
    },
    twilioAccountSid: {
        id: 'app.twilioAccountSid',
        defaultMessage: 'Twilio account SID',
        description: 'twilioAccountSid',
    },
    twilioAuthToken: {
        id: 'app.twilioAuthToken',
        defaultMessage: 'Twilio auth token',
        description: 'twilioAuthToken',
    },
    twilioPhone: {
        id: 'app.twilioPhone',
        defaultMessage: 'Twilio phone number',
        description: 'twilioPhone',
    },
    maxUploadLabel: {
        id: 'app.maxUploadLabel',
        defaultMessage: 'Maximum image upload size [MB/KB]',
        description: 'maxUploadLabel',
    },
    manageSiteConfig: {
        id: 'app.manageSiteConfig',
        defaultMessage: 'Manage site configuration',
        description: 'manageSiteConfig',
    },
    stripeSettings: {
        id: 'app.stripeSettings',
        defaultMessage: 'Stripe account settings',
        description: 'stripeSettings'
    },
    twilioSettings: {
        id: 'app.twilioSettings',
        defaultMessage: 'Twilio SMS setup',
        description: 'twilioSettings'
    },
    smtpSettings: {
        id: 'app.smtpSettings',
        defaultMessage: 'SMTP settings',
        description: 'smtpSettings'
    },
    pushNotificationSettings: {
        id: 'app.pushNotificationSettings',
        defaultMessage: 'Push notification settings',
        description: 'pushNotificationSettings'
    },
    subtractTime: {
        id: 'app.subtractTime',
        defaultMessage: 'Maximum time to allocate for delivery partner in prior',
        description: 'subtractTime'
    },
    distance: {
        id: 'app.distance',
        defaultMessage: 'Maximum distance to cover while fetching delivery partners',
        description: 'distance'
    },
    contactNumber: {
        id: 'app.contactNumber',
        defaultMessage: 'Contact Number',
        description: 'contactNumber'
    },
    contactEmail: {
        id: 'app.contactEmail',
        defaultMessage: 'Contact Email',
        description: 'contactEmail'
    },
    contactSkype: {
        id: 'app.contactSkype',
        defaultMessage: 'Contact Skype',
        description: 'contactSkype'
    },
    notificationInterval: {
        id: 'app.notificationInterval',
        defaultMessage: 'Delivery request acceptance time for delivery partner',
        description: 'notificationInterval',
    },
    notificationIntervalInvalid: {
        id: 'app.notificationIntervalInvalid',
        defaultMessage: 'Notification interval must be greater than 0.5 min',
        description: 'notificationIntervalInvalid',
    },
    manageConfigHeading: {
        id: 'app.manageConfigHeading',
        defaultMessage: 'Config Settings',
        description: 'manageConfigHeading',
    },
    subratctTimeInvalid: {
        id: 'app.subratctTimeInvalid',
        defaultMessage: 'Interval must be greater than 10 min',
        description: 'subratctTimeInvalid',
    },
    otherSettings: {
        id: 'app.otherSettings',
        defaultMessage: 'Other settings',
        description: 'otherSettings',
    },
    validateDietary: {
        id: 'app.validateDietary',
        defaultMessage: 'Please choose any delivery type option.',
        description: 'validateDietary',
    },
    confirmationCode: {
        id: 'app.confirmationCode',
        defaultMessage: 'Delivery code',
        description: 'confirmationCode',
    },
    orderConfirmation: {
        id: 'app.orderConfirmation',
        defaultMessage: 'Are you sure want to complete this order?',
        description: 'Are you sure want to complete this order?',
    },
    orderComplete: {
        id: 'app.orderComplete',
        defaultMessage: 'Complete order',
        description: 'Order complete',
    },
    confirmButton: {
        id: 'app.confirmButton',
        defaultMessage: 'Confirm',
        description: 'Confirm',
    },
    stripeCountriesOnly: {
        id: 'app.stripeCountriesOnly',
        defaultMessage: 'Only stripe-supported countries have been displayed here',
        description: 'Payout',
    },
    bic: {
        id: 'app.bic',
        defaultMessage: 'BIC',
        description: 'Payout',
    },
    confirmCbu: {
        id: 'app.confirmCbu',
        defaultMessage: 'Confirm CBU',
        description: 'Payout',
    },
    cbu: {
        id: 'app.cbu',
        defaultMessage: 'CBU',
        description: 'Payout',
    },
    swiftBicCode: {
        id: 'app.swiftBicCode',
        defaultMessage: 'SWIFT / BIC CODE',
        description: 'Payout',
    },
    confirmIbanNumberMismatch: {
        id: 'app.confirmIbanNumberMismatch',
        defaultMessage: 'IBAN number mismatch',
        description: 'Payout',
    },
    institutionNumber: {
        id: 'app.institutionNumber',
        defaultMessage: 'Institution Number',
        description: 'Institution Number',
    },
    clearingCode: {
        id: 'app.clearingCode',
        defaultMessage: 'Clearing Code',
        description: 'Payout',
    },
    accountOwnerName: {
        id: 'app.accountOwnerName',
        defaultMessage: 'Account Owner Name',
        description: 'Payout',
    },

    bsbNumber: {
        id: 'app.bsbNumber',
        defaultMessage: 'BSB Number',
        description: 'Payout',
    },
    transitNumber: {
        id: 'app.transitNumber',
        defaultMessage: 'Transit Number',
        description: 'Transit Number',
    },
    branchCode: {
        id: 'app.branchCode',
        defaultMessage: 'Branch Code',
        description: 'Payout',
    },
    sortCode: {
        id: 'app.sortCode',
        defaultMessage: 'Sort Code',
        description: 'Payout',
    },
    ibanNumber: {
        id: 'app.ibanNumber',
        defaultMessage: 'IBAN Number',
        description: 'Payout form',
    },
    confirmIbanNumber: {
        id: 'app.confirmIbanNumber',
        defaultMessage: 'Confirm IBAN Number',
        description: 'Payout form',
    },
    branchName: {
        id: 'app.branchName',
        defaultMessage: 'Branch Name',
        description: 'Payout',
    },
    bankName: {
        id: 'app.bankName',
        defaultMessage: 'Bank Name',
        description: 'Payout',
    },
    bankCode: {
        id: 'app.bankCode',
        defaultMessage: 'Bank Code',
        description: 'Payout',
    },
    clabe: {
        id: 'app.clabe',
        defaultMessage: 'Clabe',
        description: 'Payout',
    },
    confirmClabe: {
        id: 'app.confirmClabe',
        defaultMessage: 'Confirm Clabe',
        description: 'Payout',
    },
    deliveryDone: {
        id: 'app.deliveryDone',
        defaultMessage: 'Delivery done',
        description: 'seconds'
    },
    pickupInstruction: {
        id: 'app.pickupInstruction',
        defaultMessage: 'Pickup instructions',
        description: 'pickupInstruction',
    },
    addAdminUser: {
        id: 'app.addAdminUser',
        defaultMessage: 'Add admin user',
        description: 'addAdminUser',
    },
    addAdminRoles: {
        id: 'app.addAdminRoles',
        defaultMessage: 'Add admin roles',
        description: 'addAdminRoles',
    },
});

export default messages;