import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import {
    UPDATE_SITE_SETTINGS_START,
    UPDATE_SITE_SETTINGS_ERROR,
    UPDATE_SITE_SETTINGS_SUCCESS
} from '../../../constants/index';
import siteSettings from './siteSettings.graphql';

export default function updateSiteSettings(siteName, siteTitle, metaDescription, facebookLink, twitterLink, instagramLink, logoHeight, logoWidth, metaKeyword, homeLogo, youtubeLink, currency, modifiers, favicon, contactNumber, contactEmail, contactSkype, notificationInterval) {

    return async (dispatch, getState, { client }) => {

        dispatch({
            type: UPDATE_SITE_SETTINGS_START
        })

        try {

            const { data } = await client.mutate({
                mutation: siteSettings,
                variables: {
                    siteName,
                    siteTitle,
                    metaDescription,
                    facebookLink,
                    twitterLink,
                    instagramLink,
                    logoHeight,
                    logoWidth,
                    metaKeyword,
                    homeLogo,
                    youtubeLink,
                    currency,
                    modifiers,
                    favicon,
                    contactNumber,
                    contactEmail,
                    contactSkype,
                    notificationInterval
                }
            })

            if (data && data.updateSiteSettings && data.updateSiteSettings.status == 200) {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_SUCCESS
                })
                toastr.success('Success', 'Site settings changes have been successfully applied')
            } else {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_ERROR
                })
                toastr.error('Oops!', 'Something went wrong' + data && data.updateSiteSettings && data.updateSiteSettings.errorMessage)
            }
        } catch (err) {
            dispatch({
                type: UPDATE_SITE_SETTINGS_ERROR
            })
            toastr.error('Oops!', 'Something went wrong' + err)
        }

    }
}