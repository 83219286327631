exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #F86540;\n  --btn-boxShadow-bg: #F86540;\n  --btn-bordercolor-bg: #F86540;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #F86540;\n  --title-color: #212121;\n  --content-color: #212121;\n\n}\n._26t1d{\n    width:100%;\n    height:550px;\n    background: #fff no-repeat;\n    background-position: 50% 50%;\n    background-size: cover;\n}\n._2gvEZ{\n    width:100%;\n    text-align: center;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -ms-flex-align: center;\n        align-items: center;\n    padding: 70px 0 0;\n}\n/* .city{\n    background: #F9F8F2;\n    padding: 60px 0 0 0 0;\n} */\n._29uSE h1{\n    letter-spacing: 1px;\n    word-spacing: 2px;\n    position: relative;\n    font-size: 30px;\n    font-weight: 600;\n    color: #212121;\n    color: var(--title-color);\n    margin-top: 0px;\n}\n._29uSE p{\n    width: 100%;\n    max-width: 950px;\n    margin: 0 auto;\n    letter-spacing: 1px;\n    word-spacing: 2px;\n    padding-top: 18px;\n    font-size: 18px;\n    color: #212121;\n    color: var(--content-color);\n}\n._3g2Wv {\n    padding: 0px;\n}\n.Zj80P {\n\twidth: 100%;\n    max-width: 1170px;\n    margin: 0 auto;\n}\n\n", ""]);

// exports
exports.locals = {
	"carBg": "_26t1d",
	"cityBg": "_2gvEZ",
	"cityText": "_29uSE",
	"noPadding": "_3g2Wv",
	"cotainerWith": "Zj80P"
};