import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout'
import MobileSettings from './MobileSettings'
import messages from '../../../locale/messages';
import { restrictUrls } from '../../../helpers/adminPrivileges';

function action({ store, intl }) {
    const title = intl.formatMessage(messages.mobileAppSettings);

    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;
    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    // Admin restriction
    // if (!restrictUrls('/siteadmin/settings/mobile', adminPrivileges, privileges)) {
    //     return { redirect: '/siteadmin' };
    // }
    return {
        title,
        component: (
            <AdminLayout>
                <MobileSettings title={title} />
            </AdminLayout>
        )
    }
}

export default action