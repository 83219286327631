import fetch from 'node-fetch';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { setRuntimeVariable } from '../../runtime';
import { closeLoginModal } from '../../modalActions';
import { getAllShopModifier } from '../modifier/getAllShopModifier';

import { firebaseConfig, vapidKey, enableFirebase } from '../../../config';
let app;
if (enableFirebase) {
    app = initializeApp(firebaseConfig);
}

import {
    SHOP_USER_LOGIN_START,
    SHOP_USER_LOGIN_SUCCESS,
    SHOP_USER_LOGIN_ERROR
} from '../../../constants';

import { loadAccount } from '../userAccount/userAccount';

const query = `
        query (
            $email: String!,
            $password: String!,
            $deviceId: String,
        ) {
            shopUserLogin (
                email: $email,
                password: $password,
                deviceId: $deviceId
            ) {
                status
                errorMessage
                result {
                    id
                    email
                }
            }
        }`;

function userLogin(email, password) {

    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: SHOP_USER_LOGIN_START
            });

            let deviceId;

            if (enableFirebase) {
                const messaging = await getMessaging(app);

                if ('serviceWorker' in navigator) {
                    await navigator.serviceWorker.register('firebase-messaging-sw.js')
                        .then(async function (registration) {
                            await getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
                                if (currentToken) {
                                    deviceId = currentToken;
                                } else {
                                    console.log('No registration token available. Request permission to generate one.');
                                }
                            }).catch((err) => {
                                console.log('An error occurred while retrieving token. ', err);
                            });
                        }).catch(function (err) {
                            console.log('Service worker registration failed, error:', err);
                        });
                }
            }

            const response = await fetch('/graphql', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    variables: {
                        email: email,
                        password: password,
                        deviceId
                    }
                }),
                credentials: 'include'
            });

            const { data } = await response.json();

            if (data.shopUserLogin.status == 200) {
                await dispatch(loadAccount());
                await dispatch(setRuntimeVariable({
                    name: 'isStoreAuthenticated',
                    value: true,
                }));
                await dispatch(getAllShopModifier());
                await dispatch(closeLoginModal());
                dispatch({ type: SHOP_USER_LOGIN_SUCCESS })
                return {
                    status: 200
                }
            } else {
                dispatch({ type: SHOP_USER_LOGIN_ERROR })
                return {
                    status: 400,
                    errorMessage: data.shopUserLogin.errorMessage
                }
            }
        } catch (error) {
            dispatch({ type: SHOP_USER_LOGIN_ERROR })

            return false;
        }

    }




}

export default userLogin;