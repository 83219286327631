import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.sliderTitle1) {
        errors.sliderTitle1 = messages.required;
    } else if (values.sliderTitle1.trim() == "") {
        errors.sliderTitle1 = messages.required;
    }

    if (!values.sliderContent1) {
        errors.sliderContent1 = messages.required;
    } else if (values.sliderContent1.trim() == "") {
        errors.sliderContent1 = messages.required;
    }


    if (!values.sliderTitle2) {
        errors.sliderTitle2 = messages.required;
    } else if (values.sliderTitle2.trim() == "") {
        errors.sliderTitle2 = messages.required;
    }

    if (!values.sliderContent2) {
        errors.sliderContent2 = messages.required;
    } else if (values.sliderContent2.trim() == "") {
        errors.sliderContent2 = messages.required;
    }


    if (!values.sliderTitle3) {
        errors.sliderTitle3 = messages.required;
    } else if (values.sliderTitle3.trim() == "") {
        errors.sliderTitle3 = messages.required;
    }

    if (!values.sliderContent3) {
        errors.sliderContent3 = messages.required;
    } else if (values.sliderContent3.trim() == "") {
        errors.sliderContent3 = messages.required;
    }

    if (!values.sliderTitle4) {
        errors.sliderTitle4 = messages.required;
    } else if (values.sliderTitle4.trim() == "") {
        errors.sliderTitle4 = messages.required;
    }

    if (!values.sliderContent4) {
        errors.sliderContent4 = messages.required;
    } else if (values.sliderContent4.trim() == "") {
        errors.sliderContent4 = messages.required;
    }


    return errors;
};

export default validate;