import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, ButtonToolbar, Button, FormControl } from 'react-bootstrap';
import s from './CancellationReasonList.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import messages from '../../../locale/messages';
import CustomPagination from '../../CustomPagination/CustomPagination';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import Link from '../../Link/Link';
import moment from 'moment'
import TrashIcon from '../../../../public/Icons/bin.svg';
import EditIcon from '../../../../public/Icons/edit.png';
import removeCancelReason from '../../../actions/siteadmin/removeCancelReason';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirst';
//image
import addIcon from '../../../../public/Icons/addNewIcon.svg';

export class CancellationReason extends Component {

    static propTypes = {
        cancelReasons: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.state = {
            currentPage: 1,
        }
        this.paginationData = this.paginationData.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    async handleDelete(id) {
        const { cancelReasons: { refetch }, removeCancelReason } = this.props;
        let variables = { currentPage: 1 };
        this.setState({ currentPage: 1 });
        removeCancelReason(id, 1);
        refetch(variables)
    }

    handleSearchClick(searchList) {
        const { cancelReasons: { refetch } } = this.props
        let variables = {
            currentPage: 1,
            searchList: searchList
        }
        this.setState({ currentPage: 1 })
        refetch(variables)
    }

    handleSearchChange(e) {
        let self = this
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout)
        }
        self.setState({
            searchList: e.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.handleSearchClick(self.state.searchList)
            }, 450)
        })
    }

    paginationData(currentPage) {
        const { cancelReasons: { refetch } } = this.props;
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }

    render() {

        const { cancelReasons, cancelReasons: { getAllCancelReason } } = this.props;
        const { currentPage } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
                <div className={cx(s.exportDisplay, s.tabelExport)}>
                    <div className={'textAlignRightRTL'}>
                        <div className={s.searchInput}>
                            <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
                        </div>
                    </div>
                    <div className={cx(s.exportTextSection, 'textAlignLeftRTL')}>
                        <div className={cx(s.paddingTop2)}>
                            <Link to={"/siteadmin/cancel-reasons/add"} className={s.addlinkBtn} ><FormattedMessage {...messages.addCancelReason} /> <span className={cx(s.addIconCss, 'addIconCssRTL')}><img src={addIcon} /></span></Link>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
                    <Table className={cx("table")}>
                        <thead>
                            <tr>
                                <th scope="col"><FormattedMessage {...messages.id} /></th>
                                <th scope="col"><FormattedMessage {...messages.cancelReason} /></th>
                                <th scope="col"><FormattedMessage {...messages.cancellationType} /></th>
                                <th scope="col"><FormattedMessage {...messages.status} /></th>
                                <th scope="col"><FormattedMessage {...messages.action} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cancelReasons && cancelReasons.getAllCancelReason && cancelReasons.getAllCancelReason.result && cancelReasons.getAllCancelReason.result.length > 0 && cancelReasons.getAllCancelReason.result.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td data-label={formatMessage(messages.id)}>{item.id}</td>
                                            <td data-label={formatMessage(messages.cancelReason)}>{capitalizeFirstLetter(item.reason)}</td>
                                            <td data-label={formatMessage(messages.cancellationType)}>{
                                                (item.userType == 1 && formatMessage(messages.userCancel)) ||
                                                (item.userType == 2 && formatMessage(messages.driverCancel)) ||
                                                (item.userType == 3 && formatMessage(messages.driverUnableToDeliver)) ||
                                                (item.userType == 4 && formatMessage(messages.storeDecline))
                                            }</td>
                                            <td data-label={formatMessage(messages.status)}>{item.isActive == true ? formatMessage(messages.active) : formatMessage(messages.inactive)}</td>
                                            <td data-label={formatMessage(messages.action)}>
                                                <Link to={'/siteadmin/cancel-reasons/edit/' + item.id} className={cx('editAlignIcon', s.editColorIcon)}>
                                                    <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                                                    <span className={s.vtrMiddle}>
                                                        <FormattedMessage {...messages.editAction} />
                                                    </span>
                                                </Link>
                                                <Button onClick={() => this.handleDelete(item.id, currentPage)} className={cx(s.iconBtn, 'iconBtnRTL')}
                                                >
                                                    <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                                                    <span className={s.vtrMiddle}>
                                                        <FormattedMessage {...messages.deleteAction} />
                                                    </span>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                ((cancelReasons && cancelReasons.getAllCancelReason && cancelReasons.getAllCancelReason.result && cancelReasons.getAllCancelReason.result.length == 0)) && (
                                    <tr>
                                        <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
                {
                    cancelReasons && cancelReasons.getAllCancelReason && cancelReasons.getAllCancelReason.result && cancelReasons.getAllCancelReason.result.length > 0
                    && <div className={cx(s.space5, s.spaceTop5)}>
                        <CustomPagination
                            total={cancelReasons.getAllCancelReason.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.manageCancelReason)}
                        />
                    </div>
                }
            </div>
        )
    }
}

const mapState = (state) => ({});

const mapDispatch = {
    removeCancelReason
};

export default injectIntl(compose(injectIntl,
    withStyles(s),
    connect(mapState, mapDispatch)
)(CancellationReason));