import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import {
	Form,
	Col,
	Card,
	Row,
	FormControl
} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import Loader from '../../Common/Loader';
import PlacesSuggest from '../PlacesSuggest';
import Link from '../../Link/Link';
import LoginModal from '../LoginModal/LoginModal';
import HeaderModal from '../../HeaderModal/HeaderModal';
import ForgotPasswordModal from '../ForgotPassword/ForgotPasswordModal';

import s from './SignupForm.css';
import rs from '../../storeCommon.css';

// Action
import { openLoginModal } from "../../../actions/modalActions";
import { openHeaderModal } from '../../../actions/siteadmin/modalActions';

import { formatLocale } from '../../../helpers/formatLocale';
import messages from '../../../locale/messages';
import validate from './validate';
import submit from './submit';
import { api, partnerHomepageUploadDir, logoUploadDir } from '../../../config';

//images
import languageIcon from '../../../../public/Icons/Language-black.svg';
import arrow from '../../../../public/Icons/right.svg';
import bgImage from '../../../../public/SiteImages/signUpBg.png';
import backArrow from '../../../../public/SiteImages/signupBackArrow.png';

export class SignupForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			countryCode: 'US',
			countryDialCode: '+1',
			phoneNumber: '',
			categories: [],
		};

		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handleLocationData = this.handleLocationData.bind(this);
		this.handleOnChange = this.handleOnChange.bind(this);
		this.changedeliveryType = this.changedeliveryType.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	static defaultProps = {
		loading: false
	};

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={s.formGroup}>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.arrow_box, 'arrow_boxRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<FormControl as="select" {...input} className={className} >
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.arrow_box, 'arrow_boxRTL')}>{formatMessage(error)}</span>}
			</div>
		)
	}


	renderAddress = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={s.formGroup}>
				<PlacesSuggest
					form={'signup'}
					label={formatMessage(messages.address)}
					className={s.formGroup}
					handleData={this.handleLocationData}
					error={error}
				/>
				{touched && error && <span className={cx(s.arrow_box, 'arrow_boxRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderPhoneNUmber = ({ meta: { touched, error } }) => {
		const { formatMessage } = this.props.intl;
		const { phoneNumber } = this.state;
		return (
			<div>
				<PhoneInput
					country={'us'}
					value={phoneNumber}
					onChange={this.handleOnChange}
					countryCodeEditable={false}
				/>
				{touched && error && <span className={cx(s.arrow_box, 'arrow_boxRTL')}>{formatMessage(error)}</span>}
			</div>
		);
	}

	componentDidMount() {
		const { initialize, getCategory } = this.props;
		const { countryCode, countryDialCode } = this.state;

		initialize({ 'phoneCountryCode': countryCode, 'phoneDialCode': countryDialCode });
	};


	handleLocationData(location) {
		const { change } = this.props;

		let addressLabel = location && location.label;
		let addressPosition = location && location.location;
		let locationArray = addressLabel && addressLabel.split(',').slice(-3).reverse();

		change('address', addressLabel)
		change('city', locationArray && locationArray[2] && locationArray[2].trim());
		change('state', locationArray && locationArray[1] && locationArray[1].trim());
		change('country', locationArray && locationArray[0] && locationArray[0].trim());
		change('lat', addressPosition && addressPosition.lat);
		change('lng', addressPosition && addressPosition.lng);
	};

	handleCountryChange(country, event) {
		const { change } = this.props;

		this.setState({
			countryCode: country.countryCode,
			countryDialCode: country.dialCode
		});

		change('phoneCountryCode', country.countryCode)
		change('phoneDialCode', country.dialCode)
	};

	handleOnChange(value, event) {
		const { change } = this.props;

		this.setState({ phoneNumber: value });
		let formattedNumber = value.replace(/[^0-9]+/g, '').slice(event.dialCode.length);
		let dialCode = '+' + event.dialCode;
		let countryCode = event.countryCode.toUpperCase();

		change('phoneCountryCode', countryCode)
		change('phoneDialCode', dialCode)
		change('phoneNumber', formattedNumber)

	}

	changedeliveryType(e) {
		const { change, getCategory } = this.props;
		let newValue = e.target.value;
		let data = getCategory && getCategory.result && getCategory.result.length > 0 && getCategory.result.filter(o => o.deliveryType == newValue);
		this.setState({
			categories: data
		});
	}

	handleChange(e) {
		const { openHeaderModal } = this.props;
		openHeaderModal('languageModal');
	}

	render() {
		const { handleSubmit, submitting, loading, error, deliveryTypes, homepage } = this.props;
		const { openLoginModal, currentLocale, logo, logoHeight, logoWidth, siteName } = this.props;
		const { countryCode, countryDialCode, phoneNumber, categories } = this.state;
		const { formatMessage } = this.props.intl;
		return (
			<div className={cx(s.responsiveNoPadding, 'tabLabelText', 'placeHolderText')}>
				<LoginModal />
				<ForgotPasswordModal />
				<HeaderModal />
				<div className={s.displayGrid}>
					<div className={s.bgImage} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir + homepage.bannerImage2})` }}>
						<div className={s.positionRelative}>

							<Link to={'/'} className={s.backBtn}>
								<span className={'floatLeftRTL'}><img src={backArrow} /></span>
								<span>{formatMessage(messages.backToHome)}</span>
							</Link>

							<div className={s.innerSection}>
								<img
									src={api.apiEndpoint + logoUploadDir + logo}
									width={Number(logoWidth)}
									height={Number(logoHeight)}
									alt={siteName}
								/>
								<div className={s.searchFormWrap}>
									<h1>
										{homepage.bannerTitle1}
									</h1>
									<p>
										{homepage.bannerContent1}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className={s.sectionTwo}>
						<div className={s.alignRight}>

							<Link noLink
								onClick={(e) => this.handleChange(e)}
								className={cx(s.siteColor, s.cursurPointer)}>
								<span className={cx(s.languageIcon, 'languageIconRTL')}><img src={languageIcon} /></span>
								<span>
									{formatLocale(currentLocale)}
								</span>
							</Link>

							<Link noLink
								onClick={openLoginModal}
								className={cx(s.storeLink, s.cursurPointer, 'signStoreRTL')}>
								<span><FormattedMessage {...messages.signin} /></span>
								<span className={'storeLinkRTL'}><img src={arrow} /></span>
							</Link>

						</div>
						<Card className={s.card}>
							<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
								<div className={rs.spaceTop5}>
									<Form.Group className={cx(s.formGroup, 'signupCategorySelect')}>
										{error && <span className={s.arrow_box}>{error.defaultMessage}</span>}
										<Field name="deliveryType" className={cx(s.formControlSelect, s.formControlInput)}
											component={this.renderFormControlSelect}
											placeholder={formatMessage(messages.chooseDelivery)}
											onChange={this.changedeliveryType}
										>
											<option value="">{formatMessage(messages.typeOfDeliverly)}</option>
											{
												deliveryTypes && deliveryTypes.results && deliveryTypes.results.map((item, key) =>
													<option value={item.id} key={key}>{item.deliveryName}</option>
												)
											}
										</Field>
										<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.typeOfDeliverly)}</label>
									</Form.Group>
								</div>
								<div className={rs.spaceTop5}>
									<Form.Group className={cx(s.formGroup, 'signupCategorySelect')}>
										{error && <span className={s.arrow_box}>{error.defaultMessage}</span>}
										<Field name="categoryType" className={cx(s.formControlSelect, s.formControlInput)}
											component={this.renderFormControlSelect}
											placeholder={formatMessage(messages.category)}
										>
											<option value="">{formatMessage(messages.chooseCategory)}</option>
											{
												categories && categories.length > 0 && categories.map((item, key) =>
													<option value={item.id} key={key}>{item.categoryName}</option>
												)
											}
										</Field>
										<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.chooseCategory)}</label>
									</Form.Group>
								</div>
								<div className={rs.spaceTop5}>
									<Form.Group className={s.formGroup}>
										<div>
											<Field
												name="shopName"
												type="text"
												placeholder={formatMessage(messages.shopName)}
												component={this.renderField}
												fieldClass={s.formControlInput}
											/>
											<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.shopName)}</label>
										</div>
									</Form.Group>
								</div>
								<div className={rs.spaceTop5}>
									<Form.Group className={s.formGroup}>
										<div className={'singUpFormPlaceSuggest'}>
											<Field
												name="address"
												component={this.renderAddress}
											/>
											<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.address)}</label>
										</div>
									</Form.Group>
								</div>
								<div className={rs.spaceTop5}>
									<Form.Group className={s.formGroup}>
										<div>
											<Field
												name="zipcode"
												type="text"
												placeholder={formatMessage(messages.zipcode)}
												component={this.renderField}
												fieldClass={s.formControlInput}
											/>
											<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.zipcode)}</label>
										</div>
									</Form.Group>
								</div>
								<div>
									<Row>
										<Col lg={6} md={12} sm={12} xs={12} className={cx(rs.spaceTop5, s.paddingReduseRight)}>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="firstName"
														type="text"
														placeholder={formatMessage(messages.firstName)}
														component={this.renderField}
														labelClass={s.labelText}
														fieldClass={s.formControlInput}
													/>
													<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.firstName)}</label>
												</div>
											</Form.Group>
										</Col>
										<Col lg={6} md={12} sm={12} xs={12} className={cx(rs.spaceTop5, s.paddingReduseLeft)}>
											<Form.Group className={s.formGroup}>
												<div>
													<Field
														name="lastName"
														type="text"
														placeholder={formatMessage(messages.lastName)}
														component={this.renderField}
														labelClass={s.labelText}
														fieldClass={s.formControlInput}
													/>
													<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.lastName)}</label>
												</div>
											</Form.Group>
										</Col>
									</Row>
								</div>
								<div className={rs.spaceTop5}>
									<Form.Group className={s.formGroup}>
										<div>
											<Field
												name="email"
												type="text"
												placeholder={formatMessage(messages.email)}
												component={this.renderField}
												labelClass={s.labelText}
												fieldClass={s.formControlInput}
											/>
											<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.email)}</label>
										</div>
									</Form.Group>
								</div>
								<div className={cx(rs.spaceTop5, 'storeActiveSelect', 'countryInputPhone')}>
									<Form.Group className={s.formGroup}>
										<div>
											<Field name="phoneNumber"
												component={this.renderPhoneNUmber}
											/>
											<label className={cx(s.signupLabel, s.phoneLabel, 'signupLabelRTL')}>{formatMessage(messages.phoneNumber)}</label>
										</div>
									</Form.Group>
								</div>

								<div className={rs.spaceTop5}>
									<Form.Group className={s.formGroup}>
										<div>
											<Field
												name="password"
												type="password"
												placeholder={formatMessage(messages.password)}
												component={this.renderField}
												labelClass={s.labelText}
												fieldClass={s.formControlInput}
											/>
											<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.password)}</label>
										</div>
									</Form.Group>
								</div>
								<div className={rs.spaceTop5}>
									<Form.Group className={s.formGroup}>
										<div>
											<Field
												name="confirmPassword"
												type="password"
												placeholder={formatMessage(messages.confirmPassword)}
												component={this.renderField}
												labelClass={s.labelText}
												fieldClass={s.formControlInput}
											/>
											<label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.confirmPassword)}</label>
										</div>
									</Form.Group>
								</div>
								<div className={rs.spaceTop2}>
									<div>
										<span className={s.singupDescriction}>
											<FormattedMessage {...messages.singUpDesc} />
										</span>
									</div>
								</div>
								<div className={cx(s.textAlignRight, rs.spaceTop5)}>
									<Form.Group className={s.formGroup}>
										<div>
											<Loader
												type={"button"}
												label={formatMessage(messages.signup)}
												show={loading}
												buttonType={'submit'}
												className={cx(s.button, s.btnPrimary, s.singUpBtn)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</div>
							</Form>
						</Card>
					</div>
				</div>
			</div>
		)
	}
};

SignupForm = reduxForm({
	form: 'SignupForm',
	onSubmit: submit,
	validate
})(SignupForm);

const mapStateToProps = (state) => ({
	loading: state.loader.StoreSignup,
	siteName: state.siteSettings.data.siteName,
	currentLocale: state.intl.locale,
	logo: state.siteSettings.data.homeLogo,
	logoHeight: state.siteSettings.data.logoHeight,
	logoWidth: state.siteSettings.data.logoWidth,
});

const mapDispatchToProps = {
	openLoginModal,
	openHeaderModal
};

export default injectIntl(withStyles(s, rs)(connect(mapStateToProps, mapDispatchToProps)(SignupForm)));