import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import getOrderDetailsQuery from '../../../routes/shop/orderDetails/getOrders.graphql';
import { closeModal } from '../../siteadmin/modalActions';


export default function updateDeliveryStatus(id, isOrderDetailPage, status) {
    return async (dispatch, getState, { client }) => {
        try {
            let refetchQueries = [];
            let mutation = gql`
                mutation updateDeliveryStatus($id: Int!, $status: String) {
                    updateDeliveryStatus (id: $id, status: $status) {
                        status
                        errorMessage
                        successMessage
                    }
                }
            `;

            if (isOrderDetailPage) {
                refetchQueries = [{
                    query: getOrderDetailsQuery,
                    variables: { id }
                }];
            }

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id,
                    status
                },
                refetchQueries
            });

            if (data && data.updateDeliveryStatus && data.updateDeliveryStatus.status === 200) {
                toastr.success('Success', data.updateDeliveryStatus.successMessage);
                dispatch(closeModal('completeConfirmModal'));
            } else {
                toastr.error('Error!', data && data.updateDeliveryStatus && data.updateDeliveryStatus.errorMessage);
            }

            return true;
        } catch (error) {
            toastr.error('Error', error);

        }
    }
};