import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EatsMobileApp.css';
import cx from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { api } from '../../../config';

class EatsMobileApp extends React.Component {

    render() {
        const { getSettings } = this.props;
        let path = "/images/homepage/";
        return (
            <div 
                className={cx(s.root, s.MobileBg)} 
                style={{ background: "linear-gradient(90deg, " + getSettings("userAppBgColor") + " 50%, rgba(255,255,255,1) 50%)" }}
            >
                <Container className={cx(s.bannerLayoutContainer)} >
                    <Row className={s.displayFlex}>
                        <Col xl={6} md={6} sm={12} xs={12}>
                            <div className={s.signUp}>
                                <div className={cx(s.phoneImgSection, 'phoneImgSectionRTL')}>
                                    <img src={api.apiEndpoint + path + getSettings("safetyGridImage3")} className={s.phoneImg} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={6} sm={12} xs={12} className={cx(s.spaceTop6, s.paddingLeftSection, 'paddingLeftSectionRTL')}>
                            <div className={cx(s.signUp, s.space5)}>
                                <h3>
                                    <span className={s.siteColor}>{getSettings("safetyGridTitle1")}</span>
                                </h3>
                            </div>
                            <div className={cx(s.spaceTop4, 'textAlignRightRTL')}>
                                <div className={cx(s.displayInlineBlock, s.paddingRight, 'appPaddingRTL')}>
                                    <a href={getSettings("safetyGridLink1")} target="_blank" className={s.btn}>
                                        <img src={api.apiEndpoint + path + getSettings("safetyGridImage1")} className={s.appImg} />
                                    </a>
                                </div>
                                <div className={cx(s.displayInlineBlock, s.paddingLeft)}>
                                    <a href={getSettings("safetyGridLink2")} target="_blank" className={s.btn}>
                                        <img src={api.apiEndpoint + path + getSettings("safetyGridImage2")} className={s.appImg} />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withStyles(s)(EatsMobileApp);