import {
    DELIVERY_TYPE_UPDATE_SUCCESS,
    DELIVERY_TYPE_UPDATE_START,
    DELIVERY_TYPE_UPDATE_ERROR
} from '../../../constants';
import { toastr } from 'react-redux-toastr';
import updateMutation from './updateStatus.graphql'

export default function updateDeliveryStatus(id, status) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: DELIVERY_TYPE_UPDATE_START
        });

        try {
            let errorMessage;
            const { data } = await client.mutate({
                mutation: updateMutation,
                variables: {
                    id,
                    status: status == '1' ? 1 : 0
                }
            });

            if (data && data.updateDeliveryTypeStatus && data.updateDeliveryTypeStatus.status === 200) {
                dispatch({
                    type: DELIVERY_TYPE_UPDATE_SUCCESS
                });
                toastr.success('Success', `The deivery type status has been updated.`);
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: DELIVERY_TYPE_UPDATE_ERROR
                });
                errorMessage = (data && data.updateDeliveryTypeStatus && data.updateDeliveryTypeStatus.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                };
            }
        } catch (err) {
            toastr.error('Error! Something went wrong!', err);
            dispatch({
                type: DELIVERY_TYPE_UPDATE_ERROR
            });
            return {
                status: 400
            };
        }

    }
};