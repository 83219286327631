import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AddPromoCode.css';
import cx from 'classnames';
import PromoCodeForm from '../../../../components/SiteAdmin/PromoCode/PromoCodeForm';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';


class AddPromoCode extends React.Component {
  render() {
    const { title, initialValues } = this.props;

    return (
      <div className={s.root}>
        <div className={s.container}>
         <div className={cx(s.heading, 'textAlignRightRTL')}>
          <FormattedMessage {...messages.addPromoCodeHead} />
          </div>
          <div>
            <PromoCodeForm initialValues={initialValues} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(AddPromoCode);