import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
	Row,
	Col,
	Button,
	Table,
	Container,
	Accordion,
	Card
} from 'react-bootstrap';

import Link from '../../Link';
import DeclineReasonModal from '../OrderManagement/DeclineReasonModal/DeclineReasonModal';
import ConfirmationPopup from '../../Common/ConfirmationPopup/ConfirmationPopup';
import CurrencyConverter from '../../CurrencyConverter';

import { openDeclineReasonModal, openModal, closeModal } from '../../../actions/siteadmin/modalActions';
import updateItemStatus from '../../../actions/shop/orders/updateItemStatus';
import updateOrderStatus from '../../../actions/shop/orders/updateOrderStatus';

import { bookingStatus } from '../../../helpers/bookingStatus';
import { isRTL } from '../../../helpers/formatLocale';
import messages from '../../../locale/messages';

// images
import downArrow from '../../../../public/StoreIcon/Dropdownwithcircle20x20.svg';
import upArrow from '../../../../public/StoreIcon/Uparrowwithcircle20x20.svg';
import printIcon from '../../../../public/Icons/printer@2x.png';

import s from './OrderDetails.css';
import rs from '../../storeCommon.css';
class OrderDetails extends Component {

	static defaultProps = {
		type: 'previous',
		siteCurrency: null
	};

	constructor(props) {
		super(props);

		this.state = {
			buttonLoader: false,
			collapseID: [],
			loading: false
		};
		this.printRef = null;
		this.handleStatusChange = this.handleStatusChange.bind(this);
		this.renderBookingStatus = this.renderBookingStatus.bind(this);
		this.handleDeliveryStatus = this.handleDeliveryStatus.bind(this);
		this.handleButtonLoader = this.handleButtonLoader.bind(this);
		this.toggleCollapse = this.toggleCollapse.bind(this);
		this.openPdf = this.openPdf.bind(this);
		this.confirmModalBody = this.confirmModalBody.bind(this);
	}

	componentWillUnmount() {
		clearTimeout(this.buttonLoaderTimeout);
	}

	async handleStatusChange(id, e) {
		const { updateOrderStatus, openDeclineReasonModal } = this.props;
		let bookingStatus = e.target.value;
		if (bookingStatus === 'approved') {
			await updateOrderStatus(id, bookingStatus, 1, null, true);
		} else if (bookingStatus === 'declined') {
			await openDeclineReasonModal(id, 1);
		}
	}

	async handleDeliveryStatus(id, status) {
		const { updateItemStatus, closeModal } = this.props;
		const { buttonLoader } = this.state;

		if (!buttonLoader) {
			this.handleButtonLoader(id);
			await updateItemStatus(id, true, status);
			await closeModal('completeConfirmModal');
		}
	}

	handleButtonLoader(id) {
		this.setState({ buttonLoader: true });
		this.buttonLoaderTimeout = setTimeout(() => {
			this.setState({ buttonLoader: false });
		}, 750);
	}

	renderBookingStatus(status) {
		const { currentLocale } = this.props;
		if (status) {
			return <div className={cx(s.totalText, s.orderItem, s.statusLabel, rs.space1, rs.spaceTop2, 'statusLabelRTL')}>{bookingStatus(status, currentLocale)}</div>;
		}
	}

	openPdf() {
		window.print();
	}

	toggleCollapse(id1, id2) {
		const { collapseID } = this.state;
		const index = collapseID.indexOf(id1 + '.' + id2);
		if (index > -1) {
			collapseID.splice(index, 1);
			this.setState({ collapseID });
		} else {
			collapseID.push(id1 + '.' + id2);
			this.setState({ collapseID });
		}
	}

	confirmModalBody(code) {
		const { formatMessage } = this.props.intl;
		return (
			code &&
			<>
				<h4 className={s.completeText}>
					<FormattedMessage {...messages.orderConfirmation} />
				</h4>
				<h5 className={s.confirmCode} >
					{`${formatMessage(messages.confirmationCode)}: ${code}`}
				</h5>
			</>
		)
	}

	render() {
		const { formatMessage } = this.props.intl;
		const { type, data, updateItemStatus, siteCurrency, siteName, intl: { locale }, completeConfirmModal, modalData, openModal, closeModal } = this.props;
		const { buttonLoader, collapseID, loading } = this.state;
		let orderPage, pageType, allowedItemStatus, utcdate, localDate;
		
		allowedItemStatus = ['approved', 'readyForDelivery'];
		utcdate = moment.utc(data.assignDeliveryAt).format('YYYY-MM-DD HH:mm:ss');
		localDate = moment.utc(utcdate).toDate();
		localDate = moment(localDate).format('YYYY-MM-DD HH:mm:ss');

		if (type === 'previous' || type === 'upcoming') {
			orderPage = 'order-management';
			pageType = type;
		} else {
			orderPage = 'transaction';
			pageType = type == "completed" ? "completed" : "future";
		}

		return (
			<div className={'mainContainer'} >
				<Container fluid ref={el => (this.componentRef = el)}>
					{
						type === 'upcoming' && <DeclineReasonModal isOrderDetailPage={true} />
					}
					<div className='printOnly'><h1>{siteName}</h1></div>
					<ConfirmationPopup
						modalStatus={completeConfirmModal}
						title={formatMessage(messages.orderComplete)}
						body={this.confirmModalBody(modalData?.confirmCode)}
						closeModal={() => closeModal('completeConfirmModal')}
						popupButtonLeftName={formatMessage(messages.cancelButton)}
						popupButtonRightName={formatMessage(messages.confirmButton)}
						popupButtonLeftFunction={() => closeModal('completeConfirmModal')}
						popupButtonRightFunction={() => this.handleDeliveryStatus(modalData.id, modalData.status)}
						orderTitle={cx(s.orderTitleText, 'textAlignRightRTL')}
					/>
					<Row>
						{
							data && <Col lg={12} md={12} sm={12} xs={12}>
								<div className={s.titleSection}>
									<div>
										<Row>
											<Col lg={6} md={8} sm={12} xs={12}>
												<h1 className={cx(s.titleBottom, rs.storeTitleText, 'textAlignRightRTL', { [s.textRightRTL]: isRTL(locale) })}><FormattedMessage {...messages.orderDetails} /></h1>
											</Col>
											<Col lg={6} md={4} sm={12} xs={12}>
												{
													data.bookingStatus != 'pending' && <div className={cx(s.receipt, 'receiptBtn', 'textAlignLeftRTL')}>
														{!loading &&
															<Button onClick={this.openPdf} className={cx('hidden-print', s.printBtn)} >
																<FormattedMessage {...messages.printReceipt} /> <img src={printIcon} className={s.printIconCss} />
															</Button>
														}
													</div>
												}
											</Col>
										</Row>
									</div>
								</div>

								<div className={cx(s.orderDetailsBg)}>
									<div>
										<div className={cx(s.detailBg, 'printDetailsBg')}>
											<p className={cx(s.titleText, rs.space2, rs.spaceTop2, s.floatLeft, 'floatRightRTL', { [s.printRightFloatRTL]: isRTL(locale) })}> <FormattedMessage {...messages.orderId} />: #{data.orderId}</p>
											<div className={cx(s.aligntextRight, s.displayTextInline, 'hidden-print', 'textAlignLeftRTL')}>
												<span><FormattedMessage {...messages.orderStatus} />:</span>{' '}
												{
													type === 'upcoming' && data.bookingStatus === 'pending' && <div className={cx(s.btn1, rs.space1, rs.spaceTop1)}>
														<select className={cx(s.orderSelect, rs.storeSelectInput, 'orderSelectRTL')}
															onChange={(e) => this.handleStatusChange(data.id, e)}
															disabled={data.bookingStatus !== 'pending' ? true : false}>
															<option value={'pending'}>{formatMessage(messages.pendingLabel)}</option>
															<option value={'approved'}>{formatMessage(messages.acceptedLabel)}</option>
															<option value={'declined'}>{formatMessage(messages.rejectedLabel)}</option>
														</select>
													</div>
												}
												{
													data.bookingStatus !== 'pending' && this.renderBookingStatus(data.bookingStatus)
												}
												{
													type === 'upcoming' && data.bookingStatus === 'approved' && <div className={cx(s.btn2, rs.space1, rs.spaceTop1)}>
														<Button
															className={cx(rs.button, rs.btnPrimary, rs.btnFullWidth, (allowedItemStatus.indexOf(data.bookingStatus) == -1 ? s.btnInactive : ''))}
															disabled={(allowedItemStatus.indexOf(data.bookingStatus) == -1 ? true : false) || buttonLoader}
															onClick={() => this.handleDeliveryStatus(data.id, 'readyForDelivery')}
														>
															<FormattedMessage {...messages.itemIsDoneLabel} />
														</Button>
													</div>
												}

												{
													type === 'upcoming' && data.bookingStatus === 'readyForDelivery' && !data.isDoorStepDelivery && <div className={cx(s.btn2, rs.space1, rs.spaceTop1)}>
														<Button
															className={cx(rs.button, rs.btnPrimary, rs.btnFullWidth)}
															disabled={buttonLoader}
															onClick={() => openModal('completeConfirmModal', { id: data.id, confirmCode: data.confirmationCode, status: 'completed' })}
														>
															<FormattedMessage {...messages.deliveryDone} />
														</Button>
													</div>
												}

											</div>
											<div className='clearBoth'></div>
										</div>
										<div className={cx(s.commonPadding, 'textAlignRightRTL', { [s.textRightRTL]: isRTL(locale) })}>
											<p className={s.subText}>
												<FormattedMessage {...messages.confirmationCode} /> - {data.confirmationCode}
											</p>
										</div>
										<div className={cx(s.commonPadding, 'textAlignRightRTL', { [s.textRightRTL]: isRTL(locale) })}>
											<p className={s.subText}>
												<FormattedMessage {...messages.preparationTime} /> - {data.shopPreparationTime} <FormattedMessage {...messages.minutes} />
											</p>
											<p className={cx(s.subText, s.noMargin)}>
												<FormattedMessage {...messages.deliveryCompleteOn} /> {moment(localDate).format('llll')}
											</p>
										</div>

									</div>
									<div className={cx(s.defaultRow)}>
										{
											data.userDetails && <div className={cx('textAlignRightRTL', { [s.textRightRTL]: isRTL(locale) })}>
												<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.userDetails} /></p>
												<p className={s.subText}>
													<FormattedMessage {...messages.userName} />: {data.userDetails && data.userDetails.firstName}
												</p>
												<p className={cx(s.subText, s.noMargin)}>
													<FormattedMessage {...messages.phoneNumber} />: {data.userDetails && data.userDetails.phoneNumber}
												</p>
											</div>
										}
										{
											data.deliveryPartnerDetails && <div className={cx('textAlignRightRTL', { [s.textRightRTL]: isRTL(locale) })}>
												<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.deliveryPartnerDetails} /></p>
												<p className={s.subText}>
													<FormattedMessage {...messages.driverName} />: {data.deliveryPartnerDetails && data.deliveryPartnerDetails.firstName}
												</p>
												<p className={cx(s.subText, s.noMargin)}>
													<FormattedMessage {...messages.phoneNumber} />: {data.deliveryPartnerDetails && data.deliveryPartnerDetails.phoneNumber}
												</p>
											</div>
										}
									</div>

									<div className={cx(s.commonPadding, 'textAlignRightRTL', { [s.textRightRTL]: isRTL(locale) })}>
										<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.deliveryLocations} /></p>
										<p className={cx(s.subText, s.paddingLocationPadding)}>
											{data.dropOffLocation}
										</p>
									</div>

									{data.orderNotes && <div className={cx(s.commonPadding, 'textAlignRightRTL', { [s.textRightRTL]: isRTL(locale) })}>
										<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.orderNotes} /></p>
										<p className={cx(s.subText, s.paddingLocationPadding, s.lineBreak)}>
											{data.orderNotes}
										</p>
									</div>}

									{!data.isDoorStepDelivery && data.deliveryInstruction && <div className={cx(rs.spaceTop5, s.marginGap)}>
									<p className={cx(s.titleText, rs.space2)}><FormattedMessage {...messages.pickupInstruction} /></p>
									<p className={cx(s.subText, s.paddingLocationPadding, s.lineBreak)}>
										{data.deliveryInstruction}
									</p>
								</div>}

									<div className={rs.spaceTop5}>

										<div className={cx(s.tableSection, 'orderAccordtionMobile', 'printDetailsBg')}>
											<p className={cx(s.titleText, rs.space2, rs.spaceTop2, s.textCenter)}><FormattedMessage {...messages.billingDetails} /></p>
											<Table className={cx(s.table, { [s.directionRTLPrint]: isRTL(locale) })}>
												<tbody>
													{
														data && data.orderItemDetails && data.orderItemDetails.length > 0 && data.orderItemDetails.map((item, key) => {
															return (
																<tr key={key}>
																	<td className={cx(s.noBorder, 'orderAccordtionLast', s.displayTableMob, s.displayInlineBtn, 'displayInlineBtnStoreRTL', 'noBorderPrint')}>
																		<div className={cx(s.displayTable)}>
																			<span className={cx(s.orderItem, s.displayTableCell, s.orderWidth, 'orderItemWidth', 'orderItemRTL')}>{item.quantity} x</span>
																			<span className={s.displayTableCell}>{item.itemName}</span>
																		</div>
																		{
																			item.orderModifierGroup && item.orderModifierGroup.length > 0 && item.orderModifierGroup.map((groupData, key2) => {
																				return (
																					<tr>
																						<div className='orderAccordion'>
																							<Accordion defaultActiveKey="0">
																								<Card className={cx({ [s.orderAccordionPrint]: isRTL(locale) })}>
																									<Card.Header>
																										<Accordion.Toggle variant="link" eventKey="0" className={cx('arrowBtn', s.displayTable, s.displayTableMob)} onClick={() => this.toggleCollapse(key, key2)}>
																											<span className={cx(s.displayTableCell, s.mobDisplayTable)}><img src={collapseID.includes(key + '.' + key2) ? downArrow : upArrow} className={'receiptRTLArrow'} /></span>
																											<span className={cx(s.choicsContent, s.displayTableCell, { [s.textRightRTL]: isRTL(locale) })}>
																												{groupData.modifierName} {' '}
																												(<CurrencyConverter
																													from={groupData.currency}
																													to={siteCurrency}
																													amount={groupData.total}
																												/>)
																											</span>
																										</Accordion.Toggle>
																									</Card.Header>
																									<Accordion.Collapse eventKey="0">
																										<Card.Body className={cx({ [s.orderAccordionBodyPrint]: isRTL(locale) })}>
																											{
																												groupData.orderModifierItem && groupData.orderModifierItem.length > 0 && groupData.orderModifierItem.map((itemData, key) => {
																													return (
																														<div className={cx(s.quantityGrid, s.marginTop, s.displayTableMob)}>
																															<span className={cx(s.orderItem, s.displayTableCell, s.orderWidth, 'orderItemWidth', 'orderItemRTL')}>{itemData.quantity} x</span>
																															<span className={cx(s.itemBottom, s.displayTableCell, 'itemBottomPrint', { [s.textRightRTL]: isRTL(locale) }, 'itemBottomRTL', { [s.dGrid]: isRTL(locale) })}><span>{itemData.modifierItemName}</span>
																																{itemData.modifierItemPrice && (itemData.modifierItemPrice.toFixed(2) >= 0.01) && <span className={s.dGrid}><span>-</span> <span>(<CurrencyConverter
																																	from={itemData.currency}
																																	to={siteCurrency}
																																	amount={itemData.modifierItemPrice}
																																/>)</span></span>}
																															</span>
																														</div>
																													)
																												})
																											}

																										</Card.Body>
																									</Accordion.Collapse>
																								</Card>
																							</Accordion>
																						</div>
																					</tr>
																				);
																			})
																		}

																	</td>
																	<td className={cx(s.noBorder, rs.alignRightText)}>
																		<CurrencyConverter
																			from={data.currency}
																			to={siteCurrency}
																			amount={item.total}
																		/>
																	</td>
																</tr>
															)
														})
													}

													<tr>
														<td className={'textAlignRightRTL'}>
															<span><FormattedMessage {...messages.subTotalLabel} /></span>
														</td>
														<td className={rs.alignRightText}>
															<CurrencyConverter
																from={data.currency}
																to={siteCurrency}
																amount={data.subTotal}
															/>
														</td>
													</tr>
													<tr>
														<td className={'textAlignRightRTL'}>
															<span><FormattedMessage {...messages.shopFee} /></span>
														</td>
														<td className={rs.alignRightText}>
															-<CurrencyConverter
																from={data.currency}
																to={siteCurrency}
																amount={data.shopServiceFare}
															/>
														</td>
													</tr>
													<tr>
														<td className={cx(s.totalText, 'textAlignRightRTL')}>
															<span><FormattedMessage {...messages.total} /></span>
														</td>
														<td className={cx(s.totalText, rs.alignRightText)}>
															<CurrencyConverter
																from={data.currency}
																to={siteCurrency}
																amount={data.shopTotalFare}
															/>
														</td>
													</tr>
												</tbody>

											</Table>
										</div>
									</div>
								</div>
								<div className={cx(rs.spaceTop5, rs.alignRightText)}>
									<div>
										{!loading && <Link to={`/store/${orderPage}/${pageType}`} className={cx(rs.button, rs.btnPrimaryBorder, s.displayInlineBtn, 'hidden-print')}><FormattedMessage {...messages.back} /></Link>}
									</div>
								</div>
							</Col>
						}
					</Row>
				</Container>
			</div>
		);
	}
};

const mapState = (state) => ({
	siteCurrency: state.siteSettings?.data?.currency,
	currentLocale: state.intl.locale,
	siteName: state.siteSettings.data.siteName,
	completeConfirmModal: state.modalStatus.completeConfirmModal,
	modalData: state.modalStatus.data && state.modalStatus.data[0],
})

const mapDispatch = {
	updateOrderStatus,
	openDeclineReasonModal,
	updateItemStatus,
	openModal,
	closeModal
}

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(OrderDetails)));