import { toastr } from 'react-redux-toastr';
import updateHomepageSettingsFooter from '../../../actions/siteadmin/updateHomepageSettingsFooter'

async function submit(values, dispatch) {
    if (!values.footerLogo1) {
        toastr.error('Error!', 'Please upload the image.');
        return;
    }
    await dispatch(updateHomepageSettingsFooter(values))
}

export default submit;