import { editUser } from '../../../actions/siteadmin/editUser';

async function submit(values, dispatch) {
    await dispatch(
        editUser(
            values.id,
            values.firstName,
            values.lastName,
            values.email,
            values.password ? values.password : undefined,
            values.phoneDialCode,
            values.phoneNumber,
            values.userStatus,
            values.isBan,
            values.phoneCountryCode
        )
    )

}

export default submit;