import gql from 'graphql-tag';
import {
    SHOP_USER_DELETE_SUCCESS,
    SHOP_USER_DELETE_START,
    SHOP_USER_DELETE_ERROR
} from '../../../constants/index';
import { toastr } from 'react-redux-toastr';
import socketNotification from '../../../core/socket/socketNotification';

export default function deleteShop(id) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: SHOP_USER_DELETE_START,
            payload: {
                deleteLoading: true
            }
        });
        
        try {
            let errorMessage;
            let mutation = gql`
            mutation deleteShop($id: String!) {
                deleteShop(id: $id) {
                  status
                  errorMessage
                }
              }
            `;

            const { data } = await client.mutate({
                mutation,
                variables : {
                    id
                }
            });
            
            if (data && data.deleteShop && data.deleteShop.status === "200") {
                dispatch({
                    type: SHOP_USER_DELETE_SUCCESS,
                    payload: {
                        deleteLoading: false
                    }
                });
                let requestData = { id };
                toastr.success('Success', `The Store has been deleted successfully.`);
                socketNotification('webUserLogout', JSON.stringify(requestData));
                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: SHOP_USER_DELETE_ERROR,
                    payload: {
                        deleteLoading: false
                    }
                });

                errorMessage = (data && data.deleteShop && data.deleteShop.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                }
            }
        } catch(err) {
            toastr.error('Error!', err);
            dispatch({
                type: SHOP_USER_DELETE_ERROR,
                payload: {
                    deleteLoading: false
                }
            });
            return {
                status: 400
            }
        }

    }
};