import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import {
    Row, Col, Tabs, Tab, Container
} from 'react-bootstrap';
import history from '../../../history';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import s from './TransactionManagement.css';
import rs from '../../storeCommon.css';
import getAllTransactions from './getAllTransactions.graphql';

// Components
import CustomPagination from '../../CustomPagination/CustomPagination';
import Loader from '../../Common/Loader';
import TransactionList from './TransactionList';

class TransactionManagement extends Component {
    static defaultProps = {
        transactions: {
            loading: true
        },
        type: 'completed'
    };

    constructor(props) {
        super(props);
        this.state = {
            tabKey: 'completed',
            currentPage: 1
        };

        this.handleTabSelect = this.handleTabSelect.bind(this);
        this.paginationData = this.paginationData.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
    }

    handleTabSelect = (key) => {
        this.setState({ currentPage: 1 });
        history.push('/store/transaction/' + key);
    }

    paginationData(currentPage) { // Pagination
        const { type, transactions, transactions: { loading, refetch } } = this.props;
        if (transactions && !loading) {
            refetch({ type, currentPage });
            this.setState({ currentPage });
        }
    }

    renderPagination(paginationLabel, currentPage, count) {
        return (
            <div className={cx(s.space5, s.spaceTop5, 'store')}>
                <CustomPagination
                    total={count}
                    currentPage={currentPage}
                    defaultCurrent={1}
                    defaultPageSize={10}
                    change={this.paginationData}
                    paginationLabel={paginationLabel}
                />
            </div>
        );
    }

    render() {
        const { transactions, transactions: { loading, getTransactionHistory }, type } = this.props;
        const { formatMessage } = this.props.intl;
        const { currentPage } = this.state;

        return (
            <div className={'mainContainer'}>
                <Container fluid>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className={s.titleSection}>
                                <h1 className={cx(s.titleBottom, rs.storeTitleText, 'textAlignRightRTL')}>
                                    <FormattedMessage {...messages.transactionManagement} />
                                </h1>
                            </div>
                        </Col>
                        <Col md={12} lg={12} sm={12} xs={12} className={rs.spaceTop5}>
                            <Tabs activeKey={type} id="uncontrolled-tab-example" onSelect={(eventKey) => this.handleTabSelect(eventKey)}>
                                <Tab eventKey="completed" title={formatMessage(messages.completedTransaction)}>
                                    {
                                        !loading && transactions && getTransactionHistory && getTransactionHistory.results && <TransactionList 
                                            data={getTransactionHistory}
                                            tabType="completed"
                                        />
                                    }
                                    {
                                        loading && <Loader containerClass={'text-center'} />
                                    }
                                    {
                                        !loading && transactions && getTransactionHistory && getTransactionHistory.count > 0 &&
                                        this.renderPagination(formatMessage(messages.transactionsLabel), currentPage, getTransactionHistory.count)
                                    }
                                </Tab>
                                <Tab eventKey="future" title={formatMessage(messages.futureTransaction)}>
                                    {
                                        !loading && transactions && getTransactionHistory && getTransactionHistory.results && <TransactionList 
                                            data={getTransactionHistory} 
                                            tabType="future"
                                        />
                                    }
                                    {
                                        loading && <Loader containerClass={'text-center'} />
                                    }
                                    {
                                        !loading && transactions && getTransactionHistory && getTransactionHistory.count > 0 &&
                                        this.renderPagination(formatMessage(messages.transactionsLabel), currentPage, getTransactionHistory.count)
                                    }
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
};

export default compose(
    injectIntl,
    withStyles(s),
    graphql(getAllTransactions, {
        name: 'transactions',
        options: (props) => ({
            variables: {
                type: props.type,
                currentPage: 1,
            },
            fetchPolicy: 'network-only',
            ssr: true
        })
    })
)(TransactionManagement);