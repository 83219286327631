import React, { Component } from 'react'
import { Field, reduxForm, formValueSelector, change, initialize } from 'redux-form';
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';

import cx from 'classnames';
import {
	Form,
	Col,
	Row,
	Container
} from 'react-bootstrap';

import ColorPickerModal from '../ColorPickerModal/ColorPickerModal';
import ImageDropzone from './ImageDropzone';
import Loader from '../../../Common/Loader/Loader';
import Link from '../../../Link/Link';

import s from './AddDeliveryTypeForm.css';

import { openColorModal } from '../../../../actions/siteadmin/modalActions';

//image
import defaultIcon from '../../../../../public/Icons/defaultIconAdmin.svg';
import colorPickerIcon from '../../../../../public/Icons/colorPickerIcon.svg';
import { api, deliveryTypeImageUploadDir } from '../../../../config';
import messages from '../../../../locale/messages';
import validate from './validate';
import submit from './submit';
export class AddDeliveryTypeForm extends Component {

	constructor(props) {
		super(props);
		this.successImage = this.successImage.bind(this);
		this.successBanner = this.successBanner.bind(this);
		this.changeColor = this.changeColor.bind(this);
	}

	static defaultProps = {
		loading: false
	};

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<Form.Control
					{...input}
					className={className}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</Form.Control>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group >
		);
	}

	renderColorField = ({ input, label, type, meta: { touched, error }, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	async successImage(file, fromServer) {
		const { change } = this.props;
		await change('imageName', fromServer && fromServer.fileName);
	}

	async successBanner(file, fromServer) {
		const { change } = this.props;
		await change('bannerName', fromServer && fromServer.fileName);
	}

	async changeColor(fieldName, value) {
		const { change } = this.props;
		await change(fieldName, value);
	}

	render() {
		const { handleSubmit, imageName, loading, submitting, imageNameLoading, openColorModal } = this.props;
		const { textColor, iconBackgroundColor, backgroundColor, deliveryName, deliveryDescription } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div className={cx(s.space5, s.spaceTop5)}>
				<Container fluid>
					<ColorPickerModal formName={'AddDeliveryTypeForm'} />
					<Row>
						<Col lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
							<div>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)} >
									<Row className={s.noMargin}>
										<Col xl={7} lg={12} md={12} sm={12} xs={12}>
											<Row className={s.space5}>
												<Col xl={6} lg={12} md={12} sm={12} xs={12}>
													<div className={cx(s.centerFlex, s.profileImgSection)}>
														<label>{formatMessage(messages.image)}</label>

														{
															imageName && <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + deliveryTypeImageUploadDir}${'medium_' + imageName})` }} />
														}
														{
															!imageName && <div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
														}

														<div>
															<ImageDropzone
																className={cx(s.btnPrimary, 'fileNoPadding', s.btnWidthadmin)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																componentConfig={{
																	iconFiletypes: ['.jpg', '.png', '.svg'],
																	multiple: false,
																	showFiletypeIcon: false,
																	postUrl: api.apiEndpoint + '/uploadDeliveryImage'
																}}
																success={this.successImage}
																loaderName={"imageName"}
															/>
														</div>
													</div>
												</Col>
												<Col xl={6} lg={12} md={12} sm={12} xs={12} className={s.colorPickerBg}>
													<Form.Group className={s.space5}>
														<div className={s.positionRelative}>
															<Field
																name="iconBackgroundColor"
																type="text"
																placeholder={formatMessage(messages.iconBackgroundColor)}
																component={this.renderColorField}
																label={formatMessage(messages.iconBackgroundColor)}
																labelClass={s.labelText}
																fieldClass={cx(s.formControlInput, 'pickerInput')}
															/>
															<div className={cx(s.pickerIcon, 'pickerIconRTL')} onClick={(e) => openColorModal('iconBackgroundColor', iconBackgroundColor)}><img src={colorPickerIcon} /></div>
														</div>
													</Form.Group>
													<Form.Group className={s.space5}>
														<div className={s.positionRelative}>
															<Field
																name="textColor"
																type="text"
																placeholder={formatMessage(messages.textColor)}
																component={this.renderColorField}
																label={formatMessage(messages.textColor)}
																labelClass={s.labelText}
																fieldClass={cx(s.formControlInput, 'pickerInput')}
															/>
															<div className={cx(s.pickerIcon, 'pickerIconRTL')} onClick={(e) => openColorModal('textColor', textColor)}><img src={colorPickerIcon} /></div>
														</div>
													</Form.Group>
													<Form.Group className={s.space5}>
														<div className={s.positionRelative}>
															<Field
																name="backgroundColor"
																type="text"
																placeholder={formatMessage(messages.backgroundColor)}
																component={this.renderColorField}
																label={formatMessage(messages.backgroundColor)}
																labelClass={s.labelText}
																fieldClass={cx(s.formControlInput, 'pickerInput')}
															/>
															<div className={cx(s.pickerIcon, 'pickerIconRTL')} onClick={(e) => openColorModal('backgroundColor', backgroundColor)} ><img src={colorPickerIcon} /></div>
														</div>
													</Form.Group>
												</Col>
											</Row>
										</Col>
										<Col xl={5} lg={12} md={12} sm={12} xs={12}>
											<div className={s.previewOutline}>
												<div className={s.previewBox}>
													<div className={s.previewTitle}>{formatMessage(messages.previewSample)}</div>
													<div className={s.previewIconBg} style={{ backgroundColor: iconBackgroundColor }}>
														{
															imageName && <div className={s.previewBgimage} style={{ backgroundImage: `url(${api.apiEndpoint + deliveryTypeImageUploadDir + 'medium_' + imageName})` }} />
														}
													</div>
													<div className={s.bgColor} style={{ backgroundColor: backgroundColor }}>
														<div className={s.previewHeading} style={{ color: textColor }}>{deliveryName}</div>
														<div className={s.previreContent} style={{ color: textColor }}>{deliveryDescription}</div>
													</div>
												</div>
											</div>
										</Col>
									</Row>
									<Row className={s.noMargin}>
										<Col xl={7} lg={12} md={12} sm={12} xs={12}>
											<Form.Group className={s.space5}>
												<div>
													<Field
														name="deliveryName"
														type="text"
														placeholder={formatMessage(messages.deliveryName)}
														component={this.renderField}
														label={formatMessage(messages.deliveryName)}
														labelClass={s.labelText}
														fieldClass={s.formControlInput}
													/>
												</div>
											</Form.Group>
											<Field name="deliveryDescription" component={this.renderFormControlTextArea} label={formatMessage(messages.deliveryDescription)} />
											<Form.Group className={cx(s.space5, 'positionRelative')}>
												<label className={'inputLabel'}>{formatMessage(messages.status)}</label>
												<Field name="status" className={cx(s.formControlSelect, s.formControlInput)} component="select">
													<option value={true}>{formatMessage(messages.active)}</option>
													<option value={false}>{formatMessage(messages.inactive)}</option>
												</Field>
											</Form.Group>
											<div className={cx(s.textAlignRight, s.spaceTop5, 'textAlignLeftRTL')}>
												<Form.Group className={s.noMargin}>
													<Link to={"/siteadmin/delivery-type"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL', s.backBtnTwoTop)} >{formatMessage(messages.goBack)}</Link>
													<div className={s.displayInlineBlock}>
														<Loader
															type={"button"}
															label={formatMessage(messages.submitButton)}
															show={loading}
															buttonType={'submit'}
															className={cx(s.button, s.btnPrimary)}
															disabled={submitting || loading}
															isSuffix={true}
														/>
													</div>
												</Form.Group>
											</div>
										</Col>
									</Row>
									{/* <Col lg={12} md={12} sm={6} xs={12}>
										<Form.Group className={s.space5}>
											<div className={cx(s.centerFlex, s.profileImgSection)}>
												<label>{formatMessage(messages.bannerImage)}</label>
												<Loader
													show={bannerNameLoading}
													type={"page"}
												>
													{
														bannerName && <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + deliveryTypeImageUploadDir}${'medium_' + bannerName})` }} />
													}
												</Loader>
												<div>
													<ImageDropzone
														className={cx(s.btnPrimary, 'fileNoPadding', s.btnWidthadmin)}
														subTextClass={s.subText}
														subText={formatMessage(messages.maximumUploadSizeLabel)}
														defaultMessage={formatMessage(messages.chooseFile)}
														componentConfig={{
															iconFiletypes: ['.jpg', '.png', '.svg'],
															multiple: false,
															showFiletypeIcon: false,
															postUrl: api.apiEndpoint + '/uploadDeliveryImage'
														}}
														success={this.successBanner}
														loaderName={"bannerName"}
													/>
												</div>
											</div>
										</Form.Group>
									</Col> */}

								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

AddDeliveryTypeForm = reduxForm({
	form: 'AddDeliveryTypeForm',
	onSubmit: submit,
	validate
})(AddDeliveryTypeForm);

const selector = formValueSelector('AddDeliveryTypeForm');

const mapState = (state) => ({
	imageName: selector(state, 'imageName'),
	bannerName: selector(state, 'bannerName'),
	textColor: selector(state, 'textColor'),
	backgroundColor: selector(state, 'backgroundColor'),
	iconBackgroundColor: selector(state, 'iconBackgroundColor'),
	deliveryName: selector(state, 'deliveryName'),
	deliveryDescription: selector(state, 'deliveryDescription'),
	loading: state.loader.AddCategory,
	imageNameLoading: state.loader.imageName,
	bannerNameLoading: state.loader.bannerName,
})

const mapDispatch = {
	initialize,
	openColorModal
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AddDeliveryTypeForm)));