import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Button,
	Col
} from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';

import s from './LogoutForm.css';
import rs from '../../storeCommon.css';

import { closeLogoutModal } from '../../../actions/modalActions';
import messages from '../../../locale/messages';
// Config
import { api, logoUploadDir } from '../../../config';

class LogoutForm extends React.Component {
	static propTypes = {
		formatMessage: PropTypes.func
	}


	render() {
		const { closeLogoutModal, logo, logoWidth, logoHeight, siteName } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div className={s.textAlignCenter}>
				<div className={cx(s.padding, rs.space3)}>
					<img
						src={api.apiEndpoint + logoUploadDir + logo}
						width={118}
						height={118}
						alt={siteName}
					/>
					<h3 className={cx(s.titleText, rs.spaceTop2)}><FormattedMessage {...messages.logOutLabel} /></h3>
					<p className={s.descriptionText}><FormattedMessage {...messages.logoutConfirm} /></p>
				</div>
				<Button className={cx(rs.button, s.modalCaptionLink, rs.space3)} onClick={() => closeLogoutModal()}>
					<FormattedMessage {...messages.goBackHome} />
				</Button>
				<form action={'/shopLogout'} method="post" className={cx(s.formSection, rs.space5)}>
					<Button type="submit" className={cx(rs.button, rs.btnPrimary, s.fullWidth)}>
						{formatMessage(messages.yesLogOutLabel)}
					</Button>
				</form>
			</div>
		)
	}
}


const mapState = (state) => ({
	logo: state.siteSettings.data.homeLogo,
	logoHeight: state.siteSettings.data.logoHeight,
	logoWidth: state.siteSettings.data.logoWidth,
	siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {
	closeLogoutModal
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(LogoutForm)));