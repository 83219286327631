import { updateDeliveryType } from '../../../../actions/siteadmin/DeliveryModule/updateDeliveryType';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {
    
    if (!values.imageName) {
        toastr.error('Error!', "Image is required.");
        return;
    }

    // if (!values.bannerName) {
    //     toastr.error('Error!', "Banner is required.");
    //     return;
    // }

    await dispatch(
        updateDeliveryType(
            values
        )
    )
}

export default submit;