// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux form
import { Field, reduxForm } from 'redux-form';

// Intl
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../../locale/messages';

// Style
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';
import s from './ForgotPasswordForm.css';
import bt from '../../../../components/storeCommon.css';
import {
  Button,
  FormGroup,
  Col,
  FormControl,
  Row
} from 'react-bootstrap';

// Internal Helpers
import validate from './validate';
import submit from './submit';

class ForgotPasswordForm extends Component {

  static propTypes = {
    openLoginModal: PropTypes.func.isRequired,
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl {...input} placeholder={label} type={type} className={className} />
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </div>
    );
  }

  render() {
    const { handleSubmit, submitting, dispatch } = this.props;
    const { formatMessage } = this.props.intl;
    const { openLoginModal } = this.props;
    return (
      <div className={s.container}>
        <form onSubmit={handleSubmit(submit)}>
          <FormGroup className={s.formGroup}>
            <p><FormattedMessage {...messages.forgotPasswordInfo} /></p>
          </FormGroup>
          <FormGroup className={s.formGroup}>
            <Field name="email"
              component={this.renderFormControl} placeholder={'Email Address'}
              className={cx(bt.formControlInputStore, s.meassageicon, 'formControlInputStoreRTL', 'meassageIconRTL')}
            />
            <label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.email)}</label>
          </FormGroup>
          <div className={cx(s.formGroup, s.formSection)}>
            <div className={s.mobileColumn}>
              <a href="#" onClick={openLoginModal} className={cx(s.modalCaptionLink, s.modalCaptionLinkLarge, s.displayTableCell)}>
                <FormattedMessage {...messages.backToLogin} />
              </a>
              <Button
                className={cx(bt.btnPrimary, s.btn)}
                type="submit"
                disabled={submitting}
              >
                <FormattedMessage {...messages.sendLink} />
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }

}

ForgotPasswordForm = reduxForm({
  form: 'ForgotPasswordForm', // a unique name for this form
  validate,
  destroyOnUnmount: true
})(ForgotPasswordForm);

export default withStyles(s, bt)(injectIntl((ForgotPasswordForm)));