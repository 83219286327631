import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import {
  Row,
  FormGroup,
  Col,
  FormControl,
  Form,
  Container,
  InputGroup
} from 'react-bootstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';

import LogoDropzone from './LogoDropzone';
import FaviconDropzone from './FaviconDropzone';
import Loader from '../../Common/Loader';
import SwitchButton from './Switch';

import s from './SiteSettingsForm.css';

import { siteSettings } from '../../../actions/siteadmin/SiteSettings/siteSettings';

import messages from '../../../locale/messages';
import submit from './submit';
import validate from './validate';
import { api, logoUploadDir, faviconUploadDir, siteUrl } from '../../../config';

//image
import defaultIcon from '../../../../public/Icons/defaultIconAdmin.svg';
export class SiteSettingsForm extends Component {

  static defaultProps = {
    loading: false,
    updateSiteSettings: false
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(s.space2, 'positionRelative')}>
        <label className={'inputLabel'}>{label}</label>
        <FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  }

  renderAddonFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength, addonLabel }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('inputFormAddon', 'addonBorder')}>
        <Form.Group>
          <label className={s.labelText} >{label}</label>
          <InputGroup>
          <FormControl {...input} placeholder={label} type={type} className={s.formControlInput} maxlength={maxlength} />
              <InputGroup.Text>{addonLabel}</InputGroup.Text>
          </InputGroup>
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
        </Form.Group>
      </div>
    );
  }

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(s.space2, 'positionRelative')}>
        <label className={'inputLabel'}>{label}</label>
        <FormControl
          {...input}
          className={className}
          placeholder={label}
          as="textarea"
          rows='4'
        >
          {children}
        </FormControl>
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  }

  renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
    return (
      <Form.Group className={cx(s.space2, 'positionRelative')}>
        <Form.Label className={'inputLabel'}>{label}</Form.Label>
        <Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass}>
          {children}
        </Form.Control>
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
      </Form.Group>
    )
  }
  renderFieldApp = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    return (
      <div className={cx('inputFormAddon', 'addonBorder', 'positionRelative')}>
        <Form.Group>
          <label className={'inputLabel'}>{label}</label>
          <InputGroup>
            <InputGroup.Append>
              <InputGroup.Text>
                V
              </InputGroup.Text>
            </InputGroup.Append>
            <FormControl {...input} placeholder={label} type={type} className={s.formControlInput} maxlength={maxlength} />
          </InputGroup>
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
        </Form.Group>
      </div>
    )
  }

  renderSwitch = ({ input, meta: { touched, error }, switchOnLabel, switchOffLabel }) => {
    const { formatMessage } = this.props.intl;
    input.value = input.value == "0" ? false : true;

    return (
      <SwitchButton
        {...input}
        defaultValue={input.value}
        switchOnLabel={switchOnLabel}
        switchOffLabel={switchOffLabel}
      />
    )
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, logo, loading, submitting, updateSiteSettings, appForceUpdate, favicon } = this.props;
    const { data: { getCurrencies } } = this.props;

    return (
      <div className={s.paddignLeftRight}>
        <Container fluid>
          <form onSubmit={handleSubmit(submit)}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className={s.space5}>
                <div className={s.adminFormGrid}>
                  <div className={cx(s.profileImgSection, s.space2)}>
                    <label>{formatMessage(messages.logo)}</label>
                    {logo &&
                      <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + logoUploadDir}${logo})` }} />
                    }
                    {
                      !logo &&
                      <div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
                    }
                    <div>
                      <LogoDropzone
                        className={cx(s.btnSecondary, s.profileNoPadding)}
                        subTextClass={s.subText}
                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                        defaultMessage={formatMessage(messages.chooseFile)}
                      />
                    </div>
                  </div>
                  <div className={cx(s.profileImgSection, s.space2)}>
                    <label className={s.labelText} >{formatMessage(messages.favIconlogoLabel)}</label>
                    {favicon &&
                      <div className={s.backgroundImg} style={{ backgroundImage: `url(${siteUrl + faviconUploadDir + favicon})` }} />
                    }
                    {
                      !favicon &&
                      <div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
                    }
                    <div className={'commonFilepicker'}>
                      <FaviconDropzone
                        className={cx(s.btnSecondary, 'fileNoPadding')}
                        subTextClass={s.subText}
                        subText={formatMessage(messages.maximumUploadSizeLabel)}
                        defaultMessage={formatMessage(messages.chooseFile)}
                      />
                    </div>
                  </div>
                  <Field name="logoWidth" type="text" component={this.renderFormControl} label={formatMessage(messages.logoWidth)} />
                  <Field name="siteName" type="text" component={this.renderFormControl} label={formatMessage(messages.siteNameLabel)} maxlength={250} />
                  <Field name="logoHeight" type="text" component={this.renderFormControl} label={formatMessage(messages.logoHeight)} />
                  <Field name="siteTitle" type="text" component={this.renderFormControl} label={formatMessage(messages.siteTitleLabel)} />
                  <Field name="metaKeyword" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metaKeywordLabel)} />
                  <Field name="metaDescription" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.metaDescriptionLabel)} />
                  <Field name="facebookLink" type="text" component={this.renderFormControl} label={formatMessage(messages.facebookURLLabel)} />
                  <Field name="twitterLink" type="text" component={this.renderFormControl} label={formatMessage(messages.twitterURLLabel)} />
                  <Field name="instagramLink" type="text" component={this.renderFormControl} label={formatMessage(messages.instagramURLLabel)} />
                  <Field name="youtubeLink" type="text" component={this.renderFormControl} label={formatMessage(messages.youtubeURLLabel)} />
                  <Field name="contactNumber" type="text" component={this.renderFormControl} label={formatMessage(messages.contactNumber)} />
                  <Field name="contactEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.contactEmail)} />
                  <Field name="contactSkype" type="text" component={this.renderFormControl} label={formatMessage(messages.contactSkype)} />
                  <Field name="notificationInterval" type="text" component={this.renderAddonFormControl} label={formatMessage(messages.notificationInterval)} addonLabel={formatMessage(messages.minutes)} />
                  <Field
                    name="currency"
                    placeholder={formatMessage(messages.currency)}
                    component={this.renderSelectField}
                    label={formatMessage(messages.currency)}
                    labelClass={s.labelText}
                    fieldClass={cx(s.formControlSelect, s.formControlInput)}
                  >
                    <option value={""}>{formatMessage(messages.currency)}</option>
                    {
                      getCurrencies && getCurrencies.map((item, index) => {
                        return item.isEnable == 1 && <option key={index} value={item.symbol}>{item.symbol}</option>
                      })
                    }
                  </Field>
                  <Form.Group className={s.formGroup}>
                    <div className={'positionRelative'}>
                      <label className={'inputLabel'}>{formatMessage(messages.modifiers)}</label>
                      <Field name="modifiers" className={cx(s.formControlSelect, s.formControlInput)} component="select">{formatMessage(messages.forceUpdate)}
                        <option value="1">{formatMessage(messages.active)}</option>
                        <option value="0">{formatMessage(messages.inactive)}</option>
                      </Field>
                    </div>
                  </Form.Group>
                </div>
                <div className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                  <Loader
                    type={"button"}
                    label={formatMessage(messages.submitButton)}
                    show={updateSiteSettings || submitting}
                    buttonType={'submit'}
                    className={cx(s.button, s.btnPrimary)}
                    disabled={updateSiteSettings || submitting}
                    isSuffix={true}
                  />
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    )
  }
}

const callSiteSettings = async (result, dispatch, props) => {
  const { refetch } = props
  await refetch()
  dispatch(siteSettings())
}

SiteSettingsForm = reduxForm({
  form: 'SiteSettingsForm',
  onSubmit: submit,
  validate,
  onSubmitSuccess: callSiteSettings
})(SiteSettingsForm);

const selector = formValueSelector('SiteSettingsForm')
const mapState = (state) => ({
  logo: selector(state, 'homeLogo'),
  appForceUpdate: selector(state, 'appForceUpdate'),
  favicon: selector(state, 'favicon'),
  updateSiteSettings: state.siteSettings.updateSiteSettings
})

const mapDispatch = {
  siteSettings
}

export default injectIntl(
  compose(
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(gql`query {
      getCurrencies {
        id
        symbol
        isEnable
      }
    }`, {
      options: {
        fetchPolicy: 'network-only',
        ssr: false
      }
    })
  )(SiteSettingsForm));