import React, { Component } from 'react'
import s from './AddCategoryForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector, initialize } from 'redux-form';
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import {
	Form,
	Col,
	Row,
	Container
} from 'react-bootstrap';
import Link from '../../../Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import submit from './submit'
import ImageDropzone from './ImageDropzone';
import { api, categoryUploadDir } from '../../../../config';
import Loader from '../../../Common/Loader';
//image
import defaultIcon from '../../../../../public/Icons/defaultIconAdmin.svg';

export class AddCategoryForm extends Component {
	static defaultProps = {
		loading: false
	};

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<Form.Control
					{...input}
					className={className}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</Form.Control>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		);
	}

	renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children, maxlength }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass} maxlength={maxlength}>
					{children}
				</Form.Control>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	render() {
		const { handleSubmit, categoryImage, loading, submitting, deliveryTypes: { getActiveDeliveryType } } = this.props;
		const { formatMessage } = this.props.intl;


		return (
			<div>
				<Container fluid>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)} >

						<Row>
							<Col lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
								<div className={s.blackCenterSection}>
									<Row>
										<Col lg={6} md={12} sm={12} xs={12}>
											<Form.Group className={s.space5}>
												<div className={cx(s.centerFlex, s.profileImgSection)}>
													<label>{formatMessage(messages.categoryIcon)}</label>
													{
														categoryImage && <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + categoryUploadDir}${categoryImage})` }} />
													}
													{
														!categoryImage && <div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
													}
													<div>
														<ImageDropzone
															key={'categoryImage'}
															className={cx(s.btnSecondary, s.profileNoPadding)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
														/>
													</div>
												</div>
											</Form.Group>
										</Col>
										<Col lg={6} md={12} sm={12} xs={12}>
											<Field
												name="categoryName"
												type="text"
												placeholder={formatMessage(messages.categoryName)}
												component={this.renderField}
												label={formatMessage(messages.categoryName)}
												labelClass={s.labelText}
												fieldClass={s.formControlInput}
											/>
											<Field
												name="deliveryType"
												placeholder={formatMessage(messages.deliveryName)}
												component={this.renderSelectField}
												label={formatMessage(messages.deliveryName)}
												labelClass={s.labelText}
												fieldClass={cx(s.formControlSelect, s.formControlInput)}
											>
												<option value={""}>{formatMessage(messages.deliveryName)}</option>
												{
													getActiveDeliveryType && getActiveDeliveryType.results && getActiveDeliveryType.results.map(item => (
														<option key={item.id} value={item.id}>{item.deliveryName}</option>
													))
												}
											</Field>
											<Form.Group className={cx(s.space5, 'positionRelative')}>
												<label className={'inputLabel'}>{formatMessage(messages.status)}</label>
												<Field name="isActive" className={cx(s.formControlSelect, s.formControlInput)} component="select">
													<option value={true}>{formatMessage(messages.active)}</option>
													<option value={false}>{formatMessage(messages.inactive)}</option>
												</Field>
											</Form.Group>
											<Field name="description" component={this.renderFormControlTextArea} label={formatMessage(messages.description)} />
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
						<div className={cx(s.textAlignRight, s.btnMargin, 'textAlignLeftRTL', 'btnMarginRTL')}>
							<Form.Group className={s.noMargin}>
								<Link to={"/siteadmin/category"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL', s.backBtnTwoTop)} >{formatMessage(messages.goBack)}</Link>
								<div className={s.displayInlineBlock}>
									<Loader
										type={"button"}
										label={formatMessage(messages.submitButton)}
										show={loading}
										buttonType={'submit'}
										className={cx(s.button, s.btnPrimary)}
										disabled={submitting || loading}
										isSuffix={true}
									/>
								</div>
							</Form.Group>
						</div>
					</Form>
				</Container>
			</div>
		)
	}
}

AddCategoryForm = reduxForm({
	form: 'AddCategoryForm',
	onSubmit: submit,
	validate
})(AddCategoryForm);

const selector = formValueSelector('AddCategoryForm');

const mapState = (state) => ({
	categoryImage: selector(state, 'categoryImage'),
	loading: state.loader.AddCategory
})

const mapDispatch = {
	initialize
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AddCategoryForm)));