import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import history from '../../history'
import normalizeCss from 'normalize.css';
import s from './Layout.css';
import SideMenu from '../SiteAdmin/SideMenu';
import AdminHeader from '../AdminHeader/AdminHeader';
import Toaster from '../Toaster';
import SocketContext from '../SocketContext';

class AdminLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    //For Highlighting Side Menu based on URL
    let location;
    if (history.location) location = history.location.pathname;

    return (
      <SocketContext.Consumer>
        {
          socket => (
            <div className="bodyOverflow">
              <Toaster />
              <div className={s.sideNav}>
                <SideMenu location={location} />
              </div>
              <div className={cx(s.mainContent, 'mainContentRTL', 'adminDroupZone')}>
                <div className={s.adminHeader}>
                  <AdminHeader socket={socket} />
                </div>
                <div className={s.mainSectionPaddingTop}>
                  {this.props.children}
                </div>
              </div>
            </div>
          )
        }
      </SocketContext.Consumer>
    );
  }
}

export default withStyles(normalizeCss, s)(AdminLayout);
