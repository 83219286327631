exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Cfgw {\n    position: relative;\n    top: -15px;\n    height: 25px; \n    display: inline-block;\n    min-width: 25px;\n    padding: 0 3px;\n    border-radius: 50%;\n    font-weight: 700;\n    font-size: 11px;\n    line-height: 24px;\n    color: #fff;\n    border: 1px solid #F86540;\n    background: #F86540;\n}", ""]);

// exports
exports.locals = {
	"badge": "_2Cfgw"
};