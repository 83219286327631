exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #F86540;\n  --btn-boxShadow-bg: #F86540;\n  --btn-bordercolor-bg: #F86540;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #F86540;\n  --title-color: #212121;\n  --content-color: #212121;\n\n}\n\n._2W1sz {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.CpU2p {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 380px;\n  \n}\n\n._1MhqW {\n    margin-bottom: 6px;\n    position: relative;\n  }\n\n.ucyzy {\n    overflow: hidden;\n  }\n\n.gVaNU {\n    position: relative;\n    z-index: 1;\n    display: block;\n    margin: 15px 0px;\n    width: 100%;\n    color: #767676;\n    text-align: center;\n    font-size: 18px;\n  }\n\n  ", ""]);

// exports
exports.locals = {
	"root": "_2W1sz",
	"container": "CpU2p",
	"formGroup": "_1MhqW",
	"formSection": "ucyzy",
	"horizontalLineThrough": "gVaNU"
};