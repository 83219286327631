import React, { Component } from 'react';
import s from './InfoForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
	Button,
	Form,
	Col,
	Card,
	Row,
	InputGroup,
	FormControl,
	FormGroup,
	Container
} from 'react-bootstrap';
import Link from '../../../Link/Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import { api, partnerHomepageUploadDir } from '../../../../config';
import Dropzone from '../../../Common/Dropzone';
import Loader from '../../../Common/Loader/Loader';

export class InfoForm extends Component {

	constructor(props) {
		super(props)

		this.handleDropzone = this.handleDropzone.bind(this);
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
				/>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<FormControl
					{...input}
					className={className}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		);
	}

	handleDropzone(fileName, fieldName) {
		const { change } = this.props

		change(fieldName, fileName)
	};

	render() {
		const { handleSubmit, infoImage1, infoImage2, infoImage3, loading, submitting } = this.props;
		const { formatMessage } = this.props.intl;
		return (

			<div>
				<Container fluid>
					<h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.infoSection)}</h1>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
						<Row>
							<Col xl={12} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
								<div className={s.blackCenterSection}>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Row>
											<Col lg={4} md={12} sm={12} xs={12}>
												<Form.Group className={s.space5}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<label className={s.space1}>{formatMessage(messages.image1Label)}</label>
														{
															infoImage1 && <div>
																<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir}${infoImage1})` }} />
															</div>
														}
														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																inputContainer={'.dzInputContainerStoreImage'}
																inputContainerClass={'dzInputContainerStoreImage'}
																url={'/uploadPartnerHomepageImage'}
																fieldName={'infoImage1'}
																handleDropzone={this.handleDropzone}
															/>
														</div>
													</div>
												</Form.Group>
												<Field name="infoTitle1" type="text" component={this.renderField} label={formatMessage(messages.imageTitle1Label)} maxlength={250} />
												<Field name="infoContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description1Label)} />
											</Col>
											<Col lg={4} md={12} sm={12} xs={12}>
												<Form.Group className={s.space5}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<label className={s.space1}>{formatMessage(messages.image2Label)}</label>
														{
															infoImage2 && <div>
																<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir}${infoImage2})` }} />
															</div>
														}
														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																inputContainer={'.dzInputContainerStoreImage'}
																inputContainerClass={'dzInputContainerStoreImage'}
																url={'/uploadPartnerHomepageImage'}
																fieldName={'infoImage2'}
																handleDropzone={this.handleDropzone}
															/>
														</div>
													</div>
												</Form.Group>
												<Field name="infoTitle2" type="text" component={this.renderField} label={formatMessage(messages.imageTitle2Label)} maxlength={250} />
												<Field name="infoContent2" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description2Label)} />
											</Col>
											<Col lg={4} md={12} sm={12} xs={12}>
												<Form.Group className={s.space5}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<label className={s.space1}>{formatMessage(messages.image3Label)}</label>
														{
															infoImage3 && <div>
																<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir}${infoImage3})` }} />
															</div>
														}
														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																inputContainer={'.dzInputContainerStoreImage'}
																inputContainerClass={'dzInputContainerStoreImage'}
																url={'/uploadPartnerHomepageImage'}
																fieldName={'infoImage3'}
																handleDropzone={this.handleDropzone} />
														</div>
													</div>
												</Form.Group>
												<Field name="infoTitle3" type="text" component={this.renderField} label={formatMessage(messages.imageTitle3Label)} maxlength={250} />
												<Field name="infoContent3" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description3Label)} />
											</Col>
										</Row>
									</Col>
								</div>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, s.bottomPadding, 'bottomPaddingRTL', 'textAlignLeftRTL')}>
								<Form.Group className={s.noMargin}>
									<div className={s.displayInlineBlock}>
										<Loader
											type={"button"}
											label={formatMessage(messages.submitButton)}
											show={loading}
											buttonType={'submit'}
											className={cx(s.button, s.btnPrimary)}
											disabled={submitting || loading}
											isSuffix={true}
										/>
									</div>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Container>
			</div>
		)
	}
}

InfoForm = reduxForm({
	form: 'InfoForm',
	onSubmit: submit,
	validate
})(InfoForm);

const selector = formValueSelector('InfoForm')

const mapState = (state) => ({
	infoImage1: selector(state, 'infoImage1'),
	infoImage2: selector(state, 'infoImage2'),
	infoImage3: selector(state, 'infoImage3'),
	loading: state.loader.UpdatePartnerHomepage
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(InfoForm)));