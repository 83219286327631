import React from 'react';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './StorePartners.css'
import { api, partnerHomepageUploadDir } from '../../../../config'

class StorePartnerSection extends React.Component {

    render() {
        const { title, Image, description } = this.props;
        return (
            <div>
                <div className={s.mainSection}>
                    <div>
                        <div className={s.bannerBackgroundImage}
                            style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir + Image})` }} />
                    </div>
                </div>
                <div className={s.spaceTop5}>
                    <div className={s.titleText}>
                        {title}
                    </div>
                    <p className={s.contentText}>
                        {description}
                    </p>
                </div>
            </div>
        );
    }
}


export default injectIntl(withStyles(s)(StorePartnerSection));