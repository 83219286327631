import React, { Component } from 'react';
import s from './EditStoreForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
	Form,
	Col,
	Row,
	FormControl,
	FormGroup,
	Badge,
	Container
} from 'react-bootstrap';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import rs from '../../../storeCommon.css';

import Link from '../../../Link/Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import Loader from '../../../Common/Loader/Loader';
import PlacesSuggest from '../../../Shop/PlacesSuggest/PlacesSuggest';
import CountryList from '../../../Shop/CountryList/CountryList';
import CustomCheckbox from '../../../Common/CustomCheckbox/CustomCheckbox';
import Dropzone from '../../../Shop/Dropzone/Dropzone';
import { api, storeImageUploadDir } from '../../../../config';
import getCategoryCategories from '../../../Shop/EditProfileForm/getCategoryCategories.graphql';

export class EditStoreForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			phoneCountryCode: '',
			phoneDialCode: '',
			defaultPreparationTime: 55
		};

		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handleLocationData = this.handleLocationData.bind(this);
		this.handleDropzone = this.handleDropzone.bind(this);
		this.handleDefaultPreparationTime = this.handleDefaultPreparationTime.bind(this);
	}

	static defaultProps = {
		loading: false
	};

	componentDidMount() {
		const { initialValues } = this.props;
		const { defaultPreparationTime } = this.state;
		let preparationTime = initialValues && initialValues.shopEstimatedTime ? initialValues.shopEstimatedTime : defaultPreparationTime;

		this.setState({
			phoneCountryCode: initialValues && initialValues.phoneCountryCode,
			phoneDialCode: initialValues && initialValues.phoneDialCode,
			defaultPreparationTime: preparationTime
		});
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderCheck = ({ input, label, options, checked, meta: { touched, error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<p>{label}</p>
				<CustomCheckbox
					name={input.value}
					onChange={event => {
						input.onChange(event);
					}}
					checked={checked}
					value={true}
				/>
				{touched && error && <span className={cx(rs.errorMessage)}>{formatMessage(error)}</span>}
			</div>
		)
	}

	renderFieldPhoneNumber = ({ input, label, writeOnce, type, meta: { touched, error }, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group>
				<Form.Control {...input} readOnly={writeOnce} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<FormControl
					{...input}
					className={cx(s.inputNoRadius, className)}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		);
	};

	checkboxGroup = ({ label, name, options, input, meta: { touched, error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div className={'adminCheckbox'}>
				<ul className={s.listStyle}>
					{
						options && options.map((option, index) => {
							if (option.isActive) {
								return (
									<li className={s.listSectionWidth} key={index}>
										<span className={s.checkBoxSection}>
											<CustomCheckbox
												key={index}
												className={'icheckbox_square-green'}
												name={`${input.name}[${index}]`}
												value={option.id}
												checked={input.value.indexOf(option.id) !== -1}
												onChange={event => {
													const newValue = [...input.value]
													if (event === true) {
														newValue.push(option.id);
													} else {
														newValue.splice(newValue.indexOf(option.id), 1);
													}
													input.onChange(newValue);
												}
												}
											/>
										</span>
										<span className={cx(s.checkBoxLabel, 'checkBoxLabelRTL')}>
											{option.categoryName}
										</span>
									</li>
								)
							}
						})
					}
				</ul>
				{error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</div>
		);
	}

	renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<FormControl as="select" {...input} className={className} >
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		)
	}

	handleLocationData(location) {
		const { change } = this.props;

		let addressLabel = location && location.label;
		let addressPosition = location && location.location;
		let country, state, city;

		location && location.gmaps && location.gmaps.address_components.map((item, key) => {
			if (item.types[0] == "administrative_area_level_1") {
				state = item.long_name;
			} else if (item.types[0] == "country") {
				country = item.long_name;
			} else if (item.types[0] == "administrative_area_level_2" || item.types[0] == "political") {
				city = item.long_name;
			}
		});

		change('address', addressLabel)
		change('city', city ? city : null);
		change('state', state ? state : null);
		change('country', country ? country : null);
		change('lat', addressPosition && addressPosition.lat);
		change('lng', addressPosition && addressPosition.lng);
	};

	handleCountryChange(country) {
		const { change } = this.props;

		this.setState({
			phoneCountryCode: country.countryCode,
			phoneDialCode: country.dialCode
		});

		change('phoneCountryCode', country.countryCode)
		change('phoneDialCode', country.dialCode)
	};

	handleDropzone(fileName, fieldName) {
		const { change } = this.props;
		change(fieldName, fileName)
	};

	handleDefaultPreparationTime(action) {
		const { defaultPreparationTime } = this.state;
		const { change } = this.props;

		let preparationTime = action == 'add' ? defaultPreparationTime + 5 : defaultPreparationTime - 5;

		if (preparationTime <= 55 && preparationTime >= 5) {
			this.setState({ defaultPreparationTime: preparationTime })
			change('shopEstimatedTime', preparationTime)
		}
	};

	checkboxHorizontalGroup = ({ label, input, checked, meta: { error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div className={cx('adminCheckbox', 'textRightRTL')}>
				<div className={s.flexCheckform}>
					<CustomCheckbox
						className={'icheckbox_square-green'}
						onChange={event => {
							input.onChange(event);
						}}
						checked={checked}
						value={true}
					/>
					<span className={cx(s.checkBoxLabel, 'checkBoxLabelRTL')}>{label}</span>
				</div>
				{error && <span className={cx(s.errorMessage, s.errorTop)}>{formatMessage(error)}</span>}
			</div>
		)
	};


	render() {
		const { handleSubmit, loading, submitting, initialValues, getCategory: { getCategoryDelivery }, getDietary, getShop, isDoorStepDelivery, isTakeAway } = this.props;
		const { availableCurrencies, picture } = this.props;
		const { phoneCountryCode, phoneDialCode, defaultPreparationTime } = this.state;
		const { formatMessage } = this.props.intl;

		return (
			<div className={cx(s.space5, s.spaceTop5, 'tabLabelText')}>
				<Container fluid>
					<Row>
						<Col xl={10} lg={12} md={12} sm={12} xs={12} className={cx(s.blackCenter, 'textAlignRightRTL')}>
							<div className={s.blackCenterSection}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<Col lg={12} md={12} sm={12} xs={12}>
										<h1 className={cx(s.titleTextAdmin, 'textAlignRightRTL')}>
											{formatMessage(messages.editStore)}
											<span className={s.userStatusBadge}>
												<Badge pill variant={getShop && getShop.isActive === 1 && getShop.isBan === 0 ? 'success' : 'danger'}>
													{getShop && getShop.isActive === 1 && getShop.isBan === 0 ? formatMessage(messages.online) : formatMessage(messages.offline)}
												</Badge>
												{' '}
												{
													getShop.isActive === 1 && getShop.isBan === 0 && <Badge pill variant={getShop.activeStatus === "active" ? 'success' : 'danger'}>
														{getShop.activeStatus === "active" ? formatMessage(messages.eligibleForAOrder) : formatMessage(messages.notEligibleForAOrder)}
													</Badge>
												}
											</span>
										</h1>
									</Col>
									<Col xl={12} lg={12} md={12} sm={12} xs={12}>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<Row>
													<Col lg={12} md={12} sm={12} xs={12}>
														<Form.Group className={cx(s.space5)}>
															<div className={cx(s.profileImgSection)}>
																<label className={s.space3}>{formatMessage(messages.storeImage)}</label>
																{/* {
                                                            picture &&
                                                            <img src={`${api.apiEndpoint + storeImageUploadDir + picture}`} height={300} width={300} />
                                                        } */}
																{
																	picture &&
																	<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + storeImageUploadDir}medium_${picture})` }} />
																}
																<div className={'siteadminProfileBtn'}>
																	<Dropzone
																		className={cx(s.btnSecondary, s.profileNoPadding)}
																		subTextClass={s.subText}
																		subText={formatMessage(messages.maximumUploadSizeLabel)}
																		defaultMessage={formatMessage(messages.chooseFile)}
																		inputContainer={'.dzInputContainerStoreImage'}
																		inputContainerClass={'dzInputContainerStoreImage'}
																		url={'/uploadStoreImage'}
																		fieldName={'picture'}
																		handleDropzone={this.handleDropzone}
																	/>
																</div>
															</div>
														</Form.Group>
													</Col>
													<Col lg={12} md={12} sm={12} xs={12}>
														<Field
															name="shopName"
															type="text"
															placeholder={formatMessage(messages.shopName)}
															component={this.renderField}
															label={formatMessage(messages.shopName)}
															labelClass={s.labelText}
															fieldClass={s.formControlInput}
														/>
													</Col>
													<Col lg={12} md={12} sm={12} xs={12}>
														<Form.Group className={s.space3}>
															<div>
																<Field
																	name="description"
																	type="text"
																	placeholder={formatMessage(messages.storeDescription)}
																	component={this.renderFormControlTextArea}
																	label={formatMessage(messages.storeDescription)}
																	labelClass={s.labelText}
																/>
															</div>
														</Form.Group>
													</Col>
													<Col lg={6} md={12} sm={12} xs={12}>
														<Form.Group className={s.space3}>
															<div>
																<Field
																	name="firstName"
																	type="text"
																	placeholder={formatMessage(messages.firstName)}
																	component={this.renderField}
																	label={formatMessage(messages.firstName)}
																	labelClass={s.labelText}
																	fieldClass={s.formControlInput}
																/>
															</div>
														</Form.Group>
													</Col>
													<Col lg={6} md={12} sm={12} xs={12}>
														<Form.Group className={s.space3}>
															<div>
																<Field
																	name="lastName"
																	type="text"
																	placeholder={formatMessage(messages.lastName)}
																	component={this.renderField}
																	label={formatMessage(messages.lastName)}
																	labelClass={s.labelText}
																	fieldClass={s.formControlInput}
																/>
															</div>
														</Form.Group>
													</Col>
												</Row>
												<Row>
													<Col xl={12} lg={12} md={12} sm={12} xs={12}>
														<Form.Group className={s.space3}>
															<div>
																<Field
																	name="email"
																	type="text"
																	placeholder={formatMessage(messages.email)}
																	component={this.renderField}
																	label={formatMessage(messages.email)}
																	labelClass={s.labelText}
																	fieldClass={s.formControlInput}
																	disabled
																/>
															</div>
														</Form.Group>
													</Col>
													<Col xl={12} lg={12} md={12} sm={12} xs={12}>
														<Row>
															<Col xl={6} lg={6} md={12} sm={12} xs={12} className={s.space2}>
																<div className={cx(s.space5, 'positionRelative')}>
																	<label className={'inputLabel'}>{formatMessage(messages.phoneNumber)}</label>
																	{phoneCountryCode && <CountryList
																		className={cx(s.formControlSelect, s.formControlInput, s.space1)}
																		defaultCountry={phoneCountryCode}
																		handleCountryChange={this.handleCountryChange}
																		disabled
																	/>}
																</div>
															</Col>
															<Col xl={6} lg={6} md={12} sm={12} xs={12}>
																<Form.Group className={s.noMargin}>
																	<div className={cx(s.countrySection, 'countrySectionRTL')}>
																		<span className={s.space1}>
																			{phoneDialCode}
																		</span>
																	</div>
																	<div className={s.phoneNumberSection}>
																		<Field
																			name="phoneNumber"
																			type="text"
																			placeholder={formatMessage(messages.phoneNumber)}
																			component={this.renderFieldPhoneNumber}
																			fieldClass={cx(s.formControlInput, s.inputRadius, 'inputRadiusRTL')}
																			disabled
																		/>
																	</div>
																</Form.Group>
															</Col>
														</Row>
													</Col>
													<Col lg={8} md={12} sm={12} xs={12}>
														<Form.Group className={cx(s.space5, 'positionRelative')}>
															<div className={cx('siteAdminProfile', 'adminEditForm')}>
																<label className={'inputLabel'}>{formatMessage(messages.address)}</label>
																<PlacesSuggest
																	label={formatMessage(messages.address)}
																	className={s.space3}
																	handleData={this.handleLocationData}
																	defaultValue={initialValues && initialValues.address}
																/>
															</div>
														</Form.Group>
													</Col>
													<Col lg={4} md={12} sm={12} xs={12}>
														<Field
															name="zipcode"
															type="text"
															placeholder={formatMessage(messages.zipcode)}
															component={this.renderField}
															label={formatMessage(messages.zipcode)}
															labelClass={s.labelText}
															fieldClass={s.formControlInput}
														/>
													</Col>
												</Row>
												<Row>
													<Col xl={6} lg={6} md={12} sm={12} xs={12}>
														<Field name="priceRange"
															type="Select"
															className={cx(s.formControlSelect, s.formControlSelectWidth, s.commonBorder, s.formControlInput)}
															component={this.renderFormControlSelect}
															placeholder={formatMessage(messages.priceRange)}
															label={formatMessage(messages.priceRange)}
														>
															<option value="">{formatMessage(messages.choosePriceRange)}</option>
															<option value={1}>$</option>
															<option value={2}>$$</option>
															<option value={3}>$$$</option>
															<option value={4}>$$$$</option>
														</Field>
													</Col>

													<Col xl={6} lg={6} md={12} sm={12} xs={12}>
														<Field
															name="password"
															type="text"
															placeholder={formatMessage(messages.password)}
															component={this.renderField}
															label={formatMessage(messages.password)}
															labelClass={s.labelText}
															fieldClass={s.formControlInput}
														/>
													</Col>
													<Col lg={6} md={6} sm={6} xs={12}>
														<Form.Group className={cx(s.space5, 'positionRelative')}>
															<label className={'inputLabel'}>{formatMessage(messages.userActiveStatus)}</label>
															<Field name="userStatus" className={cx(s.formControlSelect, s.formControlInput)} component="select">
																<option value={'pending'}>{formatMessage(messages.pending)}</option>
																<option value={'active'}>{formatMessage(messages.approve)}</option>
																<option value={'inactive'}>{formatMessage(messages.decline)}</option>
															</Field>
														</Form.Group>
													</Col>
													<Col lg={6} md={6} sm={6} xs={12}>
														<Form.Group className={cx(s.space5, 'positionRelative')} controlId="exampleForm.ControlSelect1">
															<label className={'inputLabel'}>{formatMessage(messages.banStatus)}</label>
															<Field name="isBan" className={cx(s.formControlSelect, s.formControlInput)} component="select">
																<option value="0">{formatMessage(messages.permit)}</option>
																<option value="1">{formatMessage(messages.ban)}</option>
															</Field>
														</Form.Group>
													</Col>
												</Row>

												<Row>
													<Col lg={12} md={12} sm={12} xs={12} >
														<Badge pill variant={'success'}>
															{initialValues.deliveryName}
														</Badge>
														<Form.Group className={s.space3}>
															<label className={s.labelNumberText}>{formatMessage(messages.category)}</label>
															<Field name="categoryType" component={this.checkboxGroup} options={getCategoryDelivery && getCategoryDelivery.results} />
														</Form.Group>
													</Col>
												</Row>
												<div>
													<label className={cx(s.listSectionWidth, s.margin6Xs)}>{formatMessage(messages.deliveryTypeLabel)}</label>
													<div className={s.listSectionWidth}>
														<Form.Group className={s.noMargin}>
															<Field name="isDoorStepDelivery"
																component={this.checkboxHorizontalGroup}
																label={formatMessage(messages.doorStepDelivery)}
																checked={isDoorStepDelivery}
															/>
														</Form.Group>
													</div>
													<div className={s.listSectionWidth}>
														<Form.Group className={s.noMargin}>
															<Field name="isTakeAway"
																component={this.checkboxHorizontalGroup}
																label={formatMessage(messages.takeAway)}
																checked={isTakeAway}
															/>
														</Form.Group>
													</div>

												</div>
											</Col>
										</Row>
									</Col>

									<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop3)}>
										<Form.Group className={s.noMargin}>
											<Link to={"/siteadmin/store/list"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL', s.backBtnTwoTop)} >{formatMessage(messages.goBack)}</Link>
											<div className={s.displayInlineBlock}>
												<Loader
													type={"button"}
													label={formatMessage(messages.update)}
													show={loading}
													buttonType={'submit'}
													className={cx(s.button, s.btnPrimary)}
													disabled={submitting || loading}
													isSuffix={true}
												/>
											</div>
										</Form.Group>
									</Col>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

EditStoreForm = reduxForm({
	form: 'EditStoreForm',
	onSubmit: submit,
	validate
})(EditStoreForm);

const selector = formValueSelector('EditStoreForm');

const mapState = state => ({
	loading: state.loader.UpdateStore,
	picture: selector(state, 'picture'),
	availableCurrencies: state.currency.availableCurrencies,
	isTakeAway: selector(state, 'isTakeAway'),
	isDoorStepDelivery: selector(state, 'isDoorStepDelivery')
});

const mapDispatch = {};

export default compose(
	injectIntl,
	withStyles(s),
	connect(mapState, mapDispatch),
	graphql(getCategoryCategories, {
		name: 'getCategory',
		options: (props) => ({
			variables: {
				deliveryType: props.deliveryType,
			},
			fetchPolicy: 'network-only',
			ssr: true
		})
	})
)(EditStoreForm);