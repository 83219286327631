import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import {
  Button,
  Row,
  FormGroup,
  Col,
  FormControl,
  Form,
  Container
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from '../Payout.css';
import rs from '../../../storeCommon.css';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import getPaymentMethodsQuery from './getPaymentMethods.graphql';
import CountryListDropdown from '../../../CountryListDropdown';
import validate from './validate';
import messages from '../../../../locale/messages';
import Link from '../../../Link/Link'

class PayoutBillingDetails extends Component {
  static defaultProps = {
    defaultCountry: 'US'
  };

  constructor(props) {
    super(props);
    this.handleCountryChange = this.handleCountryChange.bind(this)
  };

  renderField = ({ input, label, type, meta: { touched, error, dirty } }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(rs.space5, 'positionRelative')}>
          <label className={'inputLabel'}>{label}</label>
          <FormControl {...input} componentClass="input" className={cx(rs.formControlInputStore, 'formControlInputStoreRTL')} />
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  };

  renderCountryList = ({ input, label, meta: { touched, error }, children, className }) => {
    const { defaultCountry } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <FormGroup className={cx(rs.space5, 'positionRelative')}>
          <label className={'inputLabel'}><FormattedMessage {...messages.country} /></label>
          <CountryListDropdown
            defaultCountry={defaultCountry}
            handleCountryChange={this.handleCountryChange}
            input={input}
            className={cx(className, rs.formControlInputStore, 'formControlInputStoreRTL')}
            isEmptyFirst
          />
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
      </FormGroup>
    );
  };

  handleCountryChange(country) {
    const { change } = this.props;
    change('country', country.countryCode);
  };


  render() {
    const { handleSubmit } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={'mainContainer'}>
        <div className={s.container}>
          <Container fluid>
            <Row>
              <Col md={12} lg={12} sm={12} xs={12}>
                <div>
                  <h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>
                    <FormattedMessage {...messages.addPayout} />
                  </h1>
                </div>
                <Form className={s.fullWidth} onSubmit={handleSubmit}>
                  <div className={cx(rs.storeNewBg, rs.space5, 'countrySelectRTL')}>
                    <Row>
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <Field name="country" component={this.renderCountryList} label={formatMessage(messages.address1)} />
                        <Field name="address1" component={this.renderField} label={formatMessage(messages.address1)} />
                        <Field name="address2" component={this.renderField} label={formatMessage(messages.address2)} />
                        <Field name="city" component={this.renderField} label={formatMessage(messages.city)} />
                        <Field name="state" component={this.renderField} label={formatMessage(messages.state)} />
                        <Field name="zipcode" component={this.renderField} label={formatMessage(messages.zipcode)} />
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.spaceTop2, rs.alignRightText, 'textAlignLeftRTL')}>
                      <div className={s.displayInlineBtn}>
                        <Form.Group className={s.formGroup}>
                          <div className={rs.alignRightText}>
                            <Link to={'/store/payout'} className={cx(rs.button, rs.btnPrimaryBorder, s.btnWidthMobile, s.linkTextColor)}><FormattedMessage {...messages.back} /></Link>
                          </div>
                        </Form.Group>
                      </div>
                      <div className={s.displayInlineBtn}>
                        <Form.Group className={s.formGroup}>
                          <div className={rs.alignRightText}>
                            <Button type="submit" className={cx(rs.button, rs.btnPrimary, s.btnWidthMobile)}>
                              <FormattedMessage {...messages.next} />
                            </Button>
                          </div>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
};

PayoutBillingDetails = reduxForm({
  form: 'PayoutForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(PayoutBillingDetails);

const selector = formValueSelector('PayoutForm');

const mapState = state => ({
  userEmail: state.account && state.account.data && state.account.data.email,
  defaultCountry: selector(state, 'country')
});

const mapDispatch = {
  change
};


export default compose(
  injectIntl,
  withStyles(s, rs),
  connect(mapState, mapDispatch)
)(PayoutBillingDetails);