import React, { Component } from 'react';
import s from './AddMenuForm.css';
import rs from '../../../storeCommon.css';
import { Field, reduxForm, FieldArray, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
	Button,
	Form,
	Col,
	Row,
	FormGroup,
	Container
} from 'react-bootstrap';
import messages from '../../../../locale/messages';
import validate from './validate';
import SwitchButton from './Switch';
import { generateTimes } from '../../../../helpers/generateTimes';
import history from '../../../../history';

export class AddMenuForm extends Component {

	static defaultProps = {
		loading: false
	};

	constructor(props) {
		super(props);
		this.state = {
			isDisabled: false,
		};
		this.changeSlot = this.changeSlot.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	componentDidMount() {
		const { formErrors } = this.props;
		if (formErrors != undefined) {
			if (formErrors.hasOwnProperty('syncErrors')) {
				if (formErrors.syncErrors.hasOwnProperty('menuAvailability')) {
					this.setState({ isDisabled: true });
				} else {
					this.setState({ isDisabled: false });
				}
			} else {
				this.setState({ isDisabled: false });
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { formErrors } = nextProps;
		if (formErrors != undefined) {
			if (formErrors.hasOwnProperty('syncErrors')) {
				if (formErrors.syncErrors.hasOwnProperty('menuAvailability')) {
					this.setState({ isDisabled: true });
				} else {
					this.setState({ isDisabled: false });
				}
			} else {
				this.setState({ isDisabled: false });
			}
		}
	}

	async changeSlot(index, type) {
		const { change } = this.props;
		if (type) {
			await change(`menuAvailability[${index}].timeSlot[${0}].startTime`, 0);
			await change(`menuAvailability[${index}].timeSlot[${0}].endTime`, 0.5);
		} else {
			await change(`menuAvailability[${index}].timeSlot`, []);
		}
	}

	closeMenu() {
		history.push('/store/menu');
	}


	renderSwitch = ({ input, label, meta: { touched, error }, index, switchOnLabel, switchOffLabel }) => {
		return (
			<div>
				<SwitchButton
					{...input}
					index={index}
					defaultValue={input.value}
					switchOnLabel={switchOnLabel}
					switchOffLabel={switchOffLabel}
				/>
			</div>
		)
	}

	renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
		const { formatMessage } = this.props.intl;
		return (
			<FormGroup className={cx(rs.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control as="select" {...input} className={fieldClass}>
					{children}
				</Form.Control>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		)
	}

	renderAvailabilityBox = ({ fields, meta: { error, submitFailed }, indexValue }) => {
		const { menuAvailability } = this.props;
		const { formatMessage } = this.props.intl;
		let timeLookups = generateTimes(0, 1410);
		return (
			<div>
				{
					menuAvailability && menuAvailability.length > 0 && menuAvailability.map((item, index) => {
						return (
							<div>
								<tr className={cx(s.tableText)}>
									<td className={cx(s.noBorder, s.tableText, 'textAlignRightRTL')}>
										<div className={s.daysText}>
											<span>
												{formatMessage(messages[item.operationDay])}
											</span>
										</div>
										<div className={cx(s.displayInlineblock, s.switchSection, s.vtrTop)}>
											<FormGroup >
												<Field
													name={`menuAvailability[${index}].isOpen`}
													component={this.renderSwitch}
													index={index}
													switchOnLabel={formatMessage(messages.openLabel)}
													switchOffLabel={formatMessage(messages.closeLabel)}
												/>
											</FormGroup>
										</div>
										{
											menuAvailability[index].isOpen && <div className={cx(s.displayInlineblock, s.radioBtnSection, s.vtrTop)}>
												<FormGroup className={cx(s.formGroup, s.displayInlineblock, s.radioBtnLabelSection)}>
													<div>
														<label>
															<span>
																<Field
																	name={`menuAvailability[${index}].isWholeDay`}
																	component="input"
																	type="radio"
																	value='true'
																	onChange={(e) => this.changeSlot(index, false)}
																/>
															</span>
															<span className={cx(s.paddingLeft, s.vtrMiddle, s.labelTitleText, rs.cursorPointer)}>
																<FormattedMessage {...messages.allHours} />
															</span>
														</label>
													</div>
												</FormGroup>
												<FormGroup className={cx(s.formGroup, s.displayInlineblock, s.radioBtnLabelSection, s.vtrTop)}>
													<div>
														<label>
															<span>
																<Field
																	name={`menuAvailability[${index}].isWholeDay`}
																	component="input"
																	type="radio"
																	value="false"
																	onChange={(e) => this.changeSlot(index, true)}
																/>
															</span>
															<span className={cx(s.paddingLeft, s.vtrMiddle, s.labelTitleText, rs.cursorPointer, 'hourPaddingRTL')}>
																<FormattedMessage {...messages.specificHours} />
															</span>
														</label>
													</div>
												</FormGroup>
												{
													menuAvailability[index].isWholeDay == 'false' && <div className={cx(s.timeSection, 'storeActiveSelect', 'floatLeftRTL')}>
														<div className={cx(s.timeInline)}>
															<Field
																name={`menuAvailability[${index}].timeSlot[${0}].startTime`}
																component={this.renderSelectField}
																fieldClass={cx(rs.formControlInputStore, s.selectText, 'formControlInputStoreRTL')}
																label={formatMessage(messages.startTimeLabel)}
															>
																{
																	timeLookups && timeLookups.length > 0 && timeLookups.map((item, key) => {
																		return (<option key={key} value={item.value}>{item.label}</option>)
																	})
																}
															</Field>
														</div>
														<div className={cx(s.timeInline)}>
															<Field
																name={`menuAvailability[${index}].timeSlot[${0}].endTime`}
																component={this.renderSelectField}
																fieldClass={cx(rs.formControlInputStore, s.selectText, 'formControlInputStoreRTL')}
																label={formatMessage(messages.endTimeLabel)}
															>
																{
																	timeLookups && timeLookups.length > 0 && timeLookups.map((item, key) => {
																		return (<option key={key} value={item.value}>{item.label}</option>)
																	})
																}
															</Field>
														</div>
													</div>
												}
											</div>
										}
									</td>
								</tr>
							</div>
						)
					})
				}
			</div>
		)
	}


	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxLength }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(rs.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} maxLength={maxLength} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};


	render() {
		const { handleSubmit, loading, submitting, initialValues } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div className={'mainContainer'}>
				<div>
					<Container fluid>
						<Row className={'customRatioButtonStore'}>
							<Col md={12} lg={12} sm={12} xs={12}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<div>
										<h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>{formatMessage(messages.craftMenu)}</h1>
									</div>
									<Row>
										<Col lg={12} md={12} sm={12} xs={12}>
											<Row className={s.bgColor}>
												<Col lg={8} md={12} sm={12} xs={12}>
													<Form.Group className={cx(s.formGroup, s.noMargin)}>
														<div>
															<Field
																name="menuName"
																type="text"
																placeholder={formatMessage(messages.menuName)}
																component={this.renderField}
																labelClass={s.labelText}
																label={formatMessage(messages.menuName)}
																fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
																maxLength={100}
															/>
														</div>
													</Form.Group>
												</Col>
												<Col lg={4} md={12} sm={12} xs={12}>
													<Form.Group className={cx(rs.space5, 'positionRelative', 'statusSelectRTL')}>
														<label className={'inputLabel'}>{formatMessage(messages.status)}</label>
														<Field name="isEnable" className={cx(rs.formControlSelect, rs.formControlInputStore, 'formControlInputStoreRTL')} component="select">
															<option value={true}>{formatMessage(messages.active)}</option>
															<option value={false}>{formatMessage(messages.inactive)}</option>
														</Field>
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col lg={12} md={12} sm={12} xs={12} className={rs.spaceTop3}>
													<h1 className={cx(rs.storeTitleText, rs.space3, 'textAlignRightRTL')}>{formatMessage(messages.availabilty)}</h1>
												</Col>
												<Col lg={12} md={12} sm={12} xs={12} className={rs.spaceTop3}>
													<Form.Group className={s.formGroup}>
														<div className={s.landingMainContent}>
															<table className={cx('table')}>
																<tbody>
																	<FieldArray
																		name="menuAvailability"
																		component={this.renderAvailabilityBox}
																	/>
																</tbody>
															</table>
														</div>
													</Form.Group>
												</Col>
												<Col lg={12} md={12} sm={12} xs={12} className={rs.spaceTop2}>
													<div className={cx(rs.alignRightText, 'textAlignLeftRTL')}>
														<FormGroup className={cx(s.formGroup)}>
															<div className={rs.secondryBtnSection}>
																<a
																	href={'javascript:void(0)'}
																	onClick={() => this.closeMenu()}
																	className={cx(rs.button, rs.btnPrimaryBorder, rs.displayBlock, rs.alignCenter)}
																>
																	<FormattedMessage {...messages.cancel} />
																</a>
															</div>
															<div className={rs.secondryBtnSection}>
																<Button block type="submit" disabled={submitting} className={cx(rs.button, rs.btnPrimary)}>
																	<FormattedMessage {...messages.save} />
																</Button>
															</div>
														</FormGroup>
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		)
	}
}

AddMenuForm = reduxForm({
	form: 'AddMenuForm',
	onSubmit: submit,
	validate
})(AddMenuForm);

const selector = formValueSelector('AddMenuForm');

const mapState = state => ({
	menuAvailability: selector(state, 'menuAvailability'),
	formErrors: state.form.AddMenuForm,
});

const mapDispatch = {
	change
};


export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(AddMenuForm)));