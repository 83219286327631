import React from 'react';
import PropTypes from 'prop-types';
import s from './ConfigSettingsForm.css';
import {
  Row,
  FormGroup,
  Col,
  FormControl,
  Form,
  Container,
  InputGroup,

} from 'react-bootstrap';

import { Field, reduxForm } from 'redux-form';

import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import submit from './submit'
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';
import { connect } from 'react-redux';
import validate from './validate';
import Loader from '../../Common/Loader/Loader';

class ConfigSettingsForm extends React.Component {

  static propTypes = {
    initialValues: PropTypes.object,
    title: PropTypes.string.isRequired,
  };


  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={cx(s.space4, 'positionRelative')}>
          <Form.Label className={'inputLabel'}>{label}</Form.Label>
          <FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
        </FormGroup>
      </div>
    );
  }

  renderAddonFormControl = ({ input, label, type, meta: { touched, error }, addonLabel, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('inputFormAddon', 'addonBorder')}>
        <Form.Group>
          <InputGroup>
            <Form.Label className={'inputLabel'}>{label}</Form.Label>
            <FormControl {...input} placeholder={label} type={type} className={s.formControlInput} maxlength={maxlength} />
            <InputGroup.Text>{addonLabel}</InputGroup.Text>
          </InputGroup>
          {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
        </Form.Group>
      </div>
    );
  }

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={cx(s.space4, 'positionRelative')}>
          <Form.Label className={'inputLabel'}>{label}</Form.Label>
          <FormControl
            {...input}
            className={className}
            placeholder={label}
            as="textarea"
            rows='4'
          >
            {children}
          </FormControl>
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
        </FormGroup>
      </div>
    );
  }

  render() {
    const { error, handleSubmit, submitting, distanceUnits } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={s.paddignLeftRight}>
        <Container fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <form onSubmit={handleSubmit(submit)}>
                {error && <strong>{error}</strong>}
                <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.stripeSettings)}</h5>
                <div className={s.girdOne}>
                  <Field name="stripePublishableKey" type="text" component={this.renderFormControl} label={formatMessage(messages.stripePublishableKey)} />
                </div>
                <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.smtpSettings)}</h5>
                <div className={s.girdTwo}>
                  <Field name="smtpHost" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpHost)} />
                  <Field name="smtpPort" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpPort)} />
                  <Field name="smptEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.smptEmail)} />
                  <Field name="smtpSender" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpSender)} />
                  <Field name="smtpSenderEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpSenderEmail)} />
                  <Field name="smtpPassWord" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpPassWord)} />
                </div>
                <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.twilioSettings)}</h5>
                <div className={s.girdTwo}>
                  <Field name="twilioAccountSid" type="text" component={this.renderFormControl} label={formatMessage(messages.twilioAccountSid)} />
                  <Field name="twilioAuthToken" type="text" component={this.renderFormControl} label={formatMessage(messages.twilioAuthToken)} />
                  <Field name="twilioPhone" type="text" component={this.renderFormControl} label={formatMessage(messages.twilioPhone)} />
                </div>
                <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.pushNotificationSettings)}</h5>
                <div className={s.girdOne}>
                  <Field name="fcmPushNotificationKey" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.fcmPushNotificationKey)} />
                </div>
                <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.otherSettings)}</h5>
                <div className={cx(s.girdOne, 'configmessageRTL')}>
                  <Field name="subtractTime" type="text" component={this.renderAddonFormControl} label={formatMessage(messages.subtractTime)} addonLabel={formatMessage(messages.minutes)} />
                  <Field name="maxDistance" type="text" component={this.renderAddonFormControl} label={formatMessage(messages.distance)} addonLabel={formatMessage(messages.kmLabel)} />
                  <Field name="maxUploadSize" type="text" component={this.renderFormControl} label={formatMessage(messages.maxUploadLabel)} />
                </div>
                <div xs={12} sm={12} md={12} lg={12} className={cx(s.textAlignRight, 'textLeftRTL')}>
                  <FormGroup className={s.space3}>
                    <div>
                      <Loader
                        type={"button"}
                        label={formatMessage(messages.submitButton)}
                        show={submitting}
                        buttonType={'submit'}
                        className={cx(s.button, s.btnPrimary)}
                        disabled={submitting}
                        isSuffix={true}
                      />
                    </div>
                  </FormGroup>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div >
    );
  }
}

ConfigSettingsForm = reduxForm({
  form: 'ConfigSettingsForm', // a unique name for this form
  validate,
})(ConfigSettingsForm);

const mapState = (state) => ({

});

const mapDispatch = {};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ConfigSettingsForm)));

