import {
    OPEN_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    OPEN_FORGOT_PASSWORD_MODAL,
    CLOSE_FORGOT_PASSWORD_MODAL,
    OPEN_ORDER_MODAL,
    CLOSE_ORDER_MODAL,
    OPEN_LOGOUT_MODAL,
    CLOSE_LOGOUT_MODAL,
} from '../constants';

export function openLoginModal() {

    return (dispatch, getState) => {

        dispatch({
            type: OPEN_LOGIN_MODAL,
            isLoginModalOpen: true,
            isForgotPasswordModalOpen: false
        });
    }
}

export function closeLoginModal() {

    return (dispatch, getState) => {

        dispatch({
            type: CLOSE_LOGIN_MODAL,
            isLoginModalOpen: false
        });
    }
}

export function openForgotPasswordModal() {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_FORGOT_PASSWORD_MODAL,
            isForgotPasswordModalOpen: true,
            isLoginModalOpen: false
        });
    }
}

export function closeForgotPasswordModal() {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_FORGOT_PASSWORD_MODAL,
            isForgotPasswordModalOpen: false
        });
    }
}


export function openOrderModal() {

    return (dispatch, getState) => {

        dispatch({
            type: OPEN_ORDER_MODAL,
            isOrderModalOpen: true,
        });
    }
}

export function closeOrderModal() {

    return (dispatch, getState) => {

        dispatch({
            type: CLOSE_ORDER_MODAL,
            isOrderModalOpen: false
        });
    }
}

export function openLogoutModal() {

    return (dispatch, getState) => {

        dispatch({
            type: OPEN_LOGOUT_MODAL,
            isLogoutModalOpen: true,
        });
    }
}

export function closeLogoutModal() {

    return (dispatch, getState) => {

        dispatch({
            type: CLOSE_LOGOUT_MODAL,
            isLogoutModalOpen: false
        });
    }
}