import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import submit from './submit';
import validate from './validate';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

import {
  Form,
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AdminLoginForm.css';
import { connect } from 'react-redux';

import Loader from '../../Common/Loader';
import Link from '../../Link/Link'
import HeaderModal from '../../HeaderModal/HeaderModal';

import { openHeaderModal } from '../../../actions/siteadmin/modalActions';

import { formatLocale } from '../../../helpers/formatLocale';

//Images
import adminLoginImage from '../../../../public/SiteImages/adminLoginPage.svg'
import AdminLogo from '../../../../public/SiteImages/logo.png';
import gobalIcon from '../../../../public/Icons/languageIcon.png';
import storeIcon from '../../../../public/Icons/storeLogin.svg';
import { maxUploadSize, api } from '../../../config';

class AdminLoginForm extends Component {

  static defaultProps = {
    loading: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true
    }
  }

  componentDidMount() {
    const isBrowser = typeof window !== 'undefined';
    isBrowser && this.setState({
      isDisabled: false
    });
  }


  handleChange(e) {
    const { openHeaderModal } = this.props;
    openHeaderModal('languageModal');
  }

  renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group className={'positionRelative'}>
        <Form.Label className={'inputLabel'}>{label}</Form.Label>
        <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
      </Form.Group>
    )
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, loading, siteSettingsData, currentLocale } = this.props;
    const { formatMessage } = this.props.intl;
    const apiEndpoint = api && api.apiEndpoint;

    let adminLogo = siteSettingsData && siteSettingsData.homeLogo ? `${apiEndpoint}/images/logo/${siteSettingsData.homeLogo}` : AdminLogo;

    return (
      <div>
        <HeaderModal />
        <div className={s.loginMainBg}>
          <div className={s.loginBg} style={{ backgroundImage: `url(${adminLoginImage})` }} />
          <div className={s.formSection}>
            <div className={s.header}>
              <Link noLink
                onClick={(e) => this.handleChange(e)} className={cx(s.languageText, s.cursurPointer, 'languageTextRTL')}><span className={cx(s.languageIcon, 'languageIconRTL')}><img src={gobalIcon} /></span><span>{formatLocale(currentLocale)}</span></Link>
              <Link to={'/'} className={s.storeBtn}><span className={cx(s.languageIcon, 'languageIconRTL')}><img src={storeIcon} /></span><span><FormattedMessage {...messages.goToMainSite} /></span></Link>
            </div>
            <div className={s.formInner}>
              <div className={cx(s.loginTitleScetion, 'languageIconRTL')}>
                <img src={adminLogo} />
                <p className={s.loginTitle}><FormattedMessage {...messages.welcomeAdminLabel} /></p>
              </div>
              <div className={'loginInput'}>
                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                  {error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
                  <Field
                    name="email"
                    type="text"
                    component={this.renderField}
                    label={formatMessage(messages.email)}
                    placeholder={formatMessage(messages.email)}
                    labelClass={s.labelText}
                    fieldClass={cx(s.formControlInput)}
                  />
                  <Field
                    name="password"
                    type="password"
                    component={this.renderField}
                    label={formatMessage(messages.password)}
                    placeholder={formatMessage(messages.password)}
                    labelClass={s.labelText}
                    fieldClass={cx(s.formControlInput)}
                  />
                  <Form.Group className={s.loginButton}>
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.login)}
                      show={loading}
                      buttonType={'submit'}
                      className={cx(s.button, s.btnPrimary)}
                      disabled={submitting || loading || this.state.isDisabled}
                      isSuffix={true}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AdminLoginForm = reduxForm({
  form: 'AdminLoginForm', // a unique name for this form
  validate,
  onSubmit: submit
})(AdminLoginForm);

const mapState = (state) => ({
  loading: state.loader.AdminLogin,
  siteSettingsData: state.siteSettings.data,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  openHeaderModal
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AdminLoginForm)));