import React, { Component } from 'react';
import s from './BannerForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    FormControl,
    FormGroup,
    Container
} from 'react-bootstrap';
import Link from '../../../Link/Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import { api, partnerHomepageUploadDir } from '../../../../config';
import Dropzone from '../../../Common/Dropzone/Dropzone';
import Loader from '../../../Common/Loader/Loader';

export class BannerForm extends Component {

    constructor(props) {
        super(props)

        this.handleDropzone = this.handleDropzone.bind(this);
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={cx(s.space5, 'positionRelative')}>
                <Form.Label className={'inputLabel'}>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
                />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label className={'inputLabel'}>{label}</label>
                <FormControl
                    {...input}
                    className={className}
                    placeholder={label}
                    as="textarea"
                    rows="4"
                >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    handleDropzone(fileName, fieldName) {
        const { change } = this.props

        change(fieldName, fileName)
    };

    render() {
        const { handleSubmit, bannerImage1, loading, submitting, initialValues, bannerImage2 } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div>
                <h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.bannerSection)}</h1>
                <Container fluid>
                    <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                                <div className={s.blackCenterSection}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={6} md={12} sm={12} xs={12} className={s.space2}>
                                                <Form.Group className={s.space3}>
                                                    <div className={s.profileImgSection}>
                                                        <label className={s.space1}>{formatMessage(messages.homeImage1)}</label>
                                                        {
                                                            bannerImage1 &&
                                                            <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir}${bannerImage1})` }} />
                                                        }
                                                        <div>
                                                            <Dropzone
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                inputContainer={'.dzInputContainerStoreImage'}
                                                                inputContainerClass={'dzInputContainerStoreImage'}
                                                                url={'/uploadPartnerHomepageImage'}
                                                                fieldName={'bannerImage1'}
                                                                handleDropzone={this.handleDropzone}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12} className={s.space2}>
                                                <Form.Group className={s.space3}>
                                                    <div className={s.profileImgSection}>
                                                        <label className={s.space1}>{formatMessage(messages.homeImage2)}</label>
                                                        {
                                                            bannerImage2 &&
                                                            <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir}${bannerImage2})` }} />
                                                        }
                                                        <div>
                                                            <Dropzone
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                                inputContainer={'.dzInputContainerStoreImage'}
                                                                inputContainerClass={'dzInputContainerStoreImage'}
                                                                url={'/uploadPartnerHomepageImage'}
                                                                fieldName={'bannerImage2'}
                                                                handleDropzone={this.handleDropzone}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} md={12} sm={12} xs={12}>
                                                <Field name="bannerTitle1" type="text" component={this.renderField} label={formatMessage(messages.title1Label)} maxlength={250} />
                                                <Field name="bannerContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.description1Label)} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, s.bottomPadding, 'textAlignLeftRTL', 'bottomPaddingRTL')}>
                                <Form.Group className={s.noMargin}>
                                    <div className={s.displayInlineBlock}>
                                        <Loader
                                            type={"button"}
                                            label={formatMessage(messages.submitButton)}
                                            show={loading}
                                            buttonType={'submit'}
                                            className={cx(s.button, s.btnPrimary)}
                                            disabled={submitting || loading}
                                            isSuffix={true}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        )
    }
}

BannerForm = reduxForm({
    form: 'BannerForm',
    onSubmit: submit,
    validate
})(BannerForm);

const selector = formValueSelector('BannerForm')

const mapState = (state) => ({
    bannerImage1: selector(state, 'bannerImage1'),
    bannerImage2: selector(state, 'bannerImage2'),
    loading: state.loader.UpdatePartnerHomepage
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(BannerForm)));