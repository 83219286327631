import React, { Component } from 'react';
import s from './SafetySettingsForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
	Form,
	Col,
	Row,
	FormGroup,
	FormControl,
	Container
} from 'react-bootstrap';

import ColorPickerModal from '../DeliveryModule/ColorPickerModal';
import Dropzone from './Dropzone.js'
import Loader from '../../Common/Loader';

import { openColorModal } from '../../../actions/siteadmin/modalActions';

import { api, homepageUploadDir } from '../../../config';
import messages from '../../../locale/messages';
import validate from './validate';

//image
import defaultIcon from '../../../../public/Icons/defaultIconAdmin.svg';
import colorPickerIcon from '../../../../public/Icons/colorPickerIcon.svg';

export class SafetySettingsForm extends Component {

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} disabled={disabled} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<FormGroup className={cx(s.space5, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<FormControl
					{...input}
					className={className}
					placeholder={label}
					as="textarea"
					rows="4"
				>
					{children}
				</FormControl>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</FormGroup>
		);
	}

	render() {
		const { handleSubmit, safetyGridImage1, safetyGridImage2, safetyGridImage3, loading, submitting, userAppBgColor, openColorModal } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<Container fluid>
					<ColorPickerModal formName={'SafetySettingsForm'} />
					<h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.safetySectionSettings)}</h1>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
						<Row>
							<Col xl={12} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
								<div className={s.blackCenterSection}>
									<Col lg={12} md={12} sm={12} xs={12}>
										<Row>
											<Col lg={6} md={6} sm={6} xs={12}>
												<Field name="safetyGridTitle1" type="text" component={this.renderField} label={formatMessage(messages.userAppTitle)} />
												<Field name="safetyGridLink1" type="text" component={this.renderField} label={formatMessage(messages.playStoreLink)} />
												<Field name="safetyGridLink2" type="text" component={this.renderField} label={formatMessage(messages.appStoreLink)} />
												<div className={s.positionRelative}>
													<Field
														name="userAppBgColor"
														type="text"
														placeholder={formatMessage(messages.bgColor)}
														component={this.renderField}
														label={formatMessage(messages.bgColor)}
														labelClass={s.labelText}
														fieldClass={cx(s.formControlInput, 'pickerInput')}
													/>
													<div className={cx(s.pickerIcon, 'pickerIconRTL')} onClick={(e) => openColorModal('userAppBgColor', userAppBgColor)} ><img src={colorPickerIcon} /></div>
												</div>
											</Col>
											<Col lg={6} md={6} sm={6} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<label>{formatMessage(messages.safetyImage3)}</label><br />

														{safetyGridImage3 &&
															<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir}${safetyGridImage3})` }} />
														}
														{
															!safetyGridImage3 &&
															<div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
														}
														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																fieldName={'safetyGridImage3'}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																inputContainer={'.dzInputContainerSafetyImage3'}
																inputContainerClass={'dzInputContainerSafetyImage3'}
															/>
														</div>
													</div>
												</Form.Group>
											</Col>

										</Row>
										<Row>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<label>{formatMessage(messages.safetyImage1)}</label><br />
														{
															safetyGridImage1 &&
															<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir}${safetyGridImage1})` }} />
														}
														{
															!safetyGridImage1 &&
															<div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
														}
														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																fieldName={'safetyGridImage1'}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																inputContainer={'.dzInputContainerSafetyImage1'}
																inputContainerClass={'dzInputContainerSafetyImage1'}
															/>
														</div>
													</div>
												</Form.Group>
											</Col>
											<Col lg={6} md={12} sm={12} xs={12}>
												<Form.Group className={s.space3}>
													<div className={cx(s.profileImgSection, s.profileImgWidth)}>
														<label>{formatMessage(messages.safetyImage2)}</label><br />
														{
															safetyGridImage2 &&
															<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir}${safetyGridImage2})` }} />
														}
														{
															!safetyGridImage2 &&
															<div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
														}
														<div>
															<Dropzone
																className={cx(s.btnSecondary, s.profileNoPadding)}
																subTextClass={s.subText}
																fieldName={'safetyGridImage2'}
																subText={formatMessage(messages.maximumUploadSizeLabel)}
																defaultMessage={formatMessage(messages.chooseFile)}
																inputContainer={'.dzInputContainerSafetyImage2'}
																inputContainerClass={'dzInputContainerSafetyImage2'}
															/>
														</div>
													</div>
												</Form.Group>
											</Col>
										</Row>
									</Col>
								</div>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, s.bottomPadding, 'bottomPaddingRTL', 'textAlignLeftRTL')}>
								<Form.Group className={s.noMargin}>
									<div className={s.displayInlineBlock}>
										<Loader
											type={"button"}
											label={formatMessage(messages.submitButton)}
											show={loading}
											buttonType={'submit'}
											className={cx(s.button, s.btnPrimary)}
											disabled={submitting || loading}
											isSuffix={true}
										/>
									</div>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Container>
			</div >
		)
	}
}

SafetySettingsForm = reduxForm({
	form: 'SafetySettingsForm',
	onSubmit: submit,
	validate
})(SafetySettingsForm);

const selector = formValueSelector('SafetySettingsForm')
const mapState = (state) => ({
	safetyGridImage1: selector(state, 'safetyGridImage1'),
	safetyGridImage2: selector(state, 'safetyGridImage2'),
	safetyGridImage3: selector(state, 'safetyGridImage3'),
	userAppBgColor: selector(state, 'userAppBgColor'),
	loading: state.loader.SafetySettingsForm,
})

const mapDispatch = {
	openColorModal
}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(SafetySettingsForm)));