import React, { Component } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Table } from 'react-bootstrap';
// style
import s from './StaticPageManagement.css';
// components
import Link from '../../Link/Link';
// helper
import messages from '../../../locale/messages';

export class StaticPageManagement extends Component {
	render() {
		const { data, data: { results } } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div className={cx(s.StaicPageCss, 'StaicPageCss')}>
				<div className={cx(s.widthInner, 'widthInnerNoBorder')}>
					<div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable', 'staticTableBorder', 'tableCssStatic')}>
					<Table className={cx("table")}>
							<thead>
								<tr>
									<th scope="col"><FormattedMessage {...messages.id} /></th>
									<th scope="col"><FormattedMessage {...messages.pageName} /></th>
									<th scope="col"><FormattedMessage {...messages.preview} /></th>
									<th scope="col"><FormattedMessage {...messages.action} /></th>
								</tr>
							</thead>
							<tbody>
								{
									data && results && results.map((item, key) => {
										return (
											<tr key={key}>
												<td data-label={formatMessage(messages.id)}>{item.id}</td>
												<td data-label={formatMessage(messages.pageName)}>{item.pageName}</td>
												<td data-label={formatMessage(messages.preview)}><a href={`/${item.pageUrl}`} target={'_blank'}><FormattedMessage {...messages.preview} /></a></td>
												<td data-label={formatMessage(messages.action)}><Link to={`/siteadmin/staticpage/edit/${item.id}`}><FormattedMessage {...messages.editAction} /></Link></td>
											</tr>
										)
									})
								}
							</tbody>
						</Table>
					</div>
				</div >
			</div>
		)
	}
}

export default injectIntl(withStyles(s)(StaticPageManagement));