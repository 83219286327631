import React, { Component } from 'react';
import s from './CitySettingsForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Button,
    Form,
    Col,
    Row,
    FormGroup,
    FormControl,
    Container
} from 'react-bootstrap';
import Link from '../../Link/Link';
import messages from '../../../locale/messages';
import validate from './validate';

export class CitySettingsForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={cx(s.space3, 'positionRelative')}>
                <Form.Label className={'inputLabel'}>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
                />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    render() {
        const { handleSubmit } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={6} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.categories)}</h1>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Field name="citySectionTitle1" type="text" component={this.renderField} label={formatMessage(messages.title)} maxlength={250} />
                                    <div className={cx(s.textAlignRight, s.spaceTop1)}>
                                        <Button type="submit" className={cx(s.button, s.btnPrimary)}>
                                            {formatMessage(messages.submitButton)}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

CitySettingsForm = reduxForm({
    form: 'CitySettingsForm',
    onSubmit: submit,
    validate
})(CitySettingsForm);

const mapState = (state) => ({

})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CitySettingsForm)));