import {
    SHOP_PROFILE_UPDATE_ERROR,
    SHOP_PROFILE_UPDATE_START,
    SHOP_PROFILE_UPDATE_SUCCESS
} from '../../../constants';

import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { loadAccount } from '../userAccount/userAccount';


export default function updateShopProfile(values) {
    return async (dispatch, getState, { client }) => {
        let status, errorMessage = 'Oops! something went wrong! Please try again.';

        try {
            dispatch({
                type: SHOP_PROFILE_UPDATE_START
            });

            dispatch(setLoaderStart('StoreProfileUpdate'));

            const mutation = gql`
            mutation (
              $shopName: String,
              $firstName: String,
              $lastName: String,
              $email: String,
              $phoneDialCode: String,
              $phoneNumber: String,
              $phoneCountryCode: String,
              $address: String,
              $lat: Float,
              $lng: Float,
              $city: String,
              $state:String,
              $zipcode: String,
              $country: String,
              $categoryType: String,
              $description: String,
              $priceRangeId: Int,
              $picture: String,
              $preferredCurrency: String,
              $shopEstimatedTime: Int,
              $isDoorStepDelivery: Boolean,
              $isTakeAway: Boolean
          ) {
              updateShopProfile (
                  shopName: $shopName,
                  firstName: $firstName,
                  lastName: $lastName,
                  email: $email,
                  phoneDialCode: $phoneDialCode,
                  phoneNumber: $phoneNumber,
                  phoneCountryCode: $phoneCountryCode,
                  address: $address,
                  lat: $lat,
                  lng: $lng,
                  city: $city,
                  state: $state,
                  zipcode: $zipcode,
                  country: $country,
                  categoryType: $categoryType,
                  description: $description,
                  priceRangeId: $priceRangeId,
                  picture: $picture,
                  preferredCurrency: $preferredCurrency,
                  shopEstimatedTime: $shopEstimatedTime,
                  isDoorStepDelivery: $isDoorStepDelivery,
                  isTakeAway: $isTakeAway  
              ) {
                  status
                  errorMessage                     
              }
          }`;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    shopName: values && values.shopName,
                    firstName: values && values.firstName,
                    lastName: values && values.lastName,
                    email: values && values.email,
                    phoneDialCode: values && values.phoneDialCode,
                    phoneNumber: values && values.phoneNumber,
                    phoneCountryCode: values && values.phoneCountryCode,
                    address: values && values.address,
                    lat: values && values.lat,
                    lng: values && values.lng,
                    city: values && values.city,
                    state: values && values.state,
                    zipcode: values && values.zipcode,
                    country: values && values.country,
                    categoryType: JSON.stringify(values && values.categoryType),
                    description: values && values.description,
                    priceRangeId: values && values.priceRange,
                    picture: values && values.picture,
                    preferredCurrency: values && values.preferredCurrency,
                    shopEstimatedTime: values && values.shopEstimatedTime,
                    isDoorStepDelivery: values && values.isDoorStepDelivery,
                    isTakeAway: values && values.isTakeAway
                }
            });

            if (data && data.updateShopProfile && data.updateShopProfile.status == 200) {
                toastr.success('Success', `Your Profile has been successfully updated.`);

                await dispatch({
                    type: SHOP_PROFILE_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('StoreProfileUpdate'));

                setTimeout(() => {
                    dispatch(loadAccount())
                }, 1000)

            } else {
                errorMessage = data && data.updateShopProfile && data.updateShopProfile.errorMessage
                toastr.error('Error!', errorMessage);

                await dispatch({
                    type: SHOP_PROFILE_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('StoreProfileUpdate'));
            }
        } catch (error) {
            errorMessage = "Something went wrong! " + error;

            toastr.error('Error!', errorMessage);

            await dispatch({
                type: SHOP_PROFILE_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('StoreProfileUpdate'));
        }
    }
};