import gql from 'graphql-tag';
import histroy from '../../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import updateMutation from './updateMutation.graphql';
import addMutation from './addMutation.graphql';

export function addCategory(categoryName, isActive, categoryImage, description, deliveryType, id) {

  return async (dispatch, getState, { client }) => {

    dispatch(setLoaderStart('AddCategory'));

    let mutation = id ? updateMutation : addMutation;

    const { data } = await client.mutate({
      mutation,
      variables: {
        categoryName,
        isActive: (isActive === true || isActive === 'true') ? 1 : 0,
        categoryImage,
        description,
        deliveryType,
        id
      }
    });

    dispatch(setLoaderComplete('AddCategory'));

    if (id) {
      if (data && data.updateCategory && data.updateCategory.status === 200) {
        toastr.success('Success!', 'The category has been updated successfully.');
      } else if (data && data.updateCategory && data.updateCategory.status !== 200) {
        toastr.error('Error!', data.updateCategory.errorMessage);
      } else {
        toastr.error('Error!', "Something went wrong. Please try again.");
      }
    } else {
      if (data && data.addCategory && data.addCategory.status === 200) {
        toastr.success('Success!', 'The category has been added successfully.');
      } else if (data && data.addCategory && data.addCategory.status !== 200) {
        toastr.error('Error!', data.addCategory.errorMessage);
      } else {
        toastr.error('Error!', "Something went wrong. Please try again.");
      }
    }

    histroy.push('/siteadmin/category');

  }
}