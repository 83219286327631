import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag'
import { flowRight as compose } from 'lodash';
// style
import s from './DeclineReason.css';
import rs from '../../../storeCommon.css';
// redux actions
import updateOrderStatus from '../../../../actions/shop/orders/updateOrderStatus';
import { closeDeclineReasonModal } from '../../../../actions/siteadmin/modalActions';
// helpers
import messages from '../../../../locale/messages';
import validate from './validate';

class DeclineReason extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      errors: null
    };

    this.handleValidation = this.handleValidation.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.renderCheckBox = this.renderCheckBox.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { reason } = this.props;
    if (prevProps.reason !== reason) {
      this.handleValidation();
    }
  }

  handleValidation() {
    const { reason } = this.props;
    const { formatMessage } = this.props.intl;
    let errors = null;
    if (!reason) {
      errors = {
        reason: formatMessage(messages.selectOne)
      };
    }

    this.setState({ errors });

    return errors;
  }

  async handleFormSubmit() {
    const { formatMessage } = this.props.intl;
    const { updateOrderStatus, closeDeclineReasonModal, currentPage, isOrderDetailPage } = this.props;
    let { reason, otherReason, bookingId } = this.props;
    let submitting = false, declineReason = reason;
    let errors = this.handleValidation();

    if (!reason) {
      errors = {
        reason: formatMessage(messages.selectOne)
      };
    }

    submitting = (errors === null) ? true : false;

    this.setState({ submitting });

    if (errors === null && submitting) {
      if (reason === 'other') {
        declineReason = otherReason;
      }
      await updateOrderStatus(bookingId, 'declined', currentPage, declineReason, isOrderDetailPage, true);
      closeDeclineReasonModal();
    }
  }

  renderCheckBox = ({ input, label, className, radioValue, id }) => {
    return (
      <Form.Check
          {...input}
          type={'radio'}
          inline
          label={label}
          id={`radio-btn-${id}`}
          className={className}
          value={radioValue}
      />
    )
  }

  renderFormControlTextArea = ({ input, label, children, className, meta: { touched, error } }) => {
    const { formatMessage } = this.props.intl
    return (
      <div>
        <Form.Group className={cx(rs.space1)}>
          <Form.Control
            {...input}
            className={cx(s.inputNoRadius, className)}
            placeholder={label}
            as="textarea"
            rows="4"
          >
            {children}
          </Form.Control>
          { touched && error && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span> }
        </Form.Group>
      </div>
    );
  }

  render() {
    const { data: { getStoreCancelReason }, reason, handleSubmit } = this.props;
    const { formatMessage } = this.props.intl;
    const { errors, submitting } = this.state;

    return (
      <div className={cx(s.space5, s.responsiveNoPadding, 'store')}>
        <Container fluid>
          <Form onSubmit={handleSubmit(this.handleFormSubmit)}>
          <Row className={'customRatioButtonStore'}>
            <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.spaceTop2, 'radioBtnLabel')}>
              { errors && errors.reason && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{`*${errors.reason}`}</span>}
              {
                getStoreCancelReason && getStoreCancelReason.result && getStoreCancelReason.result.map((item, index) => {
                  return (
                    <Form.Group key={index} >
                        <Field
                          name="reason"
                          component={this.renderCheckBox}
                          label={item.reason}
                          radioValue={item.reason}
                          id={index}
                        />
                    </Form.Group>
                  )
                })
              }
              <Form.Group>
                <Field
                  name="reason"
                  component={this.renderCheckBox}
                  label={formatMessage(messages.otherLabel)}
                  radioValue={'other'} // Do not change this value
                />
              </Form.Group>
              {
                reason === 'other' && <Form.Group>
                  <Field name={'otherReason'} label={formatMessage(messages.reason)}
                    component={this.renderFormControlTextArea} />
                </Form.Group>
              }
            </Col>
            <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.alignRightText)}>
              <Form.Group className={cx(s.padding2, s.rightSide, s.formGroup)}>
                <div className={rs.secondryBtnSection}>
                  <Button block type="submit" className={cx(rs.button, rs.btnPrimary)} disabled={submitting}>
                    <FormattedMessage {...messages.submitButton} />
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
          </Form>
        </Container>
      </div>
    )
  }
}

DeclineReason = reduxForm({
  form: 'DeclineReasonForm',
  validate
})(DeclineReason);

const selector = formValueSelector('DeclineReasonForm');

const mapState = (state) => ({
  reason: selector(state, 'reason'),
  otherReason: selector(state, 'otherReason')
});

const mapDispatch = {
  updateOrderStatus,
  closeDeclineReasonModal
};

export default injectIntl(
  compose(
    withStyles(s),
    connect(mapState, mapDispatch),
    graphql(gql `
      query {
        getStoreCancelReason {
          result{
            id
          reason
          isActive
          }
          errorMessage
        }
      }`, {
      options: {
        fetchPolicy: 'network-only',
        ssr: false
      }
    })
  )(DeclineReason));