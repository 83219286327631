import {
    SHOP_PREPARATION_TIME_UPDATE_ERROR,
    SHOP_PREPARATION_TIME_UPDATE_START,
    SHOP_PREPARATION_TIME_UPDATE_SUCCESS
} from '../../../constants';

import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';


export default function updatePreparationTime(shopEstimatedTime, preparationTime) {
    return async (dispatch, getState, { client }) => {
        let status, errorMessage = 'Oops! something went wrong! Please try again.';

        try {
            dispatch({
                type: SHOP_PREPARATION_TIME_UPDATE_START
            });

            dispatch(setLoaderStart('StorePreparationTime'));

            const mutation = gql`
            mutation (
              $shopEstimatedTime: Int,
              $preparationTime: String
          ) {
              updatePreparationTime (
                shopEstimatedTime: $shopEstimatedTime,
                preparationTime: $preparationTime
              ) {
                  status
                  errorMessage                     
              }
          }`;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    shopEstimatedTime,
                    preparationTime,                  
                }
            });

            if (data && data.updatePreparationTime && data.updatePreparationTime.status == 200) {
                toastr.success('Success', `Your Preparation Times have been successfully updated.`);

                await dispatch({
                    type: SHOP_PREPARATION_TIME_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('StorePreparationTime'));

            } else {
                errorMessage = data && data.updatePreparationTime && data.updatePreparationTime.errorMessage
                toastr.error('Error!', errorMessage);

                await dispatch({
                    type: SHOP_PREPARATION_TIME_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('StorePreparationTime'));
            }
        } catch (error) {
            errorMessage = "Something went wrong! " + error;

            toastr.error('Error!', errorMessage);

            await dispatch({
                type: SHOP_PREPARATION_TIME_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('StorePreparationTime'));
        }
    }
};