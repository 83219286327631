import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withStyles from 'isomorphic-style-loader/withStyles';
import { Modal } from 'react-bootstrap';
import cx from 'classnames';

import LogoutForm from '../../LogoutForm/LogoutForm';

import s from './LogoutModal.css';
import { closeLogoutModal } from '../../../../actions/modalActions';

class LogoutModal extends React.Component {
    static propTypes = {
        closeLogoutModal: PropTypes.func,
        isLogoutModalOpen: PropTypes.bool,
        formatMessage: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            logoutModalStatus: false
        }
    }

    componentDidMount() {
        const { isLogoutModalOpen } = this.props;
        if (isLogoutModalOpen) {
            this.setState({ logoutModalStatus: true })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isLogoutModalOpen } = nextProps;
        if (isLogoutModalOpen) {
            this.setState({ logoutModalStatus: true })
        } else {
            this.setState({ logoutModalStatus: false })
        }
    }

    handleClick() {
        const { closeLogoutModal } = this.props;
        dispatch(closeLogoutModal());
    }

    render() {
        const { closeLogoutModal } = this.props;
        const { logoutModalStatus } = this.state;
        return (
            <div >
                <Modal show={logoutModalStatus} animation={false} onHide={closeLogoutModal} className={cx('eatsLoginModal', 'logonMargin')}>
                    <div className={s.sectionBlock}>
                        <Modal.Body>
                            <LogoutForm />
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapState = state => ({
    isLogoutModalOpen: state.modalStatus.isLogoutModalOpen,
})

const mapDispatch = {
    closeLogoutModal
};

export default withStyles(s)(connect(mapState, mapDispatch)(LogoutModal))