import React from 'react';

import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ConfigSettings.css';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import getSiteSettings from './getSiteSettings.graphql';
//conponents
import ConfigSettingsForm from '../../../components/SiteAdmin/ConfigSettingsForm '
import Loader from '../../../components/Common/Loader/Loader';
import cx from 'classnames';
class ConfigSettings extends React.Component{
    
    render(){
        const { getSiteSettings: { loading, getSiteSettings, refetch } } = this.props;
        let siteSettingsCollection = {}
        
        if(!loading) {
            getSiteSettings && getSiteSettings.results.map((item) => {
              siteSettingsCollection[item.name] = item.value
            })
            return (
              <div className={s.root}>
                <div className={cx(s.container, 'paddingBottomLg')}>
                  <div className={s.heading}>
                  <FormattedMessage {...messages.manageSiteConfig} />
                  </div>
                  <div className={s.spaceTop5}>
                    <ConfigSettingsForm refetch={refetch} getSiteSettings={getSiteSettings} initialValues={siteSettingsCollection}/>
                  </div>
                </div>
              </div>
              )
          }
          else {
            return <Loader type={"page"} show={loading}/>
          }
          
      }
  }
export default compose(withStyles(s),
  graphql(getSiteSettings,{
    name:'getSiteSettings',
    options:{
        fetchPolicy: 'network-only',
        ssr: true
    }
  })
)(ConfigSettings);