import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
// import s from 'isomorphic-style-loader';
import Switch from 'react-switch';

import { injectIntl } from 'react-intl';
import messages from '../../../../locale/messages';

export const uncheckedIcon = (
    <svg viewBox="0 0 52 52" fill="#000000" fillOpacity="0"
        stroke="#000000" strokeWidth="4"
        role="presentation" strokeLinecap="round" strokeLinejoin="round"
        style={{ position: "absolute", top: 1, height: '33px', width: '33px', display: 'block', transform: 'scale(1.5)' }}>
        <path d="m19.1 19.1 l14 14 m 0 -14 l -14 14"></path>
    </svg>
);

export const checkedIcon = (
    <svg viewBox="0 0 52 52" fill="#000000" fillOpacity="0"
        stroke="#000000" strokeWidth="4"
        role="presentation" strokeLinecap="round" strokeLinejoin="round"
        style={{ position: "absolute", top: 1, height: '33px', width: '33px', display: 'block', transform: 'scale(1.5)' }}>
        <path d="m19.1 25.2 4.7 6.2 12.1-11.2"></path>
    </svg>
);

class SwitchButton extends React.Component {
	static propTypes = {
		change: PropTypes.any
	}

	static defaultProps = {
        checked: false,
        checkedValue: true,
        unCheckedValue: false,
        offColor: '#dce0e0',
        onColor: '#F86540',
        checkedIcon: checkedIcon,
        uncheckedIcon: uncheckedIcon,
        height: 34,
        width: 58,
        boxShadow: 'none',
        activeBoxShadow: '0px 0px 2px 3px #F86540',
        isPersonalize: false
    };

	constructor(props) {
		super(props);
		this.state = {
			checked: false
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		const { defaultValue } = this.props;
		this.setState({
			checked: defaultValue
		});
	}

	async handleChange(e) {
		const { change, index } = this.props;
		this.setState({
			checked: e
		});
		await change('AddMenuForm', `menuAvailability[${index}].isOpen`, e);
		if (e) {
			await change('AddMenuForm', `menuAvailability[${index}].isWholeDay`, 'true');
		} else {
			await change('AddMenuForm', `menuAvailability[${index}].isWholeDay`, 'false');
			await change('AddMenuForm', `menuAvailability[${index}].startTime`, null);
			await change('AddMenuForm', `menuAvailability[${index}].endTime`, null);

		}
	}

	render() {
		const { checked } = this.state;
		const { switchOnLabel, switchOffLabel } = this.props;
		const { formatMessage } = this.props.intl;
		const { offColor, onColor, checkedIcon, uncheckedIcon, height, width, boxShadow } = this.props;
		return (
			<div>
				<Switch
				    id="open-type"
					checked={checked}
					onChange={this.handleChange}
					offColor={offColor}
                    onColor={onColor}
					handleDiameter={30}
					checkedIcon={checkedIcon}
					uncheckedIcon={uncheckedIcon}
					height={height}
                    width={width}
                    boxShadow={boxShadow}
					className={'siwtchLapel'}

				/>
				<label className={'siwtchLapelText'}>{checked ? switchOnLabel : switchOffLabel}</label>
			</div>
		)
	}
}

const mapState = (state) => ({});

const mapDispatch = {
	change
};

export default injectIntl(connect(mapState, mapDispatch)(SwitchButton));