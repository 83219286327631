import { SubmissionError } from 'redux-form';
import checkUserEmail from '../../../actions/shop/login/checkUserEmail';
import userLogin from '../../../actions/shop/login/userLogin';
import history from '../../../history';
import { loadAccount } from '../../../actions/shop/userAccount/userAccount';

async function submit(values, dispatch) {

    let response = {};
    response = await dispatch(checkUserEmail(values));
    if (response.status == 400) {
        throw new SubmissionError({ _error: response.errorMessage });
    } else {
        response = {};
        response = await dispatch(userLogin(values.email, values.password));
        if (response.status == 200) {
            dispatch(loadAccount());
            history.push('/store/dashboard');
        } else {
            throw new SubmissionError({ _error: response.errorMessage });
        }
    }
}

export default submit;