import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ConfigSettings from './ConfigSettings';
import { restrictUrls } from '../../../helpers/adminPrivileges';
import messages from '../../../locale/messages';

function action({ store, intl }) {
    const title = intl.formatMessage(messages.manageSiteConfig);

    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/login' }
    }
    // Admin restriction
    if (!restrictUrls('/siteadmin/settings/config', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }
    return {
        title,
        component: (
            <AdminLayout>
             <ConfigSettings title={title}/>
            </AdminLayout>
        )
    }
}

export default action;