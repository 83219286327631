import React, { Component } from 'react'
import StaticPageEditForm from '../../../components/SiteAdmin/StaticPageEditForm'
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditStaticPage.css';
import cx from 'classnames';
import getEditStaticPage from './getEditStaticPage.graphql'
import Loader from '../../../components/Common/Loader/Loader';
import { graphql } from 'react-apollo'
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class EditStaticPage extends Component {

  static defaultProps = {
    data: {
      loading: true
    }
  }

  render() {
    const { data: { loading, getEditStaticPage }, title } = this.props;

    return (
      <div className={s.root}>
        <div className={s.container}>
         <div className={cx(s.heading, 'textAlignRightRTL')}>
          <FormattedMessage {...messages.editPageDetails} />
          </div>
          <div>
            <Loader type={"page"} show={loading}>
              {
                !loading && <StaticPageEditForm title={title} initialValues={getEditStaticPage && getEditStaticPage.result} />
              }
            </Loader>
          </div>
        </div>
      </div>
    )
  }
}


export default compose(withStyles(s), graphql(getEditStaticPage, {
  options: (props) => ({
    variables: {
      id: props.id
    },
    fetchPolicy: 'network-only',
    ssr: false
  })
}))(EditStaticPage)
