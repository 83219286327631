import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';

import Loader from '../../../components/Common/Loader/Loader';
import EditProfileForm from '../../../components/Shop/EditProfileForm/EditProfileForm';

import s from './EditProfile.css';

import getShopProfile from './getShopProfile.graphql';
import getCommonSettings from './getCommonSettings.graphql';
export class EditProfile extends Component {

	static defaultProps = {
		data: {
			loading: true
		},
		settingData: {
			loading: true
		}
	}

	render() {
		const { data: { loading, getShopProfile } } = this.props;
		const { settingData: { getAllCommonSettingsData, loading: settingLoading } } = this.props;

		let initialValues = {};

		let phoneStatus = getShopProfile && getShopProfile.userVerifiedInfo && getShopProfile.userVerifiedInfo.isPhoneVerified;

		if (!loading) {
			initialValues = {
				id: getShopProfile?.id,
				email: getShopProfile?.email,
				phoneDialCode: getShopProfile?.phoneDialCode,
				phoneCountryCode: getShopProfile?.phoneCountryCode,
				phoneNumber: getShopProfile?.phoneNumber,
				profileId: getShopProfile?.profile && getShopProfile?.profile.profileId,
				shopName: getShopProfile?.profile && getShopProfile?.profile.shopName,
				firstName: getShopProfile?.profile && getShopProfile?.profile.firstName,
				lastName: getShopProfile?.profile && getShopProfile?.profile.lastName,
				country: getShopProfile?.profile && getShopProfile?.profile.country,
				address: getShopProfile?.profile && getShopProfile?.profile.address,
				zipcode: getShopProfile?.profile && getShopProfile?.profile.zipcode,
				picture: getShopProfile?.profile && getShopProfile?.profile.picture,
				description: getShopProfile?.profile && getShopProfile?.profile.description,
				priceRange: getShopProfile?.profile && getShopProfile?.profile.priceRangeId,
				categoryType: getShopProfile && getShopProfile?.shopCategoryData && getShopProfile?.shopCategoryData.length > 0 && getShopProfile?.shopCategoryData.map((item) => item),
				preferredCurrency: getShopProfile?.profile && getShopProfile?.profile.preferredCurrency,
				shopEstimatedTime: getShopProfile?.profile && getShopProfile?.profile.shopEstimatedTime,
				dietaryType: getShopProfile?.profile && getShopProfile?.profile.dietaryType,
				deliveryType: getShopProfile?.profile && getShopProfile?.profile.deliveryType,
				deliveryName: getShopProfile?.profile && getShopProfile?.profile.deliveryName,
				isDoorStepDelivery: getShopProfile?.profile && getShopProfile?.profile.isDoorStepDelivery,
				isTakeAway: getShopProfile?.profile && getShopProfile?.profile.isTakeAway
			}
		}

		return (
			<Loader type={"page"} show={loading}>
				<div className={s.paddingRoutesSection}>
					{
						!loading && initialValues && <EditProfileForm
							initialValues={initialValues}
							getDietary={!settingLoading && getAllCommonSettingsData && getAllCommonSettingsData.results && getAllCommonSettingsData.results.length > 0 && getAllCommonSettingsData.results[0]}
							phoneStatus={phoneStatus}
							deliveryType={initialValues.deliveryType}
						/>
					}
				</div>
			</Loader>
		)
	}
}

export default compose(withStyles(s),
	graphql(getShopProfile, {
		options: (props) => ({
			variables: {
				id: props.id
			},
			fetchPolicy: 'network-only',
			ssr: true
		})
	}),
	graphql(getCommonSettings, {
		name: 'settingData',
		options: (props) => ({
			variables: {
				typeId: 1
			},
			fetchPolicy: 'network-only',
			ssr: true
		})
	}),
)(EditProfile)