import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { flowRight as compose } from 'lodash';
import {
  Row,
  FormGroup,
  Col,
  FormControl,
  Form,
  Container,
  InputGroup
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';

import Loader from '../../Common/Loader/Loader';
import ToneDropzone from './ToneDropzone';

import s from './MobileSettingsForm.css';
import { siteSettings } from '../../../actions/siteadmin/SiteSettings/siteSettings';
import { deleteTone } from '../../../actions/siteadmin/SiteSettings/updateMobileSettings';

import pauseIcon from '../../../../public/Icons/tonePause.svg';
import playIcon from '../../../../public/Icons/tonePlay.svg';
import deleteIcon from '../../../../public/Icons/toneClose.svg';

import submit from './submit';
import validate from './validate';
import messages from '../../../locale/messages';
import { api, toneUploadDir } from '../../../config';

export class MobileSettingsForm extends Component {

  static defaultProps = {
    loading: false,
    updateSettings: false
  };

  constructor(props) {
    super(props);
    this.state = {
      play: false
    }
    this.togglePlay = this.togglePlay.bind(this);
  }

  togglePlay = () => {
    const audioEl = document.getElementById("audio-element");
    if (audioEl) {
      this.setState({ play: !this.state.play }, () => {
        this.state.play ? audioEl.play() : audioEl.pause();
      });
    }
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={'positionRelative'}>
        <FormGroup className={s.space2}>
          <label className={'inputLabel'}>{label}</label>
          <FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
        </FormGroup>
      </div>
    );
  }

  renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
    return (
      <Form.Group className={cx(s.space2, 'positionRelative')}>
        <Form.Label className={'inputLabel'}>{label}</Form.Label>
        <Form.Control as="select" {...input} placeholder={placeholder} className={fieldClass}>
          {children}
        </Form.Control>
        {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
      </Form.Group>
    )
  }

  renderFieldApp = ({ input, label, type, meta: { touched, error }, className, maxlength, suffix }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('inputFormAddon', 'addonBorder')}>
        <Form.Group className={'positionRelative'}>
          {!suffix && <label className={cx('inputLabel', 'accordionLabel')}>{label}</label>}
          {suffix && <label className={cx('inputLabel')}>{label}</label>}
          <InputGroup>
            {!suffix && <InputGroup.Append>
              <InputGroup.Text>
                V
              </InputGroup.Text>
            </InputGroup.Append>}
            <FormControl {...input} placeholder={label} type={type} className={cx(s.formControlInput, suffix && 'inputLeft')} maxlength={maxlength} />
            {suffix && <InputGroup.Append className={'rightAddonBtn'}>
              <InputGroup.Text>
                {formatMessage(messages.seconds)}
              </InputGroup.Text>
            </InputGroup.Append>}
          </InputGroup>
          {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{error.defaultMessage}</span>}
        </Form.Group>
      </div>
    )
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, submitting, updateSettings, appForceUpdate, requestTone, deleteTone, requestToneFile } = this.props;

    return (
      <div className={s.paddignLeftRight}>
        <Container fluid>
          <form onSubmit={handleSubmit(submit)}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className={s.adminFormGrid}>
                  <Field
                    name="distanceUnits"
                    placeholder={formatMessage(messages.distanceUnits)}
                    component={this.renderSelectField}
                    label={formatMessage(messages.distanceUnits)}
                    labelClass={s.labelText}
                    fieldClass={cx(s.formControlSelect, s.formControlInput)}
                  >
                    <option value="km">{formatMessage(messages.kmLabel)}</option>
                    <option value="miles">{formatMessage(messages.milesLabel)}</option>
                  </Field>
                  <Field name="orderAcceptInterval" type="text" component={this.renderFieldApp} label={formatMessage(messages.orderAcceptInterval)} suffix={true}
                    maxlength={3} />
                  <Form.Group className={s.space2}>
                    <div className={'positionRelative'}>
                      <label className={'inputLabel'} >{formatMessage(messages.forceUpdate)}</label>
                      <Field name="appForceUpdate" className={cx(s.formControlSelect, s.formControlInput)} component="select">{formatMessage(messages.forceUpdate)}
                        <option value="true">{formatMessage(messages.enable)}</option>
                        <option value="false">{formatMessage(messages.disable)}</option>
                      </Field>
                    </div>
                  </Form.Group>
                  <Form.Group className={s.space2}>
                    <div className={'positionRelative'}>
                      <label className={'inputLabel'} >{formatMessage(messages.preferredDelivery)}</label>
                      <Field name="preferredDelivery" className={cx(s.formControlSelect, s.formControlInput)} component="select">{formatMessage(messages.preferredDelivery)}
                        <option value="1">{formatMessage(messages.meetAtDoor)}</option>
                        <option value="2">{formatMessage(messages.leaveAtDoor)}</option>
                        <option value="3">{formatMessage(messages.pickUpOutside)}</option>
                      </Field>
                    </div>
                  </Form.Group>
                  {appForceUpdate === 'true' && <>
                    <Field name="userAndroidVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.userAndriodVersion)}
                      maxlength={10} />
                    <Field name="userIosVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.useriosVersion)} maxlength={10} />
                    <Field name="driverAndroidVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.driverAndriodVersion)}
                      maxlength={10} />
                    <Field name="driverIosVersion" type="text" component={this.renderFieldApp} label={formatMessage(messages.driveriosVersion)} maxlength={10} />
                  </>}
                  <Form.Group className={s.space2}>
                    <div className={'positionRelative'}>
                      <label className={'inputLabel'} >{formatMessage(messages.appOpenRequest)}</label>
                      <Field name="openAppOnRequest" className={cx(s.formControlSelect, s.formControlInput)} component="select">
                        <option value="0">{formatMessage(messages.disable)}</option>
                        <option value="1">{formatMessage(messages.enable)}</option>
                      </Field>
                    </div >
                  </Form.Group>
                  <Form.Group className={s.space2}>
                    <div className={'positionRelative'}>
                      <label className={'inputLabel'}>{formatMessage(messages.requestTone)}</label>
                      <Field name="isRequestTimerToneEnable" className={cx(s.formControlSelect, s.formControlInput)} component="select">
                        <option value="0">{formatMessage(messages.disable)}</option>
                        <option value="1">{formatMessage(messages.enable)}</option>
                      </Field>
                    </div >
                  </Form.Group>
                </div>
                <div className={s.adminFormGrid}>
                  <Form.Group className={s.formGroup}>
                    <div className={'positionRelative'}>
                      <label className={'inputLabel'}>{formatMessage(messages.uploadRequestTone)}</label>
                      {!requestTone && <div className={cx('commonFilepicker', 'tonePicker')}>
                        <ToneDropzone
                          className={cx(s.btnSecondary, 'fileNoPadding')}
                          subTextClass={s.subText}
                          subText={formatMessage(messages.maximumUploadSizeLabel)}
                          defaultMessage={formatMessage(messages.chooseFile)}
                        />
                      </div>}
                      {requestTone && <div className={s.audioSection}>
                        <div>{requestToneFile}</div>
                        <div className={s.flex}>
                          <div>
                            <span onClick={this.togglePlay}>{this.state.play ? <img src={pauseIcon} className={s.imgWidth} /> : <img src={playIcon} className={s.imgWidth} />}
                            </span>
                          </div>
                          <div className={cx(s.paddingLeft, 'toneCloseRTL')}>
                            <span onClick={() => deleteTone(requestTone)}><img src={deleteIcon} className={s.imgWidth} /></span>
                          </div>
                        </div>
                        <audio id="audio-element">
                          <source src={api.apiEndpoint + toneUploadDir + requestTone}></source>
                        </audio>
                      </div>}
                    </div >
                  </Form.Group>
                </div>
                <div className={cx(s.textAlignRight, 'textAlignLeftRTL')}>
                  <FormGroup className={s.space3}>
                    <div>
                      <Loader
                        type={"button"}
                        label={formatMessage(messages.submitButton)}
                        show={updateSettings || submitting}
                        buttonType={'submit'}
                        className={cx(s.button, s.btnPrimary)}
                        disabled={updateSettings || submitting}
                        isSuffix={true}
                      />
                    </div>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    )
  }
}

const callSiteSettings = async (result, dispatch, props) => {
  const { refetch } = props
  await refetch()
  dispatch(siteSettings())
}

MobileSettingsForm = reduxForm({
  form: 'MobileSettingsForm',
  onSubmit: submit,
  validate,
  onSubmitSuccess: callSiteSettings
})(MobileSettingsForm);

const selector = formValueSelector('MobileSettingsForm')
const mapState = (state) => ({
  appForceUpdate: selector(state, 'appForceUpdate'),
  updateSettings: state.siteSettings.updateSettings,
  loading: state.loader.MobileSettings,
  requestTone: selector(state, 'requestTimeTone'),
  requestToneFile: selector(state, 'requestToneFile'),
})

const mapDispatch = {
  siteSettings,
  deleteTone

}

export default injectIntl(
  compose(
    withStyles(s),
    connect(mapState, mapDispatch),
  )(MobileSettingsForm));