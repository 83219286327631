import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { toastr } from 'react-redux-toastr';
import {
    SHOP_SIGNUP_ERROR,
    SHOP_SIGNUP_START,
    SHOP_SIGNUP_SUCCESS
} from '../../../constants';
import history from '../../../history';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { setRuntimeVariable } from '../../runtime';
import { loadAccount } from '../../shop/userAccount/userAccount';
import { getAllShopModifier } from '../modifier/getAllShopModifier';
import createMutation from './createMutation.graphql';
import { firebaseConfig, vapidKey, enableFirebase } from '../../../config';

let app;

if (enableFirebase) {
    app = initializeApp(firebaseConfig);
}

export function shopSignup(values) {
    return async (dispatch, getState, { client }) => {
        let status, errorMessage = 'Oops! something went wrong! Please try again.';

        try {
            dispatch({
                type: SHOP_SIGNUP_START,
                payload: {
                    storeSignupLoading: true
                }
            });

            dispatch(setLoaderStart('StoreSignup'));

            let deviceId;

            if (enableFirebase) {
                const messaging = await getMessaging(app);

                if ('serviceWorker' in navigator) {
                    await navigator.serviceWorker.register('firebase-messaging-sw.js')
                        .then(async function (registration) {
                            await getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
                                if (currentToken) {
                                    deviceId = currentToken;
                                } else {
                                    console.log('No registration token available. Request permission to generate one.');
                                }
                            }).catch((err) => {
                                console.log('An error occurred while retrieving token. ', err);
                            });
                        }).catch(function (err) {
                            console.log('Service worker registration failed, error:', err);
                        });
                }
            }

            const { data } = await client.mutate({
                mutation: createMutation,
                variables: {
                    shopName: values && values.shopName,
                    firstName: values && values.firstName,
                    lastName: values && values.lastName,
                    email: values && values.email,
                    password: values && values.password,
                    phoneDialCode: values && values.phoneDialCode,
                    phoneNumber: values && values.phoneNumber,
                    phoneCountryCode: values && values.phoneCountryCode,
                    address: values && values.address,
                    lat: values && values.lat,
                    lng: values && values.lng,
                    city: values && values.city,
                    state: values && values.state,
                    zipcode: values && values.zipcode,
                    country: values && values.country,
                    categoryType: values && values.categoryType,
                    deliveryType: values && values.deliveryType,
                    deviceId
                }
            });

            if (data && data.createShopUser && data.createShopUser.status == 200) {
                toastr.success('Success', `You have successfully signed up.`);
                dispatch({
                    type: SHOP_SIGNUP_SUCCESS,
                    payload: {
                        storeSignupLoading: false
                    }
                });
                dispatch(setRuntimeVariable({
                    name: 'isStoreAuthenticated',
                    value: true,
                }));
                dispatch(getAllShopModifier());
                dispatch(setLoaderComplete('StoreSignup'));
                dispatch(loadAccount());
                history.push('/store/dashboard');
            } else {
                errorMessage = data && data.createShopUser && data.createShopUser.errorMessage
                toastr.error('Error!', errorMessage);

                dispatch({
                    type: SHOP_SIGNUP_ERROR,
                    payload: {
                        storeSignupLoading: false,
                        error: errorMessage
                    }
                });
                dispatch(setLoaderComplete('StoreSignup'));
            }
        } catch (error) {
            errorMessage = "Something went wrong! " + error;

            toastr.error('Error!', errorMessage);

            dispatch({
                type: SHOP_SIGNUP_ERROR,
                payload: {
                    storeSignupLoading: false,
                    error: errorMessage
                }
            });
            dispatch(setLoaderComplete('StoreSignup'));
        }
    }
};