import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './StaticImage.css';
import { api } from '../../../config';
class StaticImage extends React.Component {

  render() {
    const { getSettings } = this.props;

    return (
      <div
        className={s.bgImage}
        style={{ backgroundImage: `url(${api.apiEndpoint}/images/homepage/${getSettings("footerLogo1")})` }}
      >
      </div>
    );
  }
}


export default withStyles(s)(StaticImage);