import React from 'react';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './HomePartners.css';
import { Row, Col, Container } from 'react-bootstrap';

import HomePartnersCommon from './HomePartnersCommon';
import { api } from '../../../config';
class HomePartners extends React.Component {
	render() {
		const { getSettings } = this.props;
		let path = '/images/homepage/', homePartnersCommon = [];

		homePartnersCommon.push(
			{
				title: getSettings("aboutGridTitle1"),
				description: getSettings("aboutGridContent1"),
				image: `${api.apiEndpoint + path + getSettings("aboutGridImage1")}`,
				Url: '/',
			},
			{
				title: getSettings("aboutGridTitle2"),
				description: getSettings("aboutGridContent2"),
				image: `${api.apiEndpoint + path + getSettings("aboutGridImage2")}`,
				Url: '/',
			},
			{
				title: getSettings("aboutGridTitle3"),
				description: getSettings("aboutGridContent3"),
				image: `${api.apiEndpoint + path + getSettings("aboutGridImage3")}`,
				Url: '/',
			}
		);

		return (
			<div className={s.bgColor}>
				<div className={s.root}>
					<Container className={s.bannerLayoutContainer}>
						<Row>
							<Col lg={12} md={12} sm={12} xs={12}>
								<div className={s.positionRelative}>
									<div className={cx(s.textCenter, s.headerMargin)}>
										<h1 className={s.headerText}>
											{getSettings("aboutGridTitle5")}
										</h1>
									</div>
									<div className={s.orderDisplayGrid}>
										{
											homePartnersCommon.map(item => {
												return (
													<HomePartnersCommon
														title={item.title}
														description={item.description}
														Image={item.image}
														Url={'/'}
													/>
												);
											})
										}
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
}

export default injectIntl(withStyles(s)(HomePartners));