import React from 'react';
import Layout from '../../../components/Layout/Layout';
import Page from '../../../components/Page/Page';
import fetch from 'node-fetch';
import { siteUrl } from '../../../config';
import NotFound from '../../not-found/NotFound';

const query = `
query getStaticPage($id: Int!) {
    getStaticPage(id: $id) {
      status
      errorMessage
      result {
        id
        pageName
        pageBanner
        content
        metaTitle
        metaDescription
        createdAt
      }
    }
}
`;

async function action({ locale }) {
    let result;
    const resp = await fetch(`${siteUrl}/graphql`, {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query,
            variables: { id: 5 }
        }),
        credentials: 'include',
    });

    const { data } = await resp.json();

    if (data && data.getStaticPage && data.getStaticPage.status === 200) {
        result = data.getStaticPage.result;

        return {
            title: result.metaTitle,
            description: result.metaDescription,
            component: <Layout><Page data={result} /></Layout>,
        };
    } else {
        return {
            title: 'Not Found!',
            component: <Layout><NotFound /></Layout>,
        };
    }
}

export default action