import gql from 'graphql-tag';
import {
    SHOP_UPDATE_SUCCESS,
    SHOP_UPDATE_START,
    SHOP_UPDATE_ERROR
} from '../../../constants/index';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';

export default function updateShop(values) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: SHOP_UPDATE_START
        });

        try {
            let errorMessage;

            dispatch(setLoaderStart('UpdateStore'));

            let mutation = gql`
            mutation (
                $id: ID,
                $shopName: String
                $firstName: String,
                $lastName: String,
                $email: String,
                $phoneDialCode: String,
                $phoneNumber: String,
                $phoneCountryCode: String,
                $address: String,
                $lat: Float,
                $lng: Float,
                $city: String,
                $state:String,
                $zipcode: String,
                $country: String,
                $categoryType: [Int],
                $description: String,
                $priceRangeId: Int,
                $picture: String,
                $preferredCurrency: String,
                $shopEstimatedTime: Int,
                $password: String,
                $userStatus: String,
                $isBan: Int,
                $isDoorStepDelivery: Boolean,
                $isTakeAway: Boolean
            ) {
                updateShop (
                    id: $id,
                    shopName: $shopName,
                    firstName: $firstName,
                    lastName: $lastName,
                    email: $email,
                    phoneDialCode: $phoneDialCode,
                    phoneNumber: $phoneNumber,
                    phoneCountryCode: $phoneCountryCode,
                    address: $address,
                    lat: $lat,
                    lng: $lng,
                    city: $city,
                    state: $state,
                    zipcode: $zipcode,
                    country: $country,
                    categoryType: $categoryType,
                    description: $description,
                    priceRangeId: $priceRangeId,
                    picture: $picture,
                    preferredCurrency: $preferredCurrency,
                    shopEstimatedTime: $shopEstimatedTime,
                    password: $password,
                    userStatus: $userStatus
                    isBan: $isBan,
                    isDoorStepDelivery: $isDoorStepDelivery,
                    isTakeAway: $isTakeAway
                ) {
                    status
                    errorMessage   
                }
            }
            `;

            const { data } = await client.mutate({
                mutation,
                variables: {
                    id: values && values.id,
                    shopName: values && values.shopName,
                    firstName: values && values.firstName,
                    lastName: values && values.lastName,
                    email: values && values.email,
                    phoneDialCode: values && values.phoneDialCode,
                    phoneNumber: values && values.phoneNumber,
                    phoneCountryCode: values && values.phoneCountryCode,
                    address: values && values.address,
                    lat: values && values.lat,
                    lng: values && values.lng,
                    city: values && values.city,
                    state: values && values.state,
                    zipcode: values && values.zipcode,
                    country: values && values.country,
                    categoryType: values && values.categoryType,
                    description: values && values.description,
                    priceRangeId: values && values.priceRange,
                    picture: values && values.picture,
                    preferredCurrency: values && values.preferredCurrency,
                    shopEstimatedTime: values && values.shopEstimatedTime,
                    password: values && values.password,
                    userStatus: values && values.userStatus,
                    isBan: values && values.isBan,
                    isDoorStepDelivery: values && values.isDoorStepDelivery,
                    isTakeAway: values && values.isTakeAway
                }
            });

            if (data && data.updateShop && data.updateShop.status == "200") {
                dispatch({
                    type: SHOP_UPDATE_SUCCESS
                });

                await dispatch(setLoaderComplete('UpdateStore'));
                toastr.success('Success', `The Store details has been updated successfully.`);
            } else {
                dispatch({
                    type: SHOP_UPDATE_ERROR
                });
                await dispatch(setLoaderComplete('UpdateStore'));
                errorMessage = (data && data.updateShop && data.updateShop.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
            }
        } catch (err) {
            toastr.error('Error!', err);
            dispatch({
                type: SHOP_UPDATE_ERROR
            });
        }

    }
};