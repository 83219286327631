import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import MobileSettingsForm from '../../../components/SiteAdmin/MobileSettingsForm/MobileSettingsForm';
import Loader from '../../../components/Common/Loader/Loader';

import s from './MobileSettings.css';

import getSiteSettings from './getSiteSettings.graphql'
//meassage
import messages from '../../../locale/messages';

export class MobileSettings extends Component {

  render() {
    const { getSiteSettings: { loading, getSiteSettings, refetch } } = this.props;
    let mobileSettingsCollection = {}

    if (!loading) {
      getSiteSettings && getSiteSettings.results && getSiteSettings.results.length > 0 && getSiteSettings.results.map((item) => {
        mobileSettingsCollection[item.name] = item.value
      })
      return (
        <div className={s.root}>
          <div className={cx(s.container, 'paddingBottomLg')}>
           <div className={cx(s.heading, 'textAlignRightRTL')}>
              <FormattedMessage {...messages.mobileAppSettings} />
            </div>
            <div className={s.spaceTop5}>
              <MobileSettingsForm refetch={refetch} initialValues={mobileSettingsCollection} />
            </div>
          </div>
        </div>
      )
    }
    else {
      return <Loader type={"page"} show={loading} />
    }

  }
}


export default compose(
  withStyles(s),
  graphql(getSiteSettings, {
    name: 'getSiteSettings',
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: { type: 'appSettings' },
      ssr: true,
    }),
  })
)(MobileSettings)
