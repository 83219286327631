import React from 'react';
import PropTypes from 'prop-types';
import s from './AddContentPageForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Button,
    Form,
    Col,
    Row,
    FormGroup,
    FormControl,
    InputGroup,
    Container
} from 'react-bootstrap';
import Link from '../../../Link';
import messages from '../../../../locale/messages';
import { siteUrl } from '../../../../config';
import submit from './submit';
import { formatURL } from '../../../../helpers/formatUrl';
import validate from './validate';
import Dropzone from './Dropzone';
import { api, contentPageUploadDir } from '../../../../config';
//image
import defaultIcon from '../../../../../public/Icons/defaultIconAdmin.svg';

class AddContentPageForm extends React.Component {
    constructor(props) {
        super(props)
        if (typeof window !== 'undefined') {
            this.ReactQuill = require('react-quill');
        }
        this.state = { editorHtml: '' };
        this.handlePageTitle = this.handlePageTitle.bind(this);
    }

    static propTypes = {
        title: PropTypes.string.isRequired,
        initialValues: PropTypes.object,
    };

    async handlePageTitle(e) {
        const { change } = this.props;
        if (e.target.value) {
            await change('pageUrl', formatURL(e.target.value));
        } else {
            await change('pageUrl', '');
        }
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <Form.Label className={'inputLabel'}>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        )
    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label className={'inputLabel'}>{label}</label>
                <FormControl {...input} placeholder={label} type={type} className={cx(className, s.formControlInput)} maxlength={maxlength} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    renderFormControlPageUrl = ({ input, label, placeholder, type, meta: { touched, error }, className }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label className={cx('inputLabel', s.pageUrlLeft, s.labelZintex, 'pageUrlLeftRTL')}>{label}</label>
                <div className={cx('pageUrlInput', 'pageUrlRadius')}>
                    <InputGroup>
                        <InputGroup.Text>
                            <InputGroup.Append>
                                <span>{siteUrl}/<FormattedMessage {...messages.page} />/</span>
                            </InputGroup.Append>
                        </InputGroup.Text>
                        <FormControl {...input} placeholder={placeholder} type={type} className={cx(className, 'pageUrlFormInput', s.formControlInput)} />
                    </InputGroup>
                    {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
                </div>
            </FormGroup>

        );
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label className={'inputLabel'}>{label}</label>
                <FormControl
                    {...input}
                    className={className}
                    placeholder={label}
                    as="textarea"
                    rows="4"
                >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    renderQuill = ({ input, label, type, meta: { touched, error }, className }) => {
        const ReactQuill = this.ReactQuill;
        const { formatMessage } = this.props.intl;

        let modules = {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link'],
                // ['link', 'image'],
            ],
            clipboard: {
                matchVisual: false,
            }
        };

        let formats = [
            'header', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link'
            // 'link', 'image'
        ];
        return (
            <div>
                <ReactQuill
                    {...input}
                    onChange={(newValue, delta, source) => {
                        if (source === 'user') {
                            input.onChange(newValue);
                        }
                    }}
                    onBlur={(range, source, quill) => {
                        if (quill.getHTML() == '<p><br></p>') {
                            input.onBlur('');
                        }
                        else {
                            input.onBlur(quill.getHTML());
                        }
                    }}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                />

                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </div>
        );
    }

    render() {
        const { handleSubmit } = this.props;
        const { pageBanner } = this.props;
        const ReactQuill = this.ReactQuill;
        const { formatMessage } = this.props.intl;
        if (typeof window !== 'undefined' && ReactQuill) {

            return (
                <div>
                    <Container fluid>
                        <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                                    <div className={s.blackCenterSection}>
                                        <Row>
                                            <Col lg={6} md={12} sm={12} xs={12} className={s.paddingTop}>
                                                <FormGroup className={s.space3}>
                                                    <div className={cx(s.profileImgSection)}>
                                                        <label><FormattedMessage {...messages.pageImageBanner} /></label><br />
                                                        {
                                                            pageBanner &&
                                                            <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + contentPageUploadDir}large_${pageBanner})` }} />
                                                        }
                                                        {
                                                            !pageBanner &&
                                                            <div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
                                                        }
                                                        <div>
                                                            <Dropzone
                                                                className={cx(s.btnSecondary, s.profileNoPadding)}
                                                                subTextClass={s.subText}
                                                                fieldName={'pageBanner'}
                                                                subText="Maximum upload size"
                                                                defaultMessage={formatMessage(messages.chooseFile)}
                                                            />
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                                <Field
                                                    name="metaTitle"
                                                    type="text"
                                                    placeholder={formatMessage(messages.metaTitle)}
                                                    component={this.renderFormControl}
                                                    label={formatMessage(messages.metaTitle)}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                                <Field
                                                    name="metaDescription"
                                                    type="text"
                                                    placeholder={formatMessage(messages.metaDescriptionText)}
                                                    component={this.renderFormControlTextArea}
                                                    label={formatMessage(messages.metaDescriptionText)}
                                                    labelClass={s.labelText}
                                                    fieldClass={s.formControlInput}
                                                />
                                                <Field
                                                    name="pageTitle"
                                                    type="text"
                                                    component={this.renderFormControl}
                                                    label={formatMessage(messages.pageTitle)}
                                                    placeholder={formatMessage(messages.pageTitle)}
                                                    onChange={(event) => this.handlePageTitle(event)}
                                                />
                                                <Field
                                                    name="pageUrl"
                                                    type="text"
                                                    component={this.renderFormControlPageUrl}
                                                    label={formatMessage(messages.pageUrl)}
                                                    placeholder={formatMessage(messages.pageUrl)}
                                                />

                                            </Col>
                                        </Row>
                                        <FormGroup className={cx(s.space5, 'positionRelative', s.spaceTop5)}>
                                            <label className={'inputLabel'}><FormattedMessage {...messages.content} /></label>
                                            <Field name="content" component={this.renderQuill} />
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.paddingBottom, s.btnPadding, 'btnPaddingRTL', 'textAlignLeftRTL')}>
                                    <Form.Group className={s.noMargin}>
                                        <Link to={"/siteadmin/contentpage/manage"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, s.backBtnTwoTop, 'backBtnTwoRTL')} >{formatMessage(messages.goBack)}</Link>
                                        <Button
                                            type="submit"
                                            className={cx(s.button, s.btnPrimary)}
                                        >
                                            <FormattedMessage {...messages.submitButton} />
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

}

AddContentPageForm = reduxForm({
    form: 'ContentPageForm',
    validate,
    onSubmit: submit,
})(AddContentPageForm);

const contentPageFormSelector = formValueSelector('ContentPageForm');

const mapState = (state) => ({
    pageTitle: contentPageFormSelector(state, 'pageTitle'),
    pageBanner: contentPageFormSelector(state, 'pageBanner')
});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AddContentPageForm)));