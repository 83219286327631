import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table } from 'react-bootstrap';
import s from './DeliveryVehicleList.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';
//local
import messages from '../../../../locale/messages';
import CustomPagination from '../../../CustomPagination';
import PropTypes from 'prop-types';
import Link from '../../../Link';
import history from '../../../../history';
import { Button, FormControl, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
//Images
import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';
import addIcon from '../../../../../public/Icons/addNewIcon.svg';

import updateDeliveryVehicleStatus from '../../../../actions/siteadmin/DeliveryVehicle/updateDeliveryVehicleStatus';
import deleteDeliveryVehicle from '../../../../actions/siteadmin/DeliveryVehicle/deleteDeliveryVehicle';
import vehicleTypeDetails from './getAllDeliveryVehicle.graphql'

class DeliveryVehicleList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      isDisabled: 0
    }
    this.paginationData = this.paginationData.bind(this);
    this.handleClick = this.handleClick.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleClick() {
    history.push('/add')
  }

  handleSearchClick(searchList) {
    const { vehicleTypeDetails: { refetch } } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange(e) {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }

  async handleDelete(id, vehicleImage) {
    const { deleteDeliveryVehicle, vehicleTypeDetails: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    this.setState({
      isDisabled: 1
    })
    const response = await deleteDeliveryVehicle(id, vehicleImage);

    if (response && response.status === 200) {
      this.setState({ currentPage: 1 });
      refetch(variables);
    }
  }

  paginationData(currentPage) {
    const { vehicleTypeDetails: { refetch } } = this.props;
    let variables = { currentPage: 1 };
    this.setState({ currentPage });
    refetch(variables);
  }

  async handleStatus(e, id, currentPage) {
    const { updateDeliveryVehicleStatus, vehicleTypeDetails: { refetch } } = this.props;
    let status = e.target.value == 'true' ? '1' : '0';
    let variables = { currentPage: 1 };

    const response = await updateDeliveryVehicleStatus(id, status);

    if (response && response.status === 200) {
      this.setState({ currentPage });
      refetch(variables);
    }
  }

  render() {
    const { vehicleTypeDetails } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, isDisabled } = this.state;

    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.exportDisplay, s.tabelExport)}>
        <div className={'textAlignRightRTL'}>
            <div className={s.searchInput}>
              <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
            </div>
          </div>
          <div className={cx(s.exportTextSection,'textAlignLeftRTL')}>
            <div className={cx(s.paddingTop2)}>
              <Link to={"/siteadmin/vehicle-type/add"} className={s.addlinkBtn} >{formatMessage(messages.addDeliveryVehicle)} <span className={cx(s.addIconCss, 'addIconCssRTL')}><img src={addIcon}/></span></Link>
            </div>
          </div>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
        <Table className={cx("table")}>
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.vehicleName} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
                <th scope="col"><FormattedMessage {...messages.updateAt} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
              </tr>
            </thead>
            <tbody>
              {
                vehicleTypeDetails && !vehicleTypeDetails.loading && vehicleTypeDetails.getAllDeliveryVehicle
                && vehicleTypeDetails.getAllDeliveryVehicle.count > 0 && vehicleTypeDetails.getAllDeliveryVehicle.results && vehicleTypeDetails.getAllDeliveryVehicle.results.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item.id}</td>
                      <td data-label={formatMessage(messages.vehicleName)}>{item.vehicleName}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select value={item.isActive} onChange={(e) => { this.handleStatus(e, item.id, currentPage) }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.active)}</option>
                          <option value={false}>{formatMessage(messages.inactive)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.updateAt)}>{moment(moment(item.updatedAt)).format('DD-MM-YYYY hh:mm A')}</td>
                      <td data-label={formatMessage(messages.action)}>
                        <Link to={'/siteadmin/vehicle-type/edit/' + item.id} className={cx('editAlignIcon', s.editColorIcon)}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                        <Button onClick={() => this.handleDelete(item.id, item.vehicleImage)} className={cx(s.iconBtn, 'iconBtnRTL')} disabled={isDisabled}>
                            <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                            <span className={s.vtrMiddle}>
                              <FormattedMessage {...messages.deleteAction} />
                            </span>
                          </Button>
                      </td>
                    </tr>
                  )
                })
              }
              {
                vehicleTypeDetails && !vehicleTypeDetails.loading && vehicleTypeDetails.getAllDeliveryVehicle && vehicleTypeDetails.getAllDeliveryVehicle.count == 0 && (
                  <tr>
                    <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          vehicleTypeDetails && !vehicleTypeDetails.loading && vehicleTypeDetails.getAllDeliveryVehicle && vehicleTypeDetails.getAllDeliveryVehicle.count > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={vehicleTypeDetails.getAllDeliveryVehicle.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.vehicles)}
            />
          </div>
        }
      </div>
    );
  }
}

const mapDispatch = {
  updateDeliveryVehicleStatus,
  deleteDeliveryVehicle
};
const mapState = (state) => ({});

export default compose(
    withStyles(s),
    injectIntl,
    graphql(vehicleTypeDetails, {
      name: 'vehicleTypeDetails',
      options: {
        variables: {
          currentPage: 1,
          searchList: ''
        },
        ssr: true,
        fetchPolicy: 'network-only'
      }
    }),
    connect(mapState, mapDispatch)
  )(DeliveryVehicleList);