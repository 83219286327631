import React, { Component } from 'react';
import s from './EditProfileForm.css';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
//Style
import cx from 'classnames';
import {
	Button,
	Form,
	Col,
	Row,
	FormControl,
	Container
} from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';

import Loader from '../../Common/Loader';
import PlacesSuggest from '../PlacesSuggest';
import CustomCheckbox from '../../Common/CustomCheckbox';
import Dropzone from '../Dropzone';
import PhoneNumberVerification from './PhoneNumberVerification/PhoneNumberVerification';
import rs from '../../storeCommon.css';

import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import getCategoryCategories from './getCategoryCategories.graphql';
import submit from './submit';
import { api, storeImageUploadDir } from '../../../config';
import messages from '../../../locale/messages';
import validate from './validate';
//Images
import TrashIcon from '../../../../public/StoreIcon/closeIcon.svg';
import NoLogo from '../../../../public/StoreIcon/store-profile-default.svg';
export class EditProfileForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			phoneCountryCode: '',
			phoneDialCode: '',
			isReadOnly: true,
			phoneStatus: '',
			defaultPreparationTime: 55,
		};


		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handleLocationData = this.handleLocationData.bind(this);
		this.handleDropzone = this.handleDropzone.bind(this);
		this.handleDefaultPreparationTime = this.handleDefaultPreparationTime.bind(this);
		this.handleDeleteImage = this.handleDeleteImage.bind(this);
		this.loaderStart = this.loaderStart.bind(this);
		this.loaderEnd = this.loaderEnd.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onRemove = this.onRemove.bind(this);
	}

	static defaultProps = {
		loading: false
	};

	componentWillMount() {
		const { initialValues, phoneStatus, change } = this.props;
		const { defaultPreparationTime } = this.state;
		let preparationTime = initialValues && initialValues.shopEstimatedTime ? initialValues.shopEstimatedTime : defaultPreparationTime;

		change('isPhoneVerified', phoneStatus);
		this.setState({
			phoneCountryCode: initialValues && initialValues.phoneCountryCode,
			phoneDialCode: initialValues && initialValues.phoneDialCode,
			phoneStatus: phoneStatus,
			defaultPreparationTime: preparationTime
		});
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const { phoneNumber, isPhoneVerified } = newProps;
		if (phoneNumber == null) {
			this.setState({
				isReadOnly: false,
				phoneStatus: false
			})
		}

		if (isPhoneVerified == true) {
			this.setState({
				isReadOnly: true
			})
		}
	}

	async onSelect(selectedList, selectedItem) {
		const { change } = this.props;
		await change('categoryType', selectedList);
	}

	async onRemove(selectedList, removedItem) {
		const { change } = this.props;
		await change('categoryType', selectedList);
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, disabled }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(rs.space3, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} disabled={disabled} />
				{touched && error && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	checkboxHorizontalGroupOne = ({ label, input, checked, meta: { error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<>
				<div className={s.typeFlex}>
					<CustomCheckbox
						onChange={event => {
							input.onChange(event);
						}}
						checked={checked}
						value={true}
						className={'icheckbox_square-green'}
					/>
					<span>
						{label}
					</span>
				</div>
				{error && <span>{formatMessage(error)}</span>}
			</>
		)
	};

	checkboxHorizontalGroupTwo = ({ label, input, checked, meta: { error } }) => {
		const { formatMessage } = this.props.intl;
		return (
			<>
				<div className={s.typeFlex}>
					<CustomCheckbox
						onChange={event => {
							input.onChange(event);
						}}
						checked={checked}
						value={true}
						className={'icheckbox_square-green'}
					/>
					<span>
						{label}
					</span>
				</div>
				{error && <span className={cx(rs.errorMessage, s.errorMessageAbsolute, 'errorMessageAbsoluteRTL')}>{formatMessage(error)}</span>}
			</>
		)
	};

	renderFieldPhoneNumber = ({ input, label, writeOnce, type, meta: { touched, error }, fieldClass, placeholder, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(rs.space3, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<Form.Control {...input} readOnly={writeOnce} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	};

	renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(rs.space3, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<FormControl
					{...input}
					className={cx(s.inputNoRadius, className, rs.textAreaField)}
					placeholder={label}
					as="textarea"
				>
					{children}
				</FormControl>
				{touched && error && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		);
	}

	checkboxGroup = ({ label, name, options, input, meta: { touched, error } }) => {
		const { categoryType } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(rs.space3, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<Multiselect
					options={options}
					selectedValues={categoryType}
					onSelect={this.onSelect}
					onRemove={this.onRemove}
					displayValue="categoryName"
					emptyRecordMsg={formatMessage(messages.noItem)}
					placeholder={formatMessage(messages.chooseCategory)}
				/>
				{error && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		);
	}

	renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(rs.space3, 'positionRelative')}>
				<label className={'inputLabel'}>{label}</label>
				<FormControl as="select" {...input} className={className} >
					{children}
				</FormControl>
				{touched && error && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}


	handleLocationData(location) {
		const { change } = this.props;
		let addressLabel = location && location.label;
		let addressPosition = location && location.location;
		let country, state, city;

		location && location.gmaps && location.gmaps.address_components.map((item, key) => {
			if (item.types[0] == "administrative_area_level_1") {
				state = item.long_name;
			} else if (item.types[0] == "country") {
				country = item.long_name;
			} else if (item.types[0] == "administrative_area_level_2" || item.types[0] == "political") {
				city = item.long_name;
			}
		});

		change('address', addressLabel)
		change('city', city ? city : null);
		change('state', state ? state : null);
		change('country', country ? country : null);
		change('lat', addressPosition && addressPosition.lat);
		change('lng', addressPosition && addressPosition.lng);
	};

	handleCountryChange(country) {
		const { change } = this.props;

		this.setState({
			phoneCountryCode: country.countryCode,
			phoneDialCode: country.dialCode
		});

		change('phoneCountryCode', country.countryCode)
		change('phoneDialCode', country.dialCode)
	};

	handleDropzone(fileName, fieldName) {
		const { change } = this.props
		change(fieldName, fileName)
	}

	handleDefaultPreparationTime(action) {
		const { defaultPreparationTime } = this.state;
		const { change } = this.props;

		let preparationTime = action == 'add' ? defaultPreparationTime + 5 : defaultPreparationTime - 5;

		if (preparationTime <= 55 && preparationTime >= 5) {
			this.setState({ defaultPreparationTime: preparationTime })
			change('shopEstimatedTime', preparationTime)
		}
	};

	handleDeleteImage() {
		const { change } = this.props;
		change('picture', null)
	}

	async loaderStart() {
		const { setLoaderStart } = this.props;
		setLoaderStart('profileImageLoader')
	}

	async loaderEnd() {
		const { setLoaderComplete } = this.props;
		setLoaderComplete('profileImageLoader')
	}

	render() {
		const { handleSubmit, loading, submitting, getCategory: { getCategoryDelivery }, initialValues, getDietary, imageLoader } = this.props;
		const { picture, phoneStatus, shopEstimatedTime, error, isDoorStepDelivery, isTakeAway } = this.props
		const { phoneCountryCode, phoneDialCode, isReadOnly, defaultPreparationTime } = this.state;
		const { formatMessage } = this.props.intl;
		let phoneData = {
			phoneCountryCode: phoneCountryCode,
			phoneDialCode: phoneDialCode,
			phoneNumber: initialValues ? initialValues.phoneNumber : '',
			phoneStatus: phoneStatus,
			userId: initialValues ? initialValues.id : ''
		};

		return (
			<div className={cx('mainContainer')}>
				<div className={cx(s.editProfileContainer)}>
					<Container fluid>
						<Row>
							<Col md={12} lg={12} sm={12} xs={12}>
								<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
									<div className={s.titleGrid}>
										<div>
											<h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>{formatMessage(messages.editProfile)}</h1>
										</div>
										<div className={cx(s.deliveryTypeTextAlign, 'textAlignLeftRTL')}>
											<p className={cx(s.deliveryTypeText, 'deliveryTypeTextRTL')}>
												{formatMessage(messages.deliveryTypeLabel)}
											</p>
											<p className={s.deliveryType}>
												{initialValues.deliveryName}
											</p>
										</div>
									</div>
									<div className={cx(rs.storeNewBg, rs.space5)}>
										<div className={s.profileGrid}>
											<div>
												<Field
													name="shopName"
													type="text"
													placeholder={formatMessage(messages.shopName)}
													component={this.renderField}
													label={formatMessage(messages.shopName)}
													labelClass={s.labelText}
													fieldClass={cx(rs.formControlInputStore, rs.space1, 'formControlInputStoreRTL')}
												/>
												<Field
													name="description"
													type="text"
													placeholder={formatMessage(messages.storeDescription)}
													component={this.renderFormControlTextArea}
													label={formatMessage(messages.storeDescription)}
													labelClass={s.labelText}
													fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
												/>
											</div>
											<Form.Group className={cx(rs.space5, 'positionRelative')}>
												<label className={'inputLabel'}>{formatMessage(messages.storeImage)}</label>
												<div className={cx(rs.profileImgWidth, rs.storeProfileImgSection, s.droupZoneGrid, 'editLoaderZindex')}>
													<div className={s.positionRelative}>
														<Loader
															show={imageLoader}
															type={"page"}
														>
															{
																picture ?
																	<div className={cx(rs.storeBackgroundImg, s.editZoneImgWidth)} style={{ backgroundImage: `url(${api.apiEndpoint + storeImageUploadDir + picture})` }} />
																	:
																	// <div className={cx(rs.storeBackgroundImg, s.editZoneImgWidth)} style={{ backgroundImage: `url(${NoLogo})` }}></div>
																	<div className={cx(rs.backgroundImg, s.editZoneImgWidth)}>
																		<img src={NoLogo} className={rs.defaultImage} />
																	</div>
															}
															{
																picture ?
																	<Button onClick={this.handleDeleteImage} className={cx(s.btnTrash, 'btnTrashRTL')}>
																		<img src={TrashIcon} alt='Delete' />
																	</Button>
																	:
																	''
															}
														</Loader>
													</div>
													<div className={'retaurantDroupZone'}>
														<Dropzone
															className={cx(rs.profileButton)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
															inputContainer={'.dzInputContainerStoreImage'}
															inputContainerClass={'dzInputContainerStoreImage'}
															url={'/uploadStoreImage'}
															fieldName={'picture'}
															handleDropzone={this.handleDropzone}
															deleteEnabled={true}
															loaderStart={this.loaderStart}
															loaderEnd={this.loaderEnd}
															isLoader={true}
														/>
													</div>
												</div>
											</Form.Group>
										</div>
										<hr className={s.hrBorder} />
										<div className={s.profileGrid}>
											<Field
												name="firstName"
												type="text"
												placeholder={formatMessage(messages.firstName)}
												component={this.renderField}
												label={formatMessage(messages.firstName)}
												labelClass={s.labelText}
												fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
											/>
											<Field
												name="lastName"
												type="text"
												placeholder={formatMessage(messages.lastName)}
												component={this.renderField}
												label={formatMessage(messages.lastName)}
												labelClass={s.labelText}
												fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
											/>
											<Field
												name="email"
												type="text"
												placeholder={formatMessage(messages.email)}
												component={this.renderField}
												label={formatMessage(messages.email)}
												labelClass={s.labelText}
												fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
												disabled
											/>
											<Form.Group className={cx(rs.space3, 'positionRelative')}>
												<label className={'inputLabel'}>{formatMessage(messages.phoneNumber)}</label>
												<PhoneNumberVerification phoneData={phoneData}
												/>
											</Form.Group>
											<Form.Group className={cx(rs.space3, s.mobileSpaceTop, 'positionRelative')}>
												<label className={'inputLabel'}>{formatMessage(messages.address)}</label>
												<div className={'editProfile'}>
													<PlacesSuggest
														label={formatMessage(messages.address)}
														className={s.formGroup}
														handleData={this.handleLocationData}
														defaultValue={initialValues && initialValues.address}
													/>
													{error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
												</div>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<Field
													name="zipcode"
													type="text"
													placeholder={formatMessage(messages.zipcode)}
													component={this.renderField}
													label={formatMessage(messages.zipcode)}
													labelClass={s.labelText}
													fieldClass={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
												/>
											</Form.Group>
										</div>
										<hr className={s.hrBorder} />
										<div className={s.profileGrid}>
											<Form.Group className={s.formGroup}>
												<Field name="priceRange"
													className={cx(rs.formControlSelect, rs.formControlInputStore, 'formControlInputStoreRTL')}
													component={this.renderFormControlSelect}
													placeholder={formatMessage(messages.priceRange)}
													label={formatMessage(messages.priceRange)}
												>
													<option value="">{formatMessage(messages.choosePriceRange)}</option>
													<option value={1}>$</option>
													<option value={2}>$$</option>
													<option value={3}>$$$</option>
													<option value={4}>$$$$</option>
												</Field>
											</Form.Group>
											<Form.Group className={s.formGroup}>
												<Field name="categoryType"
													component={this.checkboxGroup}
													options={getCategoryDelivery && getCategoryDelivery.results}
													label={formatMessage(messages.category)}
												/>
											</Form.Group>
										</div>
										<hr className={s.hrBorder} />
										<div className={cx('textRightRTL', s.deliveryGrid)}>
											<label>{formatMessage(messages.deliveryTypeLabel)}</label>
											<div className={cx(rs.displayGridPrifile, 'textRightRTL', 'positionRelative')}>
												<Form.Group className={s.noMargin}>
													<Field name="isDoorStepDelivery"
														component={this.checkboxHorizontalGroupTwo}
														label={formatMessage(messages.doorStepDelivery)}
														checked={isDoorStepDelivery}

													/>
												</Form.Group>
												<Form.Group className={s.noMargin}>
													<Field name="isTakeAway"
														component={this.checkboxHorizontalGroupOne}
														label={formatMessage(messages.takeAway)}
														checked={isTakeAway}
													/>
												</Form.Group>
											</div>
										</div>
									</div>

									<Form.Group className={cx(rs.alignRightText, rs.spaceTop3, s.formGroup, 'textAlignLeftRTL')}>
										<div className={s.displayInlineSection}>
											<Loader
												type={"button"}
												label={formatMessage(messages.update)}
												show={loading}
												buttonType={'submit'}
												className={cx(rs.button, rs.btnPrimary)}
												disabled={submitting || loading}
												isSuffix={true}
											/>
										</div>
									</Form.Group>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		)
	}
}

EditProfileForm = reduxForm({
	form: 'EditProfileForm',
	onSubmit: submit,
	validate
})(EditProfileForm);

const selector = formValueSelector('EditProfileForm');

const mapState = state => ({
	picture: selector(state, 'picture'),
	loading: state.loader.EditProfile,
	phoneNumber: selector(state, 'phoneNumber'),
	isPhoneVerified: selector(state, 'isPhoneVerified'),
	categoryType: selector(state, 'categoryType'),
	availableCurrencies: state.currency.availableCurrencies,
	imageLoader: state.loader.profileImageLoader,
	isDoorStepDelivery: selector(state, 'isDoorStepDelivery'),
	isTakeAway: selector(state, 'isTakeAway')
});

const mapDispatch = {
	change,
	setLoaderStart,
	setLoaderComplete,
};


export default compose(
	injectIntl,
	withStyles(s, rs),
	connect(mapState, mapDispatch),
	graphql(getCategoryCategories, {
		name: 'getCategory',
		options: (props) => ({
			variables: {
				deliveryType: props.deliveryType,
			},
			fetchPolicy: 'network-only',
			ssr: true
		})
	})
)(EditProfileForm);