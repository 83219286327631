import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';
// style
import s from './ManageRatings.css';
// helpers
import messages from '../../../locale/messages';
import debounce from '../../../helpers/debounce';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirst'
// components
import Link from '../../Link/Link';
import StarRating from '../../StarRating';
import CustomPagination from '../../CustomPagination/CustomPagination';

export class ManageRatings extends Component {

    static propTypes = {
        reviews: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.state = {
            currentPage: 1,
            keyword: ''
        }
        this.paginationData = this.paginationData.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this));
    }

    handleKeywordSearch(keyword) {
        const { reviews: { refetch } } = this.props;

        let variables = {
            currentPage: 1,
            keyword
        };

        this.setState({ keyword, currentPage: 1 });
        refetch(variables);
    }

    paginationData(currentPage) {
        const { reviews: { refetch } } = this.props;
        const { keyword } = this.state;
        let variables = { currentPage, keyword };

        this.setState({ currentPage });
        refetch(variables);
    }

    render() {
        const { reviews, reviews: { getReviews } } = this.props;
        const { currentPage } = this.state;
        const { formatMessage } = this.props.intl;

        let userTypeLabel = {
            '1': formatMessage(messages.userLabel),
            '2': formatMessage(messages.driver),
            '3': formatMessage(messages.shopLabel)
        };

        return (
            <div className={cx(s.widthInner, s.tableSection, 'widthInnerNoBorder')}>
                <div className={cx(s.exportDisplay, s.tabelExport)}>
                    <div className={s.searchInput}>
                        <FormControl type='text' placeholder={formatMessage(messages.search)}
                            onChange={(event) => this.handleKeywordSearch(event.target && event.target.value)} 
                            className={s.formControlInput} 
                        />
                    </div>
                </div>
                <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
                <Table className={cx("table")}>
                        <thead>
                            <tr>
                                <th scope="col"><FormattedMessage {...messages.orderId} /></th>
                                <th scope="col"><FormattedMessage {...messages.sender} /></th>
                                <th scope="col"><FormattedMessage {...messages.receiver} /></th>
                                <th scope="col"><FormattedMessage {...messages.ratings} /></th>
                                <th scope="col"><FormattedMessage {...messages.view} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reviews && getReviews && getReviews.count > 0 && getReviews.results && getReviews.results.map((item, index) => {
                                    let senderType = item.authData && userTypeLabel[`'${item.authData.userType}'`];
                                    let receiverType = item.userData && userTypeLabel[`'${item.userData.userType}'`];

                                    return (
                                        <tr key={index}>
                                            <td data-label={formatMessage(messages.orderId)}>
                                                <Link to={`/siteadmin/reviews/view/${item.id}`}>
                                                    {`#${item && item.bookingDetails && item.bookingDetails.orderId}`}
                                                </Link>
                                            </td>
                                            <td data-label={formatMessage(messages.sender)}>
                                                {capitalizeFirstLetter(item.authorData && item.authorData.firstName)}<br />
                                                {item && item.authorData && item.authorData.phoneNumber}<br />
                                                {senderType}
                                            </td>
                                            <td data-label={formatMessage(messages.receiver)}>
                                                {capitalizeFirstLetter(item.userData && item.userData.firstName)}<br />
                                                {item && item.userData && item.userData.phoneNumber}<br />
                                                {receiverType}
                                            </td>
                                            <td data-label={formatMessage(messages.ratings)}>
                                                <StarRating
                                                    value={item.ratings}
                                                    name={'review'}
                                                />
                                            </td>
                                            <td data-label={formatMessage(messages.view)}>
                                                <Link to={`/siteadmin/reviews/view/${item.id}`}>
                                                    <FormattedMessage {...messages.view} />
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                reviews && getReviews && getReviews.count === 0 && <tr>
                                    <td colSpan={5} className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
                {
                    reviews && getReviews && getReviews.count > 0 && <div className={cx(s.space5, s.spaceTop5)}>
                        <CustomPagination
                            total={getReviews.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.ratings)}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default injectIntl(withStyles(s)(ManageRatings));