import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import { connect } from 'react-redux';
import * as FontAwesome from 'react-icons/lib/fa';


// style
import s from './StoreSideMenu.css';
import rs from '../../storeCommon.css';


// Components
import Logout from '../../Common/Logout/Logout';
import Switch from '../../Common/Switch/Switch';
import Link from '../../Link/Link';
import HeaderModal from '../../HeaderModal/HeaderModal';
import LogoutModal from '../StoreLayout/LogoutModal';

// Redux actions
import shopUserActivityChange from '../../../actions/shop/userAccount/shopUserActivityChange';
import { shopLogout } from '../../../actions/shop/logout/shopLogout';
import { openHeaderModal } from '../../../actions/siteadmin/modalActions';
import { openLogoutModal } from '../../../actions/modalActions';

//Images
import dashboardIcon from '../../../../public/StoreIcon/dashboard.svg';
import editIcon from '../../../../public/StoreIcon/editProfile.svg';
import profileIcon from '../../../../public/StoreIcon/profile.svg';
import addPayIcon from '../../../../public/StoreIcon/payout.svg';
import preparationTimeIcon from '../../../../public/StoreIcon/preparationTim.svg';
import operationHoursIcon from '../../../../public/StoreIcon/opertaionHours.svg';
import passwordIcon from '../../../../public/StoreIcon/pswd.svg';
import CategoryIcon from '../../../../public/StoreIcon/manageMenuIcon.svg';
import DocumentUpIcon from '../../../../public/StoreIcon/docUpload.svg';
import LogOutIcon from '../../../../public/StoreIcon/logout.svg';
import TransactionManageIcon from '../../../../public/StoreIcon/transactionManagement.svg';
import OrderManageIcon from '../../../../public/StoreIcon/orderManagement.svg';
import languageIcon from '../../../../public/Icons/Language-white.svg';
import helloIcon from '../../../../public/Icons/hello.png'

// helper
import messages from '../../../locale/messages';
import history from '../../../history';
import { formatLocale } from '../../../helpers/formatLocale';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirst';
import { isRTL } from '../../../helpers/formatLocale';


class StoreSideMenu extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpen: 0,
			location: '',
			isProfileMenu: false,
			activeMenus: {
				payout: ['/store/payout', '/store/addpayout', '/store/payout/failure'],
				profile: ['/store/edit-profile', '/store/document', '/store/password'],
				order: [
					'/store/order-management/previous', '/store/order-management/upcoming',
					'/store/order-details/previous/', '/store/order-details/upcoming/'],
				transaction: [
					'/store/transaction/completed', '/store/transaction/future',
					'/store/order-details/completed/', '/store/order-details/future/']
			}
		};

		this.openMenu = this.openMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.handleSwitch = this.handleSwitch.bind(this);
		this.handleMenuActive = this.handleMenuActive.bind(this);
		this.renderSinglePageMenu = this.renderSinglePageMenu.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		const { activeMenus: { profile } } = this.state;
		let location = history && history.location && history.location.pathname;

		this.setState({ location });

		if (location && profile && profile.includes(location)) {
			this.setState({ isProfileMenu: true });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { location } = this.props;
		const { activeMenus: { profile } } = this.state;

		if (prevState.location !== location) {
			this.setState({ location });

			if (location && profile && profile.includes(location)) {
				this.setState({ isProfileMenu: true });
			}
		}
	}

	handleMenuActive(menu) {
		const { activeMenus, location } = this.state;
		if (menu && location && activeMenus[menu] && activeMenus[menu].findIndex(o => location.includes(o)) >= 0) {
			return true;
		} else {
			return false;
		}
	}

	async handleSwitch(status) {
		const { shopUserActivityChange, shopStatus } = this.props;
		shopUserActivityChange(status);
	}

	openMenu() {
		this.setState({ isOpen: 1 });
	}

	closeMenu() {
		this.setState({ isOpen: 0 });
	}

	renderSinglePageMenu(to, label, icon, iconLayer, isProfileMenu) {
		const { isProfileMenu: isProfileMenuState, location } = this.state;

		return (
			<li className={cx(s.listSection, isProfileMenuState === isProfileMenu && location == to ? s.active : '')}>
				<Link to={to} className={cx(s.sideNavitem, 'subMenuRTL')} onClick={() => {
					this.closeMenu();
				}}>
					<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
						<use xlinkHref={icon + iconLayer}></use>
					</svg>
					<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
						<span>{label}</span>
					</span>
				</Link>
			</li>
		);
	}

	renderMultiPageMenu(to, menuType, label, icon, iconLayer) {
		const { isProfileMenu, location } = this.state;

		return (
			<li className={cx(s.listSection, !isProfileMenu && this.handleMenuActive(menuType) ? s.active : '')}>
				<Link to={to} className={cx(s.sideNavitem)} onClick={() => {
					this.closeMenu();
					// isProfileMenu && this.setState({ isProfileMenu: !isProfileMenu })
				}}>
					<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
						<use xlinkHref={icon + iconLayer}></use>
					</svg>
					<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
						<span>{label}</span>
					</span>
				</Link>
			</li>
		);
	}


	handleChange(e) {
		const { openHeaderModal } = this.props;
		openHeaderModal('languageModal');
	}

	render() {
		const { shopStatus, currentLocale, openLogoutModal, userName } = this.props;
		const { location, isProfileMenu } = this.state;
		const { formatMessage } = this.props.intl;

		return (
			<div className={rs.storeSideMenuBg}>
				<LogoutModal />
				<div className={cx(rs.storeSideMenuList, 'storeSideMenu', 'sideMenuScroll', 'hidden-print')}>
					<div className={cx(s.welcomeCss, 'textAlignRightRTL')}>
						<img src={helloIcon} /> <span className={s.welcomeText}><FormattedMessage {...messages.storeWelcome} /> {capitalizeFirstLetter(userName)}!</span>
					</div>
					<Navbar expand="lg">
						<div onClick={() => this.openMenu()}>
							<div className="nav-container">
								<div className={cx("button  d-block d-md-none")} tabIndex="0">
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</div>
							</div>
						</div>
						<div className={cx({ [rs.menuOpen]: this.state.isOpen == 1 }, rs.mobileMenu)}>
							<div className={cx({ [rs.menuClose]: this.state.isOpen == 0 }, rs.rightMenuClose, 'd-block d-md-none')}>
								<div className={cx(rs.closeColor, 'closeColorRTL')} onClick={() => this.closeMenu()} >
									&#10005;
								</div>
							</div>
							<Nav className="mr-auto">
								<HeaderModal />
								<ul className={s.listMainSection}>
									<li className={cx(s.listSection, s.mobileViewOnly)}>
										<div className={cx(s.sideNavitem, s.swithPadding)}>
											<span>

												<svg className={cx(s.overviewIcon, s.noBorder, s.leftIcon)}>
													<use xlinkHref={languageIcon + '#Layer_59'}></use>
												</svg>
											</span>
											<Link noLink
												onClick={(e) => this.handleChange(e)}
												className={cx(s.siteColor, s.cursurPointer)}>
												<span className={cx(s.displayInlineBlock, s.vtrMiddle, s.iconTextPadding)}>
													{formatLocale(currentLocale)}
												</span>
											</Link>
										</div>
									</li>
									<li className={cx(s.listSection, s.mobileViewOnly)}>
										<div className={cx(s.sideNavitem, s.swithPadding)}>
											<div className={cx(s.menuHeight, 'swithMobile')}>
												<Switch
													switchOnLabel={<FormattedMessage {...messages.available} />}
													switchOffLabel={<FormattedMessage {...messages.unavailable} />}
													handleSwitch={this.handleSwitch}
													value={shopStatus}
													component={'HeaderAfterLogin'}
												/>
											</div>
										</div>
									</li>
									<li className={cx(s.listSection, !isProfileMenu && location.startsWith('/store/dashboard') ? s.active : '')}>
										<Link to="/store/dashboard" className={cx(s.sideNavitem, 'sideNavitemStoreRTL')} onClick={() => {
											this.closeMenu()
											isProfileMenu && this.setState({ isProfileMenu: !isProfileMenu })
										}}>
											<span>
												<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
													<use xlinkHref={dashboardIcon + '#dashboard'}></use>
												</svg>
											</span>
											<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
												<FormattedMessage {...messages.dashboard} />
											</span>
										</Link>
									</li>
									<li className={cx(s.listSection, !isProfileMenu && (location.startsWith('/store/order-management/') || location.startsWith('/store/order-details/upcoming') || location.startsWith('/store/order-details/previous')) ? s.active : '')}>
										<Link to="/store/order-management/upcoming" className={cx(s.sideNavitem, 'sideNavitemStoreRTL')} onClick={() => {
											this.closeMenu()
											isProfileMenu && this.setState({ isProfileMenu: !isProfileMenu })
										}}>
											<span>
												<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
													<use xlinkHref={OrderManageIcon + '#ordermanagement'}></use>
												</svg>
											</span>
											<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
												<FormattedMessage {...messages.orderManageMent} />
											</span>
										</Link>
									</li>
									<li className={cx(s.listSection, !isProfileMenu && location.startsWith('/store/menu') ? s.active : '')}>
										<Link to="/store/menu" className={cx(s.sideNavitem, 'sideNavitemStoreRTL')} onClick={() => {
											this.closeMenu()
											isProfileMenu && this.setState({ isProfileMenu: !isProfileMenu })
										}}>
											<span>
												<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
													<use xlinkHref={CategoryIcon + '#manageMenu'}></use>
												</svg>
											</span>
											<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
												<FormattedMessage {...messages.manageMenu} />
											</span>
										</Link>
									</li>
									<li className={cx(s.listSection, !isProfileMenu && location.startsWith('/store/preparation-time') ? s.active : '')}>
										<Link to="/store/preparation-time" className={cx(s.sideNavitem, 'sideNavitemStoreRTL')} onClick={() => {
											this.closeMenu()
											isProfileMenu && this.setState({ isProfileMenu: !isProfileMenu })
										}}>
											<span>
												<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
													<use xlinkHref={preparationTimeIcon + '#preparationTime'}></use>
												</svg>
											</span>
											<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
												<FormattedMessage {...messages.preparationTime} />
											</span>
										</Link>
									</li>
									<li className={cx(s.listSection, !isProfileMenu && location.startsWith('/store/operating-hours') ? s.active : '')}>
										<Link to="/store/operating-hours" className={cx(s.sideNavitem, 'sideNavitemStoreRTL')} onClick={() => {
											this.closeMenu()
											isProfileMenu && this.setState({ isProfileMenu: !isProfileMenu })
										}}>
											<span>
												<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
													<use xlinkHref={operationHoursIcon + '#operationHours'}></use>
												</svg>
											</span>
											<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
												<FormattedMessage {...messages.operationHours} />
											</span>
										</Link>
									</li>
									<li className={cx(s.listSection, !isProfileMenu && (location.startsWith('/store/payout') || location.startsWith('/store/addpayout')) ? s.active : '')}>
										<Link to="/store/payout" className={cx(s.sideNavitem, 'sideNavitemStoreRTL')} onClick={() => {
											this.closeMenu()
											isProfileMenu && this.setState({ isProfileMenu: !isProfileMenu })
										}}>
											<span>
												<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
													<use xlinkHref={addPayIcon + '#payout'}></use>
												</svg>
											</span>
											<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
												<FormattedMessage {...messages.addPayout} />
											</span>
										</Link>
									</li>

									<li className={cx(s.listSection, !isProfileMenu && (location.startsWith('/store/transaction/') || location.startsWith('/store/order-details/completed/') || location.startsWith('/store/order-details/future/')) ? s.active : '')}>
										<Link to="/store/transaction/completed" className={cx(s.sideNavitem, 'sideNavitemStoreRTL')} onClick={() => {
											this.closeMenu()
											isProfileMenu && this.setState({ isProfileMenu: !isProfileMenu })
										}}>
											<span>
												<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
													<use xlinkHref={TransactionManageIcon + '#transactionManagement'}></use>
												</svg>
											</span>
											<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
												<FormattedMessage {...messages.transactionManagement} />
											</span>
										</Link>
									</li>
									<li className={cx(s.listSection, this.handleMenuActive('profile') || isProfileMenu ? (isRTL ? 'storeActiveRTL' : s.active) : '')}>
										<div id='Profile' className={cx(s.sideNavitem, s.btnNoPadding)}>
											<Button
												className={cx(rs.button, s.noBorderBtn, s.activeMenu, s.sideNavitem, s.positionRelative, 'sideNavitemStoreRTL')}
												onClick={() => { this.setState({ isProfileMenu: !isProfileMenu }); }}>
												<span>
													<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
														<use xlinkHref={profileIcon + '#profile'}></use>
													</svg>
												</span>
												<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
													<FormattedMessage {...messages.profile} />
												</span>
												{
													this.state.isProfileMenu &&
													<div className={cx(s.displayInlineBlock, s.downArrow, 'downArrowStoreRTL')}>
														<FontAwesome.FaAngleUp className={s.navigationIcon} />
													</div>
												}

												{
													!this.state.isProfileMenu && <div className={cx(s.displayInlineBlock, s.downArrow, 'downArrowStoreRTL')}><FontAwesome.FaAngleDown className={s.navigationIcon} /></div>
												}
											</Button>
										</div>
									</li>
									<Collapse in={isProfileMenu}>
										<ul className={cx(s.droupDownSection, 'droupDownSectionRTL')}>
											{this.renderSinglePageMenu('/store/edit-profile', formatMessage(messages.editProfile), editIcon, '#editProfile', true)}
											{this.renderSinglePageMenu('/store/document', formatMessage(messages.documentUpload), DocumentUpIcon, '#document', true)}
											{this.renderSinglePageMenu('/store/password', formatMessage(messages.password), passwordIcon, '#padlock', true)}
										</ul>
									</Collapse>
									<li className={cx(s.listSection, s.hideLogout)}>
										<Button
											onClick={() => openLogoutModal()}
											className={cx(rs.button, s.noBorderBtn, s.activeMenu, s.sideNavitem, s.positionRelative, 'sideNavitemStoreRTL')}
										>
											<span>
												<svg className={cx(s.leftIcon, s.overviewIcon, s.noBorder)}>
													<use xlinkHref={LogOutIcon + '#Layer_1'}></use>
												</svg>
											</span>
											<span className={cx(s.displayInlineBlock, s.vtrMiddle)}>
												<FormattedMessage {...messages.logout} />
											</span>
										</Button>
									</li>
								</ul>
							</Nav>
						</div>
					</Navbar>
				</div>
			</div>
		);
	}
}

const mapState = state => ({
	shopStatus: state && state.account && state.account.data && state.account.data.isActive,
	userName: state?.account?.data?.profile?.firstName,
	currentLocale: state.intl.locale
});

const mapDispatch = {
	shopLogout,
	shopUserActivityChange,
	openHeaderModal,
	openLogoutModal
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(StoreSideMenu)));