import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import SignupForm from '../../../components/Shop/SignupForm/SignupForm';
import Loader from '../../../components/Common/Loader/Loader';
import s from './Signup.css';
import getCategoryCategories from './getCategoryCategories.graphql';
import getAllPartnerHomepage from '../../../routes/shop/home/getAllPartnerHomepage.graphql'; 
import getActiveDeliveryType from '../../../routes/site-admin/addCategory/getActiveDeliveryType.graphql';
class Signup extends Component {


  render() {
    const { deliveryTypes: { getActiveDeliveryType, loading } } = this.props;
    const { getCategory: { getActiveCategories }, getAllPartnerHomepage: { loading: { homeLoading }, getAllPartnerHomepage } } = this.props;
    let homepage = {};
    if (!homeLoading) {
      getAllPartnerHomepage && getAllPartnerHomepage.homePageData.map((item) => {
        homepage[item.name] = item.value
      });

    }

    return (
      <Loader type={"page"} show={loading}>
        {!loading && !homeLoading && <SignupForm deliveryTypes={getActiveDeliveryType} getCategory={getActiveCategories} homepage={homepage} />}
      </Loader>
    );
  }
};

export default compose(
  withStyles(s),
  graphql(getActiveDeliveryType, {
    name: 'deliveryTypes',
    options: {
      ssr: true,
      fetchPolicy: 'network-only'
    }
  }),
  graphql(getCategoryCategories, {
    name: 'getCategory',
    options: {
      ssr: true,
      fetchPolicy: 'network-only'
    }
  }),
  graphql(getAllPartnerHomepage, {
    name: 'getAllPartnerHomepage',
    options: {
      ssr: true,
      fetchPolicy: 'network-only'
    }
  })
)(Signup);
