import updateShopProfile from '../../../actions/shop/editProfile/updateShopProfile';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {

    let profilePicError;

    values.picture ? profilePicError = false : profilePicError = true;

    if (profilePicError) {
        return toastr.error('Error', 'Please upload store image.')
    } else {
        await dispatch(
            updateShopProfile(values)
        )
    }
}

export default submit;