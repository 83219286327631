import React from 'react';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';

import withStyles from 'isomorphic-style-loader/withStyles';
import s from './StoreHeader.css';
import { Navbar } from 'react-bootstrap';
import cx from 'classnames';

import Link from '../../../Link/Link';
import StoreNavigation from '../StoreNavigation/StoreNavigation';

import { api, logoUploadDir } from '../../../../config';

class StoreHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
    };
    this.openMenu = this.openMenu.bind(this);
    this.openClose = this.openClose.bind(this);
  }

  openMenu() {
    this.setState({
      isOpen: 1,
    })
  }

  openClose() {
    this.setState({
      isOpen: 0,
    })
  }

  render() {
    const { logo, logoHeight, logoWidth, siteName, isLogin, isOpen, socket } = this.props;

    return (
      <div className={cx(s.root, 'mainMenu', 'storeHeader', [isLogin ? 'store' : 'storeBeforeLogin'])}>
        <Navbar expand="lg" className={cx(s.navBg, [isLogin ? s.storeBg : ''], [isLogin ? 'storeBgRTL' : ''])}>
          <Navbar.Brand>
            <Link className={s.brand} to="/">
              <img
                src={api.apiEndpoint + logoUploadDir + logo}
                width={Number(logoWidth)}
                height={Number(logoHeight)}
                alt={siteName}
              />
            </Link>
          </Navbar.Brand>
          {!isLogin && <Navbar.Toggle aria-controls="basic-navbar-nav" className={cx(s.borderNone, s.outlineNone)}
            children={
              <div className={'menuToggle'} onClick={() => this.openMenu()}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
          />}
          <Navbar.Collapse id="basic-navbar-nav" className={cx({ [s.menuOpened]: this.state.isOpen == 1 }, s.justifyFlexEnd, isLogin ? 'store' : s.menuClosed)} in={isOpen}>
            <div onClick={() => this.openClose()}>
              <div className={cx(s.closeButton, 'closeButtonRTL')}> &#x2715; </div>
              <StoreNavigation socket={socket} />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

const mapState = (state) => ({
  logo: state.siteSettings.data.homeLogo,
  logoHeight: state.siteSettings.data.logoHeight,
  logoWidth: state.siteSettings.data.logoWidth,
  siteName: state.siteSettings.data.siteName,
  isLogin: state.runtime.isStoreAuthenticated
})
const mapDispatch = {}

export default compose(withStyles(s), connect(mapState, mapDispatch))(StoreHeader)