import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import {
    Button,
    Form,
    Row,
    FormGroup,
    Col,
    FormControl,
    Container
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from '../Payout.css';
import rs from '../../../storeCommon.css';
import validateStripe from './validateStripe';
import submit from './submit';
import messages from '../../../../locale/messages';
import Loader from '../../../Common/Loader';
import accountFieldSettings from './accountFieldSettings';

class Stripe extends Component {

    static defaultProps = {
        businessType: 'individual'
    };

    componentWillMount() {
        const { change } = this.props;
        change('businessType', 'individual')
    }

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Form.Group className={cx(rs.space5, 'positionRelative')}>
                <Form.Label className={'inputLabel'}>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(rs.formControlInputStore, 'formControlInputStoreRTL')} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    };

    renderSelectField = ({ input, label, type, meta: { touched, error, dirty }, children, placeholder, className }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(rs.space5, 'positionRelative')}>
                <label className={'inputLabel'}>{label}</label>
                <FormControl
                    {...input}
                    as="select"
                    placeholder={placeholder}
                    className={className}
                    type={type}
                >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    };

    render() {
        const { handleSubmit, pristine, previousPage, submitting, error, loading } = this.props;
        const { businessType, payoutCountry, payoutCurrency } = this.props;
        const { formatMessage } = this.props.intl;
        const countryField = accountFieldSettings.find(item => item.country == payoutCountry);

        return (
            <div className={cx('mainContainer', 'payoutSelectRTL')}>
                <div className={s.container}>
                    <Container fluid>
                        <Row>
                            <Col md={12} lg={12} sm={12} xs={12}>
                                <h1 className={cx(rs.storeTitleText, 'textAlignRightRTL')}>
                                    <FormattedMessage {...messages.addPayout} />
                                </h1>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <div className={s.panelBody}>
                                        <Row>
                                            <div className={cx(rs.storeNewBg, rs.space5)}>
                                                <Col lg={12} md={12} sm={12} xs={12} className={'countrySelectRTL'}>
                                                    <Field name="businessType"
                                                        className={cx(rs.formControlSelect, rs.formControlInputStore, 'formControlInputStoreRTL')}
                                                        label={formatMessage(messages.payoutType)}
                                                        component={this.renderSelectField}
                                                        defaultValue={'individual'}
                                                    >
                                                        <option value={"individual"}>{formatMessage(messages.payoutIndividual)}</option>
                                                        <option value={"company"}>{formatMessage(messages.payoutCompany)}</option>
                                                    </Field>
                                                    <Field
                                                        name="firstname"
                                                        component={this.renderField}
                                                        label={(businessType === 'individual' ? formatMessage(messages.payoutFirstName) : formatMessage(messages.payoutCompanyName))}
                                                        placeholder={(businessType === 'individual' ? formatMessage(messages.payoutFirstName) : formatMessage(messages.payoutCompanyName))}
                                                    />
                                                    {businessType && businessType === 'individual' &&
                                                        <Field
                                                            name="lastname"
                                                            component={this.renderField}
                                                            label={formatMessage(messages.payoutLastName)}
                                                            placeholder={formatMessage(messages.payoutLastName)}
                                                        />
                                                    }
                                                    {countryField && countryField.fields.length > 0 && countryField.fields.map((item) => {
                                                        return (
                                                            <Field
                                                                name={item.fieldName}
                                                                component={this.renderField}
                                                                label={formatMessage(messages[item.fieldLabel])}
                                                                placeholder={"eg: " + item.placeholder}
                                                            />)

                                                    })
                                                    }
                                                    {!countryField && payoutCurrency != 'EUR' &&

                                                        <Field
                                                            name="routingNumber"
                                                            component={this.renderField}
                                                            label={formatMessage(messages.payoutRouting)}
                                                            placeholder={"eg: 110000000"}
                                                        />
                                                    }
                                                    {!countryField &&
                                                        <div>
                                                            <Field
                                                                name="accountNumber"
                                                                component={this.renderField}
                                                                label={formatMessage(messages[payoutCurrency != 'EUR' ? 'accountNumber' : 'ibanNumber'])}
                                                                placeholder={"eg: 000123456789"}
                                                            />
                                                            <Field
                                                                name="confirmAccountNumber"
                                                                component={this.renderField}
                                                                label={formatMessage(messages[payoutCurrency != 'EUR' ? 'confirmAccountNumber' : 'confirmIbanNumber'])}
                                                                placeholder={"eg: 000123456789"}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        payoutCountry && payoutCountry === 'US' && businessType && businessType === 'individual' && <Field
                                                            name="ssn4Digits"
                                                            component={this.renderField}
                                                            label={formatMessage(messages.ssn4Digits)}
                                                            placeholder={"1234"}
                                                        />
                                                    }
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <div className={cx(s.infoBox)}>
                                                        {formatMessage(messages.payoutStripeInfo)}
                                                    </div>
                                                </Col>
                                            </div>
                                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.spaceTop2, rs.alignRightText)}>
                                                <div className={s.displayInlineBtn}>
                                                    <Form.Group className={s.formGroup}>
                                                        <div>
                                                            <Button onClick={previousPage} className={cx(rs.button, rs.btnPrimaryBorder, s.btnWidthMobile)}>
                                                                <FormattedMessage {...messages.back} />
                                                            </Button>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className={s.displayInlineBtn}>
                                                    <Form.Group className={s.formGroup}>
                                                        <div>
                                                            <Loader
                                                                type={'button'}
                                                                buttonType={'submit'}
                                                                className={cx(rs.button, rs.btnPrimary, s.btnWidthMobile)}
                                                                disabled={pristine || submitting || error}
                                                                show={loading}
                                                                label={formatMessage(messages.finish)}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
};

Stripe = reduxForm({
    form: 'PayoutForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateStripe
})(Stripe);

const selector = formValueSelector('PayoutForm');

const mapState = (state) => ({
    loading: state.loader.AddPayoutForm,
    businessType: selector(state, 'businessType'),
    payoutCountry: selector(state, 'country'),
    payoutCurrency: selector(state, 'currency')
});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(Stripe)));