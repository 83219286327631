import messages from '../../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.bannerTitle1) {
        errors.bannerTitle1 = messages.required;
    } else if (values.bannerTitle1.trim() == "") {
        errors.bannerTitle1 = messages.required;
    }

    if (!values.bannerContent1) {
        errors.bannerContent1 = messages.required;
    } else if (values.bannerContent1.trim() == "") {
        errors.bannerContent1 = messages.required;
    }

    
    return errors;
};

export default validate;