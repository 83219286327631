import React, { Component } from 'react';
import s from './ContactForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector, FieldArray } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
	Form,
	Col,
	Row,
	FormControl,
	FormGroup,
	Container
} from 'react-bootstrap';
import Link from '../../../Link/Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import { api, partnerHomepageUploadDir } from '../../../../config';
import Dropzone from '../../../Common/Dropzone/Dropzone';
import Loader from '../../../Common/Loader/Loader';
//image
import defaultIcon from '../../../../../public/Icons/defaultIconAdmin.svg';

export class ContactForm extends Component {

	constructor(props) {
		super(props)

		this.handleDropzone = this.handleDropzone.bind(this);
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
		const { formatMessage } = this.props.intl
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
				/>
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	handleDropzone(fileName, fieldName) {
		const { change } = this.props
		change(fieldName, fileName)
	};

	render() {
		const { handleSubmit, sliderImage1, sliderImage2, sliderImage3, sliderImage5, loading, submitting } = this.props;
		const { formatMessage } = this.props.intl;
		return (

			<div>
				<Container fluid>
					<h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.sliderContact)}</h1>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
						<Row>
							<Col xl={12} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
								<div className={s.blackCenterSection}>
									<Row>
										<Col lg={6} md={12} sm={12} xs={12}>
											<Form.Group className={cx(s.space3)}>
												<div className={cx(s.profileImgSection, s.profileImgWidth)}>
													<label className={s.space1}>{formatMessage(messages.sliderContactImage)}</label>
													{
														sliderImage5 &&
														<div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir}medium_${sliderImage5})` }} />
													}
													{
														!sliderImage5 &&
														<div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
													}
													<div>
														<Dropzone
															className={cx(s.btnSecondary, s.profileNoPadding)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
															inputContainer={'.dzInputContainerStoreImage'}
															inputContainerClass={'dzInputContainerStoreImage'}
															url={'/uploadPartnerHomepageImage'}
															fieldName={'sliderImage5'}
															handleDropzone={this.handleDropzone}
														/>
													</div>
												</div>
											</Form.Group>
										</Col>
										<Col lg={6} md={12} sm={12} xs={12}>
											<Field name="sliderTitle5" type="text" component={this.renderField} label={formatMessage(messages.contactUsTitle)} maxlength={250} />
											<Field name="buttonName" type="text" component={this.renderField} label={formatMessage(messages.buttonName)} maxlength={250} />
											<Field name="buttonLink" type="text" component={this.renderField} label={formatMessage(messages.buttonLink)} maxlength={250} />
										</Col>
									</Row>
								</div>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, s.bottomPadding, 'bottomPaddingRTL', 'textAlignLeftRTL')}>
								<Form.Group className={s.noMargin}>
									<div className={s.displayInlineBlock}>
										<Loader
											type={"button"}
											label={formatMessage(messages.submitButton)}
											show={loading}
											buttonType={'submit'}
											className={cx(s.button, s.btnPrimary)}
											disabled={submitting || loading}
											isSuffix={true}
										/>
									</div>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Container>
			</div>
		)
	}
}

ContactForm = reduxForm({
	form: 'ContactForm',
	onSubmit: submit,
	validate
})(ContactForm);

const selector = formValueSelector('ContactForm')

const mapState = (state) => ({
	sliderImage1: selector(state, 'sliderImage1'),
	sliderImage2: selector(state, 'sliderImage2'),
	sliderImage3: selector(state, 'sliderImage3'),
	sliderImage5: selector(state, 'sliderImage5'),
	loading: state.loader.UpdatePartnerHomepage
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ContactForm)));