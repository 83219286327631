import React from 'react';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './NewsBox.css';
import {
  Row,
  Col
} from 'react-bootstrap';

import { api, partnerHomepageUploadDir } from '../../../../config';

class NewsBox extends React.Component {

  render() {
    const { getStoreData } = this.props;

    return (
      <div className={s.bgColor}>
        <div className={s.root}>
          <div className={s.container}>
            <Row className={s.alignCenter}>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className={s.contentSection}>
                  <h3 className={s.title}>
                    {getStoreData("sliderTitle5")}
                  </h3>
                  <a href={getStoreData("buttonLink")} target="_blank" className={cx(s.btn, s.btnPrimary)}>
                    {getStoreData("buttonName")}
                  </a>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className={s.positionRelative}>
                  <div 
                    className={s.tapBannerImage} 
                    style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir + getStoreData("sliderImage5")})` }} 
                  >
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(NewsBox));