import { toastr } from 'react-redux-toastr';
import updateMobileSettings from '../../../actions/siteadmin/SiteSettings/updateMobileSettings'

async function submit(values, dispatch) {

    if (values.isRequestTimerToneEnable == '1' && !values.requestTimeTone) {
        toastr.error('Error!', 'Please upload a request notification sound.');
        return;
    }

    await dispatch(
        updateMobileSettings(
            values.distanceUnits,
            values.appForceUpdate,
            values.userAndroidVersion,
            values.userIosVersion,
            values.driverAndroidVersion,
            values.driverIosVersion,
            values.preferredDelivery,
            values.requestTimeTone,
            values.isRequestTimerToneEnable,
            values.openAppOnRequest,
            values.requestToneFile,
            values.orderAcceptInterval
        )
    )
}

export default submit;