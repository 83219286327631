import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import AddCategoryForm from '../../../components/SiteAdmin/Category/AddCategoryForm';
import Loader from '../../../components/Common/Loader/Loader';
import s from './EditCategory.css';
import cx from 'classnames';
//meassage
import messages from '../../../locale/messages';
import getCategory from './getCategory.graphql';
import getActiveDeliveryType from '../addCategory/getActiveDeliveryType.graphql';
export class EditCategory extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired
    }
    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const { data: { loading, getCategory }, deliveryTypes } = this.props;

        let initialValues = {};

        if (!loading && getCategory && getCategory.category) {
            initialValues = {
                id: getCategory.category.id,
                categoryName: getCategory.category.categoryName,
                categoryImage: getCategory.category.categoryImage,
                isActive: getCategory.category.isActive,
                description: getCategory.category.description,
                deliveryType: getCategory.category.deliveryType
            }
        }
        if (loading) {
            return <div><Loader type={"page"} show={loading}></Loader></div>
        } else {
            return (
                <div className={s.root}>
                    <div className={s.container}>
                       <div className={cx(s.heading, 'textAlignRightRTL')}>
                            <FormattedMessage {...messages.EditCategory} />
                        </div>
                        <div>
                            <AddCategoryForm deliveryTypes={deliveryTypes} initialValues={initialValues} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default compose(withStyles(s),
    graphql(getCategory, {
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only',
            ssr: true
        }),
    }),
    graphql(getActiveDeliveryType, {
        name: 'deliveryTypes',
        options: {
            ssr: true,
            fetchPolicy: 'network-only'
        }
    })
)(EditCategory)
