import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag'
import { flowRight as compose } from 'lodash'
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

// Style
import {
  FormGroup,
  Col,
  FormControl,
  Row,
  Container
} from 'react-bootstrap';
import cx from 'classnames';
import bt from '../../commonStyle.css'
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ChangeAdminForm.css';
import Loader from '../../Common/Loader';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { injectIntl } from 'react-intl';
import messages from '../../../locale/messages'
import ShowPassword from '../../../../public/StoreIcon/pswVisible.svg';
import HidePassword from '../../../../public/StoreIcon/pwdHidden.svg';


import validate from './validate';

class ChangeAdminForm extends Component {


  constructor(props) {
    super(props);
    this.state = {
      showPassword: ''
    };
    this.submitForm = this.submitForm.bind(this);
  }

  static defaultProps = {
    loading: false
  };

  handleChange(fieldName) {
    this.setState({ showPassword: fieldName === this.state.showPassword ? "" : fieldName })
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, note, showPassword }) => {
    const { formatMessage } = this.props.intl
    return (
      <FormGroup className={cx(s.space5, 'positionRelative')}>
            <label className={'inputLabel'}>{label}</label>
            <FormControl {...input} placeholder={label} type={showPassword === input.name ? input : type} className={className} />
            {
              type == 'password' && <span className={cx(s.pwdImage, 'pwdImageRTL')} onClick={() => this.handleChange(input.name)}>
                {showPassword === input.name ? <img src={ShowPassword} /> : <img src={HidePassword}/>}
              </span>
            }
            {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            {
              note && <p className={s.subtext}>{note}</p>
            }
      </FormGroup>
    );
  }

  async submitForm(values, dispatch) {

    dispatch(setLoaderStart('changePassword'));

    const { mutate } = this.props;
    const { data } = await mutate({ variables: values });

    if (data && data.changeAdminUser) {
      if (data.changeAdminUser.status == '200') {
        toastr.success("Changed Successfully!", "Admin details changed successfully!");
      } else if (data.changeAdminUser.status === 'email') {
        toastr.error("Failed to update!", "Oops! this email address is already exist.");
      } else {
        toastr.error("Oops!", "Your changes to admin details failed!");
      }
    }

    dispatch(setLoaderComplete('changePassword'));
    dispatch(reset('ChangeAdminForm'));

  }

  render() {

    const { error, handleSubmit, submitting, dispatch, title, isSuperAdmin, loading } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <Container fluid>
          <form onSubmit={handleSubmit(this.submitForm)}>
            <Row>
              <Col lg={6} md={12} sm={12} xs={12} className={s.blackCenter}>
                <div className={s.blackCenterSection}>
                  {error && <strong>{error}</strong>}
                  {
                    <Field
                      name="email"
                      type="text"
                      component={this.renderFormControl}
                      label={formatMessage(messages.email)}
                      note={formatMessage(messages.emailNotes)}
                      className={cx(s.formControlInput)}
                    />
                  }
                  <Field name="password" type="password" component={this.renderFormControl} label={formatMessage(messages.password)} className={cx(s.formControlInput, 'passwordInput')} showPassword={this.state.showPassword} />
                  <Field name="confirmPassword" type="password" component={this.renderFormControl} label={formatMessage(messages.confirmPassword)} className={cx(s.formControlInput, 'passwordInput')} showPassword={this.state.showPassword} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={6} className={cx(s.textAlignRight, s.btnPadding, 'textAlignLeftRTL', 'btnPaddingRTL')}>
                <Loader
                  type={"button"}
                  label={formatMessage(messages.submitButton)}
                  show={loading}
                  buttonType={'submit'}
                  className={cx(s.button, s.btnPrimary)}
                  disabled={submitting || loading}
                  isSuffix={true}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    );
  }

}

ChangeAdminForm = reduxForm({
  form: 'ChangeAdminForm',
  validate
})(ChangeAdminForm);

const mapState = (state) => ({
  isSuperAdmin: state.runtime.isSuperAdmin,
  loading: state.loader.changePassword
});

const mapDispatch = {};

export default injectIntl(compose(
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(gql`
    mutation changeAdminUser($email: String, $password: String!) {
      changeAdminUser (email: $email, password: $password) {
        status
      }
    }
  `),
)(ChangeAdminForm));