import histroy from '../../../history';
import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';

import updateMutation from './updateDelivery.graphql'

export function updateDeliveryType(values) {

  return async (dispatch, getState, { client }) => {


    dispatch(setLoaderStart('updateDelivery'));

    const { data } = await client.mutate({
      mutation: updateMutation,
      variables: {
        id: values.id,
        deliveryName: values.deliveryName,
        deliveryDescription: values.deliveryDescription,
        imageName: values.imageName,
        bannerName: values.bannerName,
        status: (values.status === true || values.status === 'true') ? 1 : 0,
        textColor: values.textColor,
        backgroundColor: values.backgroundColor,
        iconBackgroundColor: values.iconBackgroundColor,
      }
    });

    dispatch(setLoaderComplete('updateDelivery'));

    if (data && data.updateDeliveryType && data.updateDeliveryType.status === 200) {
      toastr.success('Success!', 'The delivery type has been ' + (values.id ? 'updated' : 'added') + ' successfully.');
      histroy.push('/siteadmin/delivery-type');
    } else if (data && data.updateDeliveryType && data.updateDeliveryType.status !== 200) {
      toastr.error('Error!', data.updateDeliveryType.errorMessage);
    } else {
      toastr.error('Error!', "Something went wrong. Please try again.");
    }
  }
}