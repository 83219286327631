import {
  OPEN_ADMIN_ROLES_MODAL,
  CLOSE_ADMIN_ROLES_MODAL,
  OPEN_ADMIN_USER_MODAL,
  CLOSE_ADMIN_USER_MODAL,
  OPEN_COLOR_MODAL,
  CLOSE_COLOR_MODAL
} from '../constants';

export default function adminModalStatus(state = {}, action) {
  switch (action.type) {

    case OPEN_ADMIN_ROLES_MODAL:
      return {
        ...state,
        adminRolesModal: action.payload.adminRolesModal,
        adminRolesModalType: action.payload.adminRolesModalType
      };

    case CLOSE_ADMIN_ROLES_MODAL:
      return {
        ...state,
        adminRolesModal: action.payload.adminRolesModal,
        adminRolesModalType: null
      };

    case OPEN_ADMIN_USER_MODAL:
      return {
        ...state,
        adminUserModal: action.payload.adminUserModal,
        adminUserModalType: action.payload.adminUserModalType
      };

    case CLOSE_ADMIN_USER_MODAL:
      return {
        ...state,
        adminUserModal: action.payload.adminUserModal,
        adminUserModalType: null
      };

    case OPEN_COLOR_MODAL:
      return {
        ...state,
        isOpenColor: action.payload.isOpenColor,
        fieldName: action.payload.fieldName,
        color: action.payload.color,
      };

    case CLOSE_COLOR_MODAL:
      return {
        ...state,
        isOpenColor: action.payload.isOpenColor,
      };

    default:
      return {
        ...state,
      };
  }
}
