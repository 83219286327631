import {
	DELIVERY_TYPE_UPDATE_SUCCESS,
	DELIVERY_TYPE_UPDATE_START,
	DELIVERY_TYPE_UPDATE_ERROR
} from '../../../constants';
import { toastr } from 'react-redux-toastr';
import deleteMutation from './deleteDelivery.graphql'

export default function deleteDeliveryType(id) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: DELIVERY_TYPE_UPDATE_START
		});

		try {
			let errorMessage;

			const { data } = await client.mutate({
				mutation: deleteMutation,
				variables: {
					id
				}
			});

			if (data && data.deleteDeliveryType && data.deleteDeliveryType.status === 200) {
				dispatch({
					type: DELIVERY_TYPE_UPDATE_SUCCESS
				});

				toastr.success('Success', `The delivery type has been deleted.`);

				return {
					status: 200
				};

			} else {
				dispatch({
					type: DELIVERY_TYPE_UPDATE_ERROR
				});

				errorMessage = (data && data.deleteDeliveryType && data.deleteDeliveryType.errorMessage) || "Oops! Something went wrong. Please try again.";
				toastr.error('Error!', errorMessage);
				return {
					status: 400
				};
			}
		} catch (err) {
			toastr.error('Error!', err);
			dispatch({
				type: DELIVERY_TYPE_UPDATE_ERROR
			});
			return {
				status: 400
			};
		}

	}
};