import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Button,
	FormGroup,
	Col,
	Row,
	FormControl,
	Form
} from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Link from '../../Link/Link';

import s from './LoginForm.css';
import rs from '../../storeCommon.css';

import { openForgotPasswordModal, closeLoginModal } from '../../../actions/modalActions';
import messages from '../../../locale/messages';
import validate from './validate';
import submit from './submit';
class LoginForm extends React.Component {
	static propTypes = {
		formatMessage: PropTypes.func
	}

	renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div>
				<FormControl {...input} placeholder={label} type={type} className={className} />
				{touched && error && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</div>
		);
	}


	render() {
		const { error, handleSubmit, submitting, openForgotPasswordModal, closeLoginModal } = this.props;
		const { formatMessage } = this.props.intl;
		return (
			<div className={s.padding}>
				<Form onSubmit={handleSubmit(submit)} className={'store'}>
					{error && <span className={cx(rs.errorMessage, s.errorMsg)}>{error}</span>}
					<div>
						<FormGroup className={s.formGroup}>
							<Form.Label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.email)} </Form.Label>
							<Field name="email" label={formatMessage(messages.email)}
								component={this.renderFormControl} placeholder={formatMessage(messages.email)}
								className={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
							/>
						</FormGroup>

					</div>
					<div className={s.marginTop}>
						<FormGroup className={s.formGroup}>
							<Form.Label className={cx(s.signupLabel, 'signupLabelRTL')}>{formatMessage(messages.password)} </Form.Label>
							<Field name="password" type="password" label={formatMessage(messages.password)}
								component={this.renderFormControl} placeholder={formatMessage(messages.password)}
								className={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
							/>
						</FormGroup>
					</div>

					<Col xs={12} sm={12} md={12} lg={12} className={s.textAlignCenter}>
						<a onClick={openForgotPasswordModal} className={s.modalCaptionLink}>
							<FormattedMessage {...messages.cantSignIn} />
						</a>
					</Col>

					<FormGroup className={s.formGroup}>
						<Button className={cx(s.button, s.btnPrimaryBorder, s.btnLarge)} block type="submit" disabled={submitting}>
							{formatMessage(messages.signin)}
						</Button>
					</FormGroup>

					<FormGroup className={cx(s.formGroup, s.formSection)}>
						<div className={s.dontSignup} onClick={closeLoginModal}>
							<FormattedMessage {...messages.dontSignup} /> <Link to='/store/signup' className={s.signupColor}><span><FormattedMessage {...messages.signup} /></span></Link>
						</div>
					</FormGroup>

				</Form>
			</div>
		)
	}
}

LoginForm = reduxForm({
	form: 'LoginForm',
	validate,

})(LoginForm);

const mapState = (state) => ({
});

const mapDispatch = {
	change,
	openForgotPasswordModal,
	closeLoginModal
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(LoginForm)));