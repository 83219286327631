import gql from 'graphql-tag';
import {
    SHOP_USER_LOGOUT_START,
    SHOP_USER_LOGOUT_SUCCESS,
    SHOP_USER_LOGOUT_ERROR,
    SET_RUNTIME_VARIABLE
} from '../../../constants/index';
import history from '../../../history';

export function shopLogout() {
    return async (dispatch, getState, { client }) => {

        const query = gql`
        query {
            shopUserLogout {
                status
            }
          }
        `; 

        dispatch({
            type: SHOP_USER_LOGOUT_START
        });

        try {
            const { data } = await client.query({
                query,
                fetchPolicy: 'network-only'
            })
            if (data && data.shopUserLogout && data.shopUserLogout.status === 200) {

                await history.push('/store');

                await dispatch({
                    type: SHOP_USER_LOGOUT_SUCCESS
                });

                await dispatch({
                    type: SET_RUNTIME_VARIABLE,
                    payload: {
                        name: 'isStoreAuthenticated',
                        value: false
                    }
                });


            } else {
                dispatch({
                    type: SHOP_USER_LOGOUT_ERROR
                });
            }
        } catch (error) {
            dispatch({
                type: SHOP_USER_LOGOUT_ERROR
            });
            return false;
        }
        return true;
    }
}