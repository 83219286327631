import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import messages from '../../../locale/messages';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './PreparationTime.css';
import rs from '../../storeCommon.css';
import {
    Col,
    Row,
    Form,
    Button,
    FormGroup
} from 'react-bootstrap';

import { generateTimes } from '../../../helpers/generateTimes';

//Images
import closeIcon from '../../../../public/StoreIcon/closeIconRes.png';


class PreparationTimeList extends React.Component {

    constructor(props) {
        super(props);

        this.handleIncrement = this.handleIncrement.bind(this);
        this.handleDecrement = this.handleDecrement.bind(this);
    }


    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <Form.Group>
                {/* <Form.Label>{label}</Form.Label> */}
                <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    };

    renderSelectField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, children }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx('storeActiveSelect')}>
                {/* <Form.Label>{label}</Form.Label> */}
                <Form.Control as="select" {...input} className={fieldClass}>
                    {children}
                </Form.Control>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        )
    }

    renderPreparationTime = ({ fields }) => {
        const { preparationTimeValidation } = this.props;
        const { formatMessage } = this.props.intl;
        let startTimeLookups = generateTimes(0, 1410);
        let endTimeLookups = generateTimes(30, 1410);
        let isAlreadyExist = false;

        preparationTimeValidation && preparationTimeValidation.map((item, index, arr) => {

            if (parseFloat(item.endTime) < parseFloat(item.startTime)) {
                isAlreadyExist = true;
            } else if (parseFloat(item.endTime) == parseFloat(item.startTime)) {
                isAlreadyExist = true;
            }

            let checkError = arr.filter((match) => {
                if (match.operationDay == item.operationDay) {
                    if (parseFloat(match.startTime) == parseFloat(item.startTime)) {
                        return true;
                    } else if (parseFloat(match.endTime) == parseFloat(item.endTime)) {
                        return true;
                    } else if (parseFloat(item.startTime) > parseFloat(match.startTime) && parseFloat(item.startTime) < parseFloat(match.endTime)) {
                        return true;
                    }
                } else {
                    return false
                }
            });

            if (isAlreadyExist) {
                isAlreadyExist = true;
            } else {
                isAlreadyExist = checkError.length > 1 ? true : false
            }

        })

        let operationDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        return (
            <div>


                {fields && fields.map((preparationTime, index) => {
                    
                    return (
                        <div>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.space4)}>
                                    <div>
                                        <div className={s.preparGrid}>
                                            <div className={s.minutesSection}>
                                                <div className={cx(s.addSection, s.addMargin)}>
                                                    <div className={cx(s.displayInlineSection, s.addPlus, s.vtrTop)}>
                                                        <Button className={cx(rs.preparDecreseBtn, 'disableTimeBtn')} onClick={() => {
                                                            this.handleDecrement(fields.get(index).estimatedTime, index);
                                                            }} disabled={fields.get(index).estimatedTime <= 5 ? true : false}>-</Button>
                                                    </div>
                                                    <div className={cx(s.displayInlineSection, s.addmainSection, s.addTextWidth)}>
                                                        {fields.get(index).estimatedTime} {formatMessage(messages.minutes)}
                                                    </div>
                                                    <div className={cx(s.displayInlineSection, s.addDec, s.vtrTop)}>
                                                        <Button className={cx(rs.preparAddBtn, 'disableTimeBtn')} onClick={() => {
                                                            this.handleIncrement(fields.get(index).estimatedTime, index);
                                                        }} disabled={fields.get(index).estimatedTime >= 55 ? true : false}>+</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={s.timeSlotSection}>
                                                <Field
                                                    fieldClass={s.seleSection}
                                                    name={`${preparationTime}.operationDay`}
                                                    component={this.renderSelectField}
                                                    label={formatMessage(messages.daysLabel)}
                                                >
                                                    {
                                                        operationDay && operationDay.length > 0 && operationDay.map((item, key) => {
                                                            return (<option key={key} value={item}>{formatMessage(messages[item])}</option>)
                                                        })
                                                    }
                                                </Field>
                                            </div>
                                            <div className={s.timeSlotSection}>
                                                <Field
                                                    fieldClass={s.seleSection}
                                                    name={`${preparationTime}.startTime`}
                                                    component={this.renderSelectField}
                                                    label={formatMessage(messages.startTimeLabel)}
                                                >
                                                    {
                                                        startTimeLookups && startTimeLookups.length > 0 && startTimeLookups.map((item, key) => {
                                                            return (<option key={key} value={item.value}>{item.label}</option>)
                                                        })
                                                    }
                                                </Field>
                                            </div>
                                            <div className={s.timeSlotSection}>
                                                <Field
                                                    fieldClass={s.seleSection}
                                                    name={`${preparationTime}.endTime`}
                                                    component={this.renderSelectField}
                                                    label={formatMessage(messages.endTimeLabel)}
                                                >
                                                    {
                                                        endTimeLookups && endTimeLookups.length > 0 && endTimeLookups.map((item, key) => {
                                                            return (<option key={key} value={item.value}>{item.label}</option>)
                                                        })
                                                    }
                                                </Field>
                                            </div>
                                            <div className={cx('storeActiveSelect', s.timeSlotSection)}>
                                                <Field name={`${preparationTime}.isActive`} component="select" className={cx(rs.storeSelectInput, s.seleSection, s.borderColor)}>
                                                    <option value={1}>{formatMessage(messages.active)}</option>
                                                    <option value={0}>{formatMessage(messages.inactive)}</option>
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={cx(s.closeBtnMargin, 'closeBtnMarginRTL')}>
                                            <Button onClick={() => fields.remove(index)} className={s.removeBtnSection}>
                                                <img src={closeIcon} />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                })}
                <div>
                    {isAlreadyExist && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(messages.timeslotExist)}</span>}
                </div>
                <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.space2, rs.alignRightText, s.noPadding, 'textAlignLeftRTL')}>
                    <Button className={cx(rs.button, rs.btnPrimaryBorder)} onClick={() => {
                        if (fields.length > 0) {
                            let currentValue = fields.getAll()[fields.length - 1]
                            let startTimeValue = parseFloat(currentValue.endTime) < 23.5 ? parseFloat(currentValue.endTime) + 0.5 : 0
                            let endTimeValue = startTimeValue + 0.5

                            fields.push({ estimatedTime: 5, startTime: startTimeValue, endTime: endTimeValue, operationDay: 'Sunday', isActive: 1 })

                        } else {
                            fields.push({ estimatedTime: 5, startTime: 0, endTime: 0.5, operationDay: 'Sunday', isActive: 1 })
                        }
                    }}>{formatMessage(messages.addMore)}</Button>
                </Col>
            </div>
        )

    }

    handleIncrement(value, index) {
        const { change, formName } = this.props
        let estimatedTime = value + 5;
        if (estimatedTime <= 55) {
            change(`${formName}`, `preparationTime[${index}].estimatedTime`, estimatedTime)
        }

    }

    handleDecrement(value, index) {
        const { change, formName } = this.props
        let estimatedTime = value - 5;
        if (estimatedTime >= 5) {
            change(`${formName}`, `preparationTime[${index}].estimatedTime`, estimatedTime)
        }
    }

    render() {
        const { fieldName } = this.props;

        return (
            <div >
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className={cx(s.spaceTop1)}>
                        <Form.Group className={s.formGroup}>
                            <FieldArray name={`${fieldName}`} rerenderOnEveryChange={true} component={this.renderPreparationTime} onChange={() => ''} />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        )
    }
}

const selector = formValueSelector('PreparationTime');

const mapState = (state) => ({
    preparationTimeValidation: selector(state, 'preparationTime')
});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(PreparationTimeList)));