exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #F86540;\n  --btn-boxShadow-bg: #F86540;\n  --btn-bordercolor-bg: #F86540;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #F86540;\n  --title-color: #212121;\n  --content-color: #212121;\n\n}\n\n._3kpJn{\n  font-size: 18px;\n  color: #F86540;\n}\n\n._19MGc{\n  font-size: 48px;\n}\n\n._3kpJn span {\n  -webkit-animation-name: _3uUYR;\n          animation-name: _3uUYR;\n  -webkit-animation-duration: 1.4s;\n          animation-duration: 1.4s;\n  -webkit-animation-iteration-count: infinite;\n          animation-iteration-count: infinite;\n  -webkit-animation-fill-mode: both;\n          animation-fill-mode: both;\n  color: #F86540;\n}\n\n._3kpJn span:nth-child(2){\n  -webkit-animation-delay: .2s;\n          animation-delay: .2s;\n\n}\n\n._3kpJn span:nth-child(3){\n  -webkit-animation-delay: .4s;\n          animation-delay: .4s;\n}\n\n@-webkit-keyframes _3uUYR{\n  0% {\n    opacity: .2;\n  }\n\n  20%{\n    opacity: 1;\n\n  }\n\n  100%{\n    opacity: .2;\n  }\n}\n\n@keyframes _3uUYR{\n  0% {\n    opacity: .2;\n  }\n\n  20%{\n    opacity: 1;\n\n  }\n\n  100%{\n    opacity: .2;\n  }\n}\n\n.FhA8T{\n  text-align: center;\n}\n\n._2isBS{\n  color: #F86540;\n  font-size: 48px;\n}\n\n\n\n\n\n\n", ""]);

// exports
exports.locals = {
	"saving": "_3kpJn",
	"savingDots": "_19MGc",
	"blink": "_3uUYR",
	"textCenter": "FhA8T",
	"iconColor": "_2isBS"
};