exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._299PJ{\n  background: #fff;\n  text-align: center;\n  color: #212121;\n}", ""]);

// exports
exports.locals = {
	"colorBg": "_299PJ"
};