import gql from 'graphql-tag';
import { change } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import {
	MOBILE_SETTINGS_UPLOAD_START,
	MOBILE_SETTINGS_UPLOAD_ERROR,
	MOBILE_SETTINGS_UPLOAD_SUCCESS
} from '../../../constants/index';
import mobileSettings from './mobileSettings.graphql';
import { api } from '../../../config';

export default function updateMobileSettings(
	distanceUnits, appForceUpdate,
	userAndroidVersion, userIosVersion,
	driverAndroidVersion, driverIosVersion,
	preferredDelivery, requestTimeTone, isRequestTimerToneEnable,
	openAppOnRequest, requestToneFile, orderAcceptInterval) {

	return async (dispatch, getState, { client }) => {

		dispatch({
			type: MOBILE_SETTINGS_UPLOAD_START
		})

		try {

			const { data } = await client.mutate({
				mutation: mobileSettings,
				variables: {
					distanceUnits,
					appForceUpdate,
					userAndroidVersion,
					userIosVersion,
					driverAndroidVersion,
					driverIosVersion,
					preferredDelivery,
					requestTimeTone,
					isRequestTimerToneEnable,
					openAppOnRequest,
					requestToneFile,
					orderAcceptInterval
				}
			})

			if (data && data.updateMobileSettings && data.updateMobileSettings.status == 200) {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_SUCCESS
				})
				toastr.success('Success', 'Mobile settings changes have been successfully applied')
			} else {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_ERROR
				})
				toastr.error('Oops!', 'Something went wrong' + data && data.updateMobileSettings && data.updateMobileSettings.errorMessage)
			}
		} catch (err) {
			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_ERROR
			})
			toastr.error('Oops!', 'Something went wrong' + err)
		}

	}
}

export function deleteTone(fileName) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: MOBILE_SETTINGS_UPLOAD_START
		})

		try {

			dispatch(change("MobileSettingsForm", "requestTimeTone", null));

			const url = api.apiEndpoint + "/deleteTone";
			const resp = await fetch(url, {
				method: 'post',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ fileName }),
			});

			const { status } = await resp.json();

			if (status == 200) {

				const mutation = gql`
				mutation updateTone {
						updateTone {
						status
						}
					}
				`
				const { data } = await client.mutate({
					mutation
				})

				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_SUCCESS
				})

			} else {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_ERROR
				})
			}

		} catch (err) {
			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_ERROR
			})
			showToaster({ messageId: 'catchMessage', requestContent: { content: err }, toasterType: 'error' });
			return true;
		}
	}
}