import React, { Component } from 'react';
import s from './PromoCodeForm.css';
import { Field, reduxForm, getFormValues, change, formValueSelector, initialize } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl, FormattedMessage } from 'react-intl';
//Style
import cx from 'classnames';
import {
	Form,
	Col,
	Row,
	InputGroup,
	Container
} from 'react-bootstrap';
import Link from '../../../Link';
import messages from '../../../../locale/messages';
import validate from './validate';
import { normalizePromoCode } from './normalize';

import DatePicker from '../../../Common/DatePicker';
import Loader from '../../../Common/Loader';
import ImageDropzone from './ImageDropzone';

import { api, promoCodeImageUploadDir } from '../../../../config';

//image
//image
import defaultIcon from '../../../../../public/Icons/defaultIconAdmin.svg';

export class PromoCodeForm extends Component {

	static defaultProps = {
		currency: 'USD',
		promoId: null,
		promoCurrency: 'USD',
		expiryDate: null
	}

	componentDidMount() {
		const { initialValues, initialize } = this.props;
		if (!initialValues) {
			initialize({ type: 1 })
		}
	}

	renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	renderFieldDiscount = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, promoType, promoCurrency }) => {
		const { formatMessage } = this.props.intl;
		return (
			<div className={cx('inputFormAddon', 'addonBorder')}>
				<Form.Group className={cx(s.space5, 'positionRelative')}>
					<Form.Label className={cx('inputLabel')}>{label}</Form.Label>
					<InputGroup>
						{
							promoType == 2 && <InputGroup.Append>
								<InputGroup.Text>
									{
										promoCurrency
									}
								</InputGroup.Text>
							</InputGroup.Append>
						}
						<Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, promoType != 2 && 'inputLeft')} />
						{
							promoType != 2 && <InputGroup.Append className={'promoCodeFeeAddon'}>
								<InputGroup.Text>%</InputGroup.Text>
							</InputGroup.Append>
						}
					</InputGroup>
					{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
				</Form.Group>
			</div>
		)
	}

	renderTextAreaField = ({ input, label, type, meta: { touched, error }, children, labelClass, fieldClass, placeholder }) => {
		const { formatMessage } = this.props.intl;
		return (
			<Form.Group className={cx(s.space5, 'positionRelative')}>
				<Form.Label className={'inputLabel'}>{label}</Form.Label>
				<Form.Control as="textarea" rows="4" {...input} placeholder={placeholder} type={type} className={fieldClass} />
				{touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
			</Form.Group>
		)
	}

	render() {
		const { handleSubmit, currency, promoId, promoType, promoCurrency, expiryDate, loading, submitting, promoCodeImage } = this.props;
		const { formatMessage } = this.props.intl;

		return (
			<div className={cx(s.space5, s.spaceTop5)}>
				<Container fluid>
					<Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
						<Row>
							<Col xl={12} lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
								<div className={s.blackCenterSection}>
									<Row>
										<Col lg={6} md={12} sm={12} xs={12}>
											<Form.Group className={s.space3}>
												<div className={cx(s.centerFlex, s.profileImgSection)}>
													<label>{formatMessage(messages.promoCodeImage)}</label>
													{
														promoCodeImage && <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + promoCodeImageUploadDir}${promoCodeImage})` }} />
													}
													{
														!promoCodeImage && <div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
													}
													<div>
														<ImageDropzone
															key={'promoCodeImage'}
															className={cx(s.btnSecondary, s.profileNoPadding)}
															subTextClass={s.subText}
															subText={formatMessage(messages.maximumUploadSizeLabel)}
															defaultMessage={formatMessage(messages.chooseFile)}
														/>
													</div>
												</div>
											</Form.Group>
										</Col>
										<Col lg={6} md={12} sm={12} xs={12}>
											<Row>
												<Col lg={12} md={12} sm={12} xs={12}>
													<Field
														name="title"
														type="text"
														placeholder={formatMessage(messages.title)}
														component={this.renderField}
														label={formatMessage(messages.title)}
														labelClass={s.labelText}
														fieldClass={s.formControlInput}
													/>
												</Col>
												<Col lg={6} md={6} sm={6} xs={12}>
													<Form.Group className={s.space3}>
														<div>
															<Field
																name="code"
																type="text"
																placeholder={formatMessage(messages.code)}
																component={this.renderField}
																label={formatMessage(messages.code)}
																labelClass={s.labelText}
																fieldClass={s.formControlInput}
																normalize={normalizePromoCode}
															/>
														</div>
													</Form.Group>
												</Col>
												<Col lg={6} md={6} sm={6} xs={12}>
													<Form.Group className={cx(s.space5, 'positionRelative')}>
														<label className={'inputLabel'}>{formatMessage(messages.promoType)}</label>
														<Field name="type" className={cx(s.formControlSelect, s.formControlInput)} component="select">
															<option value={0}>{formatMessage(messages.percentage)}</option>
															<option value={1}>{formatMessage(messages.fixedAmount)}</option>
														</Field>
													</Form.Group>
												</Col>
											</Row>
											<Form.Group className={s.space3}>
												<Field
													name="promoValue"
													type="text"
													placeholder={formatMessage(messages.discount)}
													component={this.renderFieldDiscount}
													label={formatMessage(messages.discount)}
													labelClass={s.labelText}
													fieldClass={s.formControlInput}
													promoType={promoType}
													promoCurrency={promoCurrency}
												/>
											</Form.Group>
											<Form.Group className={cx(s.space5, 'positionRelative')}>
												<label className={'inputLabel'}>{formatMessage(messages.currency)}</label>
												<Field name="currency" className={cx(s.formControlSelect, s.formControlInput)} component="select">
													{
														currency && currency.availableCurrencies && currency.availableCurrencies.map(currency => (
															<option key={currency.id} value={currency.symbol}>{currency.symbol}</option>
														))
													}
												</Field>
											</Form.Group>
											<Row>
												<Col lg={6} md={6} sm={6} xs={12}>
													<Form.Group className={cx(s.space5, 'positionRelative', 'promoCodeDatePicker')}>
														<label className={'inputLabel'}>{formatMessage(messages.expireDate)}</label>
														<DatePicker
															placeholder={formatMessage(messages.expireDate)}
															formName={'PromoCodeForm'}
															fieldName={'expiryDate'}
															initialDate={expiryDate}
														/>
													</Form.Group>
												</Col>
												<Col lg={6} md={6} sm={6} xs={12}>
													<Form.Group className={cx(s.space5, 'positionRelative')}>
														<label className={'inputLabel'}>{formatMessage(messages.privacyType)}</label>
														<Field name="isPrivate" className={cx(s.formControlSelect, s.formControlInput)} component="select">
															<option value={1}>{formatMessage(messages.public)}</option>
															<option value={2}>{formatMessage(messages.private)}</option>
														</Field>
													</Form.Group>
												</Col>
											</Row>

											{
												promoId &&
												<Form.Group className={cx(s.space5, 'positionRelative')}>
													<label className={'inputLabel'}>{formatMessage(messages.status)}</label>
													<Field name="isEnable" className={cx(s.formControlSelect, s.formControlInput)} component="select">
														<option value="true">{formatMessage(messages.active)}</option>
														<option value="false">{formatMessage(messages.inactive)}</option>
													</Field>
												</Form.Group>

											}
											<Field
												name="description"
												type="text"
												placeholder={formatMessage(messages.description)}
												component={this.renderTextAreaField}
												label={formatMessage(messages.description)}
												labelClass={s.labelText}
											/>
										</Col>
									</Row>
								</div>
							</Col>
							<Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, s.btnPadding, 'textAlignLeftRTL', 'btnPaddingRTL')}>
								<Form.Group className={s.noMargin}>
									<Link to={"/siteadmin/promo-code/list"} className={cx(s.backBtnTwo, s.btnSecondaryBorder, s.linkBtnInline, 'backBtnTwoRTL', s.backBtnTwoTop)} >{formatMessage(messages.goBack)}</Link>
									<div className={s.displayInlineBlock}>
										<Loader
											type={"button"}
											label={promoId ? formatMessage(messages.update) : formatMessage(messages.submitButton)}
											show={loading}
											buttonType={'submit'}
											className={cx(s.button, s.btnPrimary)}
											disabled={submitting || loading}
											isSuffix={true}
										/>
									</div>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Container>
			</div>
		)
	}
}

PromoCodeForm = reduxForm({
	form: 'PromoCodeForm',
	onSubmit: submit,
	validate
})(PromoCodeForm);

const selector = formValueSelector('PromoCodeForm');

const mapState = state => ({
	currency: state.currency,
	promoId: selector(state, 'id'),
	promoType: selector(state, 'type'),
	promoCurrency: selector(state, 'currency'),
	expiryDate: selector(state, 'expiryDate'),
	promoCodeImage: selector(state, 'promoCodeImage'),
	loading: state.loader.AddPromoCode
});

const mapDispatch = {};


export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(PromoCodeForm)));