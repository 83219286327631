import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './ServiceFee.css';
import cx from 'classnames';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../components/Common/Loader/Loader';
import getServiceFee from './getServiceFee.graphql';
import ManageServiceFee from '../../../components/SiteAdmin/ManageServiceFee/ManageServiceFee';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

class ServiceFee extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        getServiceFee: PropTypes.shape({
            getServiceFee: PropTypes.array
        })
    };

    static defaultProps = {
        getServiceFee: {
            loading: false
        }
    }

    render() {
        const { title,serviceFee, serviceFee:{ getServiceFee, loading }} = this.props;
        
        return (
            <Loader type={"page"} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                       <div className={cx(s.heading, 'textAlignRightRTL')}>
                        <FormattedMessage {...messages.manageServiceHeading} />
                        </div>
                        <div>
                            <ManageServiceFee initialValues={getServiceFee}/>
                        </div>
                    </div>
                </div>
            </Loader>
        )
    }
}

export default compose(
    withStyles(s),
    graphql(getServiceFee, {
        name: 'serviceFee',
        options: {
            ssr: true,
            fetchPolicy: 'network-only'
        }
    })
)(ServiceFee);