import React from 'react';

import withStyles from 'isomorphic-style-loader/withStyles';
import s from './StoreBanner.css';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Row, Container, Col } from 'react-bootstrap';

import Link from '../../../Link/Link';

//Locale 
import messages from '../../../../locale/messages';
import { api, partnerHomepageUploadDir } from '../../../../config'

class StoreBanner extends React.Component {
    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this);
    }

    scrollTop() {
        window.scrollTo({
            top: screen.height,
            behavior: 'smooth'
        })
    }

    render() {
        const { getStoreData } = this.props
        return (
            <div className={cx(s.layout4Container)}>
                <div
                    className={cx(s.bannerBackgroundImage)}
                    style={{ backgroundImage: `url(${api.apiEndpoint + partnerHomepageUploadDir + getStoreData("bannerImage1")})` }}
                />
                <Container className={s.bannerLayoutContainer}>
                    <Row>
                        <div className={s.bannerDisplayGrid}>
                            <div className={s.searchFormContainer}>
                                <div className={cx(s.searchFormWrap, 'searchFormWrapRTL')}>
                                    <Col md={12} lg={12} sm={12} xs={12}>
                                        <h1>{getStoreData("bannerTitle1")}</h1>
                                        <p>{getStoreData("bannerContent1")}</p>
                                        <Link to='/store/signup' className={s.accountBtn}>
                                            <FormattedMessage {...messages.createAccout} />
                                        </Link>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}


export default withStyles(s)(StoreBanner);