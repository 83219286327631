import messages from '../../../locale/messages'

const validate = values => {

  const errors = {}

  if (!values.userAndroidVersion) {
    errors.userAndroidVersion = messages.required;
  } else if (values.userAndroidVersion && values.userAndroidVersion.toString().trim() === '') {
    errors.userAndroidVersion = messages.required;
  } else if (values.userAndroidVersion && !/^\d+(\.\d+){0,2}$/i.test(values.userAndroidVersion)) {
    errors.userAndroidVersion = messages.invalidVersionNumber;
  }

  if (!values.userIosVersion) {
    errors.userIosVersion = messages.required
  } else if (values.userIosVersion.trim() == '') {
    errors.userIosVersion = messages.required
  } else if (values.userIosVersion && !/^\d+(\.\d+){0,2}$/i.test(values.userIosVersion)) {
    errors.userIosVersion = messages.invalidVersionNumber;
  }
  if (!values.driverAndroidVersion) {
    errors.driverAndroidVersion = messages.required
  } else if (values.driverAndroidVersion.trim() == '') {
    errors.driverAndroidVersion = messages.required
  } else if (values.driverAndroidVersion && !/^\d+(\.\d+){0,2}$/i.test(values.driverAndroidVersion)) {
    errors.driverAndroidVersion = messages.invalidVersionNumber;
  }
  if (!values.driverIosVersion) {
    errors.driverIosVersion = messages.required
  } else if (values.driverIosVersion.trim() == '') {
    errors.driverIosVersion = messages.required
  } else if (values.driverIosVersion && !/^\d+(\.\d+){0,2}$/i.test(values.driverIosVersion)) {
    errors.driverIosVersion = messages.invalidVersionNumber;
  }

  if (!values.orderAcceptInterval) {
    errors.orderAcceptInterval = messages.required
  } else if (values.orderAcceptInterval.trim() == '') {
    errors.orderAcceptInterval = messages.required
  } else if (values.orderAcceptInterval && !/^\d+(\.\d+){0,2}$/i.test(values.orderAcceptInterval)) {
    errors.orderAcceptInterval = messages.invalidVersionNumber;
  }

  return errors
}

export default validate;
