import React from 'react';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './StorePartners.css'
import {
    Row,
    Col,
    Container
} from 'react-bootstrap';

//Images
import StorePartnerSection from './StorePartnerSection';

class StorePartners extends React.Component {

    render() {
        const { getStoreData } = this.props;
        let partners = [1, 2, 3];
        return (
            <div className={s.root}>
                <Container fluid>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
                            <div className={s.positionRelative}>
                                <div className={s.orderDisplayGrid}>
                                    {
                                        partners && partners.map((item, key) => {
                                            return <StorePartnerSection
                                                key={key}
                                                title={getStoreData('infoTitle'+ item)}
                                                Image={getStoreData('infoImage'+ item)}
                                                description={getStoreData('infoContent'+ item)}
                                            />
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}

export default injectIntl(withStyles(s)(StorePartners));