import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import s from './CitySection.css';
import { Container, Row, Col } from 'react-bootstrap';
import cx from 'classnames';

import HomeSlider from '../../Home/HomeSlider/HomeSlider';
import Loader from '../../Common/Loader';

import getActiveDeliveryTypes from './getActiveDeliveryTypes.graphql';
class CitySection extends React.Component {
  render() {
    const { getDeliveryCategories, getSettings } = this.props;
    const homepage = this.context;
    let loading, data;

    loading = !getDeliveryCategories.loading ? true : false;
    data = getDeliveryCategories?.getActiveDeliveryType?.results;

    if (!loading) return <Loader type="text" />;
    return (
      <>
        {
          data && data.length > 0 ? <div className={cx(s.cityBg, s.city)}>
            <Container className={s.cotainerWith}>
              <div className={s.cityText}>
                <h1>{getSettings("citySectionTitle1")}</h1>
                <div className={s.cityButton}>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className={s.noPadding}>
                      <HomeSlider data={data} />
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div> : <></>
        }
      </>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getActiveDeliveryTypes, {
    name: 'getDeliveryCategories',
    options: {
      ssr: true,
    },
  }),
)(CitySection);
