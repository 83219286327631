import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SiteSettings.css';
import SiteSettingsForm from '../../../components/SiteAdmin/SiteSettingsForm';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../components/Common/Loader/Loader';
//meassage
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import getSiteSettingsQuery from '../mobileSettings/getSiteSettings.graphql'

export class SiteSettings extends Component {

  render() {
    const { getSiteSettings: { loading, getSiteSettings, refetch } } = this.props;
    let siteSettingsCollection = {}

    if (!loading) {
      getSiteSettings && getSiteSettings.results && getSiteSettings.results.length > 0 && getSiteSettings.results.map((item) => {
        siteSettingsCollection[item.name] = item.value
      })
      return (
          <div className={'paddingBottomLg'}>
            <div className={cx(s.heading, 'textAlignRightRTL')}>
              <FormattedMessage {...messages.siteSettingsHeading} />
            </div>
            <div className={s.spaceTop5}>
              <SiteSettingsForm refetch={refetch} initialValues={siteSettingsCollection} />
            </div>
          </div>
      )
    }
    else {
      return <Loader type={"page"} show={loading} />
    }

  }
}


export default compose(
  withStyles(s),
  graphql(getSiteSettingsQuery, {
    name: 'getSiteSettings',
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: { type: 'site_Settings' },
      ssr: true,
    }),
  })
)(SiteSettings)
