import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import s from './StoreHome.css';
import { Container } from 'react-bootstrap';

import NewsBox from '../../../components/Shop/StoreHome/NewsBox/NewsBox';
import StorePartners from '../../../components/Shop/StoreHome/StorePartners/StorePartners';
import StoreSlider from '../../../components/Shop/StoreHome/StoreSlider/StoreSlider';
import StoreBanner from '../../../components/Shop/StoreHome/StoreBanner/StoreBanner';
import Loader from '../../../components/Common/Loader';

import getAllPartnerHomepage from './getAllPartnerHomepage.graphql';
class StoreHome extends React.Component {

  getStoreInformation = (name) => {
    const { getAllPartnerHomepage: { getAllPartnerHomepage } } = this.props;
    const setting = Array.isArray(getAllPartnerHomepage?.homePageData) && getAllPartnerHomepage.homePageData.find(item => item.name === name);
    return (setting && setting.value);
  }


  render() {
    const { getAllPartnerHomepage: { loading } } = this.props;
    return (
      <>
        {
          !loading ? <>
            <StoreBanner getStoreData={this.getStoreInformation} />
           <Container className={s.container}>
              <StorePartners getStoreData={this.getStoreInformation} />
            </Container>
            <NewsBox getStoreData={this.getStoreInformation} />
           <Container className={s.container}>
              <StoreSlider getStoreData={this.getStoreInformation} />
            </Container>
          </> : <Loader type={"text"} />
        }
      </>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllPartnerHomepage, {
    name: 'getAllPartnerHomepage',
    options: {
      ssr: true
    }
  })
)(StoreHome);
