import React from 'react';
import s from '../EditProfileForm.css';
import rs from '../../../storeCommon.css';
import cx from 'classnames';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
    Form,
    FormControl,
    Row,
    Col,
    Container
} from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';
import { change, formValueSelector } from 'redux-form';
import CountryList from '../../CountryList/CountryList';
import Loader from '../../../Common/Loader/Loader';
import sendVerification from '../../../../actions/shop/smsVerification/sendVerification';
import { openSmsVerificationModal, closeSmsVerificationModal } from '../../../../actions/shop/smsVerification/smsVerificationModaAction';

class AddPhoneNumberForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: '',
            phoneDialCode: '+1',
            phoneCountryCode: 'US',
            userId: '',
            loader: false,
            submitting: false,
            error: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async handleCountryChange(country) {

        const { change } = this.props;

        this.setState({
            phoneCountryCode: country.countryCode,
            phoneDialCode: country.dialCode
        });

        await change("EditProfileForm", 'phoneCountryCode', country.countryCode)
        await change("EditProfileForm", 'phoneDialCode', country.dialCode)
    };

    async handleSubmit() {
        const { sendVerification, userId } = this.props;
        const { formatMessage } = this.props.intl;
        const { phoneDialCode, phoneNumber } = this.state;
        let error = null, submitting = false;
        if (!phoneNumber || phoneNumber.trim() == '') {
            error = {
                phoneNumber: formatMessage(messages.required)
            }
        } else if (isNaN(phoneNumber)) {
            error = {
                phoneNumber: formatMessage(messages.required)
            }
        }

        submitting = (error === null) ? true : false;

        this.setState({
            submitting,
            error
        });

        if (error === null && submitting) {
           
            const { status, errorMessage } = await sendVerification(phoneNumber, phoneDialCode, userId);
            if (status != 200) {
                if (errorMessage) {
                    error = {
                        phoneNumber: errorMessage
                    };
                } else {
                    error = {
                        phoneNumber: 'Sorry, something went wrong. Please try again'
                    };
                }
            }
        }
        if (this.refs.addPhoneNumberForm) {
            this.setState({ submitting: false, error });
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { phoneCountryCode, phoneDialCode, phoneNumber, submitting, error } = this.state;
        return (
            <div ref="addPhoneNumberForm" className={rs.phoneNumberSection}>
            <Container fluid>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.noPadding, rs.space3)}>
                        {phoneCountryCode && <CountryList
                            defaultCountry={phoneCountryCode}
                            handleCountryChange={this.handleCountryChange}
                            className={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
                        />}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.noPadding, rs.space2)}>
                        <div className={s.countrySection}>
                            <span className={s.numberVtrSub}>{phoneDialCode}</span>
                        </div>
                        <div className={s.phoneNumberSection}>
                            <FormControl
                                name={'phoneNumber'}
                                value={phoneNumber}
                                placeholder={''}
                                type={'text'}
                                onChange={this.handleChange}
                                className={cx(rs.formControlInputStore, 'formControlInputStoreRTL')}
                            />
                        </div>
                        {error&& error.phoneNumber && <span className={cx(rs.errorMessage, 'errorMessageRTL')}>{error.phoneNumber}</span>}
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12} className={cx(rs.noPadding, rs.alignRightText)}>
                        <Loader
                            type={"button"}
                            label={formatMessage(messages.verify)}
                            show={submitting}
                            buttonType={'button'}
                            className={cx(rs.button, rs.btnPrimary, s.btnPadding)}
                            handleClick={this.handleSubmit}
                        />
                    </Col>
                </Row>
                </Container>
            </div>
        )
    }
}

const mapState = (state) => ({
    userId: state.user.id || state.account.data.id
})

const mapDispatch = {
    sendVerification,
    openSmsVerificationModal,
    closeSmsVerificationModal,
    change
}

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(AddPhoneNumberForm)));