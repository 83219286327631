import { toastr } from 'react-redux-toastr';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import history from '../../../history';
import getAllSubMenuQuery from './getAllSubMenu.graphql';
import updateItemMutation from './updateItem.graphql';

function addItem(values) {
	return async (dispatch, getState, { client }) => {
		try {
			dispatch(setLoaderStart('CommonSettings'));

			if (values.id) {
				const { data: { updateItem } } = await client.mutate({
					mutation: updateItemMutation,
					variables: values,
					refetchQueries: [{ query: getAllSubMenuQuery, variables: { currentPage: 1, searchList: '', menuId: values.menuId } }]
				});

				if (updateItem && updateItem.status == 200) {
					toastr.success('Success', 'Your changes has been updated successfully.');
					history.push('/store/menu/' + values.menuId + '/item');
				} else {
					toastr.error('Error', updateItem.errorMessage);
				}
			} else {
				const { data: { updateItem } } = await client.mutate({
					mutation: updateItemMutation,
					variables: values,
					refetchQueries: [{ query: getAllSubMenuQuery, variables: { currentPage: 1, menuId: values.menuId } }]
				});

				if (updateItem && updateItem.status == 200) {
					toastr.success('Success', 'A new item has been added successfully.');
					history.push('/store/menu/' + values.menuId + '/item');
				} else {
					toastr.error('Error', updateItem.errorMessage);
				}
			}

			dispatch(setLoaderComplete('CommonSettings'))

		} catch (error) {

			dispatch(setLoaderComplete('CommonSettings'))

		}
	}
}

export default addItem;