import React, { Component } from 'react'
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './AdminUser.css';
import adminUserQuery from './adminUserQuery.graphql';
import adminRolesQuery from './adminRolesQuery.graphql';
import AdminUserManagement from '../../../components/SiteAdmin/AdminUserManagement';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import Loader from '../../../components/Common/Loader/Loader';
import cx from 'classnames';
//meassage
import {  FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';

export class AdminUser extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getAllAdminRoles: PropTypes.array,
    })
  };

  static defaultProps = {
    data: {
      loading: true
    },
    adminRoles: {
      getAllAdminRoles: []
    }
  };

  render() {
    const { adminRoles: { getAdminRoles } } = this.props;
    const { adminUsers, adminUsers: { loading } } = this.props;

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
         <div className={cx(s.heading, 'textAlignRightRTL')}>
            <FormattedMessage {...messages.manageAdminUsers} />
            </div>
            <div className={s.paddingRoutesSection}>
              {
                !loading && <AdminUserManagement adminUsers={adminUsers} roles={getAdminRoles} />
              }
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(adminUserQuery, {
    name: 'adminUsers',
    options: (props) => ({
      variables: {
        searchList: props.searchList,
        currentPage: 1,
      },
      fetchPolicy: 'network-only',
      ssr: true
    })
  }),
  graphql(adminRolesQuery, {
    name: 'adminRoles',
    options: {
      fetchPolicy: 'network-only',
      ssr: true
    }
  })
)(AdminUser);
