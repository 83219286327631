import React, { Component } from 'react';
import s from './FooterSettingsForm.css';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import submit from './submit';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
//Style
import cx from 'classnames';
import {
    Form,
    Col,
    Card,
    Row,
    FormControl,
    FormGroup,
    Container
} from 'react-bootstrap';
import messages from '../../../locale/messages';
import validate from './validate';
import Dropzone from './Dropzone.js'
import Loader from '../../Common/Loader';
import { api, homepageUploadDir } from '../../../config';
import defaultIcon from '../../../../public/Icons/defaultIconAdmin.svg';

export class FooterSettingsForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxlength }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={cx(s.space5, 'positionRelative')}>
                <Form.Label className={'inputLabel'}>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} maxlength={maxlength}
                />
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <FormGroup className={cx(s.space5, 'positionRelative')}>
                <label className={'inputLabel'}>{label}</label>
                <FormControl
                    {...input}
                    className={className}
                    placeholder={label}
                    as="textarea"
                    rows="4"
                >
                    {children}
                </FormControl>
                {touched && error && <span className={cx(s.errorMessage, 'errorMessageRTL')}>{formatMessage(error)}</span>}
            </FormGroup>
        );
    }

    render() {
        const { handleSubmit, footerLogo1, loading, submitting } = this.props;
        const { formatMessage } = this.props.intl;
        return (

            <div>
                <Container fluid>
                    <h1 className={cx(s.heading, 'textAlignRightRTL')}>{formatMessage(messages.footerSectionSettings)}</h1>
                    <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12} className={s.blackCenter}>
                                <div className={s.blackCenterSection}>
                                    <Row>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className={s.space3}>
                                                <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                    <label>{formatMessage(messages.image)}</label><br />
                                                    {footerLogo1 &&
                                                        <div className={s.backgroundImg} style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir}medium_${footerLogo1})` }} />
                                                    }
                                                    {
                                                        !footerLogo1 &&
                                                        <div className={cx(s.backgroundImg, s.defaultIcon)} style={{ backgroundImage: `url(${defaultIcon})` }} />
                                                    }
                                                    <div>
                                                        <Dropzone
                                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                                            subTextClass={s.subText}
                                                            fieldName={'footerLogo1'}
                                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                            defaultMessage={formatMessage(messages.chooseFile)}
                                                            inputContainer={'.dzInputContainerHomeImage1'}
                                                            inputContainerClass={'dzInputContainerHomeImage1'}
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <Field name="footerTitle1" type="text" component={this.renderField} label={formatMessage(messages.footerTitleLabel)} maxlength={250} />
                                            <Field name="footerContent1" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.footerContentLabel)} />
                                            <Field name="footerLinkTitle" type="text" component={this.renderField} label={formatMessage(messages.footerURLTitleLabel)} maxlength={250} />
                                            <Field name="footerBottom" type="text" component={this.renderField} label={formatMessage(messages.copyRightsContentLabel)} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, s.bottomPadding, 'textAlignLeftRTL', 'bottomPaddingRTL')}>
                                <Form.Group className={s.noMargin}>
                                    <div className={s.displayInlineBlock}>
                                        <Loader
                                            type={"button"}
                                            label={formatMessage(messages.submitButton)}
                                            show={loading}
                                            buttonType={'submit'}
                                            className={cx(s.button, s.btnPrimary)}
                                            disabled={submitting || loading}
                                            isSuffix={true}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        )
    }
}

FooterSettingsForm = reduxForm({
    form: 'FooterSettingsForm',
    onSubmit: submit,
    validate
})(FooterSettingsForm);

const selector = formValueSelector('FooterSettingsForm')
const mapState = (state) => ({
    footerLogo1: selector(state, 'footerLogo1'),
    loading: state.loader.SignupSettingsForm
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(FooterSettingsForm)));