import messages from '../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.shopName) {
        errors.shopName = messages.required;
    } else if (!isNaN(values.shopName)) {
        errors.shopName = messages.required
    } else if (values.shopName.trim() == "") {
        errors.shopName = messages.required;
    } else if (values.shopName.length < 2) {
        errors.shopName = messages.required
    } else if (values.shopName.length > 35) {
        errors.shopName = messages.exceedLimit
    };

    if (!values.firstName) {
        errors.firstName = messages.required;
    } else if (!isNaN(values.firstName)) {
        errors.firstName = messages.required
    } else if (values.firstName.trim() == "") {
        errors.firstName = messages.required;
    } else if (values.firstName.length < 2) {
        errors.firstName = messages.required
    } else if (values.firstName.length > 35) {
        errors.firstName = messages.exceedLimit
    };

    if (!values.lastName) {
        errors.lastName = messages.required;
    } else if (!isNaN(values.lastName)) {
        errors.lastName = messages.required
    } else if (values.lastName.trim() == "") {
        errors.lastName = messages.required;
    } else if (values.lastName.length < 2) {
        errors.lastName = messages.required
    } else if (values.lastName.length > 35) {
        errors.lastName = messages.exceedLimit
    };

    if (!values.email) {
        errors.email = messages.required;
    } else if (!isNaN(values.email)) {
        errors.email = messages.required
    } else if (!values.email.includes('@')) {
        errors.email = messages.emailInvalid
    } else if (!/^(([^<>()[\]\\.,;.!-#$_&%*+/=?:{|}~-\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.email)) {
        errors.email = messages.emailInvalid
    } else if (values.email.length < 4) {
        errors.email = messages.required
    } else if (values.email.length > 35) {
        errors.email = messages.exceedLimit
    };

    if (!values.password) {
        errors.password = messages.required;
    } else if (values.password.trim() == "") {
        errors.password = messages.required;
    } else if (values.password.length < 8) {
        errors.password = messages.required
    } else if (values.password.length > 35) {
        errors.password = messages.exceedLimit
    };

    if (!values.confirmPassword) {
        errors.confirmPassword = messages.required;
    } else if (values.password != values.confirmPassword) {
        errors.confirmPassword = messages.passwordMatch;
    }

    if (!values.zipcode) {
        errors.zipcode = messages.required;
    } else if (values.zipcode.trim() == "") {
        errors.zipcode = messages.required;
    } else if (values.zipcode.length < 2) {
        errors.zipcode = messages.required
    } else if (values.zipcode.length > 35) {
        errors.zipcode = messages.exceedLimit
    };

    if (!values.phoneNumber) {
        errors.phoneNumber = messages.required;
    } else if (isNaN(values.phoneNumber)) {
        errors.phoneNumber = messages.required;
    } else if (values.phoneNumber.trim() == "") {
        errors.phoneNumber = messages.required;
    } else if (values.phoneNumber.length < 4) {
        errors.phoneNumber = messages.required
    } else if (values.phoneNumber.length > 35) {
        errors.phoneNumber = messages.exceedLimit
    };

    if (!values.categoryType) {
        errors.categoryType = messages.required;
    }

    if (!values.deliveryType) {
        errors.deliveryType = messages.required;
    }

    if (!values.address) {
        errors.address = messages.required;
    }


    return errors;
};

export default validate;