import React from 'react';
import AdminLayout from '../../../../components/Layout/AdminLayout'
import AddDeliveryModule from './AddDeliveryModule'
import { restrictUrls } from '../../../../helpers/adminPrivileges';
import messages from '../../../../locale/messages';

function action({ store, intl }) {
	const title = intl.formatMessage(messages.addDeliveryModule);

	let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
	let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
	let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;
	if (!isAdminAuthenticated) {
		return { redirect: '/siteadmin/login' }
	}

	// Admin restriction
	if (!restrictUrls('/siteadmin/delivery-type', adminPrivileges, privileges)) {
		return { redirect: '/siteadmin' };
	}

	return {
		title,
		component: (
			<AdminLayout>
				<AddDeliveryModule title={title} />
			</AdminLayout>
		)
	}
}

export default action;