import gql from 'graphql-tag';
import {
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_START,
    CATEGORY_DELETE_ERROR
} from '../../../constants';
import { toastr } from 'react-redux-toastr';

export default function deleteCategory(id) {
    return async (dispatch, getState, { client }) => {
        dispatch({
            type: CATEGORY_DELETE_START
        });

        try {
            let errorMessage;

            let mutation = gql`
            mutation deleteCategory($id: Int) {
                deleteCategory(id: $id) {
                  status
                  errorMessage
                }
              }
            `;

            const { data } = await client.mutate({
                mutation,
                variables : {
                    id
                }
            });
            
            if (data && data.deleteCategory && data.deleteCategory.status === 200) {
                dispatch({
                    type: CATEGORY_DELETE_SUCCESS
                });

                toastr.success('Success', `The category has been deleted.`);

                return {
                    status: 200
                };
            } else {
                dispatch({
                    type: CATEGORY_DELETE_ERROR
                });

                errorMessage = (data && data.deleteCategory && data.deleteCategory.errorMessage) || "Oops! Something went wrong. Please try again.";
                toastr.error('Error!', errorMessage);
                return {
                    status: 400
                };
            }
        } catch(err) {
            toastr.error('Error!', err);
            dispatch({
                type: CATEGORY_DELETE_ERROR
            });
            return {
                status: 400
            };
        }

    }
};