exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Comfortaa', cursive;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1170px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #F86540;\n  --btn-boxShadow-bg: #F86540;\n  --btn-bordercolor-bg: #F86540;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #1C0303;\n  --btn-secondary-bg: #F86540;\n  --title-color: #212121;\n  --content-color: #212121;\n\n}\n._1avl7 {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n._3YPN- {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 1170px;\n  max-width: var(--max-content-width);\n}\n.-EKGZ {\n  margin: 0 0 32px;\n  margin: 0 0 2rem;\n}\n._3GwAp {\n  font-size: 24px;\n  font-size: 1.5rem;\n}\n._107uo h1, ._107uo h2, ._107uo h3, ._107uo h4, ._107uo h5, ._107uo h6 {\n  font-size: 18px;\n  font-size: 1.125rem;\n}\n._107uo pre {\n  white-space: pre-wrap;\n  font-size: 14px;\n  font-size: 0.875rem;\n}\n._107uo img {\n  max-width: 100%;\n}\n._2WUi2 {\n  font-size: 0.8em;\n  color: #777;\n}\n@media screen and (max-width: 767px) {\n  .YWlIy {\n    overflow: hidden;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "_1avl7",
	"container": "_3YPN-",
	"newsItem": "-EKGZ",
	"newsTitle": "_3GwAp",
	"newsDesc": "_107uo",
	"publishedDate": "_2WUi2",
	"overFlowHidden": "YWlIy"
};