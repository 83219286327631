
import React from 'react';
import Signup from './Signup';
import Toaster from '../../../components/Toaster/Toaster';

import messages from '../../../locale/messages';

function action({ intl }) {
  const title = intl.formatMessage(messages.signup);

  return {
    title,
    component: (
      <div>
        <Toaster />
        <Signup title={title} />
      </div>
    ),
  };
};

export default action;
